/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";


import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import RouteAnimate from '../tptlogin/routeAnimate';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Consignmentforceclose from '../manage/consignmentforceclose';
//import Grid from '../layouts/gridnosidebarComponent';
import DateFormater from '../layouts/dateComponent';
import DrawMap from '../common/drawmap';
import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYYHHMM,
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault,
	getDDMMYYYYHHMM,
	getHyphenDDMMMYYYY
} from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ExportPDF from "./exportpdf"
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import EditActions from '../manage/EditActionsComponent';
import TptMSILTripsCounters from './tptmsiltripscounters';
import reorder from 'material-ui/svg-icons/action/reorder';
import ForceCloseSideBar from "../layouts/forceclosuresidebarcomponent";
import CSVFileValidator from 'csv-file-validator';
$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
var geoFencingArea;
var containerslist = [];
export default class TPTTrips extends Component {

	constructor(props) {
		super(props);
		this.displayData = [];
		this.state = {
			deptcode: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			eventAction: null,
			forceCloseRowNode: "",
			allRowData: [],
			activeFilter: null,
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			sliderCommentTranslate: "",
			tabsliderTranslate: '',
			timelineinfo: '',
			consignment_code: '',
			sliderSegmentTranslate: "",
			mapinfo: '',
			commentsRowData: [],
			dealer: '',
			maptruckno: '',
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			overly: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			basicTitle: '',
			rownode: '',
			leg_no: '',
			basicType: "default",
			modules: AllModules,
			filterstatess: {},
			columnsdatas: {},
			rowgroupdcols: [],
			valuecolumns: [],
			activeconsignemnt: [],
			activetrucks: [],
			transitdelays: [],
			gpsdatana: [],
			overspeedtrucks: [],
			nightdrive: [],
			open: false,
			openforceclose: false,
			pivotmodeis: false,
			pivotcols: [],
			defTransitCoords: '',
			routeTruck: {},
			sidebarSubHeader: '',
			googelRoutes: '',
			plants: [],
			consgmntdata: [],
			transitdata: [],
			gpsntavadata: [],
			overspeed: [],
			nightdriving: [],
			loadingdelay: [],
			stoppages: [],
			critical: [],
			probaccdnt: [],
			counters_data: [],
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				pivot: true,
				enableValue: true,
				enableRowGroup: true,
			},
			rowData: [],
			maprowData: null,
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				consignmentActions: ConsignmentActions,
				editActions: EditActions,
				consignmentforceclose: Consignmentforceclose
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			triptype: { "value": 'all', "label": 'All' },
			tripvalue: '',
			consigner: [{ "value": 'all', "label": 'All' }],
			originalData: [],
			isCounterDisplay: 1,
			screenpage: '',
			pagetitle: 'Trips Data',
			countersjson: '',
			originalcountersjson: '',
			consigneecoords: '',
			consigner_coordinates: '',
			containerslist: [],
			forceclosedata: '',
			reached_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			reachedhh: '',
			reachedmm: '',
			reachedss: '00',
			left_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			lefthh: '',
			leftmm: '',
			leftss: '00',
			invoice_time: '',
			invoicehh: '',
			invoicemm: '',
			segementConsignmentCode: "",
			counter_screen: "",
			invoicess: '00',
			reasonforceclose: '',
			sliderTranslatesidebar: '',
			consignment_details: '',
			startDatetime: '',
			endDatetime: '',
			tolls: [],
			excelStyles: [
				{
					id: "dateFormat",
					dataType: 'dateTime',
					numberFormat: { format: "yyyy-mm-dd" }
				}
			],
			getRowHeight: function (params) {
				if (typeof params.data.dct_comment != 'undefined') {
					//console.log("pars ", params.data.dct_comment.length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.dct_comment.length / 50))
					);
				}
				if (typeof params.data.vin_list != 'undefined') {
					//console.log("pars ", params.data.vin_list.toString().length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.vin_list.toString().length / 50))
					);
				}
				else if (typeof params.data.force_closure_reason != 'undefined') {
					//console.log("pars ", params.data.dct_comment.length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.force_closure_reason.length / 50))
					);
				}
				else {
					return 30;
				}
			},
			defaultsdate: '',
			defaultedate: '',
			movementtype: { "value": "all", "label": "All" },
			usergridstate: [],
			screenurl: "",
			screentitle: "",
			showumncr: 0,
			showummove: 0,
			usermanualncrmodal: false,
			usermanualmovemodal: false,
			slideuploadeway: "",
			cancelled_data: [],
			pod_received_data: [],
			trip_closed_by_pod: [],
			transporterTrucks: [],
			tpttrucks: [],
			adaniCheck: 0,
			addressColumn: 300,
			pdfRowData: [],
			pdfColumns: [],
			msilCounters: {
				"transit_delay": [],
				"nightdriving_exception": [],
				"no_gps_data": [],
				"overspeeding_exception": [],
				"loading_delay_exception": [],
				"enroute_exception": [],
				"is_critical": [],
				"probable_accident": []
			},
			oems: [],
			oem: { "value": "", "label": "OEM" },
			dealers: [],
			plantlocations: [],
			dealer: { "value": "", label: "Dealer" },
			city: { "value": "", label: "City" },
			transittype: { "value": "Road", "label": "Road" },
			transit_distance: "",
			transit_time: "",
			truck_no: "",
			trip_no: "",
			vin_no: "",
			invoice_date: "",
			gate_in_time: "",
			gate_out_time: "",
			selected_eway_bill_date: "",
			quantity: "",
			fleet_amount: "",
			distance_in_km: "",
			expected_eta: "",
			drivers: [],
			driver: { "value": "", "label": "Driver" },
			sliderRso: "",
			sliderUpdate: "",
			tcode: "",
			cities: [],
			filteredDataTrip: [],
			alltrucks: [],
			showTrucksListTrip: "show-n",
			localtransporter: [],
			selectedRowNode: "",
			sliderForceCloseTranslate: "",
			csvcontent: [],
			't329Check': 0,
			sliderBulkForceClose: '',
			csvcontentBulk: '',
			file1: '',
			selectedService: [],
			servicelocationvalues: [],
			servicelocation: [],
			tickets_no: [],
			expected_date_of_service: [],
			transporters_name: [],
			ticket_remarks: [],
			service_remarks: [],
			service_location_list: [],
			transporter_list: [],
			selectedStatus: [],
			update_remarks: [],
			expected_closure_date: [],
			mobile_number: [],
			startDate: moment.parseZone(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
			endDate: moment.parseZone(new Date()).format("YYYY-MM-DD"),
			truck_options: [],
			vin_options: [],
			sliderForIVCLogs: "",
			vin_number: ""
		}
	}

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentDidMount() {
		loadDateTimeScript()
		redirectURL.post("/dashboard/tripDataOptions").then(async (response) => {
			var trucks = response.data.trucks;
			var vin_numbers = response.data.vin_numbers;
			var truck_options = trucks.map(item => {
				return { label: item, value: item }
			})
			var vin_options = vin_numbers.map(item => {
				return { label: item, value: item }
			})
			this.setState({
				truck_options,
				vin_options
			});

		})
		this.onLoadPageData();

	}
	getUserGridState() {
		redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			.then(async (resp) => {
				// console.log("resp ", resp)
				this.setState({
					usergridstate: resp.data
				});
				this.restoreGridStates();
			})
	}

	onLoadPageData = () => {
		const { startDate, endDate, vin_no, truck_no } = this.state
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m'
		})
		var params = {
			from_date: startDate,
			to_date: endDate,
			vin_no: vin_no,
			truck_no: truck_no
		}
		redirectURL.post("/dashboard/tptTripsData", params).then(async (response) => {
			var records = response.data.tripsData;
			console.log(records, "tripsdata");
			this.setState({
				rowData: records,
				loadshow: 'show-n',
				overly: 'show-n'
			});

		})
	}

	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

		if (this.state.deptcode == 'LOG-PRT') {
			console.log("Step 1 ")
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception", "loading_delay_exception", "unloading_delay_exception", "trip_type"], false);

			this.gridColumnApi.setColumnsVisible(["trip_type"], false);
		}
		else if (this.state.deptcode == 'SNDG') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
			//this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], true);
			this.gridColumnApi.setColumnsVisible(["consignee_city"], true);
			this.gridColumnApi.setColumnsVisible(["trip_type"], false);
		}
		else if (this.state.deptcode == 'LOG-TNP') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
			//this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], true);
			this.gridColumnApi.setColumnsVisible(["consignee_city"], true);
			this.gridColumnApi.setColumnsVisible(["trip_type"], true);
		}

		else { }



	};

	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);



	}

	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderForIVCLogs: "",
		});

	}

	onRowSelection(event) {
		var containerslist = [];
		var rwCount = event.api.getSelectedNodes();
		var rowCount = event.data

		rwCount.map((item) => {
			containerslist.push(item.data.truck_no)
		});

		this.setState({
			containerslist: containerslist
		})

	}

	changeHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	onClickSaveGridState() {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();

		let screenpage = 'MSIL Trips';



		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: window.sortState,
			gridcolfilter: window.filterState,
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
			.then((response) => {
				//console.log("State response ",response.data)
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				})
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}

	restoreGridStates() {
		///console.log("this.state.usergridstate ", this.state.usergridstate)
		try {
			if (this.state.usergridstate.length > 0) {
				var windowstates = this.state.usergridstate;
				// console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

			}
		}
		catch (e) { }
	}

	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully reset default grid layout",
					basicType: "success"
				})
			})

	};

	onCellClicked(e) {
		if (e.colDef.field == "logs") {

			this.setState({
				loadshow: "show-m",
				overly: "show-m"
			})
			redirectURL.post("/dashboard/getLogsData", { vin_no: e.data.vin_number }).then((response) => {
				var records = response.data.logsData
				if (records.length > 0) {
					var records = records.sort(GetSortDescOrder("booking_date"))
					this.setState({
						logs_data: records,
						vin_number: e.data.vin_number,
						sliderForIVCLogs: "slider-translate-60p",
						loadshow: "show-n",
					})

				} else {
					this.setState({
						logs_data: [],
						sliderForIVCLogs: "slider-translate-60p",
						loadshow: "show-n",
					})
				}
			})
		}
	}

	handlerStartDateTime = (date) => {
		const selectedDate = moment(date).format('YYYY-MM-DD');

		if (this.state.endDate && moment(selectedDate).isAfter(this.state.endDate)) {
			this.setState({
				show: true,
				basicTitle: 'Booking from date should be less than Booking to date',
				basicType: "danger",
				loadshow: 'show-n',
				startDate: null, // Reset the startDate to null
			});
		} else {
			this.setState({
				startDate: selectedDate // Store the raw date object in state
			});
		}
	};

	handlerEndDateTime = (date) => {
		const selectedDate = moment(date).format('YYYY-MM-DD');

		if (this.state.startDate && moment(selectedDate).isBefore(this.state.startDate)) {
			this.setState({
				show: true,
				basicTitle: 'Booking to date should be greater than Booking from date',
				basicType: "danger",
				loadshow: 'show-n',
				endDate: null, // Reset the endDate to null
			});
		} else {
			this.setState({
				endDate: selectedDate // Store the raw date object in state
			});
		}
	};

	onSubmitTripData = (event) => {
		event.preventDefault()
		console.log("check_entered_or_not")
		const { startDate, endDate } = this.state
		if (["", undefined, null].includes(startDate) && ["", undefined, null].includes(endDate)) {
			this.setState({
				show: true,
				basicType: "danger",
				basicTitle: "Select Booking From Date and To Date"
			})
			return;
		}
		else if (["", undefined, null].includes(startDate)) {
			this.setState({
				show: true,
				basicType: "danger",
				basicTitle: "Select Booking From Date"
			})
			return;
		}
		else if (["", undefined, null].includes(endDate)) {
			this.setState({
				show: true,
				basicType: "danger",
				basicTitle: "Select Booking To Date"
			})
			return;
		}
		this.onLoadPageData();
	}

	resetFilters = async (event) => {
		await this.setState({
			truck_no: "",
			vin_no: "",
			startDate: moment.parseZone(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
			endDate: moment.parseZone(new Date()).format("YYYY-MM-DD"),
		})
		await this.onLoadPageData();
	}

	render() {

		var columnwithDefs = [
			{
				headerName: "Truck No",
				field: "truck_no",
				width: 120,
				// pinned : "left",
				filter: "agSetColumnFilter",
				resizable: true
			},
			{
				headerName: "Logs",
				field: "logs",
				pinned: 'left',
				resizable: true,
				width: 100,
				filter: false,
				cellRenderer: function (params) {
					var htmloption = '<button style="padding:2% 10% 5% 10%" class="btn btn-warning" title=" Logs">View</button>'
					return htmloption;
				},
				editable: false
			},
			{
				headerName: "Invoice Number",
				field: "invoice_number",
				width: 150,
				// pinned : "left",
				filter: "agSetColumnFilter",
				resizable: true
			},
			{
				headerName: "Vin Number",
				field: "vin_number",
				width: 150,
				// pinned : "left",
				filter: "agSetColumnFilter",
				resizable: true
			},
			{
				headerName: "Booking ID",
				field: "booking_id",
				width: 120,
				// pinned : "left",
				filter: "agSetColumnFilter",
				resizable: true
			},
			{
				headerName: "Booking Date",
				field: "booking_date",
				width: 150,
				editable: false,
				filter: "agSetColumnFilter", resizable: true,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.booking_date)
				}
			},
			{
				headerName: "Trip Status",
				field: "status",
				width: 120,
				// pinned : "left",
				filter: "agSetColumnFilter",
				resizable: true
			},
			{
				headerName: "Trip Start Time",
				field: "trip_start_time",
				width: 140,
				// pinned : "left",
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.trip_start_time)
				}
			},
			{
				headerName: "Trip End Time",
				field: "trip_end_time",
				width: 140,
				// pinned : "left",
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.trip_end_time)
				}
			},
			{
				headerName: "Created Date",
				field: "created_date",
				width: 160,
				// pinned : "left",
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.created_date)
				}
			},
			{
				headerName: "Modified Date",
				field: "modified_date",
				width: 140,
				// pinned : "left",
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
				}
			},

		];

		var columnwithdefsForLogs = [
			{
				headerName: "Truck No",
				field: "truck_no",
				width: 120,
				filter: "agSetColumnFilter",
				resizable: true
			},
			{
				headerName: "Vin Number",
				field: "vin_no",
				width: 150,
				filter: "agSetColumnFilter",
				resizable: true
			},
			{
				headerName: "Booking ID",
				field: "booking_id",
				width: 120,
				filter: "agSetColumnFilter",
				resizable: true
			},
			{
				headerName: "Booking Date",
				field: "booking_date",
				width: 150,
				editable: false,
				filter: "agSetColumnFilter", resizable: true,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.booking_date)
				}
			},
			{
				headerName: "Trip Status",
				field: "status",
				width: 120,
				filter: "agSetColumnFilter",
				resizable: true
			},
			{
				headerName: "Trip Start Time",
				field: "trip_start_date",
				width: 140,
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.trip_start_date)
				}
			},
			{
				headerName: "Trip End Time",
				field: "trip_end_date",
				width: 140,
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.trip_end_date)
				}
			},
			{
				headerName: "Created Date",
				field: "created_date",
				width: 160,
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.created_date)
				}
			}
		];

		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}

				<div className="row">
					<div className='col-xl-12 col-md-12'>
						<div className="card card-body pb-0">
							<div className="row">
								<div className="col-2">
									<label>Booking From Date </label>
									<Datetime value={this.state.startDate}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{ placeholder: 'From Date', name: 'startDate', autoComplete: 'off', readOnly: true }}
										dateFormat="YYYY-MM-DD"
										timeFormat={false}
										name="fromDate"
										onChange={this.handlerStartDateTime} />
								</div>
								<div className="col-2">
									<label>Booking To Date </label>
									<Datetime
										value={this.state.endDate}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{ placeholder: 'To Date', name: 'toDate', autoComplete: 'off', readOnly: true }}
										dateFormat="YYYY-MM-DD"
										timeFormat={false}
										name="toDate"
										onChange={this.handlerEndDateTime} />
								</div>
								<div className="col-2">
									<label>Truck No   </label>
									<input
										type="text"
										placeholder="Truck No"
										className="form-control"
										value={this.state.truck_no}
										onChange={(e) => this.setState({ truck_no : e.target.value })}
									/>
									{/* <Select
										className="border-radius-0"
										isMulti={false}
										closeMenuOnSelect={true}
										id="selectTruckNo"
										style={{ borderRadius: "0px" }}
										value={this.state.truck_no}
										onChange={(e) => { this.setState({ truck_no: e }) }}
										options={this.state.truck_options}
									/> */}
								</div>
								<div className="col-2">
									<label>Vin No   </label>
									<input
										type="text"
										placeholder="Vin No"
										className="form-control"
										value={this.state.vin_no}
										onChange={(e) => this.setState({ vin_no : e.target.value })}
									/>
									{/* <Select
										className="border-radius-0"
										isMulti={false}
										closeMenuOnSelect={true}
										id="selectTruckNo"
										style={{ borderRadius: "0px" }}
										value={this.state.vin_no}
										onChange={(e) => { this.setState({ vin_no: e }) }}
										options={this.state.vin_options}
									/> */}
								</div>
								<div className="form-group"   >
									<button className="btn btn-success mt-30p" type="submit" onClick={this.onSubmitTripData.bind(this)}>Submit</button>
								</div>
								<div className="form-group"   >
									<button className="btn btn-info  mt-30p ml-30p" type="reset" onClick={this.resetFilters.bind(this)}>Reset</button>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
								</h5>
							</div>
							<div className="card-body pt-10px" >
								<div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={false}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										stopEditingWhenGridLosesFocus={true}
										enableCellChangeFlash={false}
										rowClassRules={this.state.rowClassRules}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										rowSelection={this.state.rowSelection}
										onRowSelected={this.onRowSelection.bind(this)}
										suppressRowClickSelection={true}
										overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
										excelStyles={this.state.excelStyles}
										onCellClicked={this.onCellClicked.bind(this)}
									/>

								</div>
							</div>
							<div className={"slide-r " + (this.state.sliderForIVCLogs)} style={{ overflow: "auto" }}>
								<div className="slide-r-title">
									<h4>
										Trips Logs - {this.state.vin_number}
										<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

									</h4>
								</div>
								<div className="slide-r-body" style={{ position: "relative" }}>
									<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
										<div id="myGrid" style={{ height: "100vh", width: "100%", padding: "15px" }} className="ag-theme-balham">
											<AgGridReact
												modules={this.state.modules}
												columnDefs={columnwithdefsForLogs}
												defaultColDef={this.state.defaultColDef}
												rowData={this.state.logs_data}
												enableCharts={false}
												paginationPageSize={this.state.paginationPageSize}
												statusBar={this.state.statusBar}
												sideBar={this.state.sideBar}
												pagination={true}
												onGridReady={this.onGridReady}
												onGridState={this.onGridState}
												deleteRowHandler={this.deleteRowHandler}
												frameworkComponents={this.state.frameworkComponents}
												detailCellRendererParams={this.state.detailCellRendererParams}
												stopEditingWhenGridLosesFocus={true}
												enableCellChangeFlash={true}
												suppressCellFlash={true}
												gridOptions={{
													context: { componentParent: this }
												}}
												masterDetail={true}
												onCellClicked={this.onCellClicked}
											/>
										</div>

									</div>
								</div>
							</div>

						</div>
					</div>

				</div>

				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
			</div>

		);
	}
}

$(document).ready(function () {
	$(document).on("click", ".tptdropdown-truck-no", function () {
		var vals = $(this).text();
		$("#inputTruckticket").val(vals);
		$(".trucks-dropdown").removeClass("show-m");
		$(".trucks-dropdown").addClass("show-n");
	});

	$("body").on("click", function (e) {
		var container = $("#inputTruckticket");
		if (!container.is(e.target)) {
			try {
				if ($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown") {
					$(".trucks-dropdown").removeClass("show-m");
					$(".trucks-dropdown").addClass("show-n");
				}
			} catch (e) { }

		}


	})
})

function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
function timeConvert(n) {
	// var num = n/1000;
	// var hours = (num / (3600*24));
	// var rhours = Math.floor(hours);
	// var minutes = (n) / (60*60);
	// var rminutes = Math.round(minutes);

	var diffMs = n;
	var diffDays = Math.floor(diffMs / 86400000); // days
	var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


	return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
	var timeinfo = new window.google.maps.InfoWindow({
		content: "Time at this location: " + timedet
	});

	marker.addListener('click', function () {

		if (currentinfowindow != null) {
			currentinfowindow.close();
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}
		else {
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}

	});
}

function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}
function CheckForHtmlTag() {
	var rs = document.getElementById("invalid_reason");
	var reg = /<(.|\n)*?>/g;
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";
		rs.value = ''
		//alert();
		return 1;
	}
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}

function loadDateTimeScript() {
	$('.datetimepicker_mask').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i',
		onShow: false
	});
	$('.datetimepicker_date').datetimepicker({
		mask: '39-19-9999',
		format: 'd-m-Y',
		timepicker: false,
		onShow: false
	});
	var index = window.document.getElementsByTagName("script")[1]
	var script = window.document.createElement("script")
	script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
	return function (a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		} else if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	}
}


function GetSortAscOrder(prop) {
	return function (a, b) {
		if (a[prop] > b[prop]) {
			return 1;
		} else if (a[prop] < b[prop]) {
			return -1;
		}
		return 0;
	}
}

function secondsToHM(seconds) {
	seconds = Number(seconds);
	// var d = Math.floor(seconds / (3600*24));
	// var h = Math.floor(seconds % (3600*24) / 3600);
	// var m = Math.floor(seconds % 3600 / 60);
	// var s = Math.floor(seconds % 60);

	// var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
	// var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
	// var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
	// var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
	// return dDisplay + hDisplay + mDisplay;

	var hh = Math.floor((seconds / 3600));
	var hrr = Math.floor(seconds - (hh * 3600));
	var mm = Math.floor(hrr / 60)
	if (mm < 10) {
		var mins = "0" + mm;
	}
	else {
		var mins = mm;
	}

	if (hh < 10) {
		var hrs = "0" + hh;
	}
	else {
		var hrs = hh;
	}
	return hrs + ":" + mins;
}