/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import DateFormater from '../layouts/dateComponent';
import DrawMap from '../common/drawmap';
import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYYHHMM,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault,
    getDDMMYYYYHHMM,
    getHyphenDDMMMYYYY
} from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class TPTTrips extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            loadshow: 'show-n',
            overly: 'show-n',
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                //editable: true,
                autoHeight: true,
                resizable: true,
                width: 130
            },
            pagetitle: "Base Routes",
            rowData: [],
            headerHeight: 60,
            rowHeight: 50,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            griddata: [],
            routeRowData: {},
            rowSelection: "single",
            context: { componentParent: this },
            // frameworkComponents: {
            //     MyHeaderComponent: MyHeaderComponent,
            //     Showtolls: Showtolls,
            //     ShowTollsList: ShowTollsList,
            //     ApprovalDeviationRoute: ApprovalDeviationRoute,
            //     DateDeviationRoute: DateDeviationRoute,
            //     ViewDeviationRouteData: ViewDeviationRouteData,
            //     ShowChokePoints: ShowChokePoints
            // },
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize: 50,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,
            originaldata: "",
            orignalresponse: "",
            mapinfo: "",
            originoption: { "value": "", "label": "Select Origin" },
            destinationoption: { "value": "", "label": "Select Destination" },
            destinationarr: [],
            selectedorigin: '',
            selecteddestination: '',
            columnDefs: [],
            checkedinputs: [],
            uncheckedinputs: [],
            noofroutes: 3,
            tolls: [],
            tollshow: false,
            plantcoords: "",
            plantname: "",
            sliderTranslate: "",
            viewtolls: [],
            tollrowid: "",
            routeid: "",
            routeapprovestatus: "",
            open: false,
            route_reason: "",
            gridapi: [],
            viewroutedata: [],
            sliderRouteTranslate: "",
            sliderRouteTranslate1: "",
            sliderChokeTranslate: "",
            chokepoints: [],
            chokepointdata: [],
            originoptions: [],
            btn1: "btn-default",
            btn2: "btn-default",
            btn3: "btn-default",
            btn4: "btn-default",
            complete_analysis: [],
            base_routes_anlsysis: [],
            transporters_analysis: [],
            complete_trips: [],
            breaks_list: [],
            route_coords: [],
            gridHeight: "160px",
            "mapfor": "",
            destinationOptions: [],
            defaultTrips: [],
            selectedRoutes: [],
            dealersList: [],
            route_coords1: [],
            breaks_list1: [],
            tripsGridApi: {},
            defaultCompleteTrips: [],
            hideSummaryTab: false,
            lspusers_options: [],
            truck_types: [],
            period_types: [],
            selected_provider: { "label": "ALL", "value": "ALL" },
            selected_truck_type: { "label": "ALL", "value": "ALL" },
            selected_period_type: { "label": "ALL", "value": "ALL" },
            ignoredRouteSubmit: 0,
            ignoredRoutes: [],
            base_route: [],
            destination: "",
            source: "",
            source_options: [],
            destination_options: [],
            from_date: "",
            to_date: "",
            row_destination: "",
            row_source: "",
        };
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    componentDidMount() {
        loadDateTimeScript()
        redirectURL.post("/dashboard/baseRouteFilterOptions").then(async (response) => {
            var trucks = response.data.trucks;
            var vin_numbers = response.data.vin_numbers;
            var source_options = trucks.map(item => {
                return { label: item, value: item }
            })
            var destination_options = vin_numbers.map(item => {
                return { label: item, value: item }
            })
            this.setState({
                source_options,
                destination_options
            });

        })
        // this.onLoadPageData();

    }

    getUserGridState() {
        redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
            .then(async (resp) => {
                // console.log("resp ", resp)
                this.setState({
                    usergridstate: resp.data
                });
                this.restoreGridStates();
            })
    }

    onLoadPageData = () => {
        const { source, destination } = this.state
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m'
        })
        var params = {
            destination: destination ? destination.value : "",
            source: source ? source.value : ""
        }
        redirectURL.post("/dashboard/getBaseRouteData", params).then(async (response) => {
            var records = response.data.baseRouteData;
            console.log(records, "baseRouteData");
            this.setState({
                rowData: records,
                loadshow: 'show-n',
                overly: 'show-n'
            });

        })
    }

    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;

        if (this.state.deptcode == 'LOG-PRT') {
            console.log("Step 1 ")
            this.gridColumnApi.setColumnsVisible(["nightdriving_exception", "loading_delay_exception", "unloading_delay_exception", "trip_type"], false);

            this.gridColumnApi.setColumnsVisible(["trip_type"], false);
        }
        else if (this.state.deptcode == 'SNDG') {
            this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
            this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
            //this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], true);
            this.gridColumnApi.setColumnsVisible(["consignee_city"], true);
            this.gridColumnApi.setColumnsVisible(["trip_type"], false);
        }
        else if (this.state.deptcode == 'LOG-TNP') {
            this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
            this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
            //this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], true);
            this.gridColumnApi.setColumnsVisible(["consignee_city"], true);
            this.gridColumnApi.setColumnsVisible(["trip_type"], true);
        }

        else { }



    };

    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);



    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderForIVCLogs: "",
            sliderRouteTranslate1: ""
        });

    }

    onRowSelection(event) {
        var containerslist = [];
        var rwCount = event.api.getSelectedNodes();
        var rowCount = event.data

        rwCount.map((item) => {
            containerslist.push(item.data.source)
        });

        this.setState({
            containerslist: containerslist
        })

    }

    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }

    onClickSaveGridState() {
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Save Grid Layout",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        window.colState = this.gridColumnApi.getColumnState();
        window.groupState = this.gridColumnApi.getColumnGroupState();
        window.sortState = this.gridApi.getSortModel();
        window.filterState = this.gridApi.getFilterModel();

        let screenpage = 'MSIL Trips';



        let reqparams = {
            gridcolumns: window.colState,
            gridgroup: window.groupState,
            gridcolsort: window.sortState,
            gridcolfilter: window.filterState,
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname,
            screentitle: screenpage
        }
        //console.log("reqparams ", reqparams)
        redirectURL.post("/consignments/saveGridStates", reqparams)
            .then((response) => {
                //console.log("State response ",response.data)
                this.setState({
                    show: true,
                    basicTitle: "Successfully saved grid layout",
                    basicType: "success",
                    screenurl: window.location.pathname,
                    screentitle: screenpage
                })
            })
            .catch(function (e) {
                console.log("Error ", e)
            })
    }

    restoreGridStates() {
        ///console.log("this.state.usergridstate ", this.state.usergridstate)
        try {
            if (this.state.usergridstate.length > 0) {
                var windowstates = this.state.usergridstate;
                // console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
                this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
                this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
                this.gridApi.setSortModel(windowstates[0].gridcolsort);
                this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

            }
        }
        catch (e) { }
    }

    resetState = () => {
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Reset Grid Layout",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        this.gridColumnApi.resetColumnState();
        this.gridColumnApi.resetColumnGroupState();
        this.gridApi.setSortModel(null);
        this.gridApi.setFilterModel(null);
        //console.log('column state reset');
        var reqparams = {
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname,
            screentitle: this.state.screenpage
        }

        redirectURL.post("/consignments/removeUserGridState", reqparams)
            .then((response) => {
                this.setState({
                    show: true,
                    basicTitle: "Successfully reset default grid layout",
                    basicType: "success"
                })
            })

    };

    onCellClicked(e) {
        if (e.colDef.field == "base_routes_map") {
            this.setState({
                loadshow: 'show-m',
                overly: 'show-m'
            })
            var formdata = {
                // "row_id": e.data._id,
                from_date: getHyphenYYYYMMDDHHMMSS(e.data.from_date) || "",
                to_date: getHyphenYYYYMMDDHHMMSS(e.data.to_date) || "",
                truck_no: e.data.truck_no || ""
            }


            redirectURL.post("/dashboard/baseroutemap", formdata).then((resp) => {
                // redirectURL.post("/consignments/maproutes", formdata).then((resp) => {
                var records = resp.data
                var distance = records.total_distance
                var coords = records.coords
                console.log(records, "records123")
                var route_coords1 = [
                    {
                        "consignment_code": ["Base Route - " + e.data.truck_no],
                        "coords": coords,
                        "breaks": []
                    },
                ]
                this.setState({
                    sliderRouteTranslate1: "slider-translate-50p",
                    mapfor: "map_routes",
                    from_date: e.data.from_date,
                    to_date: e.data.to_date,
                    distance: distance,
                    coords: coords,
                    route_coords1: route_coords1,
                    row_source: e.data.plant_name,
                    row_destination: e.data.dealer_city,
                    overly: "show-n",
                    loadshow: 'show-n',
                    truck_no: e.data.truck_no,

                })

            })

        }
        if (e.colDef.field == "approve") {
            var params = { unique_id: (e.data._id).toString() , user : localStorage.getItem("email")}
            if (window.confirm("Are you sure! Do you want to approve this base route")) {
                redirectURL.post("/dashboard/approveBaseRoute", params).then((response) => {
                    console.log(response.data, "Approve Functionality");
                    if (response.data.message == 'success') {
                        this.setState({
                            show: true,
                            basicTitle: "Successfully Approved Base Route",
                            basicType: "success"
                        });
                    }
                    else {
                        this.setState({
                            show: true,
                            basicTitle: "Failed To Approve Base Route",
                            basicType: "danger"
                        });
                    }
                    // window.location.reload();

                }).catch(function (error) {
                    console.log(error);
                })
            }
        }
    }

    onSubmitBaseRouteData = (event) => {
        event.preventDefault()
        console.log("check_entered_or_not")
        const { source, destination } = this.state
        console.log(source, destination, "123")
        if (!source) {
            this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Select Source"
            });
            return;
        }
        if (!destination) {
            this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Select Destination"
            });
            return;
        }
        if (source && destination) {
            this.onLoadPageData();
        }
    }

    resetFilters = async (event) => {
        await this.setState({
            source: "",
            destination: "",
        })
        // await this.onLoadPageData();
    }

    render() {

        var columnwithDefs = [
            {
                headerName: "",
                field: "base_routes_map",
                cellRenderer: (params) => {
                    return "<i class='icofont icofont-map-pins f20'></i>"
                },
                width: 80
            },
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 140,
            },
            {
                headerName: "Invoice No",
                field: "invoice_no",
                width: 130,
            },
            // {
            //     headerName: "Consignment Code",
            //     field: "consignment_codes",
            // },
            // {
            //     "headerName": "Base Route",
            //     field: "base_route_no",
            // },
            {
                headerName: "Dealer Code",
                field: "dealer_code",
                width: 130,
            },
            // {
            //     headerName: "Dealer Name",
            //     field: "consignee_name",
            // },
            {
                headerName: "Source",
                field: "plant_name",
                width: 140,
            },
            {
                headerName: "Destination",
                field: "dealer_city",
                width: 140,
            },
            // {
            //     headerName: "Zone",
            //     field: "zone"
            // },
            {
                headerName: "Invoice Time",
                field: "from_date",
                width : 180,
                valueGetter: (params) => {
                    return getHyphenYYYYMMDDHHMMSS(params.data.from_date)
                }
            },
            {
                headerName: "Trip End Time",
                field: "to_date",
                width : 180,
                valueGetter: (params) => {
                    return getHyphenYYYYMMDDHHMMSS(params.data.to_date)
                }
            },
            {
                headerName: "Route Travelled Count",
                width: 140,
                field: "route_travelled_count",
            },
            {
                headerName: '',
                field: 'approve',
                width: 110,
                cellRenderer: function (params) {
                    try {
                        const isApproved = params.data.approved_baseroute == 1;
                        return `<button style="border : none; background : none; padding : 0; cursor:${isApproved ? 'not-allowed' : 'pointer'}; color:${isApproved ? '#d3d3d3' : '#007bff'}" className ="custom-btn label label-success" title="${isApproved ? 'Already Approved' : 'Approve'}" ${isApproved ? 'disabled' : ''}><i class="icofont icofont-edit"></i> Approve</button>`
                    }
                    catch (e) {
                        console.log("error", e)
                    }

                }

            },
        ]

        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}

                <div className="row">
                    <div className='col-xl-12 col-md-12'>
                        <div className="card card-body pb-0">
                            <div className="row">

                                <div className="col-2">
                                    <label>Source</label>
                                    <Select
                                        className="border-radius-0"
                                        isMulti={false}
                                        closeMenuOnSelect={true}
                                        id="selectSource"
                                        placeholder="Select Source"
                                        style={{ borderRadius: "0px" }}
                                        value={this.state.source}
                                        onChange={(e) => { this.setState({ source: e }) }}
                                        options={this.state.source_options}
                                    />
                                </div>
                                <div className="col-2">
                                    <label>Destination</label>
                                    <Select
                                        className="border-radius-0"
                                        isMulti={false}
                                        closeMenuOnSelect={true}
                                        id="selectDestination"
                                        placeholder="Select Destination"
                                        style={{ borderRadius: "0px" }}
                                        value={this.state.destination}
                                        onChange={(e) => { this.setState({ destination: e }) }}
                                        options={this.state.destination_options}
                                    />
                                </div>
                                <div className="form-group"   >
                                    <button className="btn btn-success mt-30p ml-20p" type="submit" onClick={this.onSubmitBaseRouteData.bind(this)}>Submit</button>
                                </div>
                                <div className="form-group"   >
                                    <button className="btn btn-info  mt-30p ml-30p" type="reset" onClick={this.resetFilters.bind(this)}>Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
                                </h5>
                            </div>
                            <div className="card-body pt-10px" >
                                <div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={false}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        stopEditingWhenGridLosesFocus={true}
                                        enableCellChangeFlash={false}
                                        rowClassRules={this.state.rowClassRules}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        rowSelection={this.state.rowSelection}
                                        onRowSelected={this.onRowSelection.bind(this)}
                                        suppressRowClickSelection={true}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        excelStyles={this.state.excelStyles}
                                        onCellClicked={this.onCellClicked.bind(this)}
                                    />

                                </div>
                            </div>

                            {
                                (this.state.sliderRouteTranslate1 != "") ?
                                    <div className={"slide-r " + (this.state.sliderRouteTranslate1)} style={{ overflow: "auto" }}>
                                        <div className="slide-r-title">
                                            <h4>
                                                {/* Base Route - {this.state.truck_no} */}
                                                View Route Data
                                                <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

                                            </h4>
                                        </div>
                                        <div className="slide-r-body" style={{ position: "relative" }}>

                                            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                                <DrawMap
                                                    mapFor={"baseroute"}
                                                    mapinfo={{
                                                        "coords": this.state.coords,
                                                        "route_details": {
                                                            "start_time": this.state.routeStartTime || "",
                                                            "end_time": this.state.routeEndTime || "",
                                                        }
                                                    }}
                                                    row_source={this.state.row_source}
                                                    row_destination={this.state.row_destination}
                                                    start_time={this.state.from_date}
                                                    end_time={this.state.to_date}
                                                    // route_details={this.state.coords} 
                                                    coords={this.state.coords}
                                                />

                                                <div className="col-xl-12 col-lg-12 n-p-0">
                                                    <div className="crm-numbers pb-0">
                                                        <h3 className="subH">{"Details"}</h3>
                                                        <div className="col-xl-12 col-lg-12 row">
                                                            
                                                            {/* <div className="col route-block">
                                                                <label className="sidebar-label">Truck No</label>
                                                                <div>{this.state.truck_no}</div>
                                                            </div>
                                                            <div className="col route-block">
                                                                <label className="sidebar-label">Start Time</label>
                                                                <div>{(this.state.from_date != '') ? getDDMMYYYYHHMMSS(this.state.from_date) : "NA"}</div>
                                                            </div>
                                                            <div className="col route-block">
                                                                <label className="sidebar-label">End Time</label>
                                                                <div>{(this.state.to_date != '') ? getDDMMYYYYHHMMSS(this.state.to_date) : "NA"}</div>
                                                            </div> */}

                                                            <div className="col route-block">
                                                                <label className="sidebar-label">Distance</label>
                                                                <div>{Math.round(this.state.distance) + " Kms"}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                    : ""}




                        </div>
                    </div>

                </div>

                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>

        );
    }
}

$(document).ready(function () {
    $(document).on("click", ".tptdropdown-truck-no", function () {
        var vals = $(this).text();
        $("#inputTruckticket").val(vals);
        $(".trucks-dropdown").removeClass("show-m");
        $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#inputTruckticket");
        if (!container.is(e.target)) {
            try {
                if ($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown") {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            } catch (e) { }

        }


    })
})

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function timeConvert(n) {
    // var num = n/1000;
    // var hours = (num / (3600*24));
    // var rhours = Math.floor(hours);
    // var minutes = (n) / (60*60);
    // var rminutes = Math.round(minutes);

    var diffMs = n;
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


    return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
    var timeinfo = new window.google.maps.InfoWindow({
        content: "Time at this location: " + timedet
    });

    marker.addListener('click', function () {

        if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
        else {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }

    });
}

function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}
function CheckForHtmlTag() {
    var rs = document.getElementById("invalid_reason");
    var reg = /<(.|\n)*?>/g;
    if (reg.test(rs.value)) {
        var ErrorText = "Oops! HTML or Script is not allowed.";
        rs.value = ''
        //alert();
        return 1;
    }
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    //console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function loadDateTimeScript() {
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i',
        onShow: false
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false,
        onShow: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}


function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);

    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;

    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh * 3600));
    var mm = Math.floor(hrr / 60)
    if (mm < 10) {
        var mins = "0" + mm;
    }
    else {
        var mins = mm;
    }

    if (hh < 10) {
        var hrs = "0" + hh;
    }
    else {
        var hrs = hh;
    }
    return hrs + ":" + mins;
}