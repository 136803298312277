/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component, Suspense } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';

import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

import GPSIntegration from "./tptgpsintegrationsinglescreenComponent";
// import MapView from "./transporter-nearby-trucks";
// MapView = React.lazy(() => import('./transporter-nearby-trucks'))
// import Trips from "./tpttripsComponent";
import MSILTrips from "./tptconsignmentsComponent";
// import HeroTrips from "./tptheroconsignmentsComponent";
// import HeroTrips from "./tptherotripsComponent";
// HeroTrips = React.lazy(() => import('./tptherotripsComponent'))
// import NONMSILTrips from "./tptnonmsilconsignmentsComponent";
// NONMSILTrips = React.lazy(() => import('./tptnonmsilconsignmentsComponent'))
// import TrucksWithGPSDataCharts from "./trucksWithGPSDataCharts";
// TrucksWithGPSDataCharts = React.lazy(() => import('./trucksWithGPSDataCharts'))
// import TruckStatusTab from './truckStatusTab';
// TruckStatusTab = React.lazy(() => import('./truckStatusTab'))
// import TPTHistoricTrips from './tpthistorictripsComponent'
// Trips = React.lazy(() => import('./tpttripsComponent'))
// import NextLoadPlanner from './nextloadplanner';
// Trips = React.lazy(() => import('./tpttripsComponent'))
// import OtherOEMTripData from './otherOemConsignmentsComponent';
// Trips = React.lazy(() => import('./tpttripsComponent'))
$.datetimepicker.setLocale('en');
var redirectURL = require('../redirectURL');
var moment = require('moment');
const MapView = React.lazy(() => import('./transporter-nearby-trucks'))
const Trips = React.lazy(() => import('./tpttripsComponent'))
const HeroTrips = React.lazy(() => import('./tptherotripsComponent'))
const NONMSILTrips = React.lazy(() => import('./tptnonmsilconsignmentsComponent'))
const TrucksWithGPSDataCharts = React.lazy(() => import('./trucksWithGPSDataCharts'))
const TruckStatusTab = React.lazy(() => import('./truckStatusTab'))
const TPTHistoricTrips = React.lazy(() => import('./tpthistorictripsComponent'))
const NextLoadPlanner = React.lazy(() => import('./nextloadplanner'))
const OtherOEMTripData = React.lazy(() => import('./otherOemConsignmentsComponent'))
const ExceptionsData = React.lazy(() => import('./exceptionsComponent'))

var googleAnalytics = require("../common/googleanalytics");


export default class TPTIntegrationWithTabsComponent extends Component {

	constructor(props) {
		super(props);
		this.displayData = [];
		this.state = {
			active_tab: localStorage.getItem("is_hero" == 1) ? '3' : '1',
			active_tab_bottom_left: '1',
			active_tab_icon: '1',
			active_tab_right_align: '1',
			active_tab_vertical: '1',
			active_tab_pill: '1',
			active_tab_pill_icon: '1',
			active_tab_dark_color: '1',
			active_tab_primary: '1',
			active_tab_secondary: '1',
			isRoleExists: 0,
			adaniCheck: 0,
			test: false,
			information_tab_data: {},
			information_tab_data_kia:{},
			defaulttab: "all",
			tpttrucks: [],
			ontrips: [],
			exceptionTrucksNos: [],
			onTripsTrucksData : [],
			lastRefreshTime: null,
		};

		this.toggle = this.toggle.bind(this);
		this.timer = null;
	}

	componentDidMount(){
		this.getData()
		if (this.state.active_tab == 1) {
			console.log("entering or notttt")
			this.refreshForEvery5mins();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.active_tab !== prevState.active_tab) {
			if (this.state.active_tab == 1) {
				this.refreshForEvery5mins();
			} else {
				clearInterval(this.timer);
			}
		}
	}

	refreshForEvery5mins = () => {
		if (this.state.active_tab == 1) {
		this.timer = setInterval(() => {
			this.getData();
			const currentTime = moment.parseZone(new Date()).format("YYYY-MM-DD hh:mm A")  
			this.setState({ lastRefreshTime: currentTime });	
		}, 600000);  //Refreshes every 10 mins
	}
	}

	getData = () => {
		
			this.setState({
				loadshow: 'show-m',
				overly: 'show-m'
			})
			// console.log("tpt_otheroem ", localStorage.getItem("roles"))
			try {
				// console.log(localStorage.getItem("transportercode"), "transporter code")
				if (localStorage.getItem("roles").includes("tpt_otheroem") == true || localStorage.getItem("transportercode").includes("T297") ||
					localStorage.getItem("transportercode") == "T297") {
					this.setState({
						isRoleExists: 1
					})
					// this.toggle('6')
				}
				else {
					this.setState({
						isRoleExists: 0
					})
				}

				if (localStorage.getItem("is_hero") == 1 || localStorage.getItem("is_hero") == "1") {
					this.setState({
						active_tab: '3'
					})
				}
				// console.log("localStorage.getItem(transportercode) ", localStorage.getItem("transportercode"))
				else if (localStorage.getItem("transportercode") == "T297" || JSON.parse(localStorage.getItem("transportercode")).indexOf("T297") >= 0
					|| localStorage.getItem("transportercode").includes("T297") || localStorage.getItem("transportercode").includes("T305")) {
					this.setState({
						active_tab: '1'
					})
					// this.toggle('6')
				}
			}
			catch (e) {
	
			}
			var tptCode = localStorage.getItem('transportercode');
			if (tptCode.includes("T293") == true) {
				var adaniCheck = 1;
			}
			else if (tptCode.includes('T329') == true) {
				var ahmedabadCheck = 1
			}
			else {
				var adaniCheck = 0;
				ahmedabadCheck = 0
			}
			this.setState({
				adaniCheck: adaniCheck,
				ahmedabadCheck
			});
	
			//for transporter T329 arlp he wants his screen to refresh every 5 mins
	
			var tptCode = localStorage.getItem('transportercode');
			var vehicle_group = localStorage.getItem("vehicle_group");
			if (vehicle_group != "") {
				var tparms = {
					transporter_code: tptCode,
					region: vehicle_group
				}
				redirectURL.post("/dashboard/tpregiontrucksdata", tparms)
					.then((response) => {
						var records = response.data.records;
						this.setState({
							tpttrucks: records
						})
					})
			}
			var edate = moment.parseZone().format('YYYY-MM-DD');
	
			var sdate = moment.parseZone().subtract(10, 'days').format('YYYY-MM-DD');
	
			// var consignDatapp = {
			// 	transporter_code: tptCode,
			// 	startDate: sdate,
			// 	endDate: edate,
			// 	consigner: [{ "value": 'all', "label": 'All' }]
			// }
			// redirectURL.post("/dashboard/oemconsignments", consignDatapp)
			// 	.then((response) => {
			// 		var records = response.data.records;
			// 		var recordsarr = []
			// 		let nonMsilActiveTrucks = [];
			// 		if (records.length > 0) {
			// 			records.map((item) => {
			// 				if (item.status == 2 || item.status == 3 || item.status == 4) nonMsilActiveTrucks.push(item.truck_no)
			// 				recordsarr.push(item.truck_no)
			// 			})
			// 		}
			// 		this.setState({
			// 			nonMsilActiveTrucks,
			// 			nonmsil: recordsarr,
			// 			nonmsillist: records
			// 		})
			// 	})
	
			var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
			if (is_enmovil_transporter == 1) {
				this.logPageView(); // Log page view to GA
				let eventAction = googleAnalytics.page.actionType.gridAction + " - " + "/trucksgpsintegrationdata";
				this.setState({ eventAction: eventAction });
				var pagetitle = 'GPS Integration'
	
				if (tptCode.includes("T293") == true) {
					var adaniCheck = 1;
				}
				else if (tptCode.includes("T329") == true) {
					var t329Check = 1
				} else {
					adaniCheck = 0;
				}
				this.setState({
					't329Check': t329Check,
					'adaniCheck': adaniCheck
				});
				// console.log("localStorage.getItem(is_hero) ",localStorage.getItem("is_hero"))
				var oemcode = "";
				if (localStorage.getItem("is_hero") != undefined && localStorage.getItem("is_hero") == 1) {
					oemcode = "HERO";
				}
				else {
					oemcode = "Maruti"
				}
				// console.log("S1")
				var sdate = moment.parseZone().subtract(10, "days").format("YYYY-MM-DD");
				var edate = moment.parseZone().format("YYYY-MM-DD");
				if (localStorage.getItem("transportercode") != undefined) {
					var transporter = JSON.parse(localStorage.getItem("transportercode"))
				}
				else {
					var transporter = "";
				}
				// console.log("transporter ", transporter)
				if (Array.isArray(transporter)) {
					var transporter_code = JSON.stringify(transporter)
				}
				else {
					var transporter_code = JSON.stringify([transporter])
				}
	
				var consignData = {
					tpt_code: localStorage.getItem("transportercode"),
					transporter_code: transporter_code,
					consigner: [{ "value": "all", "label": "All" }],
					startDate: sdate,
					endDate: edate,
					oem_name: oemcode
				}
				// console.log("consignData ", consignData)
				redirectURL.post("/consignments/tptherotrips", consignData).then((response1) => {
					var consignments = response1.data.consignments;
					// console.log("tpthero consignments ", consignments)
					var consignmentsarr = []
					var consignmentitems = []
					let onTripTptTrucks = []
	
					if (consignments.length > 0) {
						consignments.map((item) => {
							// console.log(item.status,"item data")
							if (item.status == 2 || item.status == 3 || item.status == 4) onTripTptTrucks.push(item.truck_no)
							consignmentsarr.push(item.truck_no);
							consignmentitems.push(item);
						})
					}
	
					this.setState({
						onTripTptTrucks,
						msiltrips: consignmentsarr,
						msiltripitems: consignmentitems
					})
				})
				// }
				// else{
				// 	var consignData = {
				// 		tpt_code:localStorage.getItem("transportercode"),
				// 		consigner:[{"value":"all","label":"All"}]
				// 	}
				// 	    redirectURL.post("/dashboard/tptmsiltrips",consignData).then((response1)=>{
				// 			try
				// 			{
	
				// 				var consignments = JSON.parse(response1.data.consignments);
				// 				// console.log("msil consignments ", consignments)
				// 				var consignmentsarr = []
				// 				var consignmentitems = []
				// 				let onTripTptTrucks = []
				// 				if(consignments.length > 0)
				// 				{
				// 					consignments.map((item) => {
				// 						// console.log("item check ", item)
				// 						if(item.status == 2 || item.status == 3 || item.status == 4) onTripTptTrucks.push(item.truck_no)
				// 						consignmentsarr.push(item.truck_no);
				// 						consignmentitems.push(item);
				// 					})
				// 				}
	
				// 				this.setState({
				// 					onTripTptTrucks,
				// 					msiltrips:consignmentsarr,
				// 					msiltripitems:consignmentitems
				// 				})
				// 			}
				// 			catch(e)
				// 			{
	
				// 			}
				// 		})
	
				// }
				// Active consignments are not applicable for LOG-SC
				// let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
	
				var deviceparams = {
					transporter_code: localStorage.getItem("transportercode")
				}
				redirectURL.post("/dashboard/devices", deviceparams).then((response1) => {
					var transporterTrucks = [];
					response1.data.devices.map(function (e) {
						// console.log(e,"devices data")
						if (tptCode.indexOf(e.transporter_code) > -1) {
							transporterTrucks.push(e)
						}
					})
					// console.log(transporterTrucks,"transporterTrucks")
					this.setState({
						transporterTrucks: transporterTrucks
					});
					this.filterByDepartment();
				});
			}
			else {
				window.location.href = "/";
			}
		
	
	}

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + "/trucksgpsintegrationdata";
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + "/trucksgpsintegrationdata";
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + "/trucksgpsintegrationdata";
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				// console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	
	componentWillUnmount() {
		clearInterval(this.timer);
	}

	toggle(tab) {
		// console.log(this.state.active_tab ,"tab", tab, )
		if (this.state.active_tab !== tab) {
			this.setState({
				active_tab: tab
			});
		}

	}
	filterByDepartment = async () => {
		this.setState({
			loadshow: 'show-m',
			overly: "show-m",
		})
		var depmntCode = "";
		if (localStorage.getItem("dept_code") != undefined) {
			var depmntCodeJns = JSON.parse(localStorage.getItem("dept_code"));
			var depmntCode = depmntCodeJns[0];
		}
		let currentDepartmentCode = depmntCode;

		if (currentDepartmentCode == 'SNDG') {
			var defaulttab = this.state.defaulttab
		}
		else {
			var defaulttab = ""
		}

		var israil = 0;
		var userroles = localStorage.getItem("roles");
		if (userroles.indexOf("tpt_rail") > 0) {
			israil = 1;
			var defaulttab = this.state.defaulttab
		}
		var depmntCode = "";
		if (localStorage.getItem("dept_code") != undefined) {
			var depmntCodeJns = JSON.parse(localStorage.getItem("dept_code"));
			var depmntCode = depmntCodeJns[0];
		}
		var tptCode = localStorage.getItem('transportercode');
		let layoutdata = {
			token: localStorage.getItem('token'),
			israil: israil,
			defaulttab: defaulttab,
			deptcode: currentDepartmentCode,
			is_enmovil_transporter: localStorage.getItem('is_enmovil_transporter'),
			transporter_code: localStorage.getItem('transportercode'),
		}
		this.setState({ deptvalue: currentDepartmentCode });

		if (currentDepartmentCode == 'SNDG') {
			this.setState({
				sndshow: 'show-m',
				departmentchrtshow: 'show-n'
			});
		}
		else {
			this.setState({
				sndshow: 'show-n',
				departmentchrtshow: 'show-m'
			});
		}
		// Get active consignments for grid display

		let activeConsignmentsLayoutData = {
			token: localStorage.getItem('token'),
			is_enmovil_transporter: localStorage.getItem('is_enmovil_transporter'),
			transportercode: localStorage.getItem('transportercode'),
			dept_code: currentDepartmentCode,
			defaulttab: defaulttab,
			israil: israil,
			transporter_code: localStorage.getItem('transportercode'),
			email : localStorage.getItem("email"),
		}
		// console.log(activeConsignmentsLayoutData,"activeConsignmentsLayoutData")
		var userroles = localStorage.getItem("roles");
		console.log(userroles.indexOf("tpt_rail") >= 0, "iforelse")
		if (userroles.indexOf("tpt_rail") >= 0) {
			// Counters data for "Total Trucks" and "On Trip Trucks" under "Total Trucks"
			redirectURL.post('/dashboard/getrailtrucks', activeConsignmentsLayoutData, {
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
				}
			})
				.then(async (response) => {
					var result3arr = []
					var onTripsTrucksData = response.data.ontrips
					var msiltrips = this.state.msiltrips;
					var onTripTrucks = this.state.onTripTptTrucks;
					var nonmsil = this.state.nonmsil;
					let nonMsilActiveTrucks = this.state.nonMsilActiveTrucks;
					let result = JSON.parse(JSON.stringify(response)).data;

					var uqTrucks = result.uniqueTrucks;
					
					var uqTrck = uqTrucks;
					
					let totalTrucks = (uqTrck) ? uqTrck : [];
					let totalTrucksCount = (uqTrck) ? uqTrck.length : 0;

					let uniqueActiveConsignments = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments : [];
					let uniqueActiveConsignmentsCount = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments.length : 0;


					result3arr = await totalTrucks.filter(f => {
						
						f.device_address = f.address
						f.device_city = f.city
						f.device_state = f.state
						//     }
						// }
						f.timestamp = f.last_active_on;
						// eslint-disable-next-line no-self-assign
						f.modified_date = f.modified_date;
						f.today_distance = f.today_distance
						f.idle_time_remarks = f.idle_time_remarks
						// console.log("f.last_moved_time ", f.last_moved_time)
						var elasptm = "";
						var elspa = ''
						if (f.last_moved_time != "" && f.last_moved_time != undefined) {
							f.truck_last_moved = f.last_moved_time                  
							var td1 = moment.parseZone(new Date()).format("YYYY-MM-DD")
							var tday1 = moment.parseZone(new Date(td1 + " 00:00:00")).format("YYYY-MM-DD HH:mm:ss")
							// console.log("tday1 ", tday1)
							var e1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
							// console.log("current date is: ", e1)
							var e2 = moment.parseZone(f.last_moved_time).format("YYYY-MM-DD HH:mm:ss")
							// console.log("last moved date is: ", e2)
							var ems = moment(e1, "YYYY-MM-DD HH:mm:ss").diff(moment(e2, "YYYY-MM-DD HH:mm:ss"));
							// console.log("Difference : ", ems)

							var ed = moment.duration(ems);
							// console.log("Duration : ", ed)
							var es = Math.floor(ed.asSeconds());
							// console.log("Seconds ",es, f.truck_no)
							var esmins = Math.floor(ed.asMinutes());
							// console.log("MInutes ",esmins, f.truck_no)

							var todayems = moment(tday1, "YYYY-MM-DD HH:mm:ss").diff(moment(e2, "YYYY-MM-DD HH:mm:ss"));
							// console.log("Today Difference : ", todayems)

							var todayed = moment.duration(todayems);
							var todaysec = Math.floor(todayed.asSeconds());
							var todaymin = Math.floor(todayed.asMinutes());

							// console.log("todayhrs ", todaysec)
							if (todaysec > 0) {
								// elspa = 0
								elspa = todaysec
							}
							else {
								// elspa = todaysec
								elspa = 0
							}
							if (es > 0) {
								elasptm = es
							}
							else {
								elasptm = 0
							}
						}
						else {
							elasptm = ""
						}
						// console.log("elasptm ", elasptm)
						f.elaped_time_from_last_moved_seconds = elasptm
						f.last_moved_time = elspa
						if (f.last_active_on != undefined) {
							var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
							var d2 = moment.parseZone(f.last_active_on).format("YYYY-MM-DD HH:mm:ss")
							var ms = moment(d1, "YYYY-MM-DD HH:mm:ss").diff(moment(d2, "YYYY-MM-DD HH:mm:ss"));
							var d = moment.duration(ms);
							var s = Math.floor(d.asHours());


							// console.log("last_active_on ", d2, f.last_active_on)
							f.travelstatus = s;
							if (s >= 24) {
								// f.today_distance = "0";
								f.travel_sttaus = "Inactive";
							}
							// console.log(onTripsTrucksData.length,"onTripsTrucksData.length")
							var ottData = [];
							onTripsTrucksData.map((ot) => {
								ottData.push(ot.truck_no);
							})
							if (ottData.length > 0) {
								if (ottData.indexOf(f.truck_no) >= 0) {
									// console.log(f.truck_no,"truckkkkkkkkkkNOOOOOOOOOOOOOOOO")
									f.trip_status = "On Trip"
								}
								else {
									f.trip_status = "Not On Trip"
								}
							}
							else if (nonMsilActiveTrucks.length > 0) {
								if (nonMsilActiveTrucks.includes(f.truck_no)) {
									f.trip_status = "On Trip"
								} else {
									f.trip_status = "Not On Trip"
								}
								// if(nonmsil.indexOf(f.truck_no) >= 0)
								// {
								// 	f.trip_status = "On Trip"
								// }
								// else
								// {
								// 	f.trip_status = "Not On Trip"
								// }
							}
							else {
								f.trip_status = "Not On Trip"
							}
							// else{
							// 	if(f.last_moved_time == "" && f.last_moved_time == undefined)
							// 	{      
							// 		f.today_distance = "0";
							// 		f.travel_sttaus = "Inactive";
							// 	}
							// }

							var t1 = moment.parseZone(new Date(f.timestamp)).format("x")

						}
						else {
							f.travel_sttaus = "Inactive";
							// f.today_distance = "0"
						}
						
						f.packettimeseconds = t1;
						return f;
						// }
						// })
					});
					var msiltripitems = this.state.msiltripitems;
					var resultsdata = await result3arr.filter(f =>
						onTripsTrucksData.filter((d) => {
							// console.log(d,"trips data")
							if (d.truck_no == f.truck_no) {
								f.aprox_dist_travelled_from_start = d.aprox_dist_travelled_from_start;
								f.dealer_name = d.dealer_name;
								f.distance_from_dealer_location = d.distance_from_dealer_location
								return f;
							}
						})
					);
					var rowDataarr = []
					var gpsrowData = []
					var ontrips = onTripsTrucksData
					if (resultsdata.length > 0) {
						resultsdata.map((item, index) => {
							// console.log("item12 ",item)
							// console.log("index ", index+1)
							if (item.travel_sttaus == "Inactive") {
								rowDataarr.push(item)
							}
							else {
								gpsrowData.push(item)
							}
							// if(item.trip_status == "On Trip")
							// {
							//     ontrips.push(item)
							// }
						})
						// console.log(ontrips,"ontrips")
					}
					var onTripsData = await totalTrucks.filter(f =>
						onTripsTrucksData.filter((d) => {
							if (d.truck_no == f.truck_no) {
								f.trip_status = "On Trip"
								return f;
							}
						})
					);
					var onTripData = []
					if (onTripsData.length > 0) {
						onTripsData.map((item) => {
							if (item.trip_status == "On Trip") {
								onTripData.push(item)
							}
						})
					}
					// console.log(rowDataarr,"rowDataarr")
					if (resultsdata.length > 0) {
						resultsdata = resultsdata.sort(GetSortDescOrder("packettimeseconds"));
					}
					
					const exceptionTrucksNos = []
					totalTrucks.map(eachItem => exceptionTrucksNos.push(eachItem.truck_no))

					await this.setState({
						exceptionTrucksNos: exceptionTrucksNos,
						information_tab_data: {
							totalTrucks: totalTrucks,
							truckscnt: totalTrucksCount,
							uniqueActiveConsignments: uniqueActiveConsignments,
							totalActiveConsignmentsCount: uniqueActiveConsignmentsCount,
							loadshow: 'show-n',
							allrowData: resultsdata,
							originalData: resultsdata,
							gpsnodata: rowDataarr,
							gpsrowData: gpsrowData,
							ontrips: ontrips,
							onTripData: onTripData,
							
						},
						ontrips: ontrips,
						onTripsTrucksData
					});

					console.log(this.state.information_tab_data , 'information_tab_data')
				})
				.catch(function (error) {
					console.log(error);
				});


			/*Trucks with GPS Data*/
			// Counter for "Trucks with GPS Data"
			redirectURL.post('/dashboard/getrailtruckswithgpsdata', activeConsignmentsLayoutData, {
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
				}
			})
				.then(async (response) => {
					let result = JSON.parse(JSON.stringify(response)).data;

					var twgl = result.trucksWithGpsLocation;
					var transporterTrucks = this.state.transporterTrucks;
					var trucksWithGpsLocation = [];
					
					var trucksWithGpsLocation = await twgl.filter(f =>
						transporterTrucks.some(d => d.truck_no == f.truck_no)
					);
					var trucksWithGpsLocationCount = trucksWithGpsLocation.length;

					var ontripwithgps = (result.ontripwithgps) ? result.ontripwithgps : [];

					let gpsDataNotReportedToday = trucksWithGpsLocation.filter(truck => {
						if (truck.timestamp) {
							let result = false;
							let consignmentDate = truck.timestamp;
							let consignmentDateOnly = consignmentDate.toString().slice(0, 10);
							let todayDateOnly = moment.parseZone().format("YYYY-MM-DD HH:mm:ss").toString().slice(0, 10);

							// console.log("consignmentDateOnly ", consignmentDateOnly)
							// console.log("todayDateOnly ", todayDateOnly)
							var s1 = moment.parseZone(consignmentDateOnly).format("x")
							var s2 = moment.parseZone(todayDateOnly).format("x")
							
							result = true;
							
							return result;

						} else {
						}
					});

					let gpsDataNotReportedTodayCount = (trucksWithGpsLocation) ? trucksWithGpsLocation.length : 0;
					let trucksWithGpsDataCount = (trucksWithGpsLocationCount && gpsDataNotReportedTodayCount) ? trucksWithGpsLocationCount - gpsDataNotReportedTodayCount : 0;


					this.setState({
						trucklocations: trucksWithGpsLocation.length,
						trucksWithGpsLocation: trucksWithGpsLocation,
						ontripwithgps: ontripwithgps.length

					});

				})
				.catch(function (error) {
					console.log(error);
				});

		}
		else {
			// Counters data for "Total Trucks" and "On Trip Trucks" under "Total Trucks"
			redirectURL.post('/dashboard/gettrucks', activeConsignmentsLayoutData, {
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
				}
			})
				.then(async (response) => {
					let result = JSON.parse(JSON.stringify(response)).data;
					var onTripsTrucksData = response.data.ontrips					
					var result3arr = []					
					var uqTrck = result.uniqueTrucks;					
					var tpttrucks = this.state.tpttrucks;
					// console.log(tpttrucks,"length 1")
					var tptunqrecords = []
					if (tpttrucks != undefined || tpttrucks != "undefined") {
						if (tpttrucks.length > 0) {
							await uqTrck.filter(f =>
								tpttrucks.filter((d) => {
									if (d.truck_no == f.truck_no) {
										tptunqrecords.push(f)
									}
								})
							);
						}
						else {
							tptunqrecords = uqTrck
						}
					}
				
					var ottData = [];
					if (onTripsTrucksData != undefined || onTripsTrucksData != "undefined") {
						onTripsTrucksData.map((ot) => {
							if (ottData.includes(ot.truck_no)) {
							} else {
								ottData.push(ot.truck_no);
							}
						})
					}


					let totalTrucks = (tptunqrecords) ? tptunqrecords : [];
					
					let uniqueActiveConsignments = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments : [];
					let uniqueActiveConsignmentsCount = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments.length : 0;

					var msiltrips = this.state.msiltrips;
					let onTripTptTrucks = this.state.onTripTptTrucks;
					var nonmsil = this.state.nonmsil;
					let nonMsilActiveTrucks = this.state.nonMsilActiveTrucks;
					
					var allontripstrucks = []
					result3arr = await totalTrucks.filter(f => {
						f.device_address = f.address
						f.device_city = f.city
						f.device_state = f.state
						f.timestamp = f.last_active_on;
						// f.modified_date = f.modified_date; 
						var elasptm = "";
						var elspa = ''
						var tchk = f.last_moved_time + "===" + f.truck_no;
						// console.log("f.last_moved_time" , tchks)
						if (f.last_moved_time != "" && f.last_moved_time != undefined) {
							f.truck_last_moved = f.last_moved_time                  
							var td1 = moment.parseZone(new Date()).format("YYYY-MM-DD")
							var tday1 = moment.parseZone(new Date(td1 + " 00:00:00")).format("YYYY-MM-DD HH:mm:ss")
							// console.log("tday1 ", tday1)
							var e1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
							// console.log("current date is: ", e1)
							var e2 = moment.parseZone(f.last_moved_time).format("YYYY-MM-DD HH:mm:ss")
							// console.log("last moved date is: ", e2)
							var ems = moment(e1, "YYYY-MM-DD HH:mm:ss").diff(moment(e2, "YYYY-MM-DD HH:mm:ss"));
							// console.log("Difference : ", ems)

							var ed = moment.duration(ems);
							// console.log("Duration : ", ed)
							var es = Math.floor(ed.asSeconds());
							// console.log("Seconds ",es, f.truck_no)
							var esmins = Math.floor(ed.asMinutes());
							// console.log("MInutes ",esmins, f.truck_no)

							var todayems = moment(tday1, "YYYY-MM-DD HH:mm:ss").diff(moment(e2, "YYYY-MM-DD HH:mm:ss"));
							// console.log("Today Difference : ", todayems)

							var todayed = moment.duration(todayems);
							var todaysec = Math.floor(todayed.asSeconds());

							// console.log("todayhrs ", todaysec)
							if (todaysec >= 0) {
								elspa = 0
							}
							else {
								elspa = todaysec
							}
							if (es > 0) {
								elasptm = es
							}
							else {
								elasptm = 0
							}
						}
						else {
							elasptm = ""
						}
						if (JSON.parse(localStorage.getItem("transportercode")).indexOf("T297") >= 0) {
							if (f.last_known_speed > 0) {
								f.elaped_time_from_last_moved_seconds = 0
							}
							else {
								f.elaped_time_from_last_moved_seconds = elasptm
							}
						}
						else {
							f.elaped_time_from_last_moved_seconds = elasptm
						}
						if (JSON.parse(localStorage.getItem("transportercode")).indexOf("T293") >= 0) {
							if (f.last_known_speed > 0) {
								f.last_moved_time = 0
							}
							else {
								f.last_moved_time = elspa
							}
						}
						else {
							f.last_moved_time = elspa
						}
						if (f.last_active_on != undefined) {
							var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
							var d2 = moment.parseZone(f.last_active_on).format("YYYY-MM-DD HH:mm:ss")
							var ms = moment(d1, "YYYY-MM-DD HH:mm:ss").diff(moment(d2, "YYYY-MM-DD HH:mm:ss"));
							var d = moment.duration(ms);
							var s = Math.floor(d.asHours());


							// console.log("last_active_on ", d2, f.last_active_on)
							f.travelstatus = s;
							if (s >= 24) {
								// f.today_distance = "0";
								f.travel_sttaus = "Inactive";
							}
							if (ottData != undefined || ottData != "undefined") {
								if (ottData.length > 0) {
									if (ottData.indexOf(f.truck_no) >= 0) {
										// console.log(f.truck_no,"truckNO1291")
										f.trip_status = "On Trip"
										allontripstrucks.push(f);
									}
									else {
										// console.log("checking else")
										f.trip_status = "Not On Trip"
									}
								}
							}

							else if (nonMsilActiveTrucks.length > 0) {
								// console.log("Step ", nonmsil);
								if (nonMsilActiveTrucks.indexOf(f.truck_no) >= 0) {
									// console.log("Step 2");
									f.trip_status = "On Trip"
								}
								else {
									// console.log("Step 3");
									f.trip_status = "Not On Trip"
								}
							}
							else {
								// console.log("Step 4");
								f.trip_status = "Not On Trip"
							}

							var t1 = moment.parseZone(new Date(f.timestamp)).format("x")

						}
						else {
							f.travel_sttaus = "Inactive";
							// f.today_distance = "0"
						}
						if (elspa == 0) {
							f.today_distance = "0"
						}
						else {
							// eslint-disable-next-line no-self-assign
							f.today_distance = f.today_distance
						}
						f.packettimeseconds = t1;
						return f;
						// }
						// })
					});
					var msiltripitems = this.state.msiltripitems;
					// console.log(result3arr.length,"length 23")
					var resultsdataarr = await result3arr.filter(f =>
						onTripsTrucksData.filter((d) => {
							if (d.truck_no == f.truck_no) {
								f.aprox_dist_travelled_from_start = d.aprox_dist_travelled_from_start;
								f.invoice_time = d.invoice_time;
								f.gate_out_time = d.gate_out_time;
								f.dealer_name = d.dealer_name;
								f.distance_from_dealer_location = d.distance_from_dealer_location
								f.status = d.status
								return f;
							}
						})
					);


					// console.log(localStorage.getItem('transportercode'),"plant_location")
					if (localStorage.getItem('transportercode').includes("T293") && localStorage.getItem("plant_location") != "" &&
						localStorage.getItem("plant_location") != undefined) {
						resultsdataarr = resultsdataarr.filter((item) => {
							if (item.state == localStorage.getItem("plant_location")) {
								return item;
							}
						})
					}

					var rowDataarr = []
					var gpsrowData = []
					var rtrucks = this.state.tpttrucks;
					if (rtrucks != undefined || rtrucks != "undefined") {
						if (rtrucks.length > 0) {
							// console.log("allontrip",allontripstrucks)
							var regionwiseontrips = await allontripstrucks.filter(f =>
								onTripsTrucksData.filter((d) => {
									if (d.truck_no == f.truck_no) {
										f.aprox_dist_travelled_from_start = d.aprox_dist_travelled_from_start;
										f.invoice_time = d.invoice_time;
										f.gate_out_time = d.gate_out_time;
										return f;
									}
								})
							);
							var ontrips = regionwiseontrips;
							// console.log("ontrips spareparys ", ontrips);
						}
						else {
							var ontrips = onTripsTrucksData
						}
					}

					
					if (resultsdataarr != undefined || resultsdataarr != "undefined") {
						if (resultsdataarr.length > 0) {
							// console.log(resultsdataarr.length,"length")
							resultsdataarr.map((item) => {
								console.log("item1 ", item.travel_sttaus)
								// console.log("index ", index+1)
								if (item.travel_sttaus == "Inactive") {
									rowDataarr.push(item)
								}
								else {
									gpsrowData.push(item)
								}
								
							})
						}
					}

					var onTripsData = await totalTrucks.filter(f =>
						onTripsTrucksData.filter((d) => {
							if (d.truck_no == f.truck_no) {
								f.trip_status = "On Trip"
								return f;
							}
						})
					);
					console.log(onTripsData, "onTripsData")
					var onTripData = []
					if (onTripsData != undefined || onTripsData != "undefined") {
						if (onTripsData.length > 0) {
							onTripsData.map((item) => {
								
								if (item.trip_status == "On Trip") {
									onTripData.push(item)
								}
							})
						}
					}

					let totalTrucksCount = (resultsdataarr) ? resultsdataarr.length : 0;
					if (resultsdataarr != undefined || resultsdataarr != "undefined") {
						if (resultsdataarr.length > 0) {
							resultsdataarr = resultsdataarr.sort(GetSortDescOrder("packettimeseconds"));
						}
					}

					
					if (localStorage.getItem("email").includes("ivc_rake@enmovil.in")){
						var kia_totalTrucks =[]
						var ivc_totalTrucks = []
						totalTrucks.map(e=>{
							if(e.transporter_code =="KIA_RAKE"){
								kia_totalTrucks.push(e)
							}else{
								ivc_totalTrucks.push(e)
							}
						})
						var kia_totalTrucks_count = kia_totalTrucks.length	
						var ivc_totalTrucks_count = ivc_totalTrucks.length

						var kia_resultsdataarr = []
						var ivc_resultsdataarr = []
						resultsdataarr.map(e=>{
							if(e.transporter_code =="KIA_RAKE"){
								kia_resultsdataarr.push(e)
							}else{
								ivc_resultsdataarr.push(e)
							}
						})

						var kia_rowDataarr = []
						var ivc_rowDataarr = []
						rowDataarr.map(e=>{
							if(e.transporter_code =="KIA_RAKE"){
								kia_rowDataarr.push(e)
							}else{
								ivc_rowDataarr.push(e)
							}
						})

						var kia_gpsrowData = []
						var ivc_gpsrowData = []
						gpsrowData.map(e=>{
							if(e.transporter_code =="KIA_RAKE"){
								kia_gpsrowData.push(e)
							}else{
								ivc_gpsrowData.push(e)
							}
						})

						this.setState({
							information_tab_data_kia: {
								totalTrucks: kia_totalTrucks,
								truckscnt: kia_totalTrucks_count,
								uniqueActiveConsignments: uniqueActiveConsignments,
								totalActiveConsignmentsCount: uniqueActiveConsignmentsCount,
								loadshow: 'show-n',
								allrowData: kia_resultsdataarr,
								originalData: kia_resultsdataarr,
								gpsnodata: kia_rowDataarr,
								gpsrowData: kia_gpsrowData,
								ontrips: ontrips,
								onTripData: onTripData,
							},
							information_tab_data: {
								totalTrucks: ivc_totalTrucks,
								truckscnt: ivc_totalTrucks_count,
								uniqueActiveConsignments: uniqueActiveConsignments,
								totalActiveConsignmentsCount: uniqueActiveConsignmentsCount,
								loadshow: 'show-n',
								allrowData: ivc_resultsdataarr,
								originalData: ivc_resultsdataarr,
								gpsnodata: ivc_rowDataarr,
								gpsrowData: ivc_gpsrowData,
								ontrips: ontrips,
								onTripData: onTripData,
							}
						})


					}else{
						const exceptionTrucksNos = []
						totalTrucks.map(eachItem => exceptionTrucksNos.push(eachItem.truck_no))
						await this.setState({
							exceptionTrucksNos: exceptionTrucksNos,
							onTripsTrucksData,
							information_tab_data: {
								totalTrucks: totalTrucks,
								truckscnt: totalTrucksCount,
								uniqueActiveConsignments: uniqueActiveConsignments,
								totalActiveConsignmentsCount: uniqueActiveConsignmentsCount,
								loadshow: 'show-n',
								allrowData: resultsdataarr,
								originalData: resultsdataarr,
								gpsnodata: rowDataarr,
								gpsrowData: gpsrowData,
								ontrips: ontrips,
								onTripData: onTripData,
							}
						});
						console.log(this.state.information_tab_data , 'information_tab_data')
					}				
				})


		}

		if (currentDepartmentCode == 'LOG-SC') {
			//// Counter for "Trucks with GPS Data"
			redirectURL.post('/dashboard/getSCData', activeConsignmentsLayoutData)
				.then(async (response) => {
					//	console.log("Response SC ", response.data)
					var ontriptrucks = response.data[0].ontriptrucks;
					var nogpslgsc = response.data[0].nogpslogsc;
					var transporterTrucks = this.state.transporterTrucks;
					var nogpslogsc = [];
					
					var nogpslogsc = await nogpslgsc.filter(f =>
						transporterTrucks.some(d => d.truck_no == f.truck_no)
					);
					this.setState({
						totalActiveConsignmentsCount: ontriptrucks.length,
						gpsNotTodayActiveConsignments: nogpslogsc,
						gpsNotTodayActiveConsignmentsCount: nogpslogsc.length
					})
				})
				.catch(function (e) {

				})

		}
		else {
			var userroles = localStorage.getItem("roles");
			if (userroles.indexOf("tpt_rail") >= 0) {
				// Counter for "GPS Data Not Reported Today -> On Trip Trucks"
				redirectURL.post('/dashboard/getrailGpsDataNotReportedTodayOnTripTrucks', activeConsignmentsLayoutData, {
					headers: {
						"Access-Control-Allow-Origin": "*",
						"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
					}
				})
					.then(async (response) => {
						let result = JSON.parse(JSON.stringify(response)).data;
						// console.log("result = ", result);
						let twglt = (result.trucksWithGpsLocationNotToday.length) ? result.trucksWithGpsLocationNotToday : [];
						let trucksWithGpsLocationNotTodayCount = (result.trucksWithGpsLocationNotToday.length) ? result.trucksWithGpsLocationNotToday.length : 0;
						let ontripgpsna = (result.ontripgpsna) ? result.ontripgpsna : [];
						var transporterTrucks = this.state.transporterTrucks;
						var trucksWithGpsLocationNotToday = [];
						
						var trucksWithGpsLocationNotToday = await twglt.filter(f =>
							transporterTrucks.some(d => d.truck_no == f.truck_no)
						);
						this.setState({
							gpsNotTodayActiveConsignments: trucksWithGpsLocationNotToday,
							gpsNotTodayActiveConsignmentsCount: trucksWithGpsLocationNotToday.length,
							ontripgpsna: ontripgpsna

						});
					})
					.catch(function (error) {
						console.log(error);
					});

			}
			else {
			}

		}



		redirectURL.post('/overview/trucksWithNoTransportersDataCount', layoutdata)
			.then((response) => {

				var trucks = JSON.parse(JSON.stringify(response)).data.count;
				// console.log("Truck GPS",trucks);
				this.setState({
					notrans: trucks
				});


			})
			.catch(function (error) {
				console.log(error);
			});


		/*Truck with gpsdata*/
		//console.log("bhanuteja",layoutdata)
		//    redirectURL.post('/overview/truckWithGPSData', layoutdata)
		//   .then(async (response) => {

		//    	var recs = JSON.parse(JSON.stringify(response)).data;
		//    	if(recs.length > 0)
		//    	{
		// 		var transporterTrucks = this.state.transporterTrucks;
		// 		if(transporterTrucks.length > 0)
		// 		{
		// 			var records = [];
		// 			var recordsarr = [];
		// 			// recs.map((t) => {
		// 			// 	if(transporterTrucks.indexOf(t.truck_no) >= 0 )
		// 			// 	{
		// 			// 		recordsarr.push(t)
		// 			// 	}
		// 			// })

		// 			var recordsa =await recs.filter(f =>
		// 				transporterTrucks.filter((d )=> {
		// 					if(d.truck_no == f.truck_no){
		// 						// console.log("API ", d)
		// 						if(d.registereddevices != undefined)
		// 						{
		// 							if(d.registereddevices.length > 0)
		// 							{
		// 								f.device_address = d.registereddevices[0].address
		// 								f.device_city = d.registereddevices[0].city
		// 								f.device_state = d.registereddevices[0].state
		// 							}
		// 						}
		// 						f.timestamp = d.last_active_on;
		// 						f.modified_date = d.modified_date;
		// 						// return f;
		// 						records.push(f)
		// 					}
		// 				})
		// 			);
		// 			var tpttrucks = this.state.tpttrucks;
		// 			var tptrecords = []
		// 			if(tpttrucks.length > 0)
		// 			{
		// 				await records.filter(f =>
		// 					tpttrucks.filter((d )=> {
		// 						if(d.truck_no == f.truck_no){									
		// 							tptrecords.push(f)
		// 						}
		// 					})
		// 				);
		// 			}
		// 			else
		// 			{
		// 				tptrecords = records
		// 			}
		// 			// console.log("records ", recs)
		// 			//console.log("In indexcomponent, /overview/truckWithGPSData, records = ", records);
		// 			this.setState({
		//                 rowData:tptrecords,
		//                 // gpsrowData:tptrecords
		// 			});
		// 		}
		//    	}	   

		//   })
		//   .catch(function (error) {
		//     console.log(error);
		//   });
	}
	render() {
		const { adaniCheck, ahmedabadCheck, active_tab } = this.state
		const email = localStorage.getItem("email")
		return (

			<div className="container-fluid">

					<div className="row">
						<div className="col-xl-12 col-lg-12">
							{localStorage.getItem("transportercode").indexOf("DAIMLER_TPT") >= 0 ? 
							<Nav tabs className="d-flex justify-content-between align-items-center tabs-color">
								<>
									<NavItem >
										<NavLink
											className={classnames({ active: this.state.active_tab === '1' })}
											onClick={() => { this.toggle('1'); }} >
											{(this.state.adaniCheck == 1) ? "Shuttles GPS Data" : ((localStorage.getItem("transportercode").includes("_RAKE")) ? "Rake Devices Information" : "Information")}
										</NavLink>
									</NavItem>
								</>
								<div className="ml-auto">
									<span style={{ fontSize: '14px', color: '#17a2b8', marginRight: '10px', fontWeight: 'bold' }}>
										{this.state.lastRefreshTime && this.state.active_tab === '1'? `Data Refreshed On: ${this.state.lastRefreshTime}` : ""}
									</span>

								</div>

							</Nav> :
							<Nav tabs className="d-flex justify-content-between align-items-center tabs-color">
								<>
								<NavItem >
									<NavLink
										className={classnames({ active: this.state.active_tab === '1' })}
										onClick={() => { this.toggle('1'); }} >
										{(this.state.adaniCheck == 1) ? "Shuttles GPS Data" : ((localStorage.getItem("transportercode").includes("_RAKE"))? "Rake Devices Information" : "Information")}
									</NavLink>
								</NavItem>
								
								{/* Temporarily Disabled For ARL Logins */}
								{(localStorage.getItem("transportercode").includes("T297") || localStorage.getItem("transportercode").includes("T305")
									// || localStorage.getItem("transportercode").includes("T329")
								) ?
									<NavItem>
										<NavLink className={classnames({ active: this.state.active_tab === '6' })}
											onClick={() => { this.toggle('6'); }} >
											Dashboard
										</NavLink>
									</NavItem>
									: ""}
								{
									((localStorage.getItem("is_hero") == 1 || localStorage.getItem("is_hero") == "1" || email == "rao_track@enmovil.in")|| (localStorage.getItem("transportercode").includes("_RAKE")) ||(localStorage.getItem("transportercode").includes("_ENTRAC"))) ? "" :
										<NavItem>
											<NavLink className={classnames({ active: this.state.active_tab === '2' })}
												onClick={() => { this.toggle('2'); }} >
												Trips
											</NavLink>
										</NavItem>
								}
								{/* Temporarily Disabled For ARL Logins */}
								{(localStorage.getItem("transportercode").includes("T305") 
								// || localStorage.getItem("email").includes("nitish.baweja@arlpl.com")
								// 	|| localStorage.getItem("email").includes("carcarriers@arlpl.com") || localStorage.getItem("vehicle_group").includes("CCARL")
								) ?
									<NavItem >
										<NavLink
											className={classnames({ active: this.state.active_tab === '10' })}
											onClick={() => { this.toggle('10'); }} >
											{/* {(this.state.adaniCheck == 1) ? "Shuttles GPS Data" : "Information"} */}
											Other OEM Trip data
										</NavLink>
									</NavItem>
									: ""}
								{(localStorage.getItem("is_hero") == 1 || localStorage.getItem("is_hero") == "1") ?
									<NavItem>
										<NavLink className={classnames({ active: this.state.active_tab === '3' })}
											onClick={() => { this.toggle('3'); }} >
											{/* Hero Trips */}
											Trips
										</NavLink>
									</NavItem>
									: ""}
									{/* Temporarily Disabled For ARL Logins */}
								{
								// localStorage.getItem("transportercode").includes("T329") || 
								localStorage.getItem("transportercode").includes("314773") ?

									<NavItem>
										<NavLink className={classnames({ active: this.state.active_tab === '9' })}
											onClick={() => { this.toggle('9'); }} >
											{/* Historic Trips */}
											Historic Trips
										</NavLink>
									</NavItem>

									: ""}
								{localStorage.getItem("email").includes("ivc_rake@enmovil.in")?
								<NavItem >
								<NavLink
									className={classnames({ active: this.state.active_tab === '13' })}
									onClick={() => { this.toggle('13'); }} >
										KIA Rake Devices Information
								</NavLink>
							</NavItem>:""}	
								{/* 
								{(this.state.isRoleExists == 1)?
									<NavItem>
										<NavLink className={classnames({ active: this.state.active_tab === '4' })}
													onClick={() => { this.toggle('4'); }} >
											Non-MSIL Trips
										</NavLink>
									</NavItem>
								:""} */}
								{(email == "rao_track@enmovil.in"||(localStorage.getItem("transportercode").includes("_RAKE")) )? "" : <NavItem>
									{/* <a href="/tptlogin/carrierlocationonmap" className="nav-link">Map View</a> */}
									<NavLink className={classnames({ active: this.state.active_tab === '5' })}
										onClick={() => { this.toggle('5'); }} >
										Map View
									</NavLink>
								</NavItem>}
								{/* Temporarily Disabled For ARL Logins */}
								{/* {(localStorage.getItem("transportercode").includes("T329") == true) ?
									<NavItem>
										<NavLink className={classnames({ active: this.state.active_tab === '7' })}
											onClick={() => { this.toggle('7'); }} >
											
											Trucks Status
										</NavLink>
									</NavItem>
									: ""} */}
								{/* Temporarily Disabled For ARL Logins */}
								{(localStorage.getItem("transportercode").includes("T305") 
								// || localStorage.getItem("transportercode").includes("T329")
							) ?
									<NavItem>
										<NavLink
											className={classnames({ active: this.state.active_tab === '8' })}
											onClick={() => { this.toggle('8'); }} >
											Next Load Planner
										</NavLink>
									</NavItem>
									: ""}
									{(localStorage.getItem("transportercode").includes("_RAKE")) ||(localStorage.getItem("transportercode").includes("_ENTRAC"))?
									"":<NavItem>
									<NavLink
										className={classnames({ active: this.state.active_tab === '11' })}
										onClick={() => { this.toggle('11'); }} >
										Exceptions
									</NavLink>
								</NavItem>}
								</>
								<div className="ml-auto">
									<span style={{ fontSize: '14px', color: '#17a2b8', marginRight: '10px', fontWeight: 'bold' }}>
										{this.state.lastRefreshTime && this.state.active_tab === '1'? `Data Refreshed On: ${this.state.lastRefreshTime}` : ""}
									</span>

								</div>
							</Nav>}
							
							
							<TabContent activeTab={this.state.active_tab}>
							{/* {(Object.keys(this.state.information_tab_data).length > 0) ? */}

								<><TabPane tabId="1">
									<Row>
										<Col sm="12">
											<GPSIntegration information_tab_data={this.state.information_tab_data} active_tab ={this.state.active_tab} />
										</Col>
									</Row>
								</TabPane>
								{console.log(this.state.active_tab ,this.state.information_tab_data_kia,"this.state.active_tab ")}
								{(this.state.active_tab == 13) ?
									<TabPane tabId="13">
									<Row>
										<Col sm="12">
											<GPSIntegration information_tab_data={this.state.information_tab_data_kia} active_tab ={this.state.active_tab}/>
										</Col>
									</Row>
								</TabPane>
									: ""}
								{(this.state.active_tab == 6) ?
									<TabPane tabId="6">
										<Row>
											<Col sm="12">
												<Suspense fallback={<div>Loading...</div>}><TrucksWithGPSDataCharts information_tab_data={this.state.information_tab_data} pageLoad={this.state.active_tab} /></Suspense>
											</Col>
										</Row>
									</TabPane>
									: ""}
									</>

								 {/* :""} */}
								{/* {(this.state.active_tab == 2)? */}
								<TabPane tabId="2">
									<Row>
										<Col sm="12">
											<Suspense fallback={<div>Loading...</div>}><Trips pageLoad={this.state.active_tab} /></Suspense>
										</Col>
									</Row>
								</TabPane>
								{/* :""} */}
								{/* <TabPane tabId="2">
									<Row>
										<Col sm="12">
											<MSILTrips />
										</Col>
									</Row>
								</TabPane> */}
								{(this.state.active_tab == 3) ?
									<TabPane tabId="3">
										<Row>
											<Col sm="12">
												<Suspense fallback={<div>Loading...</div>}><HeroTrips pageLoad={this.state.active_tab} /></Suspense>
											</Col>
										</Row>
									</TabPane>
									: ""}
								{(this.state.active_tab == 4) ?
									<TabPane tabId="4">
										<Row>
											<Col sm="12">
												<Suspense fallback={<div>Loading...</div>}><NONMSILTrips pageLoad={this.state.active_tab} /></Suspense>
											</Col>
										</Row>
									</TabPane>
									: ""}
								{(this.state.active_tab == 5) ?
									<TabPane tabId="5">
										<Row>
											<Col sm="12">
												<Suspense fallback={<div>Loading...</div>}><MapView pageLoad={this.state.active_tab} ontrips={this.state.ontrips} /></Suspense>
											</Col>
										</Row>
									</TabPane>
									: ""}
								{(this.state.active_tab == 7) ?
									<TabPane tabId="7">
										<Row>
											<Col sm="12" >
												<Suspense fallback={<div>Loading...</div>}><TruckStatusTab pageLoad={this.state.active_tab} /></Suspense>
											</Col>
										</Row>
									</TabPane>
									: ""}
								{(this.state.active_tab == 8) ?
									<TabPane tabId="8">
										<Row>
											<Col sm="12">
												<Suspense fallback={<div>Loading...</div>}><NextLoadPlanner pageLoad={this.state.active_tab} /></Suspense>
											</Col>
										</Row>
									</TabPane>
									: ""}
								{(this.state.active_tab == 9) ?
									<TabPane tabId="9">
										<Row>
											<Col sm="12">
												<Suspense fallback={<div>Loading...</div>}><TPTHistoricTrips pageLoad={this.state.active_tab} /></Suspense>
											</Col>
										</Row>
									</TabPane>
									: ""}
								{(this.state.active_tab == 10) ?
									<TabPane tabId="10">
										<Row>
											<Col sm="12">
												<Suspense fallback={<div>Loading...</div>}><OtherOEMTripData pageLoad={this.state.active_tab} /></Suspense>
												\                                    </Col>
										</Row>
									</TabPane>
									: ""}

								{(this.state.active_tab == 11) ?
									<TabPane tabId="11">
										<Row>
											<Col sm="12">
												
												<Suspense fallback={<div>Loading...</div>}><ExceptionsData tpttrucks={this.state.tpttrucks} onTripsTrucksData = {this.state.onTripsTrucksData} exceptionTrucksNos={this.state.exceptionTrucksNos} pageLoad={this.state.active_tab} /></Suspense>
											</Col>
										</Row>
									</TabPane>
									: ""}

							</TabContent>
							
							
						</div>
					</div>

			</div>

		);
	}
}
function GetSortDescOrder(prop) {
	return function (a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		} else if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	}
}