/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import $ from "jquery";
import _ from "lodash";
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Trucklocaction from '../layouts/trucklocaction';
import MarkerClusterer from "@google/markerclusterer";
import Constant from "../common/googlemapkey";
import DrawMap from '../common/drawmap';
import * as Datetime from 'react-datetime';
import Select from 'react-select';
import CryptoJS  from 'crypto-js';

import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault
} from '../common/utils';
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);

var moment = require('moment');
var marker;
var circle;
var allcords = [];
var map;
var rad = function (x) {
	return x * Math.PI / 180;
};
var markersArray;
var viewMarkersArr = [];
var rlatitude;
var rlongitude;
let screenLoaded = ''

const decipher = salt => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	return encoded => encoded.match(/.{1,2}/g)
		.map(hex => parseInt(hex, 16))
		.map(applySaltToChar)
		.map(charCode => String.fromCharCode(charCode))
		.join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

const cipher = (salt) => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	try {
		return text => text.split('')
			.map(textToChars)
			.map(applySaltToChar)
			.map(byteHex)
			.join('');
	}
	catch (e) {

	}

}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");


export default class AndriodGridMap extends Component {

	constructor(props) {
		super(props);

		this.displayData = [];
		this.state = {
			pageTitle: "TPT Truck GPS Data",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			routemap: '',
			allmarkers: '',
			trucks: [],
			truck_no: '',
			selected_truck_no: "",
			startDate: '',
			endDate: '',
			mapinfo: '',
			mapinfoclick: '',
			modules: AllModules,
			defTransitCoords: '',
			activeMarker: {},
			selectedPlace: {},
			dealerCode: '',
			timelinesmarkers: [],
			viewtimelinemarkers: true,
			viewgoogleroutes: true,
			googleroutepath: [],
			loadshow: 'show-n',
			open: false,
			alert: null,
			show: false,
			basicTitle: '',
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true

			},
			gridshow: 'show-m',
			filteredData: [],
			mapshow: 'show-m',
			actgridbtn: "btn-success",
			actmapbtn: "btn-default",
			rowData: [],
			showTrucksList: "show-n",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				trucklocaction: Trucklocaction
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			truckNoList: [],
			overly: "show-n",
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green": "data.status >= 3"

			},
			tolls: [],
			frequency: "",
			showmarkers: 0,
			tottalDistance: [],
			coordinates: [],
			transporterTrucks: [],
			plantLocations: [],
			datashow: "show-n",
			dataht: "40px",
			icofnt: "icofont-rounded-up",
			icshow: "show-m",
			total_break: '',
			screen: '',
			break_duration: '',
			dispaly_data: [],
			mobliemapinfo: [],
			duration : {label:"24 Hours" , value  :1},
			trip_no :"",
			is_link_expired:false
		};
		this.popmarker = this.popmarker.bind(this);
		this.onClickShowTruckLocation = this.onClickShowTruckLocation.bind(this);
	}



	componentDidMount = async () => {

		console.log("match.params ", this.props.match.params)
		var user = this.props.match.params.user;
		var truckno = this.props.match.params.code;
		screenLoaded = this.props.match.params.screen
		this.setState({
			truck_no: truckno,
			screen: this.props.match.params.screen
		})
		if (this.props.match.params.screen == "truckgps" ) {
			var sdate = this.props.match.params.sdate;
			var edate = this.props.match.params.edate;
			var frequency = this.props.match.params.frequency;
			var startdate = sdate.replace("%20", " ");
			var enddate = edate.replace("%20", " ");
			if (user == "tptlogin") {
				var formdata = {
					truck_no: truckno,
					// startDate:sdate+" 00:00:00",
					// endDate:edate+" 23:59:59",
					startDate: startdate,
					endDate: enddate,
					frequency: frequency,
					source: "tptlogin"
				}
			}
			else {
				formdata = {
					truck_no: truckno,
					// startDate:sdate+" 00:00:00",
					// endDate:edate+" 23:59:59",

					startDate: startdate,
					endDate: enddate,
					frequency: frequency,
				}
			}

			// console.log("formdata ", formdata)
			await redirectURL.post("/gmap", formdata, {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': "*",
					'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
					'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
				}
			})
				//.then(res => res.json())
				.then(
					async (result) => {
						// console.log("result",result.data);
						var jsondata = result.data;
						console.log('result', JSON.parse(jsondata.data))
						if (result.data.status == 'failure') {
							this.setState({
								show: true,
								basicTitle: 'No GPS data available for this period',
								basicType: "danger",
								loadshow: 'show-n',
								rowData: null,
								mapinfo: '',
							})
						}
						else {
							var coordinates = JSON.parse(jsondata.data);
							//console.log("truckno ", this.props.truckno)
							// console.log("coordinates ", coordinates)
							var dataarr = []
							var tottalDistance = [];
							//console.log("coordinates ", coordinates)
							coordinates.coords.map((item) => {
								// console.log("item ", item)
								tottalDistance.push(parseFloat(item.cumm_distance));

								dataarr.push({
									"truck_no": item.truck_no,
									"lat": item.lat,
									"lng": item.lng,
									"speed": item.speed,
									"stime": item.stime,
									//"state":item.state,
									//"area":item.area,
									//"city":item.city,
									//"location_type":item.location_type,
									"dist_from_prev_point": item.dist_from_prev_point,
									"received_on": item.received_on,
									"time_from_prev_point": item.time_from_prev_point,
									"area": item.area,
									"state": item.state,
									"cumm_distance": item.cumm_distance
								})
							});
							var pinnedBottomRowData = {}
							pinnedBottomRowData['_id'] = "total";
							pinnedBottomRowData['truck_no'] = "";
							pinnedBottomRowData['lat'] = "";
							pinnedBottomRowData['lng'] = "";
							pinnedBottomRowData['speed'] = "";
							pinnedBottomRowData['stime'] = "";
							pinnedBottomRowData['dist_from_prev_point'] = "";
							pinnedBottomRowData['received_on'] = "";
							pinnedBottomRowData['time_from_prev_point'] = "Total";
							pinnedBottomRowData['area'] = "";
							pinnedBottomRowData['states'] = "Total";
							pinnedBottomRowData['cumm_distance'] = arrayMax(tottalDistance);
							pinnedBottomRowData['total_travel_time_mins'] = "";
							this.setState({
								rowData: dataarr,
								coordinates: coordinates.coords,
								tottalDistance: [pinnedBottomRowData],
								mapinfo: JSON.parse(jsondata.data),
								loadshow: 'show-n',
								truck_no: truck_no,
								tolls: JSON.parse(jsondata.data).tolls,
								dispaly_data: {
									truck_no: truckno,
									start_time: coordinates.route_details.start_time,
									end_time: coordinates.route_details.end_time,
									distance: coordinates.route_details.distance,

								}

							})
							this.renderMap()

						}

					}
				)
				.catch(function (error) {
					console.log(error);
				});
		}
		if (this.props.match.params.screen == "sharelink") {
			this.getShareLinkData()
		}

		if (this.props.match.params.screen == "mapview") {
			var tptCode = [this.props.match.params.code]

			if (this.props.match.params.trip_type == "all") {
				var triptype = 1
			}
			if (this.props.match.params.trip_type == "ontrip") {
				var triptype = 2
			}
			if (this.props.match.params.trip_type == "atdealer") {
				var triptype = 3
			}
			if (this.props.match.params.trip_type == "emptytrips") {
				var triptype = 4
			}
			var formdata = {
				radius: 3000,
				latitude: "20.73568784876339",
				longitude: "79.38999444931353",
				dept_code: this.props.match.params.dept,
				transporter_code: JSON.stringify(tptCode),
				status: 0,
				trip_type: triptype
			}
			rlatitude = "20.73568784876339";
			rlongitude = "79.38999444931353";
			//http://autometrics.in:5000/getNearByTrucks
			redirectURL.post('/vicinity/getRadiusCoordinates', formdata, {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
					'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

				}
			})
				.then(
					(response) => {
						//console.log(response)
						//console.log("response", JSON.parse(response.data.body));
						// console.log(response.data.body)
						var mrkers = JSON.parse(JSON.parse(response.data.body).trucks_data);
						//console.log(mrkers,"markers");
						//console.log(response.data.body,"resppp");
						redirectURL.post("/dashboard/devices").then(async (response1) => {

							var transporterTrucks = [];
							var transptrucklist = [];
							response1.data.devices.map(function (e) {
								if (tptCode.indexOf(e.transporter_code) > -1) {
									transporterTrucks.push(e)
									transptrucklist.push(e.truck_no);
								}
							})

							if (mrkers.length > 0) {
								console.log(transporterTrucks, "transporterTrucks")
								var markers = [];
								if (transporterTrucks.length > 0) {
									// mrkers.map(function(t){
									// 	if(transporterTrucks.indexOf(t.truck_no) > -1 )
									// 	{
									// 		markers.push(t)
									// 	}
									// })

									markers = await mrkers.filter(f =>
										transporterTrucks.filter((d) => {
											if (d.truck_no == f.truck_no) {
												// console.log("API ", d)
												if (d.registereddevices != undefined) {
													if (d.registereddevices.length > 0) {
														f.device_address = d.registereddevices[0].address
														f.device_city = d.registereddevices[0].city
														f.device_state = d.registereddevices[0].state
													}
												}
												return f;
											}
										})
									);
								}
								console.log(markers, "markersfilter")
								this.setState({
									defaultradius: formdata.radius,
									coordinates: markers,
									totalTrucksCount: markers.length,
									mwidth: "col-xl-12 col-lg-12",
									rowData: markers,
									radius: 5000,
									loadshow: 'show-n',
									showform: 'show-n',
									showrefresh: 'show-m',
									rlatitude: "21.144644112601775",
									rlongitude: "79.08860126768066",
									alltrucks: markers,
									//onTripCounter : JSON.parse(response.data.body).gps_trucks,
									onTripCounter: markers.length,
									transporterTrucks: transporterTrucks,
									truckNoList: transptrucklist
								});
								redirectURL.post("/weather/getPlants").then((rsp) => {
									this.setState({
										plantLocations: rsp.data,
									});
									//console.log(rsp.data);
									this.renderMap();

								})
								this.renderMap();
							}
							else {
								this.setState({
									show: true, basicType: 'warning', basicTitle: "No Data found.",
									loadshow: 'show-n',
									showform: 'show-m',
								});

							}
						})

					})
				.catch(function (error) {
					console.log(error);
				});
		}
		// trucklocation

		if (this.props.match.params.screen == "trucklocation") {
			var truck_no = this.props.match.params.truckno
			var paramers = {
				truck_no: truck_no
			}
			redirectURL.post("/consignments/mobiletrucklocation", paramers)
				.then((response) => {
					console.log("response", response.data.records)
					var info = response.data.records;
					console.log(response.data.trucksDetails[0], "1729")
					var start_date = ''
					var edate = ''
					var end_date = ''
					var distance_in_km = ''
					var trip_no = ''
					if (response.data.trucksDetails.length > 0) {
						var latestRecord = response.data.trucksDetails[0]
						start_date = moment.parseZone(latestRecord.invoice_date).format('YYYY-MM-DD HH:mm:ss');
						if (latestRecord.status <= 2) {
							// console.log("status less than 2");
							end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
							edate = getHyphenDDMMYYYYHHMMSS(end_date);
						}
						else if (latestRecord.status == 3 || latestRecord.status == 4) {
							if (latestRecord.recent_dealer_reported) {
								end_date = latestRecord.recent_dealer_reported.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
								edate = getHyphenDDMMYYYYHHMMSS(latestRecord.recent_dealer_reported);
							}
							// console.log("status 3 and 4");
						}
						else if (latestRecord.status == 5) {
							if (latestRecord.left_dealer_marked) {
								end_date = latestRecord.left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
								edate = getHyphenDDMMYYYYHHMMSS(latestRecord.left_dealer_marked);
							}
							// console.log("status 5");
						}
						else if (latestRecord.status == 6) {
							if (latestRecord.force_closure_time) {
								end_date = latestRecord.force_closure_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
								edate = getHyphenDDMMYYYYHHMMSS(latestRecord.force_closure_time);
							}
							// console.log("status 6");
						}
						else {
							end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')

							edate = getHyphenDDMMYYYYHHMMSS(end_date);
							// console.log("in else condition");
						}
						distance_in_km = latestRecord.distance_in_km
						trip_no = latestRecord.trip_no
					}

					if (info.length > 0) {
						var ardata = {
							coords: [
								{
									latitude: info[0].last_known_latitude,
									longitude: info[0].last_known_longitude,
									address: info[0].address,
								}
							],
							breaks: []
						}


						this.setState({
							mapinfo: ardata,
							mobliemapinfo: info,
							dispaly_data: {
								truck_no: truck_no,
								consignment_code: trip_no,
								start_time: start_date,
								end_time: end_date,
								distance: distance_in_km * 1000
							}
						})
						this.renderMap();
					}

				})
		}

		if(this.props.match.params.screen === "trips"){
			console.log(this.props.match.params)
			var truck_no = this.props.match.params.truck_no
			var trip_no = this.props.match.params.trip_no
			var params = {
				truck_no ,
				trip_no
			}
			var decryptedExpiryTime = decode(this.props.match.params.time)
			console.log(decryptedExpiryTime , "decryptedExpiryTime")
			var current_date = new Date().getTime()
			var expired_date = new Date(decryptedExpiryTime).getTime()
			console.log(current_date , expired_date)
			if( current_date < expired_date){
				redirectURL.post("/dashboard/getConsignmetDetailsForMap" , params).then(response => {
					var records = response.data
					console.log(records , "records_1729")
				
	
				var e = records[0]
				if (e.invoice_date) {
					var gateouttime = moment.parseZone(e.invoice_date).format('YYYY-MM-DD HH:mm:ss');
				}
				else {
					if (e.gate_in_time) {
						var gateouttime = moment.parseZone(e.gate_in_time).format('YYYY-MM-DD HH:mm:ss');
					}
					else {
						if (e.gate_out_time) {
							var gateouttime = moment.parseZone(e.gate_out_time).format('YYYY-MM-DD HH:mm:ss');
						}
					}
				}	
				var end_date = "";
				if (e.status <= 2) {
					end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
					edate = getHyphenDDMMYYYYHHMMSS(end_date);
				}
				else if (e.status == 3 || e.status == 4) {
					if (e.recent_dealer_reported) {
						end_date = e.recent_dealer_reported.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						edate = getHyphenDDMMYYYYHHMMSS(e.recent_dealer_reported);
					}else{
						end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
						edate = getHyphenDDMMYYYYHHMMSS(end_date);
					}
				}
				else if (e.status == 5) {
					if (e.left_dealer_marked) {
						end_date = e.left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						edate = getHyphenDDMMYYYYHHMMSS(e.left_dealer_marked);
					}
				}
				else if (e.status == 6) {
					if (e.force_closure_time) {
						end_date = e.force_closure_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						edate = getHyphenDDMMYYYYHHMMSS(e.force_closure_time);
					}
				}
				console.log(e.status , "status")
				if(e.status == 2 ||e.status == 3||e.status == 4){
					var dealer_code = !["" , undefined , "undefined" , null].includes(e.dealer_code)?e.dealer_code  :""
					var formData = {
						truck_no: this.props.match.params.truck_no,
						consignment_code: this.props.match.params.trip_no,
						consignee_code: dealer_code,
						gate_out_time:gateouttime,
						seconddate: end_date,
						screen: "consignment"
		
					}
					this.setState({
						loadshow:'show-m',
						overly:"show-m"
					})
					console.log(formData , "formdata_1729")
					redirectURL.post('/consignments/maproutes', formData, {
						headers: {
							'content-type': 'application/json'
						}
					}).then( async (response) => {
							var records = response.data;
							console.log("Props data ", records)
							try {
								if (records.status == "failure" || records.coords.length == 0) {
									console.log("records", records.coords);
									if (records.status != undefined) {
										this.setState({
											show: true,
											basicTitle: 'No Route data available',
											basicType: "danger",
											loadshow: 'show-n',
											overly: 'show-n',
										});
									}
			
								}
								else {
									if (records != '') {
										console
										await this.setState({
											mapinfo: records,
											loadshow: 'show-n',
											overly: 'show-n',
										});
										this.renderMap();
			
									}
								}
							}
							catch (e) {
								this.setState({
									show: true,
									basicTitle:'No Route data available',
									basicType:"danger",
									loadshow: 'show-n',
									overly: 'show-n',
								});
							}
			
						})
						.catch(function (error) {
							console.log(error);
						});
		
				}
				else{
					this.setState({
						show: true,
						basicTitle:'Trip has Ended',
						basicType:"danger",
						loadshow: 'show-n',
						overly: 'show-n',
					});
				}
				})
			}
			else{
				this.setState({
					is_link_expired : true,
					loadshow: 'show-n',
					overly: 'show-n',
				});
			}
			
		}

		if (this.props.match.params.screen == "rakelocation"){
				var truck_no = this.props.match.params.truckno
				var sdate = moment.parseZone().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');		
				var enddate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
				var formdata= {
					truck_no:truck_no,
					startDate: sdate,
					endDate:enddate,
					frequency:"",
					source:"tptlogin"
				}

				redirectURL.post("/gmap",formdata,{
					headers:{
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin':"*",
						'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
						'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
					}
				})
				.then(
				async (result) => {
					var jsondata = result.data;
					if(jsondata.status == 'failure')
					{
						this.setState({
							show:true,
							basicTitle:'No GPS data available for this period',
							basicType:"danger",
							loadshow:'show-n',
							overly:"show-n",
							mapData:null,
							mapinfo:'',
						})
					}
					else{
						var coordinates = JSON.parse(jsondata.data);
						var dataarr=[]
						this.setState({
							mapData:dataarr,
							mapinfo:coordinates,
							loadshow:'show-n',
							overly:'show-m',
							tolls : coordinates.tolls,
						})
					}
						
				}
				)
		}
		let screen = this.props.match.params.screen
		let check = ["nightdriving", "overspeed", 'continuous', 'stoppage', 'breaking_acceleration'].includes(screen)
		if (check) {
			var sdate = this.props.match.params.sdate;
			var edate = this.props.match.params.edate;
			var startdate = sdate.replace("%20", " ");
			var enddate = edate.replace("%20", " ");
			if (user == "tptlogin") {
				var formdata = {
					truck_no: truckno,
					// startDate:sdate+" 00:00:00",
					// endDate:edate+" 23:59:59",
					startDate: startdate,
					endDate: enddate,
					// source:"tptlogin"
				}
			}
			else {
				var formdata = {
					truck_no: truckno,
					// startDate:sdate+" 00:00:00",
					// endDate:edate+" 23:59:59",

					startDate: startdate,
					endDate: enddate
				}
			}

			// console.log("formdata ", formdata)
			await redirectURL.post("/gmap", formdata, {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': "*",
					'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
					'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
				}
			})
				//.then(res => res.json())
				.then(
					async (result) => {
						console.log("result1729 ", result.data);
						var jsondata = result.data;
						console.log('gmapData', JSON.parse(jsondata.data))
						if (result.data.status == 'failure') {
							this.setState({
								show: true,
								basicTitle: 'No GPS data available for this period',
								basicType: "danger",
								loadshow: 'show-n',
								rowData: null,
								mapinfo: '',
								screen
							})
						}
						else {
							var coordinates = JSON.parse(jsondata.data);
							//console.log("truckno ", this.props.truckno)
							// console.log("coordinates ", coordinates)
							var dataarr = []
							var tottalDistance = [];
							//console.log("coordinates ", coordinates)
							coordinates.coords.map((item) => {
								// console.log("item ", item)
								tottalDistance.push(parseFloat(item.cumm_distance));

								dataarr.push({
									"truck_no": item.truck_no,
									"lat": item.lat,
									"lng": item.lng,
									"speed": item.speed,
									"stime": item.stime,
									//"state":item.state,
									//"area":item.area,
									//"city":item.city,
									//"location_type":item.location_type,
									"dist_from_prev_point": item.dist_from_prev_point,
									"received_on": item.received_on,
									"time_from_prev_point": item.time_from_prev_point,
									"area": item.area,
									"state": item.state,
									"cumm_distance": item.cumm_distance
								})
							});
							var pinnedBottomRowData = {}
							pinnedBottomRowData['_id'] = "total";
							pinnedBottomRowData['truck_no'] = "";
							pinnedBottomRowData['lat'] = "";
							pinnedBottomRowData['lng'] = "";
							pinnedBottomRowData['speed'] = "";
							pinnedBottomRowData['stime'] = "";
							pinnedBottomRowData['dist_from_prev_point'] = "";
							pinnedBottomRowData['received_on'] = "";
							pinnedBottomRowData['time_from_prev_point'] = "Total";
							pinnedBottomRowData['area'] = "";
							pinnedBottomRowData['states'] = "Total";
							pinnedBottomRowData['cumm_distance'] = arrayMax(tottalDistance);
							pinnedBottomRowData['total_travel_time_mins'] = "";

							if (this.props.match.params.screen == "overspeed") {
								if (coordinates.route_details.start_time !== undefined && coordinates.route_details.end_time !== undefined) {
									let startDate = coordinates.route_details.start_time
									let endDate = coordinates.route_details.end_time
									let diff = new Date(endDate).getTime() - new Date(startDate).getTime()
									// console.log('endDate',new Date(endDate), '\n startDate',new Date(startDate), '\n seconds',new Date(endDate) - new Date(startDate))
									let diffTime = secondsToString(diff / 1000)
									// console.log('diffTime',diffTime)
									coordinates.route_details['total_break'] = diffTime
									this.setState({
										total_break: diffTime
									})
								}
							}
							if (this.props.match.params.screen == "stoppage") {
								let breaks = coordinates.breaks[0]
								let seconds = breaks['break_time_seconds']
								if (seconds !== undefined && seconds !== '' && seconds !== 'undefined') {
									var break_duration = secondsToHM(seconds)
									this.setState({
										total_break: break_duration
									})
								}
							}
							this.setState({
								screen,
								rowData: dataarr,
								coordinates: coordinates.coords,
								tottalDistance: [pinnedBottomRowData],
								mapinfo: JSON.parse(jsondata.data),
								loadshow: 'show-n',
								truck_no: truck_no,
								tolls: JSON.parse(jsondata.data).tolls,
								dispaly_data: {
									truck_no: truckno,
									start_time: coordinates.route_details.start_time,
									end_time: coordinates.route_details.end_time,
									distance: coordinates.route_details.distance,

								}
							})
							this.renderMap()
						}
					}
				)
				.catch(function (error) {
					console.log(error);
				});
		}

	}

	

	handleEncrypt = (word) => {
		const plaintext = word;
		const key = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef');
		const iv = CryptoJS.enc.Hex.parse('0102030405060708090a0b0c0d0e0f00');
		const encryptedBytes = CryptoJS.AES.encrypt(plaintext, key, {
			iv: iv
		});
		const encryptedBase64 = encryptedBytes.toString();
		const encryptedText = encodeURIComponent(encryptedBase64);
		return encryptedText;
	};
	
	handleDecrypt = (word) => {
		const encryptedText = decodeURIComponent(word);
		const key = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef');
		const iv = CryptoJS.enc.Hex.parse('0102030405060708090a0b0c0d0e0f00');
		const decryptedBytes = CryptoJS.AES.decrypt(encryptedText, key, {
			iv: iv
		});
		const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
		return decryptedText;
	};

	getShareLinkData = async () => {
		this.setState({
			loadshow:"show-m",
			overly:"show-m"
		})
		var truck_no = this.props.match.params.truck_no	

		var from_date = await this.handleDecrypt(this.props.match.params.fromdate)
		var expiry_date = await this.handleDecrypt(this.props.match.params.expirydate)
		console.log(from_date , expiry_date, "943")

		var startdate = moment.parseZone(new Date(from_date)).format('YYYY-MM-DD HH:mm:ss');
		var enddate = moment.parseZone(new Date(expiry_date)).format('YYYY-MM-DD HH:mm:ss');
		var frequency = "1"

		var current_date = new Date().getTime()
		var expired_date = new Date(expiry_date).getTime()

		if(current_date < expired_date){
			var frequency = "1"
			if ( this.props.match.params.user == "tptlogin") {
				var formdata = {
					truck_no:truck_no,
					// startDate:sdate+" 00:00:00",
					// endDate:edate+" 23:59:59",
					startDate: startdate,
					endDate: enddate,
					frequency: frequency,
					source: "tptlogin"
				}
			}
			else {
				formdata = {
					truck_no: truck_no,
					// startDate:sdate+" 00:00:00",
					// endDate:edate+" 23:59:59",

					startDate: startdate,
					endDate: enddate,
					frequency: frequency,
				}
			}

			console.log("formdata ", formdata)
			await redirectURL.post("/gmap", formdata, {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': "*",
					'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
					'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
				}
			})
				//.then(res => res.json())
				.then(
					async (result) => {
						console.log("result",result);
						var jsondata = result.data;
						if (result.data.status == 'failure') {
							this.setState({
								show: true,
								basicTitle: 'No GPS data available for this period',
								basicType: "danger",
								loadshow: 'show-n',
								rowData: null,
								mapinfo: '',
								loadshow:"show-n",
								overly:"show-n"
							})
						}
						else {
							var coordinates = JSON.parse(jsondata.data);
							//console.log("truckno ", this.props.truckno)
							// console.log("coordinates ", coordinates)
							var dataarr = []
							var tottalDistance = [];
							//console.log("coordinates ", coordinates)
							coordinates.coords.map((item) => {
								// console.log("item ", item)
								tottalDistance.push(parseFloat(item.cumm_distance));

								dataarr.push({
									"truck_no": item.truck_no,
									"lat": item.lat,
									"lng": item.lng,
									"speed": item.speed,
									"stime": item.stime,
									//"state":item.state,
									//"area":item.area,
									//"city":item.city,
									//"location_type":item.location_type,
									"dist_from_prev_point": item.dist_from_prev_point,
									"received_on": item.received_on,
									"time_from_prev_point": item.time_from_prev_point,
									"area": item.area,
									"state": item.state,
									"cumm_distance": item.cumm_distance
								})
							});
							var pinnedBottomRowData = {}
							pinnedBottomRowData['_id'] = "total";
							pinnedBottomRowData['truck_no'] = "";
							pinnedBottomRowData['lat'] = "";
							pinnedBottomRowData['lng'] = "";
							pinnedBottomRowData['speed'] = "";
							pinnedBottomRowData['stime'] = "";
							pinnedBottomRowData['dist_from_prev_point'] = "";
							pinnedBottomRowData['received_on'] = "";
							pinnedBottomRowData['time_from_prev_point'] = "Total";
							pinnedBottomRowData['area'] = "";
							pinnedBottomRowData['states'] = "Total";
							pinnedBottomRowData['cumm_distance'] = arrayMax(tottalDistance);
							pinnedBottomRowData['total_travel_time_mins'] = "";
							this.setState({
								rowData: dataarr,
								coordinates: coordinates.coords,
								tottalDistance: [pinnedBottomRowData],
								mapinfo: JSON.parse(jsondata.data),
								loadshow: 'show-n',
								truck_no: truck_no,
								tolls: JSON.parse(jsondata.data).tolls,
								dispaly_data: {
									truck_no: truck_no,
									start_time: coordinates.route_details.start_time,
									end_time: coordinates.route_details.end_time,
									distance: coordinates.route_details.distance,
								},
								loadshow:"show-n",
								overly:"show-n"
							})
							this.renderMap()

						}

					}
				)
				.catch(function (error) {
					console.log(error);
				});
		}else{
			this.setState({
				is_link_expired : true,
				loadshow: 'show-n',
				overly: 'show-n',
			});
		}
			
	}

	renderMap = () => {

		loadScript("https://maps.googleapis.com/maps/api/js?key=" + Constant.GOOGLE_KEY + "&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}

	initMap = () => {
		console.log("Check whether it is hitting")
		let screen = ['truckgps','sharelink', 'nightdriving', 'overspeed', 'continuous', 'stoppage', 'breaking_acceleration',"trips"].includes(this.props.match.params.screen)
		if (screen) {
			var currentwindow;
			var dealer = '';
			var tolls = this.state.tolls;

			var arr = [];
			var jsondata = this.state.mapinfo;
			var locations = jsondata.breaks;
			var arr = jsondata.coords;
			if (arr.length > 0 && typeof arr.length != undefined) {
				var lt = arr[0].lat;
				var ln = arr[0].lng;
			}
			else {
				var lt = 28.4519751;
				var ln = 77.0310713;
			}
			var routeinfo;

			if (arr.length > 0) {
				var mapOptions = {
					zoom: 13,
					zoomControl: true,
					mapTypeControl: true,
					scaleControl: true,
					streetViewControl: true,
					rotateControl: true,
					fullscreenControl: true,
					labels: true,
					mapTypeControlOptions: {
						mapTypeIds: ['satellite', 'roadmap'],
					},
					center: new window.google.maps.LatLng(lt, ln),
					mapTypeId: window.google.maps.MapTypeId.ROADMAP
				};

				map = new window.google.maps.Map(document.getElementById('map_mobile'), mapOptions);
				var bounds = new window.google.maps.LatLngBounds();
				bounds.extend(new window.google.maps.LatLng(lt, ln));
				// Create our info window content
				var currentinfowindow = null;
				var line = new window.google.maps.Polyline(
					{
						map: map,
						strokeColor: '#157254',
						strokeOpacity: 1.0,
						strokeWeight: 2.5,
						icons: [{
							// icon: {
							// 		path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
							// 		strokeColor:'#ff8c52',
							// 		fillColor:'#ff8c52',
							// 		fillOpacity:1,
							// 		strokeWeight: 2
							// },
							repeat: '100px',
							path: []
						}]
					});
				if (arr.length > 0) {
					var allpoints = [];

					var arr1 = [];
					//console.log("Total ",ratio);
					if (this.state.timelinesmarkers.length > 0) {
						arr1 = this.state.timelinesmarkers.filter(function (value, index, arr) {
							return (index % ratio == 0);
						});


						//var arr1 = this.state.timelinesmarkers;
					}

					var arr2 = [];
					var darr = [];
					if (arr.length > 0) {
						darr = arr.filter(function (value, index, arr) {
							//console.log("Vale ",value)
							return (value.dist_from_prev_point > 0);
						});
						if (darr.length < 2000) {
							var ratio = 1;
						}
						else {
							var ratio = 20;
						}
						//console.log("darr.length ", darr.length)
						arr2 = darr.filter(function (value, index, darr) {
							return (index % ratio == 0);
						});
						//var arr1 = this.state.timelinesmarkers;
					}
					// view markers code
					// console.log("arr here ", arr)
					console.log("arr2 here ", arr2)
					if (this.state.screen == 'breaking_acceleration') {
						arr2 = arr
					}
					if (arr2.length > 0) {

						markersArray = arr2;
						// //console.log("allpoints ", allpoints)

						for (var a = 0; a < arr2.length; a++) {
							var marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(arr2[a].lat, arr2[a].lng),
								icon: {
									path: window.google.maps.SymbolPath.CIRCLE,
									strokeColor: '#157254',
									fillColor: '#157254',
									fillOpacity: 1,
									strokeWeight: 5,
									scale: 1,
								},
								map: map,
								content: arr2[a]
							});
							marker.setVisible(false);
							// 	//console.log("Arr ",arr1[a])
							window.google.maps.event.addListener(marker, 'mouseover', (function (marker) {
								return function () {
									console.log("addListener ", marker)
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
									var header = "Current Status"
									contentarr.push({ "key": "Time ", "value": getDDMMYYYYHHMMSS(marker.content.stime) })
									contentarr.push({ "key": "Speed ", "value": marker.content.speed + " KMPH" })

									var contentString = infoBox("", header, contentarr, '')


									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
								}
							})(marker));
							window.google.maps.event.addListener(marker, 'mouseout', function () {
								infowindow.close();
							});

							viewMarkersArr.push(marker);

						}
					}
					var routeinfo = jsondata.route_details;
					var icon_cnt = 100
					for (var i = 0; i < arr2.length - 1; i++) {
						var locationLatLng = [];
						locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
						locationLatLng.push(new window.google.maps.LatLng(arr2[i + 1].lat, arr2[i + 1].lng));
						if (icon_cnt % 10 == 0) {
							var lineicon = [{
								// icon: {
								//         path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
								//         strokeColor:'#FF0000',
								//         fillColor:'#FF0000',
								//         fillOpacity:1,
								//         strokeWeight: 2
								// },
								repeat: '100px',
								path: []
							}];
						} else { lineicon = [] }
						icon_cnt = icon_cnt + 1
						var consignments_missing_route_line = new window.google.maps.Polyline({
							map: map,
							path: locationLatLng,
							strokeColor: '#157254',
							strokeOpacity: 1.0,
							strokeWeight: 2.5,
							icons: lineicon
						});
						// console.log(arr2[i].msgtype)
						if (arr2[i].msgtype == "G") {
							consignments_missing_route_line.setOptions({
								strokeColor: "#452a68",
								strokeWeight: 5.5,
							})
						}
						bounds.extend(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
					}



					var infowindow = new window.google.maps.InfoWindow();
					var marker, l;

					for (l = 0; l < locations.length; l++) {
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
							icon: require('../../assets/icons/cf.png'),
							map: map,
						});

						window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
							return function () {
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";

								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Break";
								try {
									if (typeof this.props.mapFor != 'undefined') {
										var timeinsecs = moment.parseZone(locations[l].break_start).format("x") - moment.parseZone(locations[l].break_start).format("x")
										contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
										contentarr.push({ "key": "Break End", "value": locations[l].break_end })
										contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
										contentarr.push({ "key": "Address", "value": locations[l].break_address })
										contentarr.push({ "key": "City", "value": locations[l].city })
										contentarr.push({ "key": "State", "value": locations[l].break_state })
									}
									else {
										contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
										contentarr.push({ "key": "Break End", "value": locations[l].break_end })
										contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
										contentarr.push({ "key": "Address", "value": locations[l].break_address })
										contentarr.push({ "key": "City", "value": locations[l].city })
										contentarr.push({ "key": "State", "value": locations[l].break_state })
									}
								}
								catch (e) {
									contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
									contentarr.push({ "key": "Break End", "value": locations[l].break_end })
									contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
									contentarr.push({ "key": "Address", "value": locations[l].break_address })
									contentarr.push({ "key": "City", "value": locations[l].city })
									contentarr.push({ "key": "State", "value": locations[l].break_state })
								}


								var contentString = infoBox(marker.icon, header, contentarr, '')

								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log(marker.position.toJSON());
								console.log('{"lat":' + marker.position.lat() + ',"lng":' + marker.position.lng() + '}');
							}
						})(marker, l));
					}
					marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
						icon: require('../../assets/icons/track_start.png'),
						map: map,
					});
					window.google.maps.event.addListener(marker, 'click', (function (marker) {
						return function () {
							//	console.log(clusters[0])

							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
							var contentarr = []
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
							var header = "Starting Point"
							contentarr.push({ "key": "Truck No", "value": arr[0].truck_no })
							contentarr.push({ "key": "Started at", "value": routeinfo.start_time })
							if (screenLoaded == 'breaking_acceleration') {
								contentarr.push({ "key": "Area", "value": arr[0].area })
								contentarr.push({ "key": "District", "value": arr[0].district })
								contentarr.push({ "key": "City", "value": arr[0].city })
							}

							var contentString = infoBox(marker.icon, header, contentarr, '')

							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							// console.log('{"lat":' + marker.position.lat() + ',"lng":' + marker.position.lng() + '}');
						}
					})(marker));
					marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
						icon: require('../../assets/icons/truck-end.png'),
						map: map,
					});
					window.google.maps.event.addListener(marker, 'click', (function (marker) {
						return function () {
							console.log(marker, "markers")

							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
							var contentarr = []
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
							var header = "End Point"
							contentarr.push({ "key": "End at", "value": routeinfo.end_time })

							var contentString = infoBox(marker.icon, header, contentarr, '')

							infowindow.setContent(contentString);
							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							console.log('{"lat":' + marker.position.lat() + ',"lng":' + marker.position.lng() + '}');
						}
					})(marker));

				}
				try {
					if (tolls.length > 0) {
						var infowindow = new window.google.maps.InfoWindow();
						var l;
						tolls.map(function (e, index) {
							var tollMarker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(e.lat, e.lon),
								icon: require('../../assets/icons/barrier.png'),
								map: map,
							});
							window.google.maps.event.addListener(tollMarker, 'mouseover', (function (tollMarker, index) {
								return function () {
									var contentarr = []
									var header = "Toll - " + e.name
									contentarr.push({ "key": "Address", "value": e.location })
									var contentString = infoBox(tollMarker.icon, header, contentarr)
									infowindow.setContent(contentString);
									var currentwindow = infowindow;
									infowindow.open(map, tollMarker);
								}
							})(tollMarker, index));
							window.google.maps.event.addListener(tollMarker, 'mouseout', function () {
								infowindow.close();
							});
						})

					}
				} catch (e) { }


				map.fitBounds(bounds)
			}
		}

		if (this.props.match.params.screen == "mapview") {

			allcords = [];
			this.state.coordinates.map((marker) => {
				allcords.push(marker);
			});

			var contentdata = this.state.contentString;
			if (this.state.rlatitude == '' && this.state.rlongitude == '') {
				var lt = 21.144644112601775;
				var ln = 79.08860126768066;
			}
			else {
				var lt = this.state.rlatitude;
				var ln = this.state.rlongitude;
			}
			try {
				map = new window.google.maps.Map(document.getElementById('map_mobile_mapview'), {
					zoom: 5,
					center: new window.google.maps.LatLng(lt, ln),
					mapTypeId: window.google.maps.MapTypeId.ROADMAP,
					/*mapTypeControl: true,
					gestureHandling: 'greedy',
					mapTypeControlOptions: {
						style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
						position: window.google.maps.ControlPosition.TOP_RIGHT
					},            
					disableDefaultUI: true,
					zoomControl: true*/
				});
			}
			catch (e) {
				// map = new window.google.maps.Map(document.getElementById('map_mobile_mapview'), {
				// 	zoom: 5,
				// 	center: new window.google.maps.LatLng(parseFloat(21.144644112601775),parseFloat(79.08860126768066)),
				// 	mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				// 	/*mapTypeControl: true,
				// 	gestureHandling: 'greedy',
				// 	mapTypeControlOptions: {
				// 		style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				// 		position: window.google.maps.ControlPosition.TOP_RIGHT
				// 	  },            
				// 	disableDefaultUI: true,
				// 	zoomControl: true*/
				// });
			}

			circle = new window.google.maps.Circle({
				map: map
			});

			marker = new window.google.maps.Marker({
				map: map,
				draggable: true,
				animation: window.google.maps.Animation.DROP,
				//position: {lat: 28.48, lng: 77.06}
			});

			marker.addListener('click', function () {
				marker.setAnimation(window.google.maps.Animation.BOUNCE)
			});

			window.google.maps.event.addListener(map, 'click', function (event) {

				//document.getElementById("latitude").value = event.latLng.lat();
				//document.getElementById("longitude").value = event.latLng.lng();
				marker.setPosition(event.latLng);

			});
			circle.bindTo('center', marker, 'position');
			var markerLatlng1;
			//console.log("Radius ", this.state.radius)
			// console.log(allcords);
			var markers = allcords.map((marker) => {
				// if(marker.truck_no = "HR46E1297")
				// {
				// 	console.log("marker123 ",marker);
				// }

				if (marker.status == 1) {
					var truckText = "Inside Plant";
					var color = "#333333";
					var image = require('../../assets/icons/truck_inside_24.png');
				}
				else if (marker.status == 2) {
					var truckText = "Going to Dealer";
					var color = "#333333";
					var image = require('../../assets/icons/truck_going_24.png');
				}
				else if (marker.status == 3 || marker.status == 4) {
					var truckText = "At Dealer Location";
					var color = "#333333";
					var image = require('../../assets/icons/truck_atdealer_24.png');
				}
				else if (marker.status == 5) {
					var truckText = "Left Destination Location";
					var color = "#333333";
					var image = require('../../assets/icons/truck_leftdealer_24.png');
				}
				else if (marker.status == 6) {
					var truckText = "Return to Plant";
					var color = "#333333";
					var image = require('../../assets/icons/truck_return_24.png');
				}
				else if (marker.status == 7) {
					var truckText = "Empty Truck Outside Plant";
					var color = "#333333";
					var image = require('../../assets/icons/truck_empty_24.png');
				}
				else {
					var truckText = marker.truck_no;
					var color = "#333333";
					var image = require('../../assets/icons/truck_na_24.png');
				}
				var image = require('../../assets/icons/truck_na_24.png');
				var contentarr = []
				var headerTitle = marker.truck_no;
				contentarr.push({ "key": "Truck No", "value": marker.truck_no });
				contentarr.push({ "key": "Transporter Name", "value": marker.transporter_name });
				//contentarr.push({"key":"Distance from geofence", "value":marker.distance});
				//contentarr.push({"key":"GPS Provider", "value":marker.actual_lspuser});
				contentarr.push({ "key": "GPS Provider", "value": "Enmovil" });
				contentarr.push({ "key": "Speed (km/h)", "value": Math.ceil(marker.speed) });
				contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(marker.timestamp) });
				if (marker.truck_travelling_status == 0) {
					var trvalstat = "Travelling"
				}
				else {
					var rrr = ConvertHHMMSeconds(marker.elaped_time_from_last_moved_seconds)
					var trvalstat = "Idle: " + rrr
				}
				contentarr.push({ "key": "Travelling Status", "value": trvalstat });
				contentarr.push({ "key": "Address", "value": marker.device_address });
				contentarr.push({ "key": "City", "value": marker.device_city });
				contentarr.push({ "key": "State", "value": marker.device_state });
				var contentString = infoBox(image, headerTitle, contentarr, '')

				//console.log(contentString,"contentString");
				// var contentString = "<table><tr><th>Status: </th><td style='color:"+color+";font-weight:600;text-align: right'>"+truckText+"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>";
				// contentString = contentString + marker.distance+"</td></tr><th>Truck No: </th><td style='text-align: right'>"+marker.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
				// contentString = contentString + marker.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+marker.lsp_name+"</td></tr><tr><th>Address:</th><td style='text-align: right'>";
				// contentString = contentString  +marker.address+"</td></tr></table>";

				//var contentString = "Here "+marker.truck_no;

				//var contentString = infoBox(require('../../assets/icons/truck_inside_24.png'), header, contentarr)

				var infowindow = new window.google.maps.InfoWindow({
					content: contentString
				});

				var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));

				if (marker.status == 1) {
					var image = require('../../assets/icons/truck_inside_24.png');
				}
				else if (marker.status == 2) {
					var image = require('../../assets/icons/truck_going_24.png');
				}
				else if (marker.status == 3 || marker.status == 4) {
					var image = require('../../assets/icons/truck_atdealer_24.png');
				}
				else if (marker.status == 5) {
					var image = require('../../assets/icons/truck_leftdealer_24.png');
				}
				else if (marker.status == 6) {
					var image = require('../../assets/icons/truck_return_24.png');
				}
				else if (marker.status == 7) {
					var image = require('../../assets/icons/truck_empty_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_na_24.png');
				}
				var image = require('../../assets/icons/truck_na_24.png');
				/*
				if(marker.overspeeding_exception == 1)
				{
					var image =  require('../../assets/icons/truck_overspeed.png');
				}
				if(marker.nightdriving_exception == 1)
				{
					var image =  require('../../assets/icons/truck-end.png');
				}
				*/


				var mark = new window.google.maps.Marker({
					position: markerLatlng,
					map: map,
					title: "Status: " + truckText + " #" + marker.truck_no,
					icon: image
				});
				mark.addListener('click', function () {
					infowindow.open(map, mark);
				});
				return mark
				mark.setMap(map);
			});
			var eventtrigger = document.getElementsByClassName('truck');
			var markerCluster = new MarkerClusterer(map, markers,
				{ imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });
			// var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
			//map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
			if (this.state.plantLocations.length > 0) {
				this.state.plantLocations.map(function (params) {
					var plant = {
						url: require("../../assets/icons/" + params.icon), // url
						scaledSize: new window.google.maps.Size(32, 32), // scaled size
						origin: new window.google.maps.Point(0, 0), // origin
						anchor: new window.google.maps.Point(0, 0) // anchor
					};
					var coords = JSON.parse(params.coordinates);
					var plantmarker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(coords.lat, coords.lng),
						map: map,
						icon: plant,
						title: params.plant_name
					});
					// var cityCircle = new window.google.maps.Circle({
					// strokeColor: '#9fc9b9',
					// strokeOpacity: 0.3,
					// strokeWeight: 2,
					// fillColor: '#9fc9b9',
					// fillOpacity: 0.6,
					// map: map,
					// center:coords,
					// radius: 50 * 1000
					// });
				})
			}



		}

		if (this.props.match.params.screen == "trucklocation") {
			var currentwindow;
			var dealer = '';

			var arr = [];
			var jsondata = this.state.mapinfo;
			var locations = jsondata.breaks;
			var arr = jsondata.coords;
			console.log(arr, "arr1729")
			if (arr.length > 0 && typeof arr.length != undefined) {
				var lt = arr[0].latitude;
				var ln = arr[0].longitude;
			}
			else {
				var lt = 28.4519751;
				var ln = 77.0310713;
			}
			var routeinfo;

			if (arr.length > 0) {
				var mapOptions = {
					zoom: 15,
					zoomControl: true,
					mapTypeControl: true,
					scaleControl: true,
					streetViewControl: true,
					rotateControl: true,
					fullscreenControl: true,
					labels: true,
					mapTypeControlOptions: {
						mapTypeIds: ['satellite', 'roadmap'],
					},
					center: new window.google.maps.LatLng(lt, ln),
					mapTypeId: window.google.maps.MapTypeId.ROADMAP
				};

				map = new window.google.maps.Map(document.getElementById('map_mobile_mapview'), mapOptions);
				var bounds = new window.google.maps.LatLngBounds();
				bounds.extend(new window.google.maps.LatLng(lt, ln));
				// Create our info window content
				var currentinfowindow = null;
				var line = new window.google.maps.Polyline(
					{
						map: map,
						strokeColor: '#157254',
						strokeOpacity: 1.0,
						strokeWeight: 2.5,
						icons: [{
							// icon: {
							// 		path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
							// 		strokeColor:'#ff8c52',
							// 		fillColor:'#ff8c52',
							// 		fillOpacity:1,
							// 		strokeWeight: 2
							// },
							repeat: '100px',
							path: []
						}]
					});
				if (arr.length > 0) {
					var allpoints = [];

					var arr1 = [];
					//console.log("Total ",ratio);
					if (this.state.timelinesmarkers.length > 0) {
						arr1 = this.state.timelinesmarkers.filter(function (value, index, arr) {
							return (index % ratio == 0);
						});


						//var arr1 = this.state.timelinesmarkers;
					}

					var arr2 = [];
					var darr = [];
					if (arr.length > 0) {
						darr = arr.filter(function (value, index, arr) {
							//console.log("Vale ",value)
							return (value.dist_from_prev_point > 0);
						});
						if (darr.length < 2000) {
							var ratio = 1;
						}
						else {
							var ratio = 20;
						}
						//console.log("darr.length ", darr.length)
						arr2 = darr.filter(function (value, index, darr) {
							return (index % ratio == 0);
						});


					}
					// view markers code
					// console.log("arr2 here ", arr2)
					if (arr2.length > 0) {

						markersArray = arr2;
						// //console.log("allpoints ", allpoints)

						for (var a = 0; a < arr2.length; a++) {
							//console.log(arr2[a],"arr2[a]");
							var marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(arr2[a].latitude, arr2[a].longitude),
								icon: {
									path: window.google.maps.SymbolPath.CIRCLE,
									strokeColor: '#157254',
									fillColor: '#157254',
									fillOpacity: 1,
									strokeWeight: 5,
									scale: 1,
								},
								map: map,
								content: arr2[a]
							});
							marker.setVisible(false);
							// 	//console.log("Arr ",arr1[a])
							window.google.maps.event.addListener(marker, 'mouseover', (function (marker) {
								return function () {
									console.log("Arr ", marker)
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
									var header = "Current Status"
									contentarr.push({ "key": "Time ", "value": getDDMMYYYYHHMMSS(marker.content.stime) })
									contentarr.push({ "key": "Speed ", "value": marker.content.speed + " KMPH" })

									var contentString = infoBox("", header, contentarr, '')


									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
								}
							})(marker));
							window.google.maps.event.addListener(marker, 'mouseout', function () {
								infowindow.close();
							});

							viewMarkersArr.push(marker);

						}
					}
					var routeinfo = jsondata.route_details;
					var icon_cnt = 100
					for (var i = 0; i < arr2.length - 1; i++) {
						var locationLatLng = [];
						locationLatLng.push(new window.google.maps.LatLng(arr2[i].latitude, arr2[i].longitude));
						locationLatLng.push(new window.google.maps.LatLng(arr2[i + 1].latitude, arr2[i + 1].longitude));
						if (icon_cnt % 10 == 0) {
							var lineicon = [{
								// icon: {
								//         path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
								//         strokeColor:'#FF0000',
								//         fillColor:'#FF0000',
								//         fillOpacity:1,
								//         strokeWeight: 2
								// },
								repeat: '100px',
								path: []
							}];
						} else { lineicon = [] }
						icon_cnt = icon_cnt + 1
						var consignments_missing_route_line = new window.google.maps.Polyline({
							map: map,
							path: locationLatLng,
							strokeColor: '#157254',
							strokeOpacity: 1.0,
							strokeWeight: 2.5,
							icons: lineicon
						});
						// console.log(arr2[i].msgtype)
						if (arr2[i].msgtype == "G") {

							consignments_missing_route_line.setOptions({
								strokeColor: "#452a68",
								strokeWeight: 5.5,
							})
						}
						bounds.extend(new window.google.maps.LatLng(arr2[i].latitude, arr2[i].longitude));
					}



					var infowindow = new window.google.maps.InfoWindow();
					var marker, l;

					// marker = new window.google.maps.Marker({
					// 	position: new window.google.maps.LatLng(arr[0].latitude, arr[0].longitude),
					// 	icon: require('../../assets/icons/track_start.png'),
					// 	map: map,
					// });
					// window.google.maps.event.addListener(marker, 'click', (function (marker) {
					// 	return function () {
					// 		// console.log("marker click ",marker)

					// 		//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
					// 		var contentarr = []
					// 		//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
					// 		var header = "Starting Point"
					// 		contentarr.push({"key":"Address", "value":arr[0].address})

					// 		var contentString = infoBox(marker.icon, header, contentarr,'')

					// 		infowindow.setContent(contentString);
					// 		currentwindow = infowindow;
					// 		infowindow.open(map, marker);
					// 		console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
					// 	}
					// })(marker));
					marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(arr[(arr.length - 1)].latitude, arr[(arr.length - 1)].longitude),
						icon: require('../../assets/icons/truck-end.png'),
						map: map,
					});
					window.google.maps.event.addListener(marker, 'click', (function (marker) {
						return function () {
							// console.log('clusters',marker)

							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
							var contentarr = []
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
							var header = "End Point"
							contentarr.push({ "key": "Address", "value": arr[0].address })

							var contentString = infoBox(marker.icon, header, contentarr, '')

							infowindow.setContent(contentString);
							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							console.log('{"lat":' + marker.position.lat() + ',"lng":' + marker.position.lng() + '}');
						}
					})(marker));

				}
				// map.fitBounds(bounds)
			}
		}

	}
	toggleBounce() {
		if (marker.getAnimation() !== null) {
			marker.setAnimation(null);
		} else {
			marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}

	}

	getDistance = (p1, p2) => {
		//console.log("p1",p1);
		//console.log("p2",p2);
		var R = 6378137; // Earth’s mean radius in meter
		var dLat = rad(p2.lat - p1.lat);
		var dLong = rad(p2.lng - p1.lng);
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d / 1000; // returns the distance in meter
	};
	onShowInfo = (e) => {
		console.log(JSON.parse(e.target.id));
		this.setState({
			contentString: "Here Success"
		});
		var ourMarker = e.target.id;
		window.google.maps.event.trigger(ourMarker, 'click');
		var infowindow = new window.google.maps.InfoWindow({
			content: "Here Success"
		});
		infowindow.open(map, marker);

	}
	popmarker(e) {
		var truckNo = e.data.truck_no;
		var truck = [];
		var trucks = this.state.alltrucks;
		console.log(truckNo, "input truck");
		var filteredValue = trucks.filter(function (e) {
			return e.truck_no == truckNo
		});
		if (filteredValue.length > 0) {
			console.log("Matched")
			var lat = parseFloat(filteredValue[0].latitude);
			var lng = parseFloat(filteredValue[0].longitude);
			var data = filteredValue[0];
			map.setCenter(new window.google.maps.LatLng(lat, lng));
			map.setZoom(22);
			var markerLatlng = new window.google.maps.LatLng(lat, lng);

			if (filteredValue[0].status == 1) {
				var truckText = "Inside Plant";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 2) {
				var truckText = "Going to Dealer";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
				var truckText = "At Dealer Location";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 5) {
				var truckText = "Left Destination Location";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 6) {
				var truckText = "Return to Plant";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 7) {
				var truckText = "Empty Truck Outside Plant";
				var color = "#333333";
			}
			else {
				var truckText = "N/A";
				var color = "#333333";
			}

			if (filteredValue[0].status == 1) {
				var image = require('../../assets/icons/truck_inside_24.png');
			}
			else if (filteredValue[0].status == 2) {
				var image = require('../../assets/icons/truck_going_24.png');
			}
			else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
				var image = require('../../assets/icons/truck_atdealer_24.png');
			}
			else if (filteredValue[0].status == 5) {
				var image = require('../../assets/icons/truck_leftdealer_24.png');
			}
			else if (filteredValue[0].status == 6) {
				var image = require('../../assets/icons/truck_return_24.png');
			}
			else if (filteredValue[0].status == 7) {
				var image = require('../../assets/icons/truck_empty_24.png');
			}
			else {
				var image = require('../../assets/icons/truck_na_24.png');
			}
			var image = require('../../assets/icons/truck_na_24.png');
			var marker = new window.google.maps.Marker({
				position: markerLatlng,
				map: map,
				title: data.truck_no,
				icon: image
			});


			var contentarr = []
			var headerTitle = filteredValue[0].truck_no;
			contentarr.push({ "key": "Truck No", "value": filteredValue[0].truck_no });
			contentarr.push({ "key": "Transporter Name", "value": filteredValue[0].transporter_name });
			//contentarr.push({"key":"Distance from geofence", "value":trucks[i].distance});
			//contentarr.push({"key":"GPS Provider", "value":filteredValue[0].actual_lspuser});
			contentarr.push({ "key": "GPS Provider", "value": "Enmovil" });
			contentarr.push({ "key": "Speed (KMPH)", "value": filteredValue[0].speed });
			contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(filteredValue[0].timestamp) });
			// contentarr.push({"key":"Address", "value":filteredValue[0].area+", "+filteredValue[0].state});
			if (filteredValue[0].truck_travelling_status == 1) {
				var travelstat = "Travelling";
			}
			else {
				var travelstat = "Idle " + ConvertSeconds(filteredValue[0].elaped_time_from_last_moved_seconds);
			}
			contentarr.push({ "key": "Travelling Status", "value": travelstat });
			contentarr.push({ "key": "Address", "value": filteredValue[0].device_address });
			contentarr.push({ "key": "City", "value": filteredValue[0].device_city });
			contentarr.push({ "key": "State", "value": filteredValue[0].device_state });

			var contentString = infoBox(image, headerTitle, contentarr, '')
			//console.log(contentarr,"contentarr")
			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});

			marker.setAnimation(window.google.maps.Animation.DROP)
			marker.addListener('click', function () {
				infowindow.open(map, marker);
			});

			marker.setMap(map);
		}
		else {
			this.setState({
				show: true, basicType: 'warning',
				basicTitle: "No Data found.",
				loadshow: 'show-n',
				showform: 'show-m',
			})
		}
		console.log(filteredValue);

	}

	closeAlert = () => {
		this.setState({
			show: false
		});
	}
	showMarkers(event) {
		// console.log("event.target.value ", event.target.value)
		if (event.target.value == 0) {
			this.setState({
				showMarkers: 1
			});
			// viewMarkersArr.map(function(e){
			// 	e.setVisible(true);
			// })
			console.log("markersArray ", markersArray)
			var infowindow = new window.google.maps.InfoWindow();
			if (markersArray.length > 0) {
				var stepby = Math.round(markersArray.length * 0.007)
				// var counter = ( stepby > 0)?stepby:1;
				// var incrementby = counter
				var counter = 1;
				var incrementby = 1;
				console.log(markersArray.length, counter);
				while (counter < markersArray.length) {
					var marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(markersArray[counter].lat, markersArray[counter].lng),
						icon: {
							path: window.google.maps.SymbolPath.CIRCLE,
							strokeColor: '#157254',
							fillColor: '#157254',
							fillOpacity: 1,
							strokeWeight: 5,
							scale: 1,
						},
						map: map,
						content: markersArray[counter]
					});
					// marker.setVisible(false);
					window.google.maps.event.addListener(marker, 'mouseover', (function (marker) {
						return function () {
							// console.log("Arr ",marker)
							var contentarr = []
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
							var header = "Current Status"
							contentarr.push({ "key": "Time ", "value": getDDMMYYYYHHMMSS(marker.content.stime) })
							contentarr.push({ "key": "Speed ", "value": marker.content.speed + " KMPH" })
							contentarr.push({ "key": "Address ", "value": marker.content.area })
							contentarr.push({ "key": "City ", "value": marker.content.city })
							contentarr.push({ "key": "State ", "value": marker.content.state })

							var contentString = infoBox("", header, contentarr, '')


							infowindow.setContent(contentString);
							infowindow.open(map, marker);
							//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
						}
					})(marker));
					window.google.maps.event.addListener(marker, 'mouseout', function () {
						infowindow.close();
					});

					viewMarkersArr.push(marker);
					counter = counter + incrementby;
					// console.log("counter",counter)
				}
				// for(var a=0;a<markersArray.length;a++)
				// {

				// }
			}

		}
		else {
			this.setState({
				showMarkers: 0
			});
			viewMarkersArr.map(function (e) {
				e.setVisible(false);
			});
		}
	}
	onClickShowTruckLocation(e) {
		// console.log("CLiclable ", e)
		var lat = parseFloat(e.data.lat);
		var lng = parseFloat(e.data.lng);
		var data = e.data;
		map.setCenter(new window.google.maps.LatLng(lat, lng));
		map.setZoom(22);
		var markerLatlng = new window.google.maps.LatLng(lat, lng);
		//var image =  require('../../assets/icons/truckblue.png');
		var image = require('../../assets/icons/gmarker.png');

		var marker = new window.google.maps.Marker({
			position: markerLatlng,
			map: map,
			title: data.truck_no,
			icon: image
		});


		var contentarr = []
		var header = data.truck_no
		//contentarr.push({"key":"Reached On", "value":getDDMMYYYYHHMMSS(data.reached_on)})
		contentarr.push({ "key": "City/Area", "value": data.area })
		contentarr.push({ "key": "State", "value": data.state })

		var contentString = infoBox(marker.icon, header, contentarr, '')

		var infowindow = new window.google.maps.InfoWindow({
			content: contentString
		});

		marker.setAnimation(window.google.maps.Animation.DROP)
		marker.addListener('click', function () {
			infowindow.open(map, marker);
		});

		marker.setMap(map);

	}
	onClickShowData() {
		this.setState({
			datashow: "show-m",
			dataht: "180px",
			icofnt: "icofont-rounded-down",
			icshow: "show-n"
		})
	}
	onClickHideData() {
		this.setState({
			datashow: "show-n",
			dataht: "40px",
			icofnt: "icofont-rounded-up",
			icshow: "show-m"
		})
	}
	
	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n'
		});
	}

	OnChangeDuration = (e) =>{
		this.getShareLinkData(e.value)
		this.setState({
			duration:e
		})
	}
 
	render() {
		if (this.state.rowData != "" && this.state.rowData != undefined && this.state.rowData != null) {
			if (this.state.rowData.length > 0) {
				var lastrecord = this.state.rowData[parseInt(this.state.rowData.length) - 1]
			}
			else {
				var lastrecord = { cumm_distance: 0 }
			}
		}
		else {
			var lastrecord = { cumm_distance: 0 }
		}

		console.log(this.state.is_link_expired , "is_link_expired")
		
		return (

			<div className="container-fluid" style={{ padding: "0px" }}>
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.is_link_expired?
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<img src={require("../../assets/icons/expired_link_image.png")} style={{ width: '60%', height: "100vh" }} title="Expired" />
					</div>
:
				<>
				{(this.props.match.params.screen == "trips" ||  this.props.match.params.screen == "sharelink" ) ?
					<div className="row col-xl-12 col-lg-12 mt-3">
							{/* <div className="col-xl-3 col-lg-3 form-group">
								<label className='col-xl-12 col-lg-12 ml-2'>Select Duration : </label>
								<Select
									placeholder={"Select Duration"}
									closeMenuOnSelect={true}
									onChange={this.OnChangeDuration}
									value = {this.state.duration}
									className={"col-xl-12 col-lg-12 border-radius-0"}
									style={{ borderRadius: "0px" }}
									options={[
										{label:"24 HOURS" , value : 1},
										{label:"2 Days" , value : 2},
										{label:"3 Days" , value : 3},
										{label:"4 Days" , value : 4},
										{label:"5 Days" , value : 5},
										{label:"6 Days" , value : 6},
										{label:"7 Days" , value : 7},
									]} />
							</div> */}
						<div className="col-xl-12 col-lg-12">
							<div className="crm-numbers pb-0">
								{/* <h3 className="subH">View Details</h3> */}
								<div className="col-xl-12 col-lg-12 row">
								<div className="col route-block" style={{borderBottom:'none'}}>
										<label className="sidebar-label">Truck No</label>
										<div>{(this.state.mapinfo != '') ? this.props.match.params.truck_no : "NA"}</div>
									</div>
									{this.props.match.params.screen == "trips" ?
									<div className="col route-block" style={{borderBottom:'none'}}>
										<label className="sidebar-label">Trip No</label>
										<div>{(this.state.mapinfo != '') ? this.props.match.params.trip_no : "NA"}</div>
									</div>:""}
									<div className="col route-block" style={{borderBottom:'none'}}>
										<label className="sidebar-label">Start Time</label>
										<div>{(this.state.mapinfo != '') ? getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time) : "NA"}</div>
									</div>
									<div className="col route-block" style={{borderBottom:'none'}}>
										<label className="sidebar-label">End Time</label>
										<div>{(this.state.mapinfo != '') ? getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time) : "NA"}</div>
									</div>

									<div className="col route-block" style={{borderBottom:'none'}}>
										<label className="sidebar-label">Distance</label>
										<div>{(!isEmpty(this.state.mapinfo)) ? Math.round(this.state.mapinfo.route_details.distance / 1000) : "0"} Kms</div>
									</div>

								</div>
							</div>
						</div>
					</div> : ""}
				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				
				<div className="">
					{(["truckgps",'sharelink', "nightdriving", "overspeed", "continuous", "stoppage", "breaking_acceleration" , "trips"].includes(this.props.match.params.screen)) ?
						<div className="row">
							<div className={"col-xl-12 col-lg-12 "} style={{ padding: "0px" }}>
								<div id="map_mobile" className="" style={{ width: '100%', height: "100vh" }}></div>


							</div>
						</div>
						: ""}
					{(this.props.match.params.screen == "mapview") ?
						<div className="row col-xl-12 col-lg-12">
							<div className="col-xl-12 col-lg-12">
								<div id="map_mobile_mapview" className="" style={{ width: '100%', height: "100vh" }}></div>
							</div>
						</div>
						: ""}
					{(this.props.match.params.screen == "trucklocation") ?
					(this.state.mobliemapinfo.length > 0 ? 
					<div id="map_mobile_mapview" className="" style={{ width: '100%', height: "100vh" }}></div>:
					<h1 className ="fbold  textAlignCenter">No Gps Records Found </h1>
					):""}
					{this.props.match.params.screen == "rakelocation"?
					 <div className={"col-xl-12 col-lg-12 "} style={{padding:"10px"}}>
					<div className="row">
						<DrawMap
						context={this} 
						mapFor={"tptrakegps"} 
						tolls = {this.state.tolls}
						mapinfo={this.state.mapinfo} 
						defTransitCoords={""} 
						adaniCheck={this.state.adaniCheck}
						/>
					</div>
					<div className="col-xl-12 col-lg-12 n-p-0 mt-20p">
						<div className="crm-numbers pb-0">
							<h3 className="subH">View Details</h3>
							<div className="col-xl-12 col-lg-12 row">
								
								<div className="col route-block">
									<label className="sidebar-label">Start Time</label>
									<div>{(this.state.mapinfo != '')?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"NA"}</div>
								</div>
								<div className="col route-block">
									<label className="sidebar-label">End Time</label>
									<div>{(this.state.mapinfo != '')?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"NA"}</div>
								</div>
								
								<div className="col route-block">
									<label className="sidebar-label">Distance</label>
									<div>{(!isEmpty(this.state.mapinfo))?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
								</div>
								
							</div>
						</div>
					</div>
				</div>:""

					}	
				</div>

				{(["truckgps","nightdriving", "overspeed", "continuous", "stoppage", "breaking_acceleration", "trucklocation"].includes(this.props.match.params.screen)) ?
					<div className={"mapics "} style={{ height: this.state.dataht }}>
						<i className={"mapici icofont f42 " + (this.state.icofnt) + " " + this.state.icshow} style={{ cursor: "pointer" }} onClick={this.onClickShowData.bind(this)}></i>
						<div className={"row " + (this.state.datashow)} style={{ position: "relative" }}>
							<span className="mapcls" onClick={this.onClickHideData.bind(this)}>X</span>
							{/* {console.log("this.state.mapinfo ", this.state.mapinfo)} */}
							{(this.state.mapinfo != "") ?
								<div className="col-xl-12 col-lg-12">
									<div className="row col-xl-12 col-lg-12" style={{ padding: "0px 15px" }}>
										<div className="col-xl-2 col-lg-2 n-p-0">
											<h5 className="mapdt textAlignCenter">Truck No</h5>
											<div className="fbold textAlignCenter p-15px f18">
												{this.state.dispaly_data.truck_no}
											</div>
										</div>
										{['trucklocation'].includes(screen) ?
											<div className="col-xl-2 col-lg-2 n-p-0">
												<h5 className="mapdt textAlignCenter">Consigement Code</h5>
												<div className="fbold textAlignCenter p-15px f18">
													{this.state.dispaly_data.consignment_code}
												</div>
											</div> : ""}

										<div className="col-xl-2 col-lg-2 n-p-0">
											<h5 className="mapdt textAlignCenter">Start Time</h5>
											<div className="fbold textAlignCenter p-15px f18">
												{getDDMMMYYYYHHMMDefault(this.state.dispaly_data.start_time)}
											</div>
										</div>
										<div className="col-xl-2 col-lg-2 n-p-0">
											<h5 className="mapdt textAlignCenter">End Time</h5>
											<div className="fbold textAlignCenter p-15px f18">
												{getDDMMMYYYYHHMMDefault(this.state.dispaly_data.end_time)}
											</div>
										</div>
										{['stoppage', 'breaking_acceleration'].includes(screen) ? (
											<div className="col-xl-2 col-lg-2 n-p-0">
												<h5 className="mapdt textAlignCenter">Total Break</h5>
												<div className="fbold textAlignCenter p-15px f18">
													{/* {Math.round(this.state.mapinfo.route_details.distance/1000)}  */}
													{this.state.total_break}
												</div>
											</div>
										) : (
											<div className="col-xl-2 col-lg-2 n-p-0">
												<h5 className="mapdt textAlignCenter">Distance (Kms)</h5>
												<div className="fbold textAlignCenter p-15px f18">
													{/* {Math.round(this.state.mapinfo.route_details.distance/1000)}  */}
													{/* {Math.round(lastrecord.cumm_distance / 1000)} */}
													<div>{(!isEmpty(this.state.mapinfo.coords[0])) ? Math.round((this.state.dispaly_data.distance) / 1000) : "0"} Kms</div>
												</div>
											</div>)}

									</div>

								</div>
								: "No data found"}
							{/* {this.state.mapinfo.route_details.start_time} */}

						</div>
					</div>
					: ""}
					</>}
			</div>

		);
	}
}


function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}
function initOMS() {
	// Initializing OMS
	oms = new oms.OverlappingMarkerSpiderfier(map, {
		//markersWontMove: true,
		//keepSpiderfied: true,
		//nearbyDistance: 10,
		//circleFootSeparation: 60,
		//legWeight: 1.5
		markersWontMove: true,
		markersWontHide: true,
		keepSpiderfied: true,
		basicFormatEvents: true
	});
}
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num - (rhours * 3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}

function ConvertMinutes(num) {
	var d = Math.floor(num / 1440); // 60*24
	var h = Math.floor((num - (d * 1440)) / 60);
	var m = Math.round(num % 60);
	return d + " Day(s) " + h + " Hrs " + m + " mins"
}

function ConvertSeconds(totalSeconds) {
	// var days = Math.floor(num/86400);
	// var hours = Math.floor(num/3600)
	// var minutes = Math.floor(num/60);
	var minutes = Math.round((totalSeconds % 3600) / 60);
	var hours = Math.round((totalSeconds % 86400) / 3600);
	var days = Math.round((totalSeconds % (86400 * 30)) / 86400);
	// let d = (new Date(t0)) - (new Date(t1));
	// let weekdays     = Math.floor(d/1000/60/60/24/7);
	// let days         = Math.floor(d/1000/60/60/24 - weekdays*7);
	// let hours        = Math.floor(d/1000/60/60    - weekdays*7*24            - days*24);
	// let minutes      = Math.ceil(d/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
	return days + " Day(s) " + hours + " Hrs " + minutes + " mins"
}

function ConvertHHMMSeconds(n) {
	var days = Math.round(parseInt(n / (24 * 3600)));

	n = n % (24 * 3600);
	var hours = Math.round(parseInt(n / 3600));

	n %= 3600;
	var minutes = Math.round(n / 60);

	n %= 60;
	var seconds = Math.round(n);

	return days + " Day(s) " + hours + " Hrs " + minutes + " mins " + seconds + " secs"
}
function convertMeters(metersValue) {
	return (metersValue / 1000).toFixed(2);
}

$(document).ready(function () {
	$(document).on('keyup', ".validateNumber", function () {
		// alert("click");
		if (isNaN($(this).val()) == true) {
			$(this).val($(this).val().slice(0, -1));
		}
	});
});
function arrayMax(arr) {
	return arr.reduce(function (p, v) {
		return (p > v ? p : v);
	});
}
function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}

function secondsToHM(seconds) {
	seconds = Number(seconds);
	// var d = Math.floor(seconds / (3600*24));
	// var h = Math.floor(seconds % (3600*24) / 3600);
	// var m = Math.floor(seconds % 3600 / 60);
	// var s = Math.floor(seconds % 60);

	// var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
	// var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
	// var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
	// var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
	// return dDisplay + hDisplay + mDisplay;

	var hh = Math.floor((seconds / 3600));
	var hrr = Math.floor(seconds - (hh * 3600));
	var mm = Math.floor(hrr / 60)
	if (mm < 10) {
		var mins = "0" + mm;
	}
	else {
		var mins = mm;
	}

	if (hh < 10) {
		var hrs = "0" + hh;
	}
	else {
		var hrs = hh;
	}
	return `${hrs} Hrs : ${mins} Min`;
}
