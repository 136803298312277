import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import Modal from 'react-responsive-modal';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { getDDMMYYYY, getYYYYMMDD,getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import NextBillionMaps from '../common/nbmaps';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import $ from 'jquery';
import Select from 'react-select';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import ExportPDF from './exportpdf';
var infoBox = require('../common/google-infowindow');
var googleAnalytics = require("../common/googleanalytics");

 
var redirectURL = require('../redirectURL');
var moment = require('moment');
 
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;

export default class TPTGeofenceIncurssions extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
			defTransitCoords:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
			geofence_name : {label : "ALL",value : "ALL"},
			truck_no : {label : "ALL",value : "ALL"},
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
				consignmentActions:ConsignmentActions,
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green":"data.status >= 3"

			},
			fencelist:[],
			startDate:'',
			endDate:'',
			mapinfo:'',
			defaultsdate:'',
			defaultedate:'',
			usermanualmodal:false,
			adaniCheck:0,
			pdfRowData: [],
			pdfColumns: [],
			trucksDropList : [],
			startDate : moment.parseZone(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
			endDate : moment.parseZone(new Date()).format("YYYY-MM-DD"),
		}
		this.selectOptionsItems = this.selectOptionsItems.bind(this);
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
	}

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	async componentDidMount(){
		loadDatepicker()
		//console.log("Props ", this.props.match)
		this.logPageView(); // Log page view to GA
		var urlpath;
		var dpt;
		var hText = 'All';
        this.setState({
            loadshow:'show-n'
        });
		
		let pageTitle = "Geofence Incursions"


		//console.log(dpt);
		this.setState({
			pageTitle:pageTitle
        });
        
		var tptCode = [];
		//localStorage.getItem('transportercode');
		if(localStorage.getItem('transportercode') !=undefined)
		{
			if(localStorage.getItem("transportercode") != "all")
			{
				var tptCode = JSON.parse(localStorage.getItem("transportercode"));
			}
			else{
				var tptCode = localStorage.getItem("transportercode");
			}
			//var transporter_code = tptCode[0];
		}
		if(tptCode.includes("T293") == true)
        {
            var adaniCheck = 1;
        }
        else
        {
            var adaniCheck = 0;
        }
        this.setState({
            adaniCheck : adaniCheck
        });
        var reqparams = {
			transporter_code:tptCode
		}
		
		redirectURL.post("/geofence/geofenceFilterOptions",reqparams)
		  .then((response) => {
            var records = response.data;
			console.log("records", records);
			var {trucks, geofences} = records
            this.setState({
                //rowData:records,
				loadshow:'show-n',
				fencelist:geofences || [],
				trucksDropList : trucks || []
			});
		  })
		.catch(function (error) {
		    console.log(error);
		});


        var sdateObj = new Date();                     
		sdateObj.setDate(sdateObj.getDate() - 1);  
		var edateObj = new Date();                     
		edateObj.setDate(edateObj.getDate());
		var ed = moment(edateObj).format("YYYY-MM-DD") 
		//console.log("edateObj ", ed)
		var sd = moment(sdateObj).format("YYYY-MM-DD") 
		//console.log("sdateObj ", sd)
		var sdate = sdateObj
		var edate = ed
		
		var formdata= {
			startDate:sd,
			endDate:ed,
			fence_name:this.state.geofence_name.value,
			transporter_code:tptCode,
			truck_no : this.state.truck_no.value
		}
		console.log("tptCode ", tptCode)
		redirectURL.post("/consignees/tptgeoincursions",formdata,{
			headers:{
				//'Content-Type': 'multipart/form-data',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin':"*",
				'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
				'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
			// 'authorization': 'Zoho-oauthtoken 1000.ecb6dbd17a44125ce5434f5f45148278.a54f59a27d9e9db5383f85ba3002ccf2' 
			}
		})
		//.then(res => res.json())
		.then(
		async(result) => {
			//console.log(result.data) 
			var records=result.data;
			var rowarray=[];
			if(JSON.parse(localStorage.getItem("transportercode")).includes("T293") && localStorage.getItem("plant_location") != "" &&
			localStorage.getItem("plant_location") != undefined)
			{
				var lsParams = {
					transporter_code : JSON.parse(localStorage.getItem("transportercode")),
					plant_location : localStorage.getItem("plant_location")
				}
				await redirectURL.post("/consignments/getDistinctTrucks",lsParams).then(async (response1) => {
					var tpttrucks = response1.data.recordsdata;
					//console.log(tpttrucks,"tpttrucks---");
					//console.log(recs,"recs---");
					if (tpttrucks.length > 0) {
						records = await result.data.filter(function(n) { return tpttrucks.indexOf(n.truck_no) !== -1;});
					}
					console.log(records,"records----1")
				})
			}
			console.log(records,"records----2")
			records.map((item) => {
				if(item.first_inside_fence != undefined)
				{
					var g1 = getDDMMYYYY(item.first_inside_fence);
					var g2 = getDDMMYYYY(item.first_outside_fence);
					var s1 = g1.split("-");
					var s2 = g2.split("-");
					var gdate1 = new Date(item.first_inside_fence);
					var gdate2 = new Date(item.first_outside_fence);
					var gatein = gdate1.getTime();
					var gateout = gdate2.getTime();
					var total = gateout-gatein;
					//console.log("gatein ", gatein)
					//console.log("gateout ", gateout)
					//var total =0 ;
				}
				else{
					var total=0;
				}
				//console.log("total ", total)
				var diffMs = total;
				var diffDays = Math.floor(diffMs / 86400000); // days
				var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
				var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000); 
				//console.log("diffHrs ", diffHrs);
				//console.log("diffMins ", diffMins);
				//console.log("diffDays ", diffDays);
				if(item.transporter.length > 0)
				{
					var transprt =  item.transporter[0].transporter_name
				}
				else{
					var transprt = "";
				}
				rowarray.push({
					"truck_no":item.truck_no,
					"fence_name":item.fence_name,
					// "lsp_user":item.truck[0].actual_lspuser,
					"first_inside_fence":item.first_inside_fence,
					"first_outside_fence": item.first_outside_fence,
					"transporter_name":transprt,
					"time_spent":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
				})
			})
			//console.log("rowarray ", rowarray)
			var name = this.state.adaniCheck === 1 ? 'Shuttle No' : 'Truck No'
			var pdfRowData = [];
			
			rowarray.map(t => {
				return(								
					pdfRowData.push({								
					name: t.truck_no,
					fence_name: (t.fence_name),
					first_inside_fence: getHyphenDDMMMYYYYHHMM(t.first_inside_fence),																			
				}))
			})
			var pdfColumns = [
				{ header: name, binding: 'name'},                
				{ header: 'Fence Name', binding: 'fence_name'},
				{ header: 'First Inside Fence', binding: 'first_inside_fence'},									
			]

			this.setState({
				rowData:rowarray,
				loadshow:'show-n',
				startDate:sd,
				endDate:ed,
				pdfRowData,
				pdfColumns
			});
		

			// this.setState({
			//      rowData:result.data,
			//      loadshow:'show-n'
			//  });
		})
		.catch(function (error) {
		  console.log(error);
		});
		this.getUserGridState()
		
	};
	getUserGridState(){
		redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			console.log("resp ", resp)
			this.setState({
				usergridstate:resp.data
			});
			this.restoreGridStates();
		})
	}
	
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	    if(this.state.deptcode == 'LOG-PRT')
	    {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], false);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], false);
		}
		if(this.state.deptcode == 'SNDG')
		{
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
		}

		
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
	handlerStartDateTime = (date) => {
		const selectedDate = moment(date).format('YYYY-MM-DD');

		if (this.state.endDate && moment(selectedDate).isAfter(this.state.endDate)) {
			this.setState({
				show: true,
				basicTitle: 'From date should be less than To date',
				basicType: "danger",
				loadshow: 'show-n',
				startDate: null, // Reset the startDate to null
			});
		} else {
			this.setState({
				startDate: selectedDate // Store the raw date object in state
			});
		}
	};

	handlerEndDateTime = (date) => {
		const selectedDate = moment(date).format('YYYY-MM-DD');

		if (this.state.startDate && moment(selectedDate).isBefore(this.state.startDate)) {
			this.setState({
				show: true,
				basicTitle: 'To date should be greater than From date',
				basicType: "danger",
				loadshow: 'show-n',
				endDate: null, // Reset the endDate to null
			});
		} else {
			this.setState({
				endDate: selectedDate // Store the raw date object in state
			});
		}
	};
    formHandler = (event) => {
		this.setState({
            loadshow:'show-m',
			overly:'show-m'
        });
		// console.log("clicked", event)
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedGetData,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        
		// var startdate = document.getElementById("startDate").value;
		// var s = startdate.split("-")
		// var sdate = s[2]+"-"+s[1]+"-"+s[0]
		// console.log("sdate ", sdate)
		// var enddate = document.getElementById("endDate").value;
		// var e = enddate.split("-")
		// var edate = e[2]+"-"+e[1]+"-"+e[0]

		// if(sdate == "undefined-undefined-")
		// {
		// 	var sdt = this.state.startDate
		// }
		// else{
		// 	var sdt = sdate;
		// }

		// if(edate == "undefined-undefined-")
		// {
		// 	var edt = this.state.endDate
		// }
		// else{

		// 	var edt = edate;
		// }
		
		var tptCode = [];
		//localStorage.getItem('transportercode');
		if(localStorage.getItem('transportercode') !=undefined)
		{
			if(localStorage.getItem("transportercode") != "all")
			{
				var tptCode = JSON.parse(localStorage.getItem("transportercode"));
			}
			else{
				var tptCode = localStorage.getItem("transportercode");
			}
			//var transporter_code = tptCode[0];
		}
    	var formdata= {
    			startDate:this.state.startDate,
				endDate:this.state.endDate,
				fence_name:this.state.geofence_name.value,
				truck_no:this.state.truck_no.value,
				transporter_code:tptCode
		}
		console.log("formdata ", formdata)
    	redirectURL.post("/consignees/tptgeoincursions",formdata)
        //.then(res => res.json())
        .then(
          (result) => {
			console.log(result.data) 
			var records=result.data;
			var rowarray=[]
			records.map((item) => {
				//console.log("Items ", item)
				if(item.first_inside_fence != undefined)
				{
					var g1 = getDDMMYYYY(item.first_inside_fence);
					var g2 = getDDMMYYYY(item.first_outside_fence);
					var s1 = g1.split("-");
					var s2 = g2.split("-");
					var gdate1 = new Date(item.first_inside_fence);
					var gdate2 = new Date(item.first_outside_fence);
					var gatein = gdate1.getTime();
					var gateout = gdate2.getTime();
					var total = gateout-gatein;
					//console.log("gatein ", gatein)
					//console.log("gateout ", gateout)
					//var total =0 ;
				}
				else{
					var total=0;
				}
				//console.log("total ", total)
				var diffMs = total;
				var diffDays = Math.floor(diffMs / 86400000); // days
				var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
				var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); 
				//console.log("diffHrs ", diffHrs);
				//console.log("diffMins ", diffMins);
				//console.log("diffDays ", diffDays);

				if(item.transporter.length > 0)
				{
					var transprt =  item.transporter[0].transporter_name
				}
				else{
					var transprt = "";
				}
				rowarray.push({
					"truck_no":item.truck_no,
					"fence_name":item.fence_name,
					// "lsp_user":item.truck[0].actual_lspuser,
					"first_inside_fence":item.first_inside_fence,
					"first_outside_fence": item.first_outside_fence,
					"transporter_name":transprt,
					"time_spent":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
				})
			})
			console.log("rowsarray ", rowarray)
            this.setState({
                rowData:rowarray,
				loadshow:'show-n',
				overly:'show-n'
			});
		  

            // this.setState({
            //      rowData:result.data,
            //      loadshow:'show-n'
            //  });
          })
        .catch(function (error) {
  		    console.log(error);
  		  });
	}

	selectOptionsItems() {
		let items = [];
		items.push({ value: 'ALL', label: "All" });
		this.state.fencelist.map(item => {
			items.push({ value: item, label: item });

		});
		return items;
	}

	selectTruckItems= () => {
		let items = [];
		items.push({ value: 'ALL', label: "ALL" });
	   this.state.trucksDropList.map(item=>{
		   items.push({ value: item, label: item });
		   
	   });
	   return items;
   }

	handlerForm = (event) => {
    	console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			tabsliderTranslate:'',
			showDiv:'show-n',
			overly:'show-n'
		});
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			showDiv:'show-n',
			overly:'show-n'
		});
	}
	
    onShowRouteDiv = (params) =>{
		console.log("Params ", params);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var fdate = moment.parseZone(params.first_inside_fence).format("YYYY-MM-DD HH:mm:ss");
		var edate = moment.parseZone(params.first_outside_fence).format("YYYY-MM-DD HH:mm:ss");
		var reqData = {
			is_hero : localStorage.getItem("is_hero") == 1 ? true : false,
            truck_no:params.truck_no,
			gate_out_time:fdate,
			seconddate:edate,
			screen:"geofence"
        }
        console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			this.setState({
				sliderRouteTranslate:"slider-translate-65p",
				overly:'show-m',
				maptruckno:params.truck_no,
				mapinfo:records,
				sidebarSubHeader:"Truck Information",
				routeTruck:	{"truck_no":params.truck_no,"startTime":response.data.route_details.start_time,"endTime": response.data.route_details.end_time},
						
			});
		})
		.catch(function(e){
			console.log("Error ",e)
		})
		// var minfo={
		// 	"breaks":[],
		// 	"coords":[],
		// 	"route_details":{
		// 		"start_time": "2020-02-28 12:53:21",
		// 		"end_time": "2020-02-29 00:22:53",
		// 		"distance": "57820"
		// 	}
		// };
		// this.setState({
		// 	sliderRouteTranslate:"slider-translate-65p",
		// 	overly:'show-m',
		// 	maptruckno:params.truck_no,
		// 	mapinfo:minfo
		// });
		//console.log("mapinfo ",this.state.mapinfo);
		//console.log("minfo ",minfo);
	}
	onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};
	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = 'Night Driving';

		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		///console.log("this.state.usergridstate ", this.state.usergridstate)
		try{
			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}


	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

	render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		
		const {usermanualmodal} = this.state;
		//console.log("Rendere ",this.state.deptcode)
        var adaniCheck = this.state.adaniCheck;
		var tkName = "Truck No";
		if(adaniCheck == 1)
		{
			tkName = "Shuttle No";
		}
		const columnwithDefs = [
				
				{
					headerName: "Actions",
					field: "_id",
					width: 40,
					cellRenderer:'consignmentActions',
					filter: false,resizable: true
					
				},
				{
					headerName: tkName,
					field: "truck_no",
					width: 150,
					filter: true,
                    resizable: true
				},
				{
					headerName: "Fence Name",
					field: "fence_name",
					width: 180,
					filter: true,
                    Routeresizable: true
				},
				// {
				// 	headerName: "Transporter Name",
				// 	field: "transporter_name",
				// 	width: 250,
				// 	filter: true,
				// 	resizable: true
				// },
				{
					headerName: "First Inside Fence",
					field: "first_inside_fence",
					width: 200,
					filter: true,
                    resizable: true,
                    valueGetter:function(params)
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.first_inside_fence)
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue;
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					
				},
				
				{
					headerName: "First Outside Fence",
					field: "first_outside_fence",
					width: 200,
					filter: true,
                    resizable: true,
                    valueGetter:function(params)
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.first_outside_fence)
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					
				},			
				{
					headerName: "Time Spent",
					field: "time_spent",
					width: 200,
					filter: true,
					resizable: true,
					cellRenderer: param => {
						const insideFence = new Date(param.data.first_inside_fence);
						const outsideFence = new Date(param.data.first_outside_fence);

						// If the dates are not valid, return an empty string
						if (isNaN(insideFence.getTime()) || isNaN(outsideFence.getTime())) {
							return "";
						}

						// Calculate the time difference in milliseconds
						const timeDifference = outsideFence - insideFence;

						// Convert the time difference into hours and minutes
						const hours = Math.floor(timeDifference / (1000 * 60 * 60)); // Get hours
						const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)); // Get minutes

						// Return formatted time with a single space between hours and minutes
						return `${hours} Hr(s) ${minutes} Min(s)`;
					}
				}
				
				
			];
		const dStyles={
			width:this.props.width,
			height:'500px',
			marginLeft:this.props.marginLeft,

		}
	
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pageTitle} </span>
									   <div className="col-xl-12 col-lg-12">
                                    
                                    <span className="layoutbtns pull-right">
                                        <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </span>
                                </div>	
                                </h5>
				   			</div>
							  
							  
		            		<div className="card-body">
                                <div className="row col-xl-12 col-lg-12">
                                    <form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
										{/* <div className="col-xl-2 col-lg-2 form-group">
											<select name="fence_name" className="form-control" placeholder='Plants' onChange={this.handlerForm.bind(this)} required>
												<option value="0">All</option>
												{this.selectOptionsItems()}
											</select>
										</div> */}
										<div className="col-xl-2 col-lg-2 form-group">
											<Select placeholder="Select Geofence"
												className="rselect"
												isMulti={false}
												value={this.state.geofence_name}
												onChange={(e) => {
													this.setState({
														geofence_name : e
													})
												}}
												options={this.selectOptionsItems()} required />
										</div>
										<div className="col-xl-2 col-lg-2 form-group">
											<Select placeholder="Select Truck"
												className="rselect"
												isMulti={false}
												value={this.state.truck_no}
												onChange={(e) => {
													this.setState({
														truck_no : e
													})
												}}
												options={this.selectTruckItems()} required />
										</div>
										<div className="col-xl-2 col-lg-2 form-group">
                                            <Datetime value={this.state.startDate} inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off', required:true }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"  name="startDate" disableCloseOnClickOutside={false}
										closeOnSelect={true} onChange={this.handlerStartDateTime.bind(this)} />
											{/* <input type="text" name="startDate" autoComplete="Off" id="startDate" placeholder="Start Date" className="datepicker form-control" onChange={this.handlerStartDateTime.bind(this)} /> */}
										</div>

                                        <div className="col-xl-2 col-lg-2 form-group">
                                            <Datetime value={this.state.endDate} inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete:'off', required:true }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" disableCloseOnClickOutside={false}
										closeOnSelect={true} onChange={this.handlerEndDateTime.bind(this)} />
											{/* <input type="text" name="endDate" autoComplete="Off" id="endDate" placeholder="End Date" className="datepicker form-control" onChange={this.handlerEndDateTime.bind(this)} /> */}
										</div>
										

                                        <div className="col-xl-2 col-lg-2 form-group">
                                            <button type="submit" className="btn btn-success">Get Data</button>											
                                        </div>
										
											
                                    </form>									
                                </div>	
								{(this.state.pdfRowData.length > 0) ?
										<div className='row col-xl-12 col-lg-12  d-flext justify-content-end' style={{ marginTop:"-30px",right:"-40px",marginBottom:"4px" }}>

											<ExportPDF rowData={this.state.pdfRowData} pagetitle="GeoFence Incursions" pdfColumns={this.state.pdfColumns} />
										</div> : ""}								
								 <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
		          		            rowData={this.state.rowData}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									//floatingFilter={true}
									//enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
		          		          />

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>

		          		        </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				 
				 {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								{(this.state.maptruckno)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						
						<div className="row col-xl-12 col-lg-12 slide-r-body" style={{height:"100%" , padding:"5px"}}>
							
							<div className="col-xl-12 col-lg-12">
								<DrawMap mapFor={"geofence"} truckno={this.state.maptruckno} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords}  
									adaniCheck={this.state.adaniCheck}/>
									{/* <NextBillionMaps 
										mapFor={"geofence"} truckno={this.state.maptruckno} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords}  
										adaniCheck={this.state.adaniCheck}
									/> */}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{/* <div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div> */}
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.endTime):"NA"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
											</div>
											{/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}
										
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				:""	
				}
				
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
			
				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
          		<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">Geofence Incursions</h5>
						
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This utility is available for SND and PRT department which provides data of list of vehicles which enters and exits for the first time within the selected geofence. The list of geofences are the fixed MSIL locations and not the dealer locations.
							</div>
							<div className="col-xl-12 col-lg-12">
								The user needs to select the location and time frame for which incursions are needed. For selected date and geofence, list of all vehicles will be displayed which have entered that particular location first time and exited that location.
							</div>
										   			
							
							<div className="col-xl-12 col-lg-12">
								Image here
							</div>
							
						</div>
					</div>
				</Modal>
			
			</div>
              	
		);
	}
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}
function loadDatepicker()
{
	$(".datepicker").datepicker({
        dateFormat: "dd-mm-yy",
        changeMonth: true,
        changeYear: true
	});
	
};
