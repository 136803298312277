/* eslint-disable no-redeclare */
/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component , useState , useRef , useEffect , useContext} from 'react';
import ReactDOM from 'react-dom';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Autosuggest from 'react-autosuggest';

import Breadcrumb from '../common/breadcrumb.component';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import $ from "jquery";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import "@grapecity/wijmo.styles/wijmo.css";
import * as pdf from "@grapecity/wijmo.pdf";
import * as wijmo from "@grapecity/wijmo";
import DrawMap from '../common/drawmap';
import NextBillionMaps from '../common/nbmaps';
import Trucklocaction from '../layouts/trucklocaction';
import Constant from "../common/googlemapkey";
import reactPDFDownload from './reactpdfdownload';
import Select from 'react-select';
import { HiVideoCamera } from 'react-icons/hi';
import { AlertTriangle, CircleX, Route, Play, Pause, GaugeIcon, Radio, FastForward, VolumeIcon, VolumeOffIcon } from 'lucide-react';
import { GoogleMap, LoadScript, useLoadScript, Marker, InfoWindow, Circle, Polyline } from '@react-google-maps/api';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import '../../assets/css/videoscreen.css';
var infoBox = require('../common/google-infowindow');
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`)
const ResponsiveReactGridLayout = WidthProvider(Responsive);
var map;
var markersArray;
var markerMapArray = []
var viewMarkersArr = [];
export default class TptTrucksGPSData extends Component {
    constructor(props) {
        super(props);
        this.colWidth = 150;
        this.rowHeight = 40;
        this.state =
        {
            pageTitle: "TPT Truck GPS Data",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            routemap: '',
            allmarkers: '',
            trucks: [],
            truck_no: '',
            selected_truck_no: "",
            startDate: '',
            endDate: '',
            mapinfo: '',
            mapinfoclick: '',
            modules: AllModules,
            defTransitCoords: '',
            activeMarker: {},
            selectedPlace: {},
            dealerCode: '',
            timelinesmarkers: [],
            viewtimelinemarkers: true,
            viewgoogleroutes: true,
            googleroutepath: [],
            loadshow: 'show-n',
            open: false,
            alert: null,
            show: false,
            basicTitle: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true
            },
            gridshow: 'show-m',
            filteredData: [],
            mapshow: 'show-m',
            actgridbtn: "btn-success",
            actmapbtn: "btn-default",
            rowData: null,
            showTrucksList: "show-n",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer,
                trucklocaction: Trucklocaction
            },
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
            childconfs: [],
            childrow: [],
			fetch_address : localStorage.getItem("is_hero") == 1 ? {label: "Without Address", value: 0} : {label: "With Address", value: 1},
            rowModelType: "serverSide",
            paginationPageSize: 50,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            truckNoList: [],
            overly: "show-n",
            loadshow:'show-n',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            rowClassRules: {
                "highlitRow": "data.transit_delay == 1",
                "green": "data.status >= 3"
            },
            tolls: [],
			frequency:localStorage.getItem("is_hero") == 1 ? 10 : "",
            showmarkers: 0,
            tottalDistance: [],
            totaldistanceformap: [],
            tpttrucks: [],
            transporterTrucks: [],
            adaniCheck: 0,
            searchtruckno: "",
            sliderTranslate:"",
            fnrRowData:[],
            usergridstate:[],
            for_google_maps : false,
            shownbmaps:true,
            view_carrier_center :"",
            truck:1,
            breaks:1,
            locationPins:0,
            is_show_grid: localStorage.getItem("is_hero") == 1 ? 0 : 1,
            carrierData: {},
            openTerminal: false,
            terminalTitle: '' , 
        };
        this.setTruckno = this.setTruckno.bind(this);
        this.onClickShowTruckLocation = this.onClickShowTruckLocation.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        this.getUserGridState()
        var endDate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
        var  startDate= moment.parseZone().subtract(5, 'days').format('YYYY-MM-DD'+" "+'00:00:00');
        var tptCode = localStorage.getItem('transportercode');
        // console.log("tptCode" ,tptCode);
        if (tptCode.includes("T293") == true) {
            var adaniCheck = 1;
        }
        else {
            var adaniCheck = 0;
        }
        this.setState({
            adaniCheck: adaniCheck,
            endDate :endDate,
            startDate :startDate
        });
        var vehicle_group = localStorage.getItem("vehicle_group");
        // console.log("vehicle_group ", vehicle_group)
        var myroles = localStorage.getItem("roles");
        var tptrucks = []
        if (vehicle_group != "") {
            var tparms = {
                transporter_code: tptCode,
                region: vehicle_group
            }
            redirectURL.post("/dashboard/tpregiontrucksdata", tparms)
                .then((response) => {
                    var records = response.data.records;
                    // console.log("tpt records ", records)
                    this.setState({
                        tpttrucks: records
                    })
                })
        }
        var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
        if (is_enmovil_transporter == 1) {
            // console.log("Box ", localStorage.getItem('token'));
            this.logPageView(); // Log page view to GA
            if (myroles.indexOf("tpt_vrc") >= 0) {
                this.setState({
                    truckNoList: ["353549091894401"]
                })
            }
            else if(myroles.indexOf("JWS") >= 0) {
                this.setState({
                    truckNoList: ["35259 911234567890"]
                })
            } 
            else {
                var params = {
                    transporter_code: localStorage.getItem("transportercode")
                }
                var deviceparams = {
                    transporter_code: localStorage.getItem("transportercode")
                }
                redirectURL.post("/dashboard/devices", deviceparams).then((response1) => {
                    var transporterTrucks = [];
                    var tptrucks = [];
                    var tptCode = JSON.parse(localStorage.getItem("transportercode"));
                    console.log(response1.data.devices,"tptrucksdevicewa")
                    response1.data.devices.map(function (e) {
                        if (tptCode.indexOf(e.transporter_code) > -1) {
                            console.log(localStorage.getItem('transportercode'),localStorage.getItem('transportercode').includes("T293"),"transporter")
                            if(localStorage.getItem('transportercode').includes("T293") == false)
                            {
                                transporterTrucks.push(e)
                                tptrucks.push(e.truck_no)
                            }
                            else
                            {
                                if(e.state == localStorage.getItem("plant_location"))
                                {
                                    transporterTrucks.push(e)
                                    tptrucks.push(e.truck_no)
                                }
                            }
                           
                        }
                    })
                    console.log(tptrucks,"tptrucksdata")
                    console.log(transporterTrucks,"transporterTrucks")
                    // if(localStorage.getItem('transportercode').includes("T293") && localStorage.getItem("plant_location") !="" &&
                    // localStorage.getItem("plant_location") != undefined)
                    // {
                    //     markers = markers.filter((item)=>{
                    //         console.log(item,"item state")
                            
                    //     })
                    // }
                    // console.log("transporterTrucks ", transporterTrucks)
                    this.setState({
                        truckNoList: tptrucks,
                        transporterTrucks: transporterTrucks
                    })
                });
            }
        }
        else {
            window.location.href = "/";
        }
    }
    getUserGridState(){
        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname , screentitle:this.state.pageTitle})
          .then( async (resp) => {
              // console.log("resp ", resp)
               this.setState({
                  usergridstate:resp.data
              });
          })
      }
    selectOptionsItems() {
        let items = [];
        //console.log(this.state.trucks);
        this.state.trucks.forEach(item => {
            if (item != '' && item != null) {
                items.push(<option key={item.truck_no} value={item.truck_no}>{item.truck_no}</option>);
            }
        });
        return items;
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds()); 
        
        if(d != "Invalid Date"){
            this.setState({
                startDate: getHyphenYYYYMMDDHHMMSS(d)
            });
        }
       
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());
        if(d != "Invalid Date"){
        this.setState({
            endDate: getHyphenYYYYMMDDHHMMSS(d)
        });
    }
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    initalList = (event) => {
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        var tpttrucks = this.state.tpttrucks;
        var tptunqrecords = []
        var tptCode = localStorage.getItem("transportercode")
        if (tptCode.includes("_RAKE")) {
            tptunqrecords = dataset
        }else {
            if (tpttrucks.length > 0) {
                dataset.filter(f =>
                    tpttrucks.filter((d) => {
                        if (d.truck_no == f) {
                            tptunqrecords.push(f)
                        }
                    })
                );
            }
            else {
                tptunqrecords = dataset
            }
        }
        
        tptunqrecords = tptunqrecords.slice(0, 100);
        this.setState({
            filteredData: tptunqrecords,
            showTrucksList: "show-m",
        })
    }
    handlerForm = (event) => {
        //console.log("Select", event.target.value);
        // let name = event.target.name;
        // let value = event.target.value;
        // this.setState({[name]:value});
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        var tpttrucks = this.state.tpttrucks;
        var tptunqrecords = []
        if (tpttrucks.length > 0) {
            dataset.filter(f =>
                tpttrucks.filter((d) => {
                    if (d.truck_no == f) {
                        tptunqrecords.push(f)
                    }
                })
            );
        }
        else {
            tptunqrecords = dataset
        }
        var filteredResult = tptunqrecords.filter(function (e) {
            if (e != "" && e != null) {
                return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
            }
        });
        // console.log("filteredResult ", filteredResult)
        filteredResult = filteredResult.slice(0, 100);
        this.setState({
            filteredData: filteredResult,
            showTrucksList: "show-m",
        })
    }
    onClickTruck(event) {
        $("#inputTruck").val($(this).text());
    }
    formHandler = async (event) => {
        await this.setState({
            loadshow: 'show-m',
            shownbmaps:false,
        })
        if(!this.state.for_google_maps ||this.state.done_with_google_maps){
            event.preventDefault();
            this.setState({
                for_google_maps:false
            })
        }
        var inputTruck = $("#inputTruck").val().toUpperCase();
        var dataset = this.state.transporterTrucks;
        var tpttrucks = this.state.tpttrucks;
        // console.log("tpttrucks",tpttrucks)
        // console.log("dataset",dataset)
        var tptunqrecords = []
        if (tpttrucks.length > 0) {
            await dataset.filter(f =>
                tpttrucks.filter((d) => {
                    if (d.truck_no == f.truck_no) {
                        tptunqrecords.push(f)
                    }
                })
            );
        }
        else {
            tptunqrecords = dataset
        }
        var truckCheck = 0;
        var deviceid = "";
        if(localStorage.getItem("transportercode").indexOf("JWS01") >= 0 || localStorage.getItem("transportercode").indexOf("Test01") >= 0 )
        {
            truckCheck = 1;
        }
        else{
            if (tptunqrecords.length > 0) {
                for (var d = 0; d < tptunqrecords.length; d++) {
                    // console.log("tpt records ", tptunqrecords[d]);
                    if (tptunqrecords[d].truck_no == inputTruck) {
                        // console.log("tptunqrecords[d] ", tptunqrecords[d])
                        truckCheck = 1;
                        deviceid = tptunqrecords[d].registereddevices
                        //[0].device_id
                        break;
                    }
                }
            }
        }
        // console.log("truckCheck ", truckCheck)
        if (truckCheck == 1) {
            if (googleAnalytics.page.enableGA) {
                let pageTitle = this.state.pageTitle;
                let eventOptions = {
                    "category": pageTitle,
                    "action": this.state.eventFormAction,
                    "label": googleAnalytics.page.action.formSubmittedGenerate,
                }
                googleAnalytics.logEvent(eventOptions);
            }
            //var formdata = new FormData(event.target);
            // console.log($("#inputTruck").val())
            if(this.state.startDate == 'Invalid date'){
                return this.setState({
                    show: true,
                    basicTitle: 'Enter Valid Date',
                    basicType: "danger",
                    loadshow: 'show-n',
                })
            }
            if(this.state.endDate == 'Invalid date'){
                return this.setState({
                    show: true,
                    basicTitle: 'Enter Valid Date',
                    basicType: "danger",
                    loadshow: 'show-n',
                })
            }
            var formdata = {
                truck_no: $("#inputTruck").val().toUpperCase(),
                startDate: (this.state.startDate),
                endDate: (this.state.endDate),
                frequency: this.state.frequency,
                source: "tptlogin",
                transporter_code : JSON.parse(localStorage.getItem("transportercode"))[0],
                device_id: deviceid,
                fetch_address : this.state.fetch_address.value
            }
            console.log("formdata ", formdata)
            await redirectURL.post("/gmap", formdata, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
                }
            })
                //.then(res => res.json())
                .then(
                    async (result) => {
                        //console.log("result ",result.data);
                        var jsondata = result.data;
                        //  console.log(JSON.parse(jsondata.data))
                        if (result.data.status == 'failure') {
                            this.setState({
                                show: true,
                                basicTitle: 'No GPS data available for this period',
                                basicType: "danger",
                                loadshow: 'show-n',
                                rowData: null,
                                mapinfo: '',
                            })
                        }
                        else {
                            var coordinates = JSON.parse(jsondata.data);
                            //console.log("truckno ", this.props.truckno)
                            console.log("coordinates ", coordinates)
                            var dataarr = []
                            var tottalDistance = [];
                            try {
                                if (coordinates.status == "Failure") {
                                    this.setState({
                                        show: true,
                                        basicTitle: 'No GPS data available for this period',
                                        basicType: "danger",
                                        loadshow: 'show-n',
                                        rowData: null,
                                        mapinfo: '',
                                    })
                                }
                                else {
                                    coordinates.coords.map((item) => {
                                        // console.log("item ", item.cumm_distance)
                                        tottalDistance.push(parseFloat(item.cumm_distance));
                                        // console.log(tottalDistance,"tottalDistance")
                                        dataarr.push({
                                            "truck_no": item.truck_no,
                                            "lat": item.lat,
                                            "lng": item.lng,
                                            "speed": item.speed,
                                            "stime": item.stime,
                                            //"state":item.state,
                                            //"area":item.area,
                                            //"city":item.city,
                                            //"location_type":item.location_type,
                                            "dist_from_prev_point": item.dist_from_prev_point,
                                            "received_on": item.received_on,
                                            "time_from_prev_point": item.time_from_prev_point,
                                            "area": item.area,
                                            "district": item.district,
                                            "state": item.state,
                                            "cumm_distance": item.cumm_distance,
                                            "battery": item.battery,
                                            "near_dealer_name": `${item.near_dealer_code}-${item.near_dealer_name}`,
                                            "near_dealer_distance": item.near_dealer_distance,
                                            "near_dealer_code":item.near_dealer_code,
                                            "geofence_name":item.geofence_name
                                        })
                                    });
                                    // console.log(localStorage.getItem('transportercode'),"plant_location")
									if(localStorage.getItem('transportercode').includes("T293") && localStorage.getItem("plant_location") !="" &&
									localStorage.getItem("plant_location") != undefined)
									{
										dataarr = dataarr.filter((item)=>{
											console.log(item,"item state")
											if(item.state == localStorage.getItem("plant_location"))
											{
												return item;
											}
										})
									}
                                    var totaldistanceData = {}
                                    if(localStorage.getItem("transportercode").indexOf("JWS01") >= 0)
                                    {
                                        var pbrData = false;
                                        totaldistanceData['cumm_distance'] = arrayMax(tottalDistance);
                                    }
                                    else{
                                        var pinnedBottomRowData = {}
                                        pinnedBottomRowData['_id'] = "total";
                                        pinnedBottomRowData['truck_no'] = "";
                                        pinnedBottomRowData['lat'] = "";
                                        pinnedBottomRowData['lng'] = "";
                                        pinnedBottomRowData['speed'] = "";
                                        pinnedBottomRowData['stime'] = "";
                                        pinnedBottomRowData['dist_from_prev_point'] = "";
                                        pinnedBottomRowData['received_on'] = "";
                                        pinnedBottomRowData['time_from_prev_point'] = "Total";
                                        pinnedBottomRowData['area'] = "";
                                        pinnedBottomRowData['district'] = "";
                                        pinnedBottomRowData['state'] = "Total";
                                        pinnedBottomRowData['cumm_distance'] = arrayMax(tottalDistance);
                                        totaldistanceData['cumm_distance'] = arrayMax(tottalDistance);
                                        pinnedBottomRowData['total_travel_time_mins'] = "";
                                        var pbrData = [pinnedBottomRowData]
                                    }
                                    this.setState({
                                        rowData: dataarr,
                                        tottalDistance: pbrData,
                                        totaldistanceformap: [totaldistanceData],
                                        mapinfo: JSON.parse(jsondata.data),
                                        loadshow: 'show-n',
                                        tolls: JSON.parse(jsondata.data).tolls,
                                        inputTruck,
                                        shownbmaps:true,
                                        // for_google_maps:false,   
                                    })
                                    await this.setState((prevState) => ({
                                        is_show_grid: prevState.fetch_address.value,
                                    }));
                                    // if(this.state.for_google_maps){
                                        this.renderMap()
                                        // this.setState({
                                        //     done_with_google_maps:true
                                        // })
                                    // }
                                }
                            }
                            catch (e) {
                                this.setState({
                                    show: true,
                                    basicTitle: 'No GPS data available for this period',
                                    basicType: "danger",
                                    loadshow: 'show-n',
                                    rowData: null,
                                    mapinfo: '',
                                })
                            }
                            //console.log("coordinates ", coordinates)
                            //console.log("Modified ", dataarr)
                        }
                    }
                )
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'Enter Valid Truck Number',
                basicType: "danger",
                loadshow: 'show-n',
                rowData: null,
                mapinfo: '',
            })
        }
    }
    onClickFnrDetails = (e) => {
        redirectURL.post("/consignments/getfnrdetails")
        .then(async (response) =>{
            var fnrdetailsdata = response.data
            console.log(fnrdetailsdata,"respData")
            this.setState({
                fnrRowData : fnrdetailsdata,
                sliderTranslate:"slider-translate-60p",
            });
        })
        
    }
    onClickShowGrid() {
        this.setState({
            gridshow: 'show-m',
            mapshow: 'show-n',
            mapinfoclick: "",
            actgridbtn: "btn-success",
            actmapbtn: "btn-default"
        });
    }
    onClickShowMap() {
        this.setState({
            gridshow: 'show-n',
            mapshow: 'show-m',
            mapinfoclick: this.state.mapinfo,
            actgridbtn: "btn-default",
            actmapbtn: "btn-success"
        });
    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderTranslate:"",
            // showTrucksList: "show-n",
        });
    }
    hideList() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            // showTrucksList: "show-n",
        });
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    setTruckno = (event) => {
        this.setState({
            selected_truck_no: event.target.text
        })
        // console.log(event.target.text)
    }
    checkHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }
    renderMap = () => {
        loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing&callback=initMap");
        window.initMap = this.initMap1
    }
    initMap1 = () => {
        //console.log("arr ",arr)
        var currentwindow;
        var dealer = '';
        var tolls = this.state.tolls;
        var arr = [];
        var jsondata = this.state.mapinfo;
        var locations = jsondata.breaks;
        var arr = jsondata.coords;
        var filterDataArr = jsondata.filter_data || []
        if (arr.length > 0 && typeof arr.length != undefined) {
            var lt = arr[0].lat;
            var ln = arr[0].lng;
        }
        else {
            var lt = 28.4519751;
            var ln = 77.0310713;
        }
        var routeinfo;
        try {
            var mapOptions = {
                zoom: 13,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                labels: true,
                mapTypeControlOptions: {
                    mapTypeIds: ['satellite', 'roadmap'],
                },
                center: new window.google.maps.LatLng(lt, ln),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP
            };
        }
        catch (e) {
            var mapOptions = {
                zoom: 13,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                labels: true,
                mapTypeControlOptions: {
                    mapTypeIds: ['satellite', 'roadmap'],
                },
                center: new window.google.maps.LatLng(28.4519751, 77.0310713),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP
            };
        }
        try {
            map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
        } catch (e) { }
        var bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng(lt, ln));
        //Newly added route 
        var fDArr2 = [];
        var darr2 = [];
        if (localStorage.getItem('email').includes('enmovil.in')) {
            if (filterDataArr.length > 0) {
                darr2 = filterDataArr.filter(function (value, index, arr) {
                    // console.log("Vale ", value)
                    return value
                });
                if (darr2.length < 2000) {
                    var ratio = 1;
                }
                else {
                    var ratio = 20;
                }
                fDArr2 = darr2.filter(function (value, index, darr) {
                    return (index % ratio == 0);
                });
                if (fDArr2.length > 0) {
                    // Filter out valid lat/lng points
                    var validPoints = fDArr2.filter(point =>
                        typeof point.latitude === 'number' && typeof point.longitude === 'number'
                    );
                    // Ensure validPoints is an array and has elements
                    var count_icon = 0 
                    if (validPoints.length > 0) {
                        // Create a path from valid points
                        var filterPath = validPoints.map((point,index) =>
                            new window.google.maps.LatLng(point.latitude, point.longitude)
                        );
                        // Add polyline to the map
                        var filterPolyline = new window.google.maps.Polyline({
                            map: map,
                            path: filterPath,
                            strokeColor: '#eb4034', // Different color for differentiation
                            strokeOpacity: 1.0,
                            strokeWeight: 2.5,
                            icons: [{
                                icon: {
                                    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                    strokeColor: '#FFC43A',
                                    fillColor: '#FFC43A',
                                    fillOpacity: 1,
                                    strokeWeight: 2
                                },
                                repeat: '200px',
                                path: []
                            }]
                        });
                        map.fitBounds(bounds);
                        // console.log("Additional polyline and markers added successfully."); // Debugging
                    } else {
                        // console.error("No valid latitude and longitude data in fDArr2:"); // Debugging
                    }
                } else {
                    // console.log("fDArr2 is empty or not defined."); // Debugging
                }
            }
        }
        // Create our info window content
        var currentinfowindow = null;
        var line = new window.google.maps.Polyline(
            {
                map: map,
                strokeColor: '#157254',
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                icons: [{
                    icon: {
                    		path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    		strokeColor:'#ff8c52',
                    		fillColor:'#ff8c52',
                    		fillOpacity:1,
                    		strokeWeight: 2
                    },
                    repeat: '100px',
                    path: []
                }]
            });
        if (arr.length > 0) {
            var allpoints = [];
            var arr1 = [];
            //console.log("Total ",ratio);
            if (this.state.timelinesmarkers.length > 0) {
                arr1 = this.state.timelinesmarkers.filter(function (value, index, arr) {
                    return (index % ratio == 0);
                });
                //var arr1 = this.state.timelinesmarkers;
            }
            var arr2 = [];
            var darr = [];
            var tptCode = localStorage.getItem('transportercode') || ''
            if (arr.length > 0) {
                darr = tptCode.includes("4WHEEL_ENTRAC") ? arr : arr.filter(value => value.dist_from_prev_point > 0);
                if (darr.length < 2000) {
                    var ratio = 1;
                }
                else {
                    var ratio = 20;
                }
                //console.log("darr.length ", darr.length)
                // arr2 = darr.filter(function (value, index, darr) {
                //     return (index % ratio == 0);
                // });
                arr2 = darr
                //var arr1 = this.state.timelinesmarkers;
            }
            // view markers code
            // console.log("arr2 here ", arr2)
            if (arr2.length > 0) {
                markersArray = arr2;
                // //console.log("allpoints ", allpoints)
                for (var a = 0; a < arr2.length; a++) {
                    //console.log(arr2[a],"arr2[a]");
                    var marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(arr2[a].lat, arr2[a].lng),
                        icon: {
                            path: window.google.maps.SymbolPath.CIRCLE,
                            strokeColor: '#157254',
                            fillColor: '#157254',
                            fillOpacity: 1,
                            strokeWeight: 5,
                            scale: 1,
                        },
                        map: map,
                        content: arr2[a]
                    });
                    marker.setVisible(false);
                    // 	//console.log("Arr ",arr1[a])
                    window.google.maps.event.addListener(marker, 'mouseover', (function (marker) {
                        return function () {
                            //console.log("Arr ",marker)
                            var contentarr = []
                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                            var header = "Current Status"
                            contentarr.push({ "key": "Time ", "value": getDDMMYYYYHHMMSS(marker.content.stime) })
                            contentarr.push({ "key": "Speed ", "value": marker.content.speed + " KMPH" })
                            var contentString = infoBox("", header, contentarr, '')
                            infowindow.setContent(contentString);
                            currentwindow = infowindow;
                            infowindow.open(map, marker);
                            //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                        }
                    })(marker));
                    window.google.maps.event.addListener(marker, 'mouseout', function () {
                        infowindow.close();
                    });
                    viewMarkersArr.push(marker);
                }
            }
            var routeinfo = jsondata.route_details;
            var icon_cnt = 0
            for (var i = 0; i < arr2.length - 1; i++) {
                var locationLatLng = [];
                locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
                locationLatLng.push(new window.google.maps.LatLng(arr2[i + 1].lat, arr2[i + 1].lng));
                if (icon_cnt % 25 == 0) {
                    var lineicon = [{
                        icon: {
                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                strokeColor:'#FF0000',
                                fillColor:'#FF0000',
                                fillOpacity:1,
                                strokeWeight: 2
                        },
                        repeat: '100px',
                        path: []
                    }];
                } else { lineicon = [] }
                icon_cnt = icon_cnt + 1
                var consignments_missing_route_line = new window.google.maps.Polyline({
                    map: map,
                    path: locationLatLng,
                    strokeColor: '#157254',
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    icons: lineicon
                });
                // console.log(arr2[i].msgtype)
                if (arr2[i].msgtype == "G") {
                    consignments_missing_route_line.setOptions({
                        strokeColor: "#452a68",
                        strokeWeight: 5.5,
                    })
                }
                bounds.extend(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
            }
            // for (let i = 0; i < arr2.length; i++) {
            //     //if(arr2[i].speed > 0)
            //     //{
            //         var linecolr = "#157254";
            //         if(arr2[i].set  == 2)
            //         {
            //             linecolr = "#0000ff";
            //         }
            //         if(arr2[i].set  == 3)
            //         {
            //             linecolr = "#980000"
            //         }
            //         line.setOptions({strokeColor: linecolr}); 
            //     var path=line.getPath().getArray();
            //     let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
            //     path.push(latLng);
            //     line.setPath(path);
            //     //Change line color based on map type
            //     window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
            //         if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
            //         {
            //             var line=new window.google.maps.Polyline(
            //             {
            //                 map:map,
            //                 strokeColor: '#FFFFFF',
            //                 strokeOpacity: 1.0,
            //                 strokeWeight: 2.5,
            //                 icons: [{
            //                         icon: {
            //                                 path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            //                                 strokeColor:'#ff8c52',
            //                                 fillColor:'#ff8c52',
            //                                 fillOpacity:1,
            //                                 strokeWeight: 2
            //                                 },
            //                         repeat:'100px',
            //                         path:[]
            //                     }]
            //                 });                
            //         }
            //         else
            //         {
            //             var line=new window.google.maps.Polyline(
            //             {
            //                 map:map,
            //                 strokeColor: '#157254',
            //                 strokeOpacity: 1.0,
            //                 strokeWeight: 2.5,
            //                 icons: [{
            //                         icon: {
            //                                 path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            //                                 strokeColor:'#ff8c52',
            //                                 fillColor:'#ff8c52',
            //                                 fillOpacity:1,
            //                                 strokeWeight: 2
            //                                 },
            //                         repeat:'100px',
            //                         path:[]
            //                     }]
            //                 });
            //         }
            //         for (i = 0; i < arr2.length; i++) {
            //                 var path=line.getPath().getArray();
            //                 let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
            //                 path.push(latLng);
            //                 line.setPath(path);
            //                         //map.setCenter(latLng);
            //             }
            //         var x = map.getZoom();
            //         var c = map.getCenter();
            //         window.google.maps.event.trigger(map, 'resize');
            //         map.setZoom(x);
            //         map.setCenter(c);
            //     } );
            //     bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
            //     //}
            // }
            var infowindow = new window.google.maps.InfoWindow();
            var marker, l;
            for (l = 0; l < locations.length; l++) {
                marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
                    icon: require('../../assets/icons/cf.png'),
                    map: map,
                });
                window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
                    return function () {
                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";
                        var contentarr = []
                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                        var header = "Break";
                        try {
                            if (typeof this.props.mapFor != 'undefined') {
                                var timeinsecs = moment.parseZone(locations[l].break_start).format("x") - moment.parseZone(locations[l].break_start).format("x")
                                contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
                                contentarr.push({ "key": "Break End", "value": locations[l].break_end })
                                contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
                                contentarr.push({ "key": "Address", "value": locations[l].break_address })
                                contentarr.push({ "key": "City", "value": locations[l].city })
                                contentarr.push({ "key": "State", "value": locations[l].break_state })
                            }
                            else {
                                contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
                                contentarr.push({ "key": "Break End", "value": locations[l].break_end })
                                contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
                                contentarr.push({ "key": "Address", "value": locations[l].break_address })
                                contentarr.push({ "key": "City", "value": locations[l].city })
                                contentarr.push({ "key": "State", "value": locations[l].break_state })
                            }
                        }
                        catch (e) {
                            contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
                            contentarr.push({ "key": "Break End", "value": locations[l].break_end })
                            contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
                            contentarr.push({ "key": "Address", "value": locations[l].break_address })
                            contentarr.push({ "key": "City", "value": locations[l].city })
                            contentarr.push({ "key": "State", "value": locations[l].break_state })
                        }
                        var contentString = infoBox(marker.icon, header, contentarr, '')
                        infowindow.setContent(contentString);
                        currentwindow = infowindow;
                        infowindow.open(map, marker);
                        //console.log(marker.position.toJSON());
                        // console.log('{"lat":' + marker.position.lat() + ',"lng":' + marker.position.lng() + '}');
                    }
                })(marker, l));
                markerMapArray.push({ marker, action: "break" });
            }
            marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
                icon: require('../../assets/icons/track_start.png'),
                map: map,
            });
            window.google.maps.event.addListener(marker, 'click', (function (marker) {
                return function () {
                    //	console.log(clusters[0])
                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                    var contentarr = []
                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                    var header = "Starting Point"
                    contentarr.push({ "key": "Started at", "value": routeinfo.start_time })
                    var contentString = infoBox(marker.icon, header, contentarr, '')
                    infowindow.setContent(contentString);
                    currentwindow = infowindow;
                    infowindow.open(map, marker);
                    // console.log('{"lat":' + marker.position.lat() + ',"lng":' + marker.position.lng() + '}');
                }
            })(marker));
            markerMapArray.push({ marker, action: "track_start" })
            if (this.state.adaniCheck == 1) {
                marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
                    icon: require('../../assets/icons/shuttle-end.png'),
                    map: map,
                });
            }
            else {
                marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
                    icon: require('../../assets/icons/truck-end.png'),
                    map: map,
                });
            }
            window.google.maps.event.addListener(marker, 'click', (function (marker) {
                return function () {
                    //	console.log(clusters[0])
                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
                    var contentarr = []
                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                    var header = "End Point"
                    contentarr.push({ "key": "End at", "value": routeinfo.end_time })
                    var contentString = infoBox(marker.icon, header, contentarr, '')
                    infowindow.setContent(contentString);
                    infowindow.setContent(contentString);
                    currentwindow = infowindow;
                    infowindow.open(map, marker);
                    // console.log('{"lat":' + marker.position.lat() + ',"lng":' + marker.position.lng() + '}');
                }
            })(marker));
            markerMapArray.push({ marker, action: "track_end" })
        }
        try {
            if (tolls.length > 0) {
                var infowindow = new window.google.maps.InfoWindow();
                var l;
                tolls.map(function (e, index) {
                    var tollMarker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(e.lat, e.lon),
                        icon: require('../../assets/icons/barrier.png'),
                        map: map,
                    });
                    window.google.maps.event.addListener(tollMarker, 'mouseover', (function (tollMarker, index) {
                        return function () {
                            var contentarr = []
                            var header = "Toll - " + e.name
                            contentarr.push({ "key": "Address", "value": e.location })
                            var contentString = infoBox(tollMarker.icon, header, contentarr)
                            infowindow.setContent(contentString);
                            var currentwindow = infowindow;
                            infowindow.open(map, tollMarker);
                        }
                    })(tollMarker, index));
                    window.google.maps.event.addListener(tollMarker, 'mouseout', function () {
                        infowindow.close();
                    });
                })
            }
        } catch (e) { }
        map.fitBounds(bounds)
    }
    exportReactPDF()
    {
        const rowData = JSON.parse(JSON.stringify(this.state.rowData));
        const combinedData = rowData.map(({ state, city, area, ...rest }) => ({
            ...rest,
            address: area
          }));
        reactPDFDownload({data: combinedData});
    }
    exportPDF() {
        let doc = new pdf.PdfDocument({
            header: {
                declarative: {
                    text: 'Carrier Route\t&[Page]\\&[Pages]',
                    font: new pdf.PdfFont('times', 14),
                    brush: '#bfc1c2'
                }
            },
            lineGap: 2,
            pageSettings: {
                margins: {
                    left: 10,
                    right: 10,
                    top: 10,
                    bottom: 10
                },
                size: "A2"
            },
            pageAdded: function (s, e) {
                let image = require('../../assets/icons/logo.png');
                let enmovilImage = require('../../assets/icons/enmovil.png');
                //
                s.drawImage(image, null, null, {
                    width: 150,
                    height: 30,
                    stretchProportionally: true,
                    align: pdf.PdfImageHorizontalAlign.Center
                });
                s.drawImage(enmovilImage, 1000, 0, {
                    width: 100,
                    height: 30,
                    stretchProportionally: true,
                    align: pdf.PdfImageHorizontalAlign.Right
                });
            },
            ended: (sender, args) => pdf.saveBlob(args.blob, 'Document.pdf')
        });
        var consignees = JSON.parse(JSON.stringify(this.state.rowData))
        // console.log(consignees, "consignees")
        consignees.map(function (c) {
            try {
                if (c.lat != undefined && c.lat != '') {
                    c.lat = c.lat.toFixed(6);
                }
                else {
                    c.lat = "";
                }
            }
            catch (e) {
                c.lat = "";
            }
            try {
                if (c.lng != undefined && c.lng != '') {
                    c.lng = c.lng.toFixed(6);
                }
                else {
                    c.lng = "";
                }
            }
            catch (e) {
                c.lng = "";
            }
            if (c.stime != "") {
                c.stime = getHyphenDDMMMYYYYHHMM(c.stime);
            }
            else {
                c.stime = "";
            }
            if (c.time_from_prev_point != '' && c.time_from_prev_point != undefined) {
                
                c.time_from_prev_point = secondsToDhms(c.time_from_prev_point);
            }
            else {
                return c.time_from_prev_point = secondsToDhms(c.time_from_prev_point);;
                // c.time_from_prev_point = "";
            }
            if (c.cumm_distance != '' && c.cumm_distance != undefined) {
                c.cumm_distance = convertMeters(c.cumm_distance);
            }
            else {
                c.cumm_distance = convertMeters(c.cumm_distance);
            }
        });
        if (consignees.length > 40) {
            var totalPages = (consignees.length / 40).toString();
            // console.log("tpages", totalPages)
            var lastRemainder = parseInt(totalPages.split(".")[1])
            totalPages = parseInt(totalPages.split(".")[0])
            // console.log(totalPages, "totalPages")
            // console.log(lastRemainder, "lastRemainder")
            // var endPage = 75
            // var startPage = 0
            while (consignees.length !== 0) {
                let sen = consignees.splice(0, 40)
                this.$_drawEmployee(doc, sen);
            }
            // for(var i=0;i<totalPages;i++)
            // {
            //     //console.log(startPage, endPage);
            //     var sen = consignees.slice(startPage,endPage);
            //     this.$_drawEmployee(doc, sen);
            //     if((i+1) == totalPages)
            //     {
            //         var sen1 = consignees.slice(endPage+1,lastRemainder);
            //         this.$_drawEmployee(doc, sen1);
            //     }
            //     else
            //     {
            //         startPage = endPage + 1;
            //         endPage = endPage + 75;
            //     }
            // }
        }
        else {
            this.$_drawEmployee(doc, consignees);
        }
        // consignees.forEach((employee, i, arr) => {
        //     this.$_drawEmployee(doc, employee);
        //     if (i < arr.length - 1) {
        //         doc.addPage();
        //     }
        // });
        doc.end();
    }
    //
    $_drawEmployee(doc, consignees) {
        //let tot = employee.expenses.totals;
        let expenses = consignees;
        // console.log(expenses,"expenses")
        if (expenses.length > 0) {
            let colIds = []
            var columnStates =  this.gridColumnApi.getColumnState()
            var columndefs = []
            let addressFieldAdded = false;  // Declare outside of the map to persist the flag
            columnStates.map(e => {
                this.gridColumnApi.columnController.columnDefs.map(f => {
                    if (e.hide == false && e.colId != "0") {
                        if (e.colId == f.field) {
                            let binding = f.field;
                            // Check if we're dealing with state, city, area, or district and address field is not yet added
                            if (!addressFieldAdded && (f.field === "state" || f.field === "city" || f.field === "area" || f.field === "district")) {
                                columndefs.push({
                                    header: "Address",
                                    binding: "area"  // You can change this based on the data you want to show for the address
                                });
                                addressFieldAdded = true; // Mark that address field is added
                            } 
                            // Only push non-address related fields
                            else if (!(f.field === "state" || f.field === "city" || f.field === "area" || f.field === "district")) {
                                columndefs.push({
                                    header: `${f.headerName}`,
                                    binding: `${f.field}`
                                });
                            }
                        }
                    }
                });
              
            })
            
            let columns =columndefs ,bold = new pdf.PdfFont('times', 16, 'normal', 'bold'), colWidth = this.colWidth, rowHeight = this.rowHeight;
            
            let y = doc.y;
            doc.moveDown(2);
            // * draw table *
            doc.saveState();
            y = 0;
            let scale = doc.width / (columns.length * colWidth), docY = doc.y;
            if (scale > 1) {
                scale = 1;
            }
            doc.scale(scale, scale, new wijmo.Point(0, docY));
            doc.translate(0, docY);
            // header
            this.$_renderRow(doc, y, columns, column => column.header, null, bold, '#fad9cd');
            y += rowHeight;
            // body
            expenses.map((item, index) => {
                //this.$_checkLineAvailable(doc);  
                this.$_renderRow(doc, y, columns, column => item[column.binding], column => column.format ,bold = new pdf.PdfFont('times', 16, 'normal'));
                y += rowHeight;
            });
            doc.addPage(doc.currentPageSettings);
            doc.footer.drawText("Report generated on enmovil.io by Enmovil Solutions", null, null, {
                align: pdf.PdfTextHorizontalAlign.Right
            });
        }
    }
    //
    $_checkLineAvailable(doc) {
        if (doc.height - doc.y < doc.lineHeight() + doc.lineGap) {
            doc.addPage();
        }
    }
    //
    $_renderRow(doc, y, values, valueGetter, formatGetter, font, brush) {
        let colWidth = this.colWidth, rowHeight = this.rowHeight;
        //
        values.forEach((v, idx) => {
            let x = idx * colWidth;
            //
            doc.paths
                .rect(x, y, colWidth, rowHeight)
                //.fill(brush || '#eaeaea')
                .stroke();
            //
            let value = valueGetter != null ? valueGetter(v) : v || '';
            let format = formatGetter != null ? formatGetter(v) : '';
            //
            if (value !== 'Total') {
                value = wijmo.changeType(value, wijmo.DataType.String, format);
            }
            //
            doc.drawText(value, x + 2, y + 2, {
                font: font,
                height: rowHeight,
                width: colWidth,
                align: pdf.PdfTextHorizontalAlign.Center
            });
        });
        doc.moveDown(5);
    }
    onClickShowTruckLocation(e) {
        // console.log("CLiclable ", e)
        var lat = parseFloat(e.data.lat);
        var lng = parseFloat(e.data.lng);
        var data = e.data;
        map.setCenter(new window.google.maps.LatLng(lat, lng));
        map.setZoom(22);
        var markerLatlng = new window.google.maps.LatLng(lat, lng);
        //var image =  require('../../assets/icons/truckblue.png');
        var image = require('../../assets/icons/gmarker.png');

        var marker = new window.google.maps.Marker({
            position: markerLatlng,
            map: map,
            title: data.truck_no,
            area : data.area,
            icon: image
        });


        var contentarr = []
        var header = data.truck_no
        //contentarr.push({"key":"Reached On", "value":getDDMMYYYYHHMMSS(data.reached_on)})
        contentarr.push({ "key": "City/Area", "value": data.area })
        contentarr.push({ "key": "State", "value": data.state })

        var contentString = infoBox(marker.icon, header, contentarr, '')

        var infowindow = new window.google.maps.InfoWindow({
            content: contentString
        });

        marker.setAnimation(window.google.maps.Animation.DROP)
        marker.addListener('click', function () {
            infowindow.open(map, marker);
        });

        marker.setMap(map);
        // this.setState({
        //     view_carrier_center : {lat:lat , lng:lng}
        // })
//         console.log(data, "data")
//         this.state.nbmap.map.setCenter({lat:lat , lng: lng})
//         this.state.nbmap.map.setZoom(20)
//     var icon = require('../../assets/icons/gmarker.png')
//     const popup = new Popup({ offset: 10, closeButton: false })
//       .setLngLat({ lat: data.lat, lng: data.lng })
//       .setHTML(`<div class="popup-card">
//     <div class="popup-header-wrapper">
//       <div class="popup-header"><img class='mr-3' src="${icon}"> ${data.truck_no}</div>
//     </div>
//     <div class="popup-body">
//       <table class="popup-table">
//         <tr>
//           <td class="popup-key">City/Area  </td>
//           <td class="popup-value">${data.area}</td>
//         </tr>
//         <tr>
//           <td class="popup-key">State  </td>
//           <td class="popup-value">${data.state}</td>
//         </tr>
//         </table>
//     </div>
//   </div>
//   `)
//       .setMaxWidth('300px')
  
//     const el = document.createElement('div')
//     el.className = 'animate-marker'
//     el.style.backgroundImage =
//       `url(${icon})`
//     el.style.backgroundSize = 'cover'
//     el.style.opacity = '1';
// el.style.animation = 'dropMarker 1s';
// el.style.animationFillMode = 'forwards';
//     const marker = new Marker({
//       element: el,
//     })
//       .setLngLat({ lat: data.lat, lng: data.lng})
//       .addTo(this.state.nbmap.map)
//       marker.setPopup(popup);
//     //   marker.getElement().addEventListener('mouseenter', () => {
//         marker.togglePopup();
//     //   });
      
//     //   marker.getElement().addEventListener('mouseleave', () => {
//         marker.togglePopup();
//     //   });
    
        // map.setCenter(new window.google.maps.LatLng(lat, lng));
        // map.setZoom(22);
        // var markerLatlng = new window.google.maps.LatLng(lat, lng);
        //var image =  require('../../assets/icons/truckblue.png');
        // var image = require('../../assets/icons/gmarker.png');

        // var marker = new window.google.maps.Marker({
        //     position: markerLatlng,
        //     map: map,
        //     title: data.truck_no,
        //     icon: image
        // });


        // var contentarr = []
        // var header = data.truck_no
        // //contentarr.push({"key":"Reached On", "value":getDDMMYYYYHHMMSS(data.reached_on)})
        // contentarr.push({ "key": "City/Area", "value": data.area })
        // contentarr.push({ "key": "State", "value": data.state })

        // var contentString = infoBox(marker.icon, header, contentarr, '')

        // var infowindow = new window.google.maps.InfoWindow({
        //     content: contentString
        // });

        // marker.setAnimation(window.google.maps.Animation.DROP)
        // marker.addListener('click', function () {
        //     infowindow.open(map, marker);
        // });

        // marker.setMap(map);  

    }
    showMarkers(event) {
        // console.log("event.target.value ", event.target.value)
        if (event.target.value == 0) {
            this.setState({
                showMarkers: 1
            });
            // viewMarkersArr.map(function(e){
            // 	e.setVisible(true);
            // })
            // console.log("markersArray ", markersArray)
            var infowindow = new window.google.maps.InfoWindow();
            if (markersArray.length > 0) {
                var stepby = Math.round(markersArray.length * 0.007)
                // var counter = ( stepby > 0)?stepby:1;
                // var incrementby = counter
                var counter = 1;
                var incrementby = 1;
                // console.log(markersArray.length, counter);
                while (counter < markersArray.length) {
                    var marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(markersArray[counter].lat, markersArray[counter].lng),
                        icon: {
                            path: window.google.maps.SymbolPath.CIRCLE,
                            strokeColor: '#157254',
                            fillColor: '#157254',
                            fillOpacity: 1,
                            strokeWeight: 5,
                            scale: 1,
                        },
                        map: map,
                        content: markersArray[counter]
                    });
                    // marker.setVisible(false);
                    window.google.maps.event.addListener(marker, 'mouseover', (function (marker) {
                        return function () {
                            // console.log("Arr ",marker)
                            var contentarr = []
                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                            var header = "Current Status"
                            contentarr.push({ "key": "Time ", "value": getDDMMYYYYHHMMSS(marker.content.stime) })
                            contentarr.push({ "key": "Speed ", "value": marker.content.speed + " KMPH" })
                            contentarr.push({ "key": "Address ", "value": marker.content.area })
                            contentarr.push({ "key": "City ", "value": marker.content.city })
                            contentarr.push({ "key": "State ", "value": marker.content.state })

                            var contentString = infoBox("", header, contentarr, '')


                            infowindow.setContent(contentString);
                            infowindow.open(map, marker);
                            //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                        }
                    })(marker));
                    window.google.maps.event.addListener(marker, 'mouseout', function () {
                        infowindow.close();
                    });

                    viewMarkersArr.push(marker);
                    counter = counter + incrementby;
                    // console.log("counter",counter)
                }
                // for(var a=0;a<markersArray.length;a++)
                // {

                // }
            }

        }
        else {
            this.setState({
                showMarkers: 0
            });
            viewMarkersArr.map(function (e) {
                e.setVisible(false);
            });
        }
    }
    
    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        this.restoreGridStates()
    };


    onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();

		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.pageTitle
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:this.state.pageTitle
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		///console.log("this.state.usergridstate ", this.state.usergridstate)
		try{
			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				console.log("gridcolumns",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}


	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.pageTitle
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

    getGoogleMaps = async () => {
       await this.setState({
            for_google_maps : true,
            done_with_google_maps:false
        })
        this.formHandler()

    }    
    switchBreakTruck(event) {
        try {
            this.setState({
                [event.target.name]: this.state[event.target.name] == 0 ? 1 : 0
            }, () => {
                if (event.target.name == "truck") {
                    console.log(markersArray, "markers arr")
                    console.log(markerMapArray, "markers Mapping")
                    markerMapArray.forEach(itm => {
                        if (itm.action == "track_end" || itm.action == "track_start") {
                            if (event.target.value == 1)
                                itm.marker.setVisible(true)
                            else
                                itm.marker.setVisible(false)
                        }
                    })
                }
                else if (event.target.name == "breaks") {
                    markerMapArray.forEach(itm => {
                        if (itm.action == "break") {
                            if (event.target.value == 1)
                                itm.marker.setVisible(true)
                            else
                                itm.marker.setVisible(false)
                        }
                    })
                }
                // else if (event.target.name == "locationPins") {
                //     markerMapArray.forEach(itm => {
                //         if (itm.action == "location") {
                //             if (event.target.value == 1)
                //                 itm.marker.setVisible(true)
                //             else
                //                 itm.marker.setVisible(false)
                //         }
                //     })
                // }
            })
        } catch (e) {
            console.log("Error ", e)
        }
    }
    onClickingRecording = async (params) => {
        try {
            //   console.log(vehicleNo)
            let { startDate, endDate, rowData } = this.state
            let truckNo = rowData[0].truck_no
            var params = {
                "vehicle_no": truckNo,
                "start_date": getFormattedDate(new Date(startDate)),
                "end_date": getFormattedDate(new Date(endDate)),
            }
            const response = await redirectURL.post(`/consignments/getdashcamvideodata`, params)
            console.log(response)
            if (response.data.status == "200") {
                if (["str_json"].includes(response.data.resp_data_type)) {
                    this.setState({
                        carrierData: response.data.data,
                        openTerminal: true,
                        terminalTitle: "Dashboard Camera View",
                    })
                } else {
                    this.setState({
                        carrierData: response.data.data,
                        openTerminal: true,
                        terminalTitle: "Dashboard Camera View",
                    })
                    console.error("Login failed: Invalid status");
                }
            } else {
                this.setState({
                    carrierData: {},
                    openTerminal: true,
                    terminalTitle: "Dashboard Camera View",
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    onClosingPopup = () => {
        this.setState({
            openTerminal: false,
            carrierData: {},
        })
    }
    render() {
        let order_cnt = [];

        const dStyles = {
            width: '100%',
            height: '100%'
        }
        const modalStyles = {
            width: '500px !important',
        }
        const { open } = this.state;
        var adaniCheck = this.state.adaniCheck;
        var tkName = "Truck No";
        if (adaniCheck == 1) {
            tkName = "Shuttle No";
        }
        if (adaniCheck == 1) {
            tkName = "Shuttle No";
        }
        if(localStorage.getItem("transportercode").indexOf("JWS01") >= 0)
        {
            tkName = "Device ID"
        }

        var hideForHero = localStorage.getItem("is_hero") == 1 ? true : false

        const columnwithDefs = [
            {
                headerName: "",
                field: "",
                width: 50,
                //   cellRenderer:'trucklocaction'
                cellRendererSelector: function (params) {
                    if (params.data._id != "total") {
                        return {
                            component: "trucklocaction"
                        }
                    }
                },
            },
            {
                headerName: tkName,
                field: "truck_no",
                width: 100,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (localStorage.getItem("transportercode").indexOf("JWS01") >= 0) {
                        return "Test Rake";
                    }
                    else {
                        return params.data.truck_no;
                    }

                }

            },
            {
                headerName: "Latitude",
                field: "lat",
                width: 120,
                filter: true,
                resizable: true,
                hide : hideForHero,
                valueGetter: function (params) {
                    try {
                        return params.data.lat.toFixed(6);
                    }
                    catch (e) {
                        return params.data.lat;
                    }

                }

            },
            {
                headerName: "Longitude",
                field: "lng",
                width: 120,
                filter: true,
                resizable: true,
                hide : hideForHero,
                valueGetter: function (params) {
                    try {
                        return params.data.lng.toFixed(6);
                    }
                    catch (e) {
                        return params.data.lng;
                    }

                }

            },
            //   {
            //       headerName: "Last Known State",
            //       field: "state",
            //       width: 140,
            //       filter: true,
            //       resizable: true

            //   },
            //   {
            //       headerName: "Last Know City",
            //       field: "city",
            //       width: 140,
            //       filter: true,
            //       resizable: true

            //   },

            //   {
            //     headerName: "Last Know Area",
            //     field: "area",
            //     width: 140,
            //     filter: true,
            //     resizable: true

            // },
            //   {
            //       headerName: "Location Type",
            //       field: "location_type",
            //       width: 140,
            //       filter: true,
            //       resizable: true

            //   },
            {
                headerName: "Area / City",
                field: "area",
                width: 130,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Geofence Name",
                field: "geofence_name",
                width: 130,
                hide : hideForHero,
                filter: true,
                resizable: true,
            },
            {
                headerName: "District",
                field: "district",
                width: 150,
                hide : hideForHero,
                filter: true,
                resizable: true,
            },
            {
                headerName: "State",
                field: "state",
                width: 130,
                hide : hideForHero,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Speed (Kms)",
                field: "speed",
                width: 80,
                filter: true,
                resizable: true

            },
            {
                headerName: "Packet Time",
                field: "stime",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    //console.log(params);
                    if (params.data.stime != "") {
                        return getHyphenDDMMMYYYYHHMM(params.data.stime);
                    }
                    else {
                        return "";
                    }

                },
                // valueGetter:function(params){
                // 	console.log(params);
                // 	return params.data.stime;
                // }
                //   // filter: "agDateColumnFilter",
                comparator: dateComparator,

            },
            //   {
            //         headerName: "Received On",
            //         field: "received_on",
            //         width: 140,
            //         filter: true,
            //         resizable: true,
            //         valueGetter:function(params){
            //             console.log(params.data.received_on);
            //             if(params.data.received_on != "NaT")
            //             {
            //                 return getHyphenDDMMMYYYYHHMM(params.data.received_on);
            //             }
            //             else{
            //                 return "";
            //             }

            //         },
            //         // // filter: "agDateColumnFilter",
            // 		comparator: dateComparator,


            //   },
            // {
            //     headerName: "Time from previous point",
            //     field: "time_from_prev_point",
            //     width: 240,
            //     filter: true,
            //     resizable: true,
            //     valueGetter: function (params) {
            //         if (params.data.time_from_prev_point != 'Total') {
            //             return secondsToDhms(params.data.time_from_prev_point);
            //         }
            //         else {
            //             return "Total";
            //         }

            //     }

            // },
            //   {
            //       headerName: "Distance from previous point (KM)",
            //       field: "dist_from_prev_point",
            //       width: 240,
            //       filter: true,
            //       resizable: true,
            //       valueGetter : function(params)
            //       {
            //         return  convertMeters(params.data.dist_from_prev_point);
            //       }

            //   },
            // {
            //     headerName: "Cummulative Distance (KM)",
            //     field: "cumm_distance",
            //     width: 140,
            //     filter: true,
            //     resizable: true,
            //     valueGetter: function (params) {
            //         return convertMeters(params.data.cumm_distance);
            //     }

            // },
        ]
        if(localStorage.getItem('email')=='gbfc@enmovil.in'  || localStorage.getItem("transportercode").includes('T332') ){
            columnwithDefs.push(
                {
                    headerName: "Near Dealer Name",
                    field: "near_dealer_name",
                    width: 80,
                    filter: true,
                    resizable: true
                    
                },
                {
                    headerName: "Near Dealer Distance (in KM)",
                    field: "near_dealer_distance",
                    width: 80,
                    filter: true,
                    resizable: true
                    
                }
            )
          }
        if(localStorage.getItem("transportercode").indexOf("JWS01") >= 0)
        {
            columnwithDefs.push(
                {
                headerName: "Battery",
                field: "battery",
                width: 140,
                filter: true,
                resizable: true,
            })
        }
        else{
            columnwithDefs.push(
                {
                headerName: "Time from previous point",
                field: "time_from_prev_point",
                width: 240,
                filter: true,
                resizable: true,
                hide : hideForHero,
                valueGetter: function (params) {
                    if (params.data.time_from_prev_point != 'Total') {
                        return secondsToDhms(params.data.time_from_prev_point);
                    }
                    else {
                        return "Total";
                    }

                }

            },
            {
                headerName: "Cummulative Distance (KM)",
                field: "cumm_distance",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    return convertMeters(params.data.cumm_distance);
                }

            })
        }
        let columnwithtrucksDefs = [
            {
                headerName: `FNR Number`,
                field: "rr_number",
                width: 120,
                editable: false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: `Status`,
                field: "last_destination_report",
                width: 120,
                editable: false,
                filter: "agSetColumnFilter",
            },
    
        ]
        var listData = []
        this.state.filteredData.map(function (e) {
            listData.push(<li key={e} className="tptdropdown-truck-no">{e}</li>)
        })
        return (
            <div >
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                {/*Container-fluid starts*/}
                {this.state.openTerminal ? (
                    <>
                    <div className="popup-overlay d-flex justify-content-center align-items-center" style={{ borderRadius: "0px", backgroundColor: "", paddingLeft: "0px", position: "fixed", zIndex: "1000000000001" }}>
                        <div
                            className={`popup-content d-flex flex-column zoom-effect zoom-effect-exit
                                }`}
                            style={{ width: "85%", height: "90%", borderRadius: "15px", backgroundColor: "#fff", position: "relative" }}
                        >
                            <div className="d-flex justify-content-between align-items-center" style={{ padding: "10px 19px 10px 19px", flexShrink: 0, backgroundColor: "#efefef", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                <p className="m-0" style={{ color: "#000", fontWeight: "500", fontSize: "15px" }}>{"Dashboard Camera View"}</p>
                                <div className="d-flex justify-content-center align-items-center"
                                    onClick={() => { this.onClosingPopup() }}
                                    style={{ width: "30px", height: "30px", borderRadius: "50%", backgroundColor: "lightgrey", color: "#fff", cursor: "pointer" }}>
                                    X
                                </div>
                            </div>
                            <CameraContainer carrierData={this.state.carrierData} routeData={this.state.mapinfo} startDate={this.state.startDate} vehicleNo={this.state.inputTruck}/>
                        </div>
                    </div>
                    <MapComponent apiKey={Constant.GOOGLE_KEY} data={this.state.mapinfo.coords} truckPlay={false} />
                    </>
                ) : ''}
                <div className="container-fluid">
                    <div className="row col-xl-12 col-lg-12">
                        <div className={"trucks-dropdown " + this.state.showTrucksList}>
                            <ul>
                                {listData}
                            </ul>
                        </div>
                        <form className="row theme-form col-xl-10 col-lg-10" onSubmit={this.formHandler}>
                            <div className="col-xl-2 col-lg-2 form-group">
                                {/*<select name="truck_no" className="form-control" onChange={this.handlerForm.bind(this)}>
	                    			<option value="">Truck No</option>
	                    			{this.selectOptionsItems()}
	                    		</select>
								*/}
                                {(localStorage.getItem("transportercode").indexOf("JWS01") >= 0)?
                                <input
                                    type="text"
                                    name="truck_no"
                                    id="inputTruck"
                                    // placeholder={(this.state.adaniCheck == 1) ? "Shuttle" : "Truck" + " No"}
                                    autoComplete="off"
                                    value = "Test Rake"
                                    className="form-control"
                                    // onFocus={this.initalList.bind(this)}
                                    // onChange={this.handlerForm.bind(this)}
                                     />
                                     :
                                <input
                                    type="text"
                                    name="truck_no"
                                    id="inputTruck"
                                    placeholder={(this.state.adaniCheck == 1) ? "Shuttle" : "Truck" + " No"}
                                    autoComplete="off"
                                    className="form-control"
                                    onFocus={this.initalList.bind(this)}
                                    onChange={this.handlerForm.bind(this)} />}
                            </div>
                            <div className="col-xl-2 col-lg-2 form-group">
                                <Datetime
                                    inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete: 'off' }}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat="HH:mm:ss"
                                    value = {this.state.startDate}
                                    name="startDate"
                                    onChange={this.handlerStartDateTime.bind(this)} />
                            </div>

                            <div className="col-xl-2 col-lg-2 form-group">
                                <Datetime
                                    inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete: 'off' }}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat="HH:mm:ss"
                                    value ={this.state.endDate} 
                                    name = "endDate"
                                    onChange={this.handlerEndDateTime.bind(this)} />
                            </div>
                            <div className="col-xl-3 col-lg-3">
                                <Select
                                    value={this.state.fetch_address}
                                    placeholder="Select Option "
                                    closeMenuOnSelect={true}
                                    onChange={(e) => {
                                        this.setState({ fetch_address: e });
                                    }}
                                    options={[
                                        { label: "With Address", value: 1 },
                                        { label: "Without Address", value: 0 }
                                    ]}
                                />
                            </div>
                            <div className="col-xl-2 col-lg-2 form-group">
                                <input type="number" name="frequency" id="frequency" value={this.state.frequency} placeholder="Data Interval (Mins)" className="validateNumber form-control" onChange={this.checkHandler.bind(this)} />
                            </div>
                            <div className="col-xl-1 col-lg-1 form-group">
                                <button type="submit" className="btn btn-success">Generate</button>
                            </div>
                        </form>
                        {(localStorage.getItem("transportercode").indexOf("JWS01") >= 0)?
                        <div className="form-group col-xl-2 col-lg-2">
                                <button className="floatright btn btn-info" onClick={this.onClickFnrDetails.bind(this)}>
                                    Track FNR</button>
                        </div>:""}
                    </div>
                    {(this.state.rowData != null) ?
                        <div className="row col-xl-12 col-lg-12">
                            <div className="col-xl-12 col-lg-12">
                            {/* <button className="btn btn-success" style={{ float: "right", marginRight: "10px" }} onClick={this.getGoogleMaps.bind(this)} >Google Maps</button> */}
                            {localStorage.getItem("is_hero") == 1 && this.state.is_show_grid == 0? "":
                                <>
                                <button className="btn btn-danger" style={{ float: "right", marginRight: "10px",padding: "5px 4px" }} onClick={this.exportReactPDF.bind(this)}>Export To PDF</button>
                                <button className="btn btn-primary" style={{ float: "right", marginRight: "10px" ,padding: "5px 4px"}} onClick={this.resetState.bind(this)}>Reset Default Layout</button>
                                <button className="btn btn-info" style={{ float: "right", marginRight: "10px",padding: "5px 4px" }} onClick={this.onClickSaveGridState.bind(this)} >Save Grid Layout</button>
                                        {localStorage.getItem("transportercode").includes('T305') &&
                                            <button className="btn btn-success" style={{ float: "right", marginRight: "10px", padding: "5px 4px" }} onClick={this.onClickingRecording.bind(this)} ><HiVideoCamera /> Video Recordings</button>

                                        }
                                </>}
                            </div>
                            {/* <button type="button" className={"btn "+(this.state.actgridbtn)} onClick={this.onClickShowGrid.bind(this)}>Grid</button>
                            <button type="button" className={"btn "+(this.state.actmapbtn)} onClick={this.onClickShowMap.bind(this)}>Map</button> */}
                            {localStorage.getItem("is_hero") == 1 && this.state.is_show_grid == 0? "":
							<>
                            <div className="col-xl-6 col-lg-6">
                                <div id="myGrid" style={{ height: "580px", width: "100%", marginTop: '10px' }} className={"ag-theme-balham " + (this.state.gridshow)}>
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        pinnedBottomRowData={this.state.tottalDistance}
                                        gridOptions={{
                                            context: { componentParent: this },
                                            getRowStyle: function (params) {
                                                if (params.node.rowPinned) {
                                                    return { 'font-weight': 'bold', 'font-size': '16px' };
                                                }
                                            },
                                        }}
                                    />


                                </div>
                                {(localStorage.getItem("transportercode").indexOf("JWS01") >= 0)?"" :
                                <div className="card-header">
                                    <h6>
                                        <i className="fa fa-info-circle" style={{color:"cornflowerblue"}}></i> <span> Note : Value is being displayed as 0 because difference between packets is less than 60 secs </span>
                                        
                                    </h6>
				   			    </div>}
                            </div>
                            </>
                            }
                            {/* {(this.state.mapinfoclick != '')?this.state.mapshow */}
                            <div className={localStorage.getItem("is_hero") == 1 && this.state.is_show_grid == 0? "col-xl-12 col-lg-12":"col-xl-6 col-lg-6"} style={{ padding: "10px" ,marginTop:"-6px"}}>
                                {/* {this.state.for_google_maps? */}
                                <div>
                                    <input type="checkbox" name="viewtimelinemarkers" value={this.state.showMarkers} onClick={this.showMarkers.bind(this)} /> <span style={{ fontSize: "14px" }}>View Markers &nbsp;&nbsp;</span>
                                    <input type="checkbox" name="truck" value={this.state.truck} checked={this.state.truck == 0 ? false : true} onClick={this.switchBreakTruck.bind(this)} /> <span style={{ fontSize: "14px" }}>&nbsp;Start Pin & Truck Pin</span> &nbsp;&nbsp;
                                    <input type="checkbox" name="breaks" value={this.state.breaks} checked={this.state.breaks == 0 ? false : true} onClick={this.switchBreakTruck.bind(this)} /> <span style={{ fontSize: "14px" }}>&nbsp;Break Pins</span> &nbsp;&nbsp;
                                    {/* <input type="checkbox" name="locationPins" value={this.state.locationPins} checked={this.state.locationPins == 0 ? false : true} onClick={this.switchBreakTruck.bind(this)} /> <span style={{ fontSize: "14px" }}>&nbsp;Location Pins</span> */}
                                </div>
                                    {/* :"" */}

                                {/* } */}

                                {/* <DrawMap 
                                    context={this} 
                                    mapFor={"truckgps"} 
                                    tolls = {this.state.tolls}
                                    mapinfo={this.state.mapinfo} 
                                    markersArray = {markersArray}
                                    defTransitCoords={""} /> */}

                                {/* {!this.state.for_google_maps?
                                this.state.shownbmaps?
                                 <NextBillionMaps 
                                 context={this} 
                                 mapFor={"truckgps"} 
                                 tolls = {this.state.tolls}
                                 mapinfo={this.state.mapinfo} 
                                 markersArray = {markersArray}
                                 view_carrier_center = {this.state.view_carrier_center}
                                />
                                        
                                :""
                                : */}
                                <div id="map" className="" style={{ width: '100%', height: "70vh" }}></div>
                                {/* } */}
                                <div class="card">
                                    <div class="card-header" style={{ background: "green", color: "white" }}>View Details</div>
                                    <div class="card-body" style={{ padding: "15px" }}>
                                        <div className="row">
                                            <div className="col-sm-3 col-xl-3 col-lg-3 col-md-3" style={{ borderRight: "1px solid #e4e4e4", textAlign: 'center' }}><b>Truck No</b> <br /><span>{this.state.inputTruck}</span></div>
                                            <div className="col-sm-3 col-xl-3 col-lg-3 col-md-3" style={{ borderRight: "1px solid #e4e4e4", textAlign: 'center' }}><b>Start Date</b><br /><span>{getHyphenYYYYMMDDHHMMSS(this.state.startDate)}</span></div>
                                            <div className="col-sm-3 col-xl-3 col-lg-3 col-md-3" style={{ borderRight: "1px solid #e4e4e4", textAlign: 'center' }}><b>End Date</b><br /><span>{getHyphenYYYYMMDDHHMMSS(this.state.endDate)}</span></div>
                                            <div className="col-sm-3 col-xl-3 col-lg-3 col-md-3" style={{ borderRight: "1px solid #e4e4e4", textAlign: 'center' }}><b>Total Distance</b><br /><span>{parseFloat((this.state.totaldistanceformap[0]).cumm_distance / 1000).toFixed(2)}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* :""} */}
                        </div>

                        : ""}

                    <div className={"dataLoadpage " + (this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                    </div>
                </div>
                {/*Container-fluid Ends*/}

                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                <div className={"slide-r " + this.state.sliderTranslate}>
                    <div className="slide-r-title">
                        <h4>
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        {/* <button className="float-right export-btn white btn-danger" onClick={() => this.onBtExport()} 
                        style={{marginRight:"1em", padding:"6px"}}> Export to Excel</button> */}
                       </h4>
                       
                    </div>
                    
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        {/* {this.state.trucks_without_driver} */}
                                <div
                                    id="myGrid"
                                    style={{ height: "450px", width: "100%" }}
                                    className="ag-theme-balham"
                                    >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithtrucksDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.fnrRowData}
                                        enableCharts={false}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady1}
                                        onGridState={this.onGridState1}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                    /> 
                                </div>
                        {/* {(this.state.trucks_without_driver.map(function(e){
                            return <div className="col-xl-12 col-lg-12">
                                <h6>{e}</h6>
                            </div>
                        }))} */}
                    </div>
                </div>
            </div>
        );
    }
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
$(document).ready(function () {
    $(document).on("click", ".tptdropdown-truck-no", function () {
        var vals = $(this).text();
        $("#inputTruck").val(vals);
        $(".trucks-dropdown").removeClass("show-m");
        // $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#inputTruck");
        if (!container.is(e.target)) {
            try {
                if ($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown") {
                    $(".trucks-dropdown").removeClass("show-m");
                    // $(".trucks-dropdown").addClass("show-n");
                }
            } catch (e) { }

        }


    })
})

function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function initOMS() {
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
        //markersWontMove: true,
        //keepSpiderfied: true,
        //nearbyDistance: 10,
        //circleFootSeparation: 60,
        //legWeight: 1.5
        markersWontMove: true,
        markersWontHide: true,
        keepSpiderfied: true,
        basicFormatEvents: true
    });
}
function timeConvert(n) {
    var num = n;
    var hours = (num / (3600));
    var rhours = parseInt(hours);
    var minutes = (num - (rhours * 3600)) / (60);
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
}

function ConvertMinutes(num) {
    var d = Math.floor(num / 1440); // 60*24
    var h = Math.floor((num - (d * 1440)) / 60);
    var m = Math.round(num % 60);
    return d + " Day(s) " + h + " Hrs " + m + " mins"
}

function ConvertSeconds(totalSeconds) {
    // var days = Math.floor(num/86400);
    // var hours = Math.floor(num/3600)
    // var minutes = Math.floor(num/60);
    var minutes = Math.round((totalSeconds % 3600) / 60);
    var hours = Math.round((totalSeconds % 86400) / 3600);
    var days = Math.round((totalSeconds % (86400 * 30)) / 86400);
    // let d = (new Date(t0)) - (new Date(t1));
    // let weekdays     = Math.floor(d/1000/60/60/24/7);
    // let days         = Math.floor(d/1000/60/60/24 - weekdays*7);
    // let hours        = Math.floor(d/1000/60/60    - weekdays*7*24            - days*24);
    // let minutes      = Math.ceil(d/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
    return days + " Day(s) " + hours + " Hrs " + minutes + " mins"
}

function ConvertHHMMSeconds(n) {
    var days = Math.round(parseInt(n / (24 * 3600)));

    n = n % (24 * 3600);
    var hours = Math.round(parseInt(n / 3600));

    n %= 3600;
    var minutes = Math.round(n / 60);

    n %= 60;
    var seconds = Math.round(n);

    return days + " Day(s) " + hours + " Hrs " + minutes + " mins " + seconds + " secs"
}
function convertMeters(metersValue) {
    return (metersValue / 1000).toFixed(2);
}

$(document).ready(function () {
    $(document).on('keyup', ".validateNumber", function () {
        // alert("click");
        if (isNaN($(this).val()) == true) {
            $(this).val($(this).val().slice(0, -1));
        }
    });
});
function arrayMax(arr) {
    return arr.reduce(function (p, v) {
        return (p > v ? p : v);
    });
}
const convertCase = (name) => {
    return (
        name.split("_").map((each) => each[0].toUpperCase() + each.slice(1,)).join(" ")
    )
}
const containerStyle = {
    width: '100%',
    height: '100%',
};
function getFormattedDate(date) {
    const now = new Date(date);

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
const defaultCenter = { lat: 20.59, lng: 78.96 };

function getDifferenceInSeconds(dateString1, dateString2) {
    const date1 = new Date(dateString1.replace(" ", "T")); // Convert to ISO format
    const date2 = new Date(dateString2.replace(" ", "T")); // Convert to ISO format

    // Get the difference in milliseconds
    const differenceInMillis = date2 - date1;

    // Convert milliseconds to seconds
    const differenceInSeconds = differenceInMillis / 1000;

    return differenceInSeconds;
}
// Define options for circles (geofences)
const circleOptions = (color) => ({
    strokeColor: color,
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: color,
    fillOpacity: 0.15,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1,
});
const CameraContainer = ({ carrierData, routeData, startDate, vehicleNo }) => {
    // console.log(data)
    // const {carrierData} = data;
    console.log(vehicleNo,"check_vehucle_no")
    const [selectedCameraView, setSelectedCameraView] = useState("All Cams")
    const [newCarrierData, setNewCarrierData] = useState(carrierData)
    const [newRouteData, setNewRouteData] = useState(routeData)
    const [liveVideo, setLiveVideo] = useState(false)
    const onClickingView = (name) => {
        setSelectedCameraView(name)
    }
    const [routePlay, setRoutePlay] = useState(false);
    const [newDuration, setNewDuration] = useState(0)
    const [trackPlaying, setTrackPlaying] = useState(false)

    const [actualDuration, setActualDuration] = useState(100)
    const [loader, setLoader] = useState(false)
    const [newStartDate, setNewStartDate] = useState(startDate)
    const onChangeRoutePlay = () => {
        setRoutePlay(false)
    }
    const getVideoComponent = () => {
        if (Object.keys(newCarrierData).length == 0) {
            return ""
        }
        var allObject = {}
        var singleObject = []
        var totalDuration = 0
        if (selectedCameraView == "All Cams") {
            allObject = newCarrierData.videos
            if (liveVideo) {
                // var countArray = []
                // Object.keys(newCarrierData.videos).length > 0 &&
                //   Object.entries(newCarrierData.videos)
                //     .map(([key, value]) => {
                //       if (value[0].s3_link != null) {
                //         countArray.push(value[0].duration)
                //       }
                //     })
                totalDuration = actualDuration
            } else {
                Object.keys(newCarrierData.videos).length > 0 &&
                    Object.entries(newCarrierData.videos)[0][1]
                        .map((each) => totalDuration += parseInt(each.duration))
            }
        } else {
            singleObject = Object.entries(newCarrierData.videos)
                .filter(([key, value]) => {
                    if (key == selectedCameraView) {
                        return true
                    }
                })
            singleObject[0][1].map((each) => totalDuration += parseInt(each.duration))
        }
        var alertArray = newCarrierData?.alerts?.length > 0 ? newCarrierData.alerts :
            [
                // { 'alert_type': 'Speeding', 'alert_time': '30' },
                // { 'alert_type': 'Harsh Braking', 'alert_time': '45' },
                // { 'alert_type': 'Collision Warning', 'alert_time': '51' },
                // { 'alert_type': 'Lane Departure', 'alert_time': '57' }
            ]
        return (
            selectedCameraView != "All Cams" ?
                <div key={`${1}-${selectedCameraView}`} id={1} className="col-12 col-md-12 col-lg-12 d-flex align-items-center" style={{ height: "100%" }}>
                    {liveVideo ? <ProgressBarWithLiveVideo name={singleObject[0][0]} alertArray={alertArray} durationInSeconds={totalDuration} videosData={singleObject[0][1]} truckPlay={true} startDate={startDate} onTrackPlaying={onTrackPlaying} getLiveData={getLiveData} liveVideo={liveVideo} onChangeDuration={onChangeDuration} />
                        : <ProgressBarWithVideo name={singleObject[0][0]} alertArray={alertArray} durationInSeconds={totalDuration} videosData={singleObject[0][1]} truckPlay={true} startDate={startDate} onTrackPlaying={onTrackPlaying} getLiveData={getLiveData} liveVideo={liveVideo} onChangeDuration={onChangeDuration} />}
                </div> :
                <div key={`${1}-${selectedCameraView}`} id={1} className="col-12 col-md-12 col-lg-12 d-flex align-items-center mb-3" style={{ height: "100%" }}>
                    {liveVideo ? <AllProgressBarWithLiveVideo key={new Date()} name={""} alertArray={alertArray} durationInSeconds={totalDuration} videosData={newCarrierData.videos} truckPlay={true} startDate={startDate} onTrackPlaying={onTrackPlaying} getLiveData={getLiveData} liveVideo={liveVideo} onChangeDuration={onChangeDuration} />
                        : <AllProgressBarWithVideo name={""} alertArray={alertArray} durationInSeconds={totalDuration} videosData={newCarrierData.videos} truckPlay={true} startDate={startDate} onTrackPlaying={onTrackPlaying} getLiveData={getLiveData} liveVideo={liveVideo} onChangeDuration={onChangeDuration} />}
                </div>
        )
    }
    const getFormattedDate = (date) => {
        const now = new Date(date);

        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const day = String(now.getDate()).padStart(2, "0");
        const hours = String(now.getHours()).padStart(2, "0");
        const minutes = String(now.getMinutes()).padStart(2, "0");
        const seconds = String(now.getSeconds()).padStart(2, "0");

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
    const getLiveData = async (name) => {
        try {
            if (name) {
                setLoader(true)
                await getVideoData()
            } else {
                setNewCarrierData(carrierData)
                setNewRouteData(routeData)
                setLiveVideo(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const getRouteData = async (data) => {
        console.log(data,"dat123")
        var start_date = addSecondsToTime(startDate, data.start_time)
        var end_date = addSecondsToTime(startDate, data.end_time)
        var vehicle_no = vehicleNo;
        var params = {
            truck_no : vehicle_no,
            startDate : start_date,
            endDate : end_date
        }
        try {
            const response = await redirectURL.post("/gmap",params);
            console.log(response, "654")
            if (response.data.status === "success") {
                if (["json", "str_json"].includes(response.data.resp_data_type)) {

                    var wholeData = JSON.parse(response.data.data);
                    wholeData = {
                        ...wholeData, coords: wholeData.coords.map((each) => {
                            return (
                                { ...each, truck_no: each.vehicle_no, lat: each.latitude, lng: each.longitude, stime: each.timestamp, recieved_on: each.timestamp }
                            )
                        })
                    }
                    console.log(wholeData.coords,"coords123")
                    // setNewStartDate(start_date)
                    return [wholeData, start_date]
                    // setNewRouteData(wholeData)
                } else {
                    // setReportData(response.data.data);
                    // setLoader(false);
                }

            } else {
                // setLoader(false);
                // window.alert(response.data.message)
                // setNewStartDate(start_date)
                return [[], start_date]
                console.error("Login failed: Invalid status");
            }
        } catch (error) {
            // setLoader(false);
            console.error('Error:', error);
        }
    }
    const getVideoData = async () => {
        try {
            console.log("working133")
            var params = {
                "vehicle_no": vehicleNo,
                "start_date": getFormattedDate(new Date(startDate)),
                "end_date": getFormattedDate(new Date()),
            }
            console.log(params,"liveddata_params")
            const response = await redirectURL.post(`consignments/getdashcamvideodata`, params)
            console.log(response,"livedata_response")
            if (response.status === "success" || response.status === 200) {
                if (["str_json"].includes(response.data.resp_data_type)) {
                    // setLoader(false)
                    // setCarrierData(JSON.parse(response.data.data));
                } else {
                    console.log(response.data.data,"432")
                    var data = response.data.data
                    var copyData = JSON.parse(JSON.stringify(data))
                    Object.keys(response.data.data.videos).map((each) => {
                        // if (each == "ADAS") {
                        //   copyData.videos[each] = [{
                        //     "start_time": 0,
                        //     "end_time": 122,
                        //     "duration": 122,
                        //     "s3_link": "https://storage.googleapis.com/dashcam-autometrics/dashcam_videos/CH1-20250319090315-20250319090517.MP4"
                        //   }]
                        // } else if (each == "CAM3") {
                        //   copyData.videos[each] = [{
                        //     "start_time": 0,
                        //     "end_time": 132,
                        //     "duration": 132,
                        //     "s3_link": "https://storage.googleapis.com/dashcam-autometrics/dashcam_videos/CH3-20250319091331-20250319091543.MP4"
                        //   }]
                        // } else {
                        copyData.videos[each] = [copyData.videos[each][copyData.videos[each].length - 1]]
                        // }
                    })
                    console.log(copyData,"5432")
                    var videoAvailableArray = []
                    var videoNotAvailableArray = []
                    var route_detail_object = null
                    var actual_duration = 100
                    Object.keys(copyData.videos).length > 0 &&
                        Object.entries(copyData.videos)
                            .map(([key, value]) => {
                                if (value[0].s3_link != null) {
                                    videoAvailableArray.push(value)
                                } else {
                                    videoNotAvailableArray.push(value)
                                }
                            })
                    if (videoAvailableArray.length > 0) {
                        const objectWithLeastDuration = videoAvailableArray.reduce((minObj, currentObj) => {
                            return currentObj.duration < minObj.duration ? currentObj : minObj;
                        });
                        route_detail_object = objectWithLeastDuration
                        actual_duration = objectWithLeastDuration[0].duration
                    } else {
                        const objectWithLeastDuration = videoNotAvailableArray.reduce((minObj, currentObj) => {
                            return currentObj.duration < minObj.duration ? currentObj : minObj;
                        });
                        route_detail_object = objectWithLeastDuration
                    }
                    console.log(route_detail_object, actual_duration)
                    var gpsData = await getRouteData(route_detail_object[0])
                    setActualDuration(actual_duration)
                    setNewCarrierData(copyData)
                    setNewRouteData(gpsData[0])
                    setNewStartDate(gpsData[1])
                    setLiveVideo(true)
                    setLoader(false)
                    // setOpenTerminal(true)
                    // setTerminalTitle("Dashboard Camera View")
                    // setCarrierData(response.data.data);
                    // console.error("Login failed: Invalid status");
                }
            }
        } catch (error) {

        }
    }
    function addSecondsToTime(timeString, secondsToAdd) {
        console.log(timeString, secondsToAdd);
        const date = new Date(timeString.replace(" ", "T"));  // Convert to ISO 8601 format
        date.setSeconds(date.getSeconds() + secondsToAdd);

        // Manually format the date to the desired format (YYYY-DD-MMTHH:mm:ss)
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');  // Get month (1-12) and pad with 0
        const day = String(date.getDate()).padStart(2, '0');  // Get day and pad with 0
        const hours = String(date.getHours()).padStart(2, '0');  // Get hours and pad with 0
        const minutes = String(date.getMinutes()).padStart(2, '0');  // Get minutes and pad with 0
        const seconds = String(date.getSeconds()).padStart(2, '0');  // Get seconds and pad with 0

        // Return formatted date in the desired format
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    const onChangeDuration = (name) => {
        setNewDuration(name)
    }
    const onTrackPlaying = (name) => {
        setTrackPlaying(name)
    }
    return (
        <div className="" style={{ flexGrow: 1, width: "100%", position: "relative" }}>
            <div className="d-flex justify-content-between" style={{ height: "100%", position: "relative", padding: "10px" }}>
                <div className="p-1 d-flex flex-column" style={{ width: "41%", height: "100%", border: "1px solid #efefef", borderRadius: "5px" }}>
                    {routeData.length > 0 && <div className="d-flex flex-wrap p-1" style={{ flexShrink: 0, backgroundColor: "#efefef", borderRadius: "5px" }}>
                        <button
                            style={{
                                color: "#000",
                                fontSize: "11px",
                                fontWeight: "500",
                                border: "1px solid #efefef",
                                borderRadius: "5px",
                                minWidth: "100px",
                                color: "#fff",
                                backgroundColor: "rgb(37, 99, 235)"
                            }}
                            className="btn btn-default mr-2"
                            onClick={() => { setRoutePlay(!routePlay) }}
                        >
                            {!routePlay ? "Route Play" : "Stop Play"}
                        </button>
                    </div>}
                    <div style={{ flexGrow: 1, maxHeight: "100%" }}>
                        <div className="bg-primary" style={{ height: "100%", position: "relative" }}>
                            <RouteComponent data={newRouteData} truckPlay={true} onChangeRoutePlay={onChangeRoutePlay} selectedCameraView={selectedCameraView} startDate={newStartDate} trackPlaying={trackPlaying} liveVideo={liveVideo} newDuration={newDuration} />
                            {newRouteData.length == 0 && <div className="d-flex justify-content-center align-items-center" style={{ position: "absolute", width: "100%", height: "100%", top: "0%", left: "0%", zIndex: "100000001" }}>
                                <div className="p-3 d-flex flex-column align-items-center" style={{ backgroundColor: "#fff", color: "red", minWidth: "190px", borderRadius: "10px" }}>
                                    <AlertTriangle size={25} />
                                    <p className="m-0 mt-1" style={{ fontSize: "13px" }}>GPS data not found</p>
                                </div>
                            </div>}
                        </div>
                    </div>

                </div>
                <div className="d-flex flex-column p-1" style={{ width: "59%", height: "100%", border: "1px solid #efefef", borderRadius: "5px" }}>
                    {Object.keys(newCarrierData).length > 0 && Object.keys(newCarrierData.videos).length > 0 ?
                        <>
                            {Object.keys(newCarrierData).length > 0 &&
                                <div className="d-flex flex-wrap p-1" style={{ flexShrink: 0, backgroundColor: "#efefef", borderRadius: "5px" }}>
                                    {["All Cams", ...Object.keys(newCarrierData.videos)].map((each) => {
                                        return (
                                            <button
                                                style={{
                                                    color: "#000",
                                                    fontSize: "11px",
                                                    fontWeight: "500",
                                                    border: "1px solid #efefef",
                                                    borderRadius: "5px",
                                                    minWidth: "100px",
                                                    color: selectedCameraView == each ? "#fff" : "#000",
                                                    backgroundColor: selectedCameraView == each ? "rgb(37, 99, 235)" : "lightgrey"
                                                }}
                                                className="btn btn-default mr-2"
                                                onClick={() => {
                                                    setSelectedCameraView(each);
                                                    setNewCarrierData(carrierData)
                                                    setNewRouteData(routeData)
                                                    setLiveVideo(false)
                                                }}
                                            >
                                                {each}
                                            </button>
                                        )
                                    })}
                                </div>
                            }
                            <div className="scrollable-container p-1" style={{ flexGrow: 1, maxHeight: "100%", overflowY: "auto", overflowX: "hidden" }}>
                                <div className="row flex-wrap " style={{ height: "100%" }}>
                                    {getVideoComponent()}
                                    {false && Object.keys(newCarrierData.videos).length > 0 && Object.entries(newCarrierData.videos)
                                        .filter(([key, value]) => {
                                            if (key == selectedCameraView) {
                                                return true
                                            } else if (selectedCameraView == "All Streams") {
                                                return true
                                            }
                                        }).map(([key, value]) => {
                                            var name = key
                                            var links = value
                                            // var alertArray = newCarrierData.alerts
                                            var alertArray = newCarrierData?.alerts?.length > 0 ? newCarrierData.alerts :
                                                [
                                                    //   { 'alert_type': 'Speeding', 'alert_time': '100' },
                                                    // { 'alert_type': 'Harsh Braking', 'alert_time': '150' },
                                                    // { 'alert_type': 'Collision Warning', 'alert_time': '250' },
                                                    // { 'alert_type': 'Lane Departure', 'alert_time': '400' },
                                                    // { 'alert_type': 'Speeding', 'alert_time': '100' },
                                                    // { 'alert_type': 'Harsh Braking', 'alert_time': '150' },
                                                    // { 'alert_type': 'Collision Warning', 'alert_time': '250' },
                                                    // { 'alert_type': 'Lane Departure', 'alert_time': '400' },
                                                    // { 'alert_type': 'Speeding', 'alert_time': '100' },
                                                    // { 'alert_type': 'Harsh Braking', 'alert_time': '150' },
                                                    // { 'alert_type': 'Collision Warning', 'alert_time': '250' },
                                                    // { 'alert_type': 'Lane Departure', 'alert_time': '400' }
                                                ]
                                            var totalDuration = 0
                                            value.map((each) => totalDuration += parseInt(each.duration))
                                            return (
                                                selectedCameraView != "All Streams" ?
                                                    <div key={`${key}-${selectedCameraView}`} id={key} className="col-12 col-md-12 col-lg-12 d-flex align-items-center" style={{ height: "100%" }}>
                                                        {/* <PlyrVideo data={{ name, links, alerts, selectedFormat }} /> */}
                                                        {/* <RFQ data={{ name, links, alertArray, selectedCameraView }} /> */}
                                                        {/* <CombineVideosComponent videoLink={links}/> */}
                                                        <ProgressBarWithVideo name={name} alertArray={alertArray} durationInSeconds={totalDuration} videosData={links} onTrackPlaying={onTrackPlaying} truckPlay={true} />
                                                    </div> :
                                                    <div key={`${key}-${selectedCameraView}`} id={key} className="col-12 col-md-12 col-lg-6 d-flex align-items-center mb-3" style={{ height: "47%" }}>
                                                        {/* <RFQ data={{ name, links, alertArray, selectedCameraView }} /> */}
                                                        {/* <CombineVideosComponent videoLink={links}/> */}
                                                        <ProgressBarWithVideo name={name} alertArray={alertArray} durationInSeconds={totalDuration} videosData={links} onTrackPlaying={onTrackPlaying} truckPlay={true} />
                                                    </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </> :
                        <div className="d-flex justify-content-center align-items-center" style={{ width: "100%", height: "100%" }}> No Data Found</div>
                    }
                </div>
                {loader &&
                    <div className="" style={{ position: "absolute", top: "0%", left: "0%", width: "100%", height: "100%", zIndex: "10001", backgroundColor: "rgb(223, 223, 223, 0.7" }}>
                        <div className="d-flex justify-content-center align-items-center" style={{ width: "100%", height: '100%' }}>
                            <div className="d-flex flex-column justify-content-center align-items-center" style={{ backgroundColor: "#fff", borderRadius: "30px", padding: "30px" }}>
                                <div className="spinner-border text-primary" role="status">
                                </div>

                                <span className="" style={{ fontSize: "13px" }}>Loading...</span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

const MapComponent = ({ apiKey, data, truckPlay }) => {
    var { coords = [], breaks = [] } = data || {};

    const mapRef = useRef(null);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(5);
    const [visibleArrows, setVisibleArrows] = useState([]);
    const [mapBounds, setMapBounds] = useState(null);
    const [selectedBreak, setSelectedBreak] = useState(null);
    const [clusterer, setClusterer] = useState(null);
    const truckMarkerRef = useRef(null);
    const [truckPosition, setTruckPosition] = useState(coords[0]);
    const [polylineOptions, setPolylineOptions] = useState({})
    useEffect(() => {
        if (mapRef.current && mapLoaded && window.google) {
            var bounds = new window.google.maps.LatLngBounds();
            if (coords.length === 0) {
                mapRef.current.setCenter(defaultCenter);
                mapRef.current.setZoom(4);
            } else {
                coords.forEach((point) => bounds.extend({ lat: point.lat, lng: point.lng }));
                mapRef.current.fitBounds(bounds);
            }
        }
    }, [mapLoaded, coords, breaks]);

    const calculateBearing = (lat1, lon1, lat2, lon2) => {
        const toRad = (deg) => deg * (Math.PI / 180);
        const toDeg = (rad) => rad * (180 / Math.PI);

        const dLon = toRad(lon2 - lon1);
        const y = Math.sin(dLon) * Math.cos(toRad(lat2));
        const x =
            Math.cos(toRad(lat1)) * Math.sin(toRad(lat2)) -
            Math.sin(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.cos(dLon);

        return (toDeg(Math.atan2(y, x)) + 360) % 360;
    };

    useEffect(() => {
        if (!mapBounds || coords.length < 2) return;

        const filteredArrows = [];
        let arrowInterval = zoomLevel > 7 ? 50 : 300; // Dynamically adjust arrow interval

        for (let i = 0; i < coords.length - 1; i += arrowInterval) {
            const start = coords[i];
            const end = coords[i + 1];

            if (
                start.lat >= mapBounds.south &&
                start.lat <= mapBounds.north &&
                start.lng >= mapBounds.west &&
                start.lng <= mapBounds.east
            ) {
                const middle = {
                    lat: (start.lat + end.lat) / 2,
                    lng: (start.lng + end.lng) / 2,
                };

                const bearing = calculateBearing(start.lat, start.lng, end.lat, end.lng);
                filteredArrows.push({
                    position: middle,
                    rotation: bearing,
                });
            }
        }

        // Avoid resetting if arrows haven't changed
        if (filteredArrows.length !== visibleArrows.length) {
            setVisibleArrows(filteredArrows);
        }
    }, [zoomLevel, mapBounds, coords, visibleArrows]);


    useEffect(() => {
        if (mapRef.current && Array.isArray(breaks) && breaks.length > 0) {
            console.log('Truck data:', breaks);

            // Validate breakPoints
            const validBreaks = breaks.filter(bp =>
                typeof bp.lat === 'number' && typeof bp.lng === 'number'
            );

            if (validBreaks.length !== breaks.length) {
                console.warn('Some breakPoints are missing valid lat/lng properties and will be ignored.');
            }

            // Create markers
            const markers = validBreaks.map((breakPoint) => {
                console.log('Creating marker for truck:', breakPoint);
                const marker = new window.google.maps.Marker({
                    position: { lat: breakPoint.lat, lng: breakPoint.lng },
                    icon: {
                        url: "https://em-content.zobj.net/source/microsoft-teams/363/teacup-without-handle_1f375.png",
                        scaledSize: new window.google.maps.Size(40, 40),
                    },
                });

                // Add click event listener
                marker.addListener('click', () => {
                    setSelectedBreak(breakPoint);
                });

                return marker;
            });

            // Create MarkerClusterer instance and add markers
            const markerClusterer = new MarkerClusterer({
                map: mapRef.current,
                markers: markers,
                imagePath: "https://em-content.zobj.net/source/microsoft-teams/363/teacup-without-handle_1f375.png",
                imageExtension: "png",
                imageSizes: [40, 56, 66, 78, 90], // Sizes should match your images
                // Optional: Add cluster options if needed
            });

            setClusterer(markerClusterer); // Save reference for future use

            // Cleanup function to remove markers and clusterer
            return () => {
                if (markerClusterer) {
                    markerClusterer.clearMarkers();
                }
                markers.forEach(marker => {
                    marker.setMap(null);
                });
            };
        }
    }, [breaks, mapLoaded]);


    useEffect(() => {
        if (mapLoaded && truckPlay && coords.length > 1 && !truckMarkerRef.current) {
            // Initialize truck marker at the start of the route
            // truckMarkerRef.current = new window.google.maps.Marker({
            //   position: coords[0],
            //   icon: {
            //     url: "https://img.icons8.com/ios-filled/50/000000/truck.png", // Custom truck icon
            //     scaledSize: new window.google.maps.Size(40, 40),
            //     pixelOffset: new window.google.maps.Size(0, -30),
            //   },
            //   map: mapRef.current,
            // });
            mapRef.current.setZoom(10); // Center the map on the truck
            animateTruck(); // Start animating the truck
        }
    }, [mapLoaded, coords, truckPlay]);

    const animateTruck = () => {
        let step = 0; // Track the position along the route
        const totalSteps = coords.length - 1;

        const interval = setInterval(() => {
            if (step < totalSteps) {
                const start = coords[step];
                const end = coords[step + 1];

                // Interpolate position along the line
                const lat = start.lat + (end.lat - start.lat) * 0.1; // Move 10% towards the next point
                const lng = start.lng + (end.lng - start.lng) * 0.1;

                // Update truck position
                setTruckPosition({ lat, lng });

                // Move the map along with the truck
                if (mapRef.current) {
                    mapRef.current.setCenter({ lat, lng }); // Center the map on the truck
                }

                step++;
            } else {
                clearInterval(interval); // Stop when the truck reaches the end
            }
        }, 100); // Update every 100ms
    };
    useEffect(() => {
        if (window.google) {
            setPolylineOptions({
                strokeColor: "blue",
                strokeOpacity: 1,
                strokeWeight: 3,
                icons: [
                    {
                        icon: {
                            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            scale: 3, // Adjust arrow size
                            strokeColor: "red"
                        },
                        offset: "50%", // Adjust arrow placement
                        repeat: "100px" // Add multiple arrows along the polyline
                    }
                ]
            });
        }
    }, [mapLoaded]); // Update options when the map is loaded

    return (
        // <LoadScript googleMapsApiKey={apiKey}>
        <GoogleMap
            mapContainerStyle={containerStyle}
            onLoad={(map) => {
                mapRef.current = map;
                setMapLoaded(true);
            }}
        // onZoomChanged={() => {
        //   if (mapRef.current) {
        //     setZoomLevel(mapRef.current.getZoom());
        //     setVisibleArrows([]); // Clear previous arrows
        //   }
        // }}
        // onBoundsChanged={() => {
        //   if (mapRef.current) {
        //     const bounds = mapRef.current.getBounds();
        //     setMapBounds({
        //       north: bounds.getNorthEast().lat(),
        //       south: bounds.getSouthWest().lat(),
        //       east: bounds.getNorthEast().lng(),
        //       west: bounds.getSouthWest().lng(),
        //     });
        //   }
        // }}
        >
            <Polyline
                path={coords.map((point) => ({ lat: point.lat, lng: point.lng }))}
                // options={{ strokeColor: "blue", strokeOpacity: 1, strokeWeight: 3 }}
                options={polylineOptions}
            />

            {/* {visibleArrows.map((arrow, index) => (
            <Marker
              key={index}
              position={arrow.position}
              icon={{
                path: window.google?.maps?.SymbolPath.FORWARD_CLOSED_ARROW,
                scaledSize: new window.google.maps.Size(40, 40),
                rotation: arrow.rotation,
                scale: 2,
                strokeColor: "#FF0000",
              }}
            />
          ))} */}
            {coords.length > 0 && <>
                <Marker
                    // key={index}
                    position={{ lat: coords[0].lat, lng: coords[0].lng }}
                    label={"S"}
                // icon={{
                //   path: window.google?.maps?.SymbolPath.FORWARD_CLOSED_ARROW,
                //   strokeColor: "#FF0000",
                // }}
                />
                <Marker
                    // key={index}
                    position={{ lat: coords[coords.length - 1].lat, lng: coords[coords.length - 1].lng }}
                    label={"E"}
                // icon={{
                //   path: window.google?.maps?.SymbolPath.FORWARD_CLOSED_ARROW,
                // //   rotation: arrow.rotation,
                // //   scale: 3,
                //   strokeColor: "#FF0000",
                // }}
                /></>}
            {/* {breaks.map((breakPoint, index) => (
            <Marker
              key={index}
              position={{ lat: breakPoint.lat, lng: breakPoint.lng }}
              icon={{
                url: "https://em-content.zobj.net/source/microsoft-teams/363/teacup-without-handle_1f375.png",
                scaledSize: new window.google.maps.Size(40, 40),
              }}
              onClick={() => setSelectedBreak(breakPoint)}
            />
          ))} */}

            {selectedBreak && (
                <InfoWindow
                    position={{ lat: selectedBreak.lat, lng: selectedBreak.lng }}
                    options={{
                        pixelOffset: new window.google.maps.Size(0, -30),
                        disableAutoPan: true,
                    }}
                >
                    <div style={{ fontSize: "13px", fontWeight: "500", maxWidth: "300px" , color: "#4d4d4d" }}>
                        <style>
                            {`
                      .gm-style-iw button {
                          display: none !important;
                      }
                      `}
                        </style>
                        <div style={{ border: "1px solid #efefef" }}>
                            <div
                                className="d-flex justify-content-between align-items-center p-2"
                                style={{ borderBottom: "1px solid #efefef" }}
                            >
                                <p className="m-0" style={{ fontSize: "14px" }}>
                                    ☕ Break Details
                                </p>
                                <CircleX size={25} style={{ cursor: "pointer" , color: 'red'}} onClick={() => setSelectedBreak(null)} />
                            </div>
                            <table className="text-center">
                                <tbody>
                                    <tr>
                                        <td style={{ border: "1px solid #efefef", padding: "5px" }}>Start Time</td>
                                        <td style={{ border: "1px solid #efefef", padding: "5px" }}>{selectedBreak.break_start}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid #efefef", padding: "5px" }}>End Time</td>
                                        <td style={{ border: "1px solid #efefef", padding: "5px" }}>{selectedBreak.break_end}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid #efefef", padding: "5px" }}>Duration</td>
                                        <td style={{ border: "1px solid #efefef", padding: "5px" }}>{selectedBreak.break_time_seconds}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid #efefef", padding: "5px" }}>Location</td>
                                        <td style={{ border: "1px solid #efefef", padding: "5px" }}>{selectedBreak.break_address}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </InfoWindow>
            )}
            {/* Truck Marker */}
            {truckMarkerRef.current && <Marker position={truckPosition} />}
        </GoogleMap>
        // </LoadScript>
    );
};

const RouteComponent = ({ apiKey, data, truckPlay, onChangeRoutePlay, selectedCameraView, startDate, trackPlaying, liveVideo, newDuration }) => {
    var { coords = [], breaks = [] } = data || {};
    const mapRef = useRef(null);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(5);
    const [visibleArrows, setVisibleArrows] = useState([]);
    const [mapBounds, setMapBounds] = useState(null);
    const [selectedBreak, setSelectedBreak] = useState(null);
    const [clusterer, setClusterer] = useState(null);
    const truckMarkerRef = useRef(null);
    const [truckPosition, setTruckPosition] = useState(coords[0]);
    const [polylineOptions, setPolylineOptions] = useState({});
    const [refresh, setRefresh] = useState(0)
    const [existRecord, setExistRecord] = useState({})
    const [count, setCount] = useState(0)
    const [trackPosition, setTrackPosition] = useState(null)
    const [newTrackObject, setNewTrackObject] = useState(null)
    const intervalRef = useRef(null);  // Use a ref to store the interval ID
    useEffect(() => {
        if (refresh < 1) {
            getBounds()
        }
    }, [mapLoaded, coords, breaks]);

    const getBounds = () => {
        if (mapRef.current && mapLoaded && window.google) {
            var bounds = new window.google.maps.LatLngBounds();
            console.log(refresh,"refresh123")
            if (coords.length === 0) {
                mapRef.current.setCenter(defaultCenter);
                mapRef.current.setZoom(4);
            } else {
                coords.forEach((point) => bounds.extend({ lat: point.lat, lng: point.lng }));
                mapRef.current.fitBounds(bounds);
                console.log("hetyu")
                setRefresh(1)
            }
        }
    }
    useEffect(() => {
        if (truckMarkerRef.current && intervalRef.current) {
            truckMarkerRef.current.setMap(null);
            clearInterval(intervalRef?.current);
            getBounds();
        }
    }, [liveVideo])
    useEffect(() => {
        if (mapRef.current && Array.isArray(breaks) && breaks.length > 0) {
            console.log('Truck data:', breaks);

            // Validate breakPoints
            const validBreaks = breaks.filter(bp =>
                typeof bp.lat === 'number' && typeof bp.lng === 'number'
            );

            if (validBreaks.length !== breaks.length) {
                console.warn('Some breakPoints are missing valid lat/lng properties and will be ignored.');
            }

            // Create markers
            const markers = validBreaks.map((breakPoint) => {
                console.log('Creating marker for truck:', breakPoint);
                const marker = new window.google.maps.Marker({
                    position: { lat: breakPoint.lat, lng: breakPoint.lng },
                    icon: {
                        url: "https://em-content.zobj.net/source/microsoft-teams/363/teacup-without-handle_1f375.png",
                        scaledSize: new window.google.maps.Size(40, 40),
                    },
                });

                // Add click event listener
                marker.addListener('click', () => {
                    setSelectedBreak(breakPoint);
                });

                return marker;
            });

            // Create MarkerClusterer instance and add markers
            const markerClusterer = new MarkerClusterer({
                map: mapRef.current,
                markers: markers,
                imagePath: "https://em-content.zobj.net/source/microsoft-teams/363/teacup-without-handle_1f375.png",
                imageExtension: "png",
                imageSizes: [40, 56, 66, 78, 90], // Sizes should match your images
                // Optional: Add cluster options if needed
            });

            setClusterer(markerClusterer); // Save reference for future use

            // Cleanup function to remove markers and clusterer
            return () => {
                if (markerClusterer) {
                    markerClusterer.clearMarkers();
                }
                markers.forEach(marker => {
                    marker.setMap(null);
                });
            };
        }
    }, [breaks, mapLoaded]);

    function addSecondsToTime(timeString, secondsToAdd) {
        const date = new Date(timeString.replace(" ", "T"));  // Convert to ISO 8601 format
        date.setSeconds(date.getSeconds() + secondsToAdd);
        return date;
    }

    function findMatchingRecord(records, standardTime, secondsToAdd) {
        const targetTime = addSecondsToTime(standardTime, secondsToAdd);
        // console.log('Target Time:', targetTime);

        let closestRecord = null;
        let nextRecord = null;

        // Find records that are closest to targetTime
        records.forEach((record) => {
            const recordTime = new Date(record.timestamp.replace(" ", "T")); // Convert to ISO format
            // console.log('Record Time:', recordTime);

            // Check if the record is in the same day and after the target time
            if (recordTime.getTime() >= targetTime.getTime()) {
                if (!nextRecord || recordTime.getTime() < new Date(nextRecord.timestamp.replace(" ", "T")).getTime()) {
                    nextRecord = record;
                }
            }

            // Check for the closest record before the target time
            if (recordTime.getTime() <= targetTime.getTime()) {
                if (!closestRecord || recordTime.getTime() > new Date(closestRecord.timestamp.replace(" ", "T")).getTime()) {
                    closestRecord = record;
                }
            }
        });

        return {
            closestRecord,
            nextRecord
        };
    }

    useEffect(() => {
        console.log("huiy")
        const { closestRecord, nextRecord } = findMatchingRecord(coords, startDate.replace("T", " "), newDuration);
        // console.log("huawei", nextRecord)
        if (newDuration <= 1) {
            if (mapLoaded) {
                initializeMapAndMarker();
            }
        }
        // console.log(closestRecord, nextRecord, newDuration)
        if (closestRecord && nextRecord && (JSON.stringify(existRecord?.closestRecord) != JSON.stringify(closestRecord) || JSON.stringify(existRecord?.nextRecord) != JSON.stringify(nextRecord))) {
            const newPosition = { lat: closestRecord.lat, lng: closestRecord.lng };
            // console.log("huawei")
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
            if (mapLoaded && count == 0) {
                mapRef.current.setZoom(15);
                setCount((prev) => prev + 1)
            }
            if (truckMarkerRef.current && newPosition) {
                truckMarkerRef.current.setPosition(newPosition);
                mapRef.current.setCenter(newPosition);
                mapRef.current.setZoom(19);
                setExistRecord({ closestRecord, nextRecord })
                const start = closestRecord;
                const end = nextRecord;
                const duration = getDifferenceInSeconds(start.timestamp, end.timestamp) * 1000; // 60,000 ms = 60 seconds
                // console.log(start, end, duration, getDifferenceInSeconds(start.timestamp, end.timestamp))
                const intervalTime = 100; // 100 ms interval
                const totalSteps = duration / intervalTime; // 600 steps
                // clearInterval(interval);
                callInterval(totalSteps, start, end, duration, intervalTime)
            }
        }
        if (nextRecord == null) {
            setRefresh(0)
            if (coords.length > 0) {
                const newPosition = { lat: coords[0].lat, lng: coords[0].lng };
                if (truckMarkerRef.current) {
                    truckMarkerRef.current.setPosition(newPosition);
                    clearInterval(intervalRef.current)
                }
            }
            setCount(0)
            setTimeout(() => {
                getBounds();
            }, 0)
        }

    }, [newDuration]);

    const callInterval = (totalSteps, start, end, duration, intervalTime) => {
        let step = 0;

        const latStep = (end.lat - start.lat) / totalSteps;
        const lngStep = (end.lng - start.lng) / totalSteps;

        intervalRef.current = setInterval(() => {
            step++;
            const newLat = start.lat + latStep * step;
            const newLng = start.lng + lngStep * step;

            const newPosition = { lat: newLat, lng: newLng };
            // console.log(newPosition)
            // setTruckPosition(newPosition);
            setTrackPosition({ totalSteps: totalSteps, start: newPosition, end: end, currentStep: step, duration: duration, intervalTime: intervalTime })
            // Update marker position
            if (truckMarkerRef.current) {
                truckMarkerRef.current.setPosition(newPosition);
            }
            // console.log("count", step, totalSteps, intervalTime)
            // Center the map on the truck
            if (mapRef.current) {
                mapRef.current.setCenter(newPosition);
            }
            if (step >= totalSteps) {
                clearInterval(intervalRef.current);
            }
        }, intervalTime);
    }
    var tyu = useRef(null);
    // var newTrackObject = null
    useEffect(() => {
        // console.log("hey", trackPosition, trackPlaying)
        // setTimeout(() => {
        if (trackPlaying) {
            if (trackPosition) {
                // console.log(trackPosition, intervalRef)
                var { totalSteps, start, end, currentStep, duration, intervalTime } = trackPosition
                var step = 0;
                var copyStep = currentStep
                totalSteps = (duration - (currentStep * 100)) / intervalTime
                const latStep = (end.lat - start.lat) / totalSteps;
                const lngStep = (end.lng - start.lng) / totalSteps;
                // console.log(duration, currentStep * 1000)
                intervalRef.current = setInterval(() => {
                    // console.log(tyu)
                    step++;
                    copyStep++;
                    const newLat = start.lat + latStep * step;
                    const newLng = start.lng + lngStep * step;

                    const newPosition = { lat: newLat, lng: newLng };
                    // setTruckPosition(newPosition);
                    setNewTrackObject({ totalSteps: totalSteps, start: newPosition, end: end, currentStep: copyStep, duration: duration, intervalTime: intervalTime })
                    // Update marker position
                    if (truckMarkerRef.current) {
                        truckMarkerRef.current.setPosition(newPosition);
                    }
                    // console.log("count", step, totalSteps, intervalTime)
                    // Center the map on the truck
                    if (mapRef.current) {
                        mapRef.current.setCenter(newPosition);
                    }
                    if (step >= totalSteps) {
                        clearInterval(tyu);
                        setTrackPosition(null);
                        setNewTrackObject(null);
                        console.log("hutyuio", step, totalSteps)
                    }
                }, intervalTime);
            }
        } else {
            clearInterval(intervalRef.current);
            // console.log(trackPosition, newTrackObject)
            if (newTrackObject) {
                //  console.log(trackPosition, newTrackObject)
                setTrackPosition(newTrackObject)
            }
            // console.log("hello")
            // clearInterval(tyu);
            // setTrackPosition(null);
        }
        // }, 10)
    }, [trackPlaying])

    useEffect(() => {
        if (mapLoaded && coords.length > 1 && !truckMarkerRef.current) {
            // Initialize the map and truck marker
            initializeMapAndMarker();
            // animateTruck(); // Start animating the truck
        }
    }, [coords]);

    const initializeMapAndMarker = () => {
        // Initialize map if not already initialized
        console.log("I am getting")
        if (!mapRef.current) {
            mapRef.current = new window.google.maps.Map(document.getElementById('map'), {
                center: coords[0],
                // zoom: 19,
            });
        } else {
            // mapRef.current.setZoom(19);
            mapRef.current.setCenter(coords[0]);
        }

        // Initialize truck marker
        const defaultMarker = {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: 'blue',
            fillOpacity: 0.6,
            scale: 8,
            strokeColor: 'white',
            strokeWeight: 2,
        };
        truckMarkerRef.current = new window.google.maps.Marker({
            position: coords[0],
            icon: {
                // url: require("../../../assets/images/truck.png"), // Custom truck icon
                scaledSize: new window.google.maps.Size(40, 40),
                anchor: new window.google.maps.Point(20, 20), // Center the icon
                pixelOffset: new window.google.maps.Size(0, -100)
            },
            map: mapRef.current
        });

        setTruckPosition(coords[0]);
        // mapRef.current.setZoom(15);
    };

    const animateTruck = () => {
        let currentIndex = 0;

        const moveToNextPoint = () => {
            if (currentIndex >= coords.length - 1) {
                // Reached the end of coordinates
                truckPlay = false; // Stop animation if needed
                return;
            }

            const start = coords[currentIndex];
            const end = coords[currentIndex + 1];
            const duration = 100; // 60,000 ms = 60 seconds
            const intervalTime = 100; // 100 ms interval
            const totalSteps = duration / intervalTime; // 600 steps
            let step = 0;

            const latStep = (end.lat - start.lat) / totalSteps;
            const lngStep = (end.lng - start.lng) / totalSteps;

            const interval = setInterval(() => {
                step++;
                const newLat = start.lat + latStep * step;
                const newLng = start.lng + lngStep * step;

                const newPosition = { lat: newLat, lng: newLng };
                setTruckPosition(newPosition);

                // Update marker position
                if (truckMarkerRef.current) {
                    truckMarkerRef.current.setPosition(newPosition);
                }

                // Center the map on the truck
                if (mapRef.current) {
                    mapRef.current.setCenter(newPosition);
                }
                console.log(step, currentIndex, coords.length - 1, "----------------------");
                if (step >= totalSteps) {
                    clearInterval(interval);
                    currentIndex++;
                    // Proceed to the next segment after a short delay
                    // console.log(currentIndex, "----------------------");
                    setTimeout(moveToNextPoint); // 1 second delay between segments
                }
                if (currentIndex >= coords.length - 2) {
                    console.log(step, currentIndex, "----uio------------------");
                    // If last point reached, remove truck marker
                    if (truckMarkerRef.current) {
                        truckMarkerRef.current.setMap(null);
                        truckMarkerRef.current = null;
                    }
                    setTruckPosition(null);
                    getBounds();
                    onChangeRoutePlay();
                }
            }, intervalTime);
        };

        moveToNextPoint(); // Start the first movement
    };

    useEffect(() => {
        if (window.google && !liveVideo) {
            setPolylineOptions({
                strokeColor: "blue",
                strokeOpacity: 1,
                strokeWeight: 3,
                icons: [
                    {
                        icon: {
                            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            scale: 3, // Adjust arrow size
                            strokeColor: "red"
                        },
                        offset: "50%", // Adjust arrow placement
                        repeat: "100px" // Add multiple arrows along the polyline
                    }
                ]
            });
        }
    }, [mapLoaded]); // Update options when the map is loaded

    useEffect(() => {
        if (coords.length > 0) {
            const newPosition = { lat: coords[0].lat, lng: coords[0].lng };
            if (truckMarkerRef.current) {
                truckMarkerRef.current.setPosition(newPosition);
                // mapRef.current.setZoom(4);
                clearInterval(intervalRef.current)
            }
        }
        setRefresh(0)
        setCount(0)
        setExistRecord({})
        setTimeout(() => {
            getBounds();
        }, 0)
    }, [selectedCameraView])
    console.log(coords,"coords1234")
    // console.log(trackPosition, newTrackObject)
    return (
        // <LoadScript googleMapsApiKey={apiKey}>
        <GoogleMap
            mapContainerStyle={containerStyle}
            onLoad={(map) => {
                mapRef.current = map;
                setMapLoaded(true);
            }}
        >
            <Polyline
                path={coords.map((point) => ({ lat: point.lat, lng: point.lng }))}
                // options={{ strokeColor: "blue", strokeOpacity: 1, strokeWeight: 3, icons: [
                //   {
                //     icon: {
                //       path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                //       scale: 3, // Adjust the arrow size
                //       strokeColor: "#0000FF"
                //     },
                //     offset: "100%" // Position at the end of the polyline
                //   }
                // ] }}
                options={polylineOptions}
            />
            {(coords.length > 0 && !liveVideo) && <>
                <Marker
                    // key={index}
                    position={{ lat: coords[0].lat, lng: coords[0].lng }}
                    label={"S"}
                // icon={{
                //   path: window.google?.maps?.SymbolPath.FORWARD_CLOSED_ARROW,
                //   strokeColor: "#FF0000",
                // }}
                />
                <Marker
                    // key={index}
                    position={{ lat: coords[coords.length - 1].lat, lng: coords[coords.length - 1].lng }}
                    label={"E"}
                // icon={{
                //   path: window.google?.maps?.SymbolPath.FORWARD_CLOSED_ARROW,
                // //   rotation: arrow.rotation,
                // //   scale: 3,
                //   strokeColor: "#FF0000",
                // }}
                /></>}
            {/* {breaks.map((breakPoint, index) => (
            <Marker
              key={index}
              position={{ lat: breakPoint.lat, lng: breakPoint.lng }}
              icon={{
                url: "https://em-content.zobj.net/source/microsoft-teams/363/teacup-without-handle_1f375.png",
                scaledSize: new window.google.maps.Size(40, 40),
              }}
              onClick={() => setSelectedBreak(breakPoint)}
            />
          ))} */}

            {selectedBreak && (
                <InfoWindow
                    position={{ lat: selectedBreak.lat, lng: selectedBreak.lng }}
                    options={{
                        pixelOffset: new window.google.maps.Size(0, -30),
                        disableAutoPan: true,
                    }}
                >
                    <div style={{ fontSize: "13px", fontWeight: "500", maxWidth: "300px" ,  color: "#4d4d4d"}}>
                        <style>
                            {`
                      .gm-style-iw button {
                          display: none !important;
                      }
                      `}
                        </style>
                        <div style={{ border: "1px solid #efefef" }}>
                            <div
                                className="d-flex justify-content-between align-items-center p-2"
                                style={{ borderBottom: "1px solid #efefef" }}
                            >
                                <p className="m-0" style={{ fontSize: "14px" }}>
                                    ☕ Break Details
                                </p>
                                <CircleX size={25} style={{ cursor: "pointer" , color: 'red'}} onClick={() => setSelectedBreak(null)} />
                            </div>
                            <table className="text-center">
                                <tbody>
                                    <tr>
                                        <td style={{ border: "1px solid #efefef", padding: "5px" }}>Start Time</td>
                                        <td style={{ border: "1px solid #efefef", padding: "5px" }}>{selectedBreak.break_start}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid #efefef", padding: "5px" }}>End Time</td>
                                        <td style={{ border: "1px solid #efefef", padding: "5px" }}>{selectedBreak.break_end}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid #efefef", padding: "5px" }}>Duration</td>
                                        <td style={{ border: "1px solid #efefef", padding: "5px" }}>{selectedBreak.break_time_seconds}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid #efefef", padding: "5px" }}>Location</td>
                                        <td style={{ border: "1px solid #efefef", padding: "5px" }}>{selectedBreak.break_address}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </InfoWindow>
            )}
            {/* Truck Marker */}
            {/* {truckMarkerRef.current &&
          <Marker
            position={truckPosition}
            icon={{
              url: "https://img.icons8.com/ios-filled/50/000000/truck.png", // Custom truck icon
              scaledSize: new window.google.maps.Size(40, 40),
              pixelOffset: new window.google.maps.Size(0, -30),
                }} 
          />
        } */}
        </GoogleMap>
        // </LoadScript>
    );
};

const ProgressBarWithVideo = ({ name, alertArray, durationInSeconds, videosData, truckPlay, onTrackPlaying, getLiveData, liveVideo, onChangeDuration }) => {
    const [elapsedTime, setElapsedTime] = useState(0);
    const [isComplete, setIsComplete] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(null);
    const [videoError, setVideoError] = useState(false);
    const [videoSrc, setVideoSrc] = useState(""); // Track video source URL
    const videoRef = useRef(null);
    const [isSeeking, setIsSeeking] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false); // ✅ Track Play/Pause
    const intervalRef = useRef(null); // ✅ Track progress update interval
    const [isSliderOpen, setIsSliderOpen] = useState(false)
    const [alertObject, setAlertObject] = useState(null);
    const [hoverPlayer, setHoverPlayer] = useState(true);
    useEffect(() => {
        if (elapsedTime >= durationInSeconds) {
            const video = videoRef.current;
            if (video) {
                video.pause();
                video.currentTime = 0;
                video.src = "";
                video.load();
            }
            setElapsedTime(0); // ✅ Reset time when reaching duration
            setIsComplete(true);
            setIsPlaying(false);
            return;
        }
        if (isPlaying) {
            const intervalId = setInterval(() => {
                onChangeDuration(elapsedTime + 1)
                setElapsedTime((prev) => prev + 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [elapsedTime, durationInSeconds, isPlaying]);

    // useEffect(() => {
    //   setElapsedTime(parseInt(videosData[videosData.length - 1].start_time));
    //   setIsPlaying(true);
    // }, [])
    useEffect(() => {
        if (elapsedTime == 0) {
            setTimeout(() => {
                setVideoError(false);
            }, 100);
        }
    }, [elapsedTime])

    // Function to format seconds into HH:MM:SS
    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${hours > 0 ? (hours < 10 ? "0" + hours : hours) + ":" : ""}${minutes < 10 ? "0" + minutes : minutes
            }:${remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds}`;
    };

    useEffect(() => {
        onTrackPlaying(isPlaying)
    }, [isPlaying])

    // Handle video playback based on the elapsed time
    const handleVideoPlayback = () => {
        const video = videosData.find(
            (video) =>
                elapsedTime >= parseInt(video.start_time) &&
                elapsedTime < parseInt(video.end_time)
        );
        var newAlertObject = alertArray.find((each) => each.alert_time == elapsedTime)
        if (newAlertObject) {
            setAlertObject(newAlertObject)
            setTimeout(() => {
                setAlertObject(null)
            }, 3000)
        }
        console.log(video, "ooioii")
        if (video) {
            // ✅ If the same video is selected, just seek without reloading
            if (currentVideo?.s3_link === video.s3_link) {
                if (isSeeking) {
                    const seekTime = elapsedTime - parseInt(video.start_time);
                    if (seekTime > 0 && videoRef.current.duration > seekTime) {
                        videoRef.current.currentTime = seekTime;
                        console.log(`⏩ Seeking within same video to ${seekTime} seconds`);
                    }
                    setIsSeeking(false); // ✅ Reset after seeking
                }
                return; // ✅ Stop further execution to avoid reloading
            }
            if (currentVideo?.s3_link !== video.s3_link) {
                // Pause and reset only if the URL changes
                if (videoRef.current) {
                    console.log("uiytuii")
                    videoRef.current.pause();
                    videoRef.current.currentTime = 0;
                    videoRef.current.src = ""; // ✅ Force removal of previous video source
                    videoRef.current.load(); // ✅ Reload the video element to clear old data
                }

                // If video is valid, update state
                if (video.s3_link && video.s3_link !== "None") {
                    setVideoError(false);
                    setVideoSrc(video.s3_link);
                } else {
                    setVideoError(true);
                    setVideoSrc(""); // Reset video source to trigger the black screen
                }
            }
            setCurrentVideo(video);
        } else {
            setVideoError(true);
            setVideoSrc(""); // ✅ Ensure black screen when no video available
        }
    };
    const retryCountsRef = useRef({});  // Ref to track retry attempts for each video
    useEffect(() => {
        if (videoSrc) {
            // Only play the video if we have a valid source
            var retryCount = retryCountsRef.current || 0;
            var playPromises = []
            var videoElement = videoRef.current
            // Create a promise to handle the video load and retries
            const loadPromise = new Promise((resolve) => {
                videoElement.onloadeddata = () => resolve(); // Resolve when the video is loaded
                videoElement.onerror = () => {
                    if (retryCount < 3) {
                        console.log(`Retrying to load video: ${name}, attempt ${retryCount + 1}`);
                        retryCountsRef.current = retryCount + 1;  // Increment retry count
                        retryCount = retryCount + 1
                        videoElement.load();  // Retry loading the video
                    } else {
                        console.warn(`Failed to load video: ${name} after 3 attempts`);  // Reject after 3 attempts
                        // setVideoError((prev) => ({ ...prev, [each]: true }));
                    }
                };
            });

            // Add the load promise to the array
            playPromises.push(loadPromise);
            videoRef.current.src = videoSrc;
            videoRef.current
                .play()
                .then(() => {
                    // ✅ Seek to the correct position if the user clicked ahead
                    const video = videosData.find(
                        (video) =>
                            elapsedTime >= parseInt(video.start_time) &&
                            elapsedTime < parseInt(video.end_time)
                    );

                    if (video) {
                        if (isSeeking) {
                            const seekTime = elapsedTime - parseInt(video.start_time);
                            if (seekTime > 0 && videoRef.current.duration > seekTime) {
                                videoRef.current.currentTime = seekTime;
                                console.log(`⏩ Seeking to ${seekTime} seconds`);
                            }
                            setIsSeeking(false); // ✅ Reset after seeking
                        }

                    }
                })
                .catch((error) => {
                    console.error("Error playing the video:", error);
                    setVideoError(true);
                });
        } else {
            if (videoRef.current) {
                videoRef.current.src = ""; // ✅ Force reset to blank when no video
                setVideoError(true)
            }
        }
    }, [videoSrc, truckPlay]);

    useEffect(() => {
        // if (isSeeking) {
        console.log("tyuioi")
        if (isPlaying) {
            handleVideoPlayback();
        }
        // }
    }, [elapsedTime]);

    // ✅ Play/Pause Button Functionality
    const togglePlayPause = () => {
        setIsPlaying((prev) => !prev);
        if (isPlaying) {
            console.log("ai")
            videoRef.current.pause();
        } else {
            videoRef.current.play().catch(() => setVideoError(true));
        }
    };

    // useEffect(() => {
    //   if (clickAlert != null) {
    //     onClickingAlert(clickAlert)
    //   }
    // }, [clickAlert])

    const onClickingAlert = (each) => {
        console.log("huawei-------------------------------------------")
        const alertTime = each.alert_time; // ✅ Get the clicked alert time
        setElapsedTime(parseInt(alertTime)); // ✅ Update progress bar
        setIsSeeking(true);  // ✅ Mark as seeking (prevents auto updates interfering)
        setIsPlaying(true);  // ✅ Ensure video starts playing
        setIsSliderOpen(false); // ✅ Close alerts panel
        setAlertObject(each)
        setTimeout(() => {
            setAlertObject(null)
            // onCancelAlert(null)
        }, 3000)
    };
    const timeoutRef = useRef(null);
    const [timeoutCheck, setTimeoutCheck] = useState(false);
    const handleMouseEnter = () => {
        setHoverPlayer(true);
        setTimeoutCheck(true);
        // clearTimeout(timeoutRef.current);
        console.log("---------------------------------------------------------------------")
    };

    const handleMouseLeave = () => {
        setHoverPlayer(false);
        setTimeoutCheck(false);
        // clearTimeout(timeoutRef.current);
    };

    const sliderBackground = `linear-gradient(to right, rgb(94, 176, 228) ${(elapsedTime / durationInSeconds) * 100
        }%, #ffffff34 ${(elapsedTime / durationInSeconds) * 100}%)`;
    const convertSecondsToHMS = (seconds) => {
        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds % 3600) / 60);
        let secs = seconds % 60;

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    }
    const onClickingLive = () => {
        console.log("check_how_many_times_renderingg")
        getLiveData(true)
    }
    // console.log(elapsedTime, "elapsedTime")
    return (
        <div className="d-flex flex-column p-1" style={{ width: "100%", height: "100%" }}>
            <div
                className="mt-1 d-flex flex-column"
                style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    background: "#efefef", // Force black background when no video
                    borderRadius: "5px",
                    // overflow: "hidden",
                    // maxHeight: "300px"
                    overflow: "hidden",
                    borderRadius: "10px"
                }}
            >

                {/* Video Player with Black Fallback */}
                <div
                    className="d-flex flex-column"
                    style={{
                        position: "relative",
                        width: "100%",
                        flexGrow: 1,
                        // height: "90%",
                        // background: "#000", // Black background for fallback
                        overflow: "hidden"
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className="bg-primary d-flex flex-column" style={{ width: "100%", height: "100%" }}>
                        {(!isPlaying || hoverPlayer || timeoutCheck) && <div className="d-flex justify-content-center align-items-center" style={{ position: "absolute", width: "100%", height: "100%", top: "0%", left: "0%", backgroundColor: "rgba(192, 187, 187, 0.3)", zIndex: "10001" }}>
                            <div className="d-flex flex-column justify-content-center align-items-center" onClick={togglePlayPause} style={{ width: "75px", height: "75px", backgroundColor: "#efefef", cursor: "pointer", border: "3px solid blue", borderRadius: "50%", zIndex: "100003" }}>
                                {isPlaying ? <Pause size={27} color={"blue"} /> : <Play size={27} color={"blue"} />}
                                {/* <p className="m-0" style={{color: "blue"}}>Play</p> */}
                                {/* <button className="btn btn-primary" onClick={togglePlayPause}>{isPlaying ? "Pause" : "Play"}</button> */}
                            </div>
                        </div>}
                        <video
                            ref={videoRef}
                            width="100%"
                            // height="100%"
                            controls={false}
                            style={{
                                background: videoError ? "#000" : "#000",
                                aspectRatio: "16/9", /* Force aspect ratio */
                                objectFit: "contain",
                                flexGrow: 1
                            }}
                        >
                            {videoSrc ? (
                                <source src={videoSrc} type="video/mp4" />
                            ) : (
                                <source
                                    src="data:video/mp4;base64,AAAA" // Empty black video
                                    type="video/mp4"
                                />
                            )}
                            Your browser does not support the video tag.
                        </video>
                        <div className="d-flex" style={{ position: "absolute", top: "3%", right: "3%" }}>
                            <div className="d-flex align-items-center" style={{ backgroundColor: '#efefef', padding: "5px 15px 5px 15px", color: "blue", borderRadius: "5px" }}>
                                <HiVideoCamera className="mr-1" />
                                <p className="m-0" style={{ fontWeight: "500", fontSize: "15px" }}>{name}</p>
                            </div>
                        </div>
                        {videoError && (
                            <div
                                className="m-0 text-danger d-flex justify-content-center pl-3 pr-3"
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    bottom: "41%",
                                    // color: "#fff",
                                    fontSize: "13px",
                                    fontWeight: "500"
                                }}
                            >
                                <div className="bg-light p-3 d-flex flex-column align-items-center" style={{ maxWidth: "100%", borderRadius: "5px",backgroundColor: "" ,  color: "red" }}>
                                    <AlertTriangle />
                                    <p className="m-0 mt-1">Video not available at this moment.</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Progress Bar */}

                <div
                    style={{
                        // position: "absolute",
                        width: "100%",
                        // bottom: "5px",
                        zIndex: "100001",
                        cursor: "pointer",
                        flexShrink: 0,
                        borderTop: "1px solid #efefef"
                    }}
                    className="p-2 pl-2 pr-2 d-flex flex-column"
                >
                    <div className="d-flex justify-content-between align-items-center pl-0">
                        <div className="d-flex align-items-center">
                            <div
                                onClick={togglePlayPause}
                                style={{
                                    cursor: "pointer",
                                    zIndex: "100001",
                                    padding: "0px",
                                    borderRadius: "1px solid #efefef"
                                }}
                                className="mr-1 mb-1"
                            >
                                {isPlaying ? <Pause size={23} /> : <Play size={23} />}
                            </div>
                            <p className="m-0" style={{ fontSize: "13px", fontWeight: "500", color: "#000" }}>{`${formatTime(elapsedTime)} / ${formatTime(durationInSeconds)}`}</p>
                        </div>
                        <div className="d-flex align-items-center mr-1">
                            <div className="ml-1 mr-2">
                                <button className="btn btn-primary" style={{ fontSize: "11px" }} onClick={() => { onClickingLive() }}><Radio size={13} /> Live</button>
                            </div>
                            <div className="ml-1 mr-2" onClick={() => { setIsSliderOpen(true) }}>
                                <button className="btn btn-danger" style={{ fontSize: "11px" }}><AlertTriangle size={13} /> Alerts</button>
                            </div>
                            <div title={"Mute"} className="ml-1 mr-1">
                                <VolumeIcon size={23} />
                            </div>
                            <div title={"Playback Rate"} className="ml-1 mr-1">
                                <img src={"https://cdn-icons-png.flaticon.com/128/17055/17055362.png"} style={{ width: "23px", height: "23px" }} />
                            </div>
                        </div>
                    </div>
                    <input
                        id="progress-slider"
                        type="range"
                        min="0"
                        max="100"
                        step="1"
                        value={(elapsedTime / durationInSeconds) * 100}
                        onChange={(e) => {
                            // console.log((e.target.value / 100) * durationInSeconds)
                            setElapsedTime(parseInt((e.target.value / 100) * durationInSeconds));
                            setIsSeeking(true)
                            onChangeDuration(parseInt((e.target.value / 100) * durationInSeconds))
                        }}
                        style={{
                            background: sliderBackground,
                            width: "100%",
                            position: "relative"
                        }}
                    />
                </div>


                {/* <!-- Sliding Panel --> */}
                <div className={`slider-panel ${isSliderOpen && "open d-block"} d-flex flex-column`} id="sliderPanel">
                    <div className="d-flex justify-content-between align-items-center p-2" style={{ borderBottom: "1px solid #efefef", flexShrink: 0 }}>
                        <div className="d-flex align-items-center">
                            <AlertTriangle color={"#fff"} size={15} className="mr-1" />
                            <p className="m-0" style={{ fontWeight: "500", fontSize: "13px" }}>Alerts</p>
                        </div>
                        <button className="btn btn-danger" id="closeSlider" style={{ padding: "5px", fontSize: "10px" }} onClick={() => { setIsSliderOpen(false) }}>✖</button>
                    </div>
                    <div className="scrollable-container p-3" style={{ flexGrow: 1, overflowY: 'auto' }}>
                        {/* <button className="btn btn-danger close-btn" id="closeSlider" onClick={() => {setIsSliderOpen(false)}}>×</button> */}

                        {alertArray.map((each) => {
                            return (
                                <div className="bg-light p-2 mb-3" title="Click to watch the video" onClick={() => { onClickingAlert(each); }} style={{ cursor: "pointer", borderRadius: "5px" }}>
                                    <p className="m-0" style={{ fontWeight: "500", fontSize: "13px", color: "#000" }}>{each.alert_type}</p>
                                    <p className="m-0" style={{ fontWeight: "500", fontSize: "11px", color: "#000" }}>Timestamp: {convertSecondsToHMS(parseInt(each.alert_time))}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>

                {alertObject &&
                    <div className="position-absolute top-0 bg-danger p-2 d-flex align-items-center" style={{ width: "100%", zIndex: "1003" }}>
                        <AlertTriangle size={25} color={"#fff"} className="mr-1" />
                        <p className="m-0" style={{ fontWeight: "500", fontSize: "15px", color: "#fff" }}>{alertObject.alert_type}</p>
                    </div>
                }
            </div>
        </div>
    );
};
const AllProgressBarWithVideo = ({ name, alertArray, durationInSeconds, videosData, truckPlay, startDate, onTrackPlaying, getLiveData, liveVideo, onChangeDuration }) => {
    console.log(startDate, "startDate")
    const [elapsedTime, setElapsedTime] = useState(0);
    const [isComplete, setIsComplete] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(Object.keys(videosData).reduce((acc, key) => {
        acc[key] = null;
        return acc;
    }, {}));
    const [videoError, setVideoError] = useState(Object.keys(videosData).reduce((acc, key) => {
        acc[key] = false;
        return acc;
    }, {}));
    const [videoSrc, setVideoSrc] = useState(Object.keys(videosData).reduce((acc, key) => {
        acc[key] = "";
        return acc;
    }, {})); // Track video source URL
    const videoRef = useRef({});
    const [isSeeking, setIsSeeking] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false); // ✅ Track Play/Pause
    const intervalRef = useRef(null); // ✅ Track progress update interval
    const [isSliderOpen, setIsSliderOpen] = useState(false)
    const [alertObject, setAlertObject] = useState(null);
    const [hoverPlayer, setHoverPlayer] = useState(true);
    const [playbackRate, setPlaybackRate] = useState(1);
    const [prevPlaybackRate, setPrevPlaybackRate] = useState(1);
    const [checkMute, setCheckMute] = useState(true)
    useEffect(() => {
        // console.log(elapsedTime, "1000000000000000000000000000000000000000000000000000")
        if (isPlaying) {
            const intervalId = setInterval(() => {
                onChangeDuration(elapsedTime + 1)
                setElapsedTime((prev) => prev + 1);
            }, 1000 / playbackRate);
            // console.log(elapsedTime, "elapsedTime")
            return () => clearInterval(intervalId);
        }
    }, [elapsedTime, durationInSeconds, isPlaying]);
    useEffect(() => {
        onTrackPlaying(isPlaying)
    }, [isPlaying])

    useEffect(() => {
        if (elapsedTime >= durationInSeconds) {
            setTimeout(() => {
                requestAnimationFrame(() => {
                    Object.keys(videosData).forEach((each) => {
                        const video = videoRef.current[each];
                        if (video) {
                            video.pause();
                            video.currentTime = 0;
                            video.src = "";
                            video.load();
                        }
                    });
                    setElapsedTime(0); // ✅ Reset time when reaching duration
                    setIsComplete(true);
                    setIsPlaying(false);
                });
            }, 10)
            // return;
        }

    }, [elapsedTime])

    useEffect(() => {
        // console.log(videosData)
        // setElapsedTime(videosData[videosData.length - 1]?.start_time);
        // setIsPlaying(true);
    }, [])
    useEffect(() => {
        if (elapsedTime == 0) {
            var updatedVideoErrors = Object.keys(videosData).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {})
            setTimeout(() => {
                setVideoError(updatedVideoErrors);
            }, 100);
        }
        if (playbackRate != prevPlaybackRate) {
            var updatedVideoErrors = Object.keys(videosData).map((each) => {
                videoRef.current[each].playbackRate = playbackRate
            }, {})
            setPrevPlaybackRate(playbackRate)
        }
    }, [elapsedTime, playbackRate])

    // Function to format seconds into HH:MM:SS
    // const formatTime = (seconds) => {
    //   const hours = Math.floor(seconds / 3600);
    //   const minutes = Math.floor((seconds % 3600) / 60);
    //   const remainingSeconds = Math.floor(seconds % 60);
    //   return `${hours > 0 ? (hours < 10 ? "0" + hours : hours) + ":" : ""}${minutes < 10 ? "0" + minutes : minutes
    //     }:${remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds}`;
    // };
    const formatTime = (seconds, startDate) => {
        // Parse the startDate (in ISO 8601 format)
        const startTime = new Date(startDate);

        // Add the seconds to the start date
        startTime.setSeconds(startTime.getSeconds() + seconds);

        // Get the updated hours, minutes, and seconds
        const hours = startTime.getHours();
        const minutes = startTime.getMinutes();
        const remainingSeconds = startTime.getSeconds();

        // Return the formatted time in HH:mm:ss format
        return `${hours > 0 ? (hours < 10 ? "0" + hours : hours) + ":" : ""}${minutes < 10 ? "0" + minutes : minutes
            }:${remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds}`;
    };

    // Handle video playback based on the elapsed time
    const handleVideoPlayback = () => {
        // Generate a list of active videos at the current elapsed time
        const videosList = Object.keys(videosData).reduce((result, key) => {
            const video = videosData[key].find(
                (video) =>
                    elapsedTime >= parseInt(video.start_time) &&
                    elapsedTime < parseInt(video.end_time)
            );
            if (video) result[key] = video;
            return result;
        }, {});

        // Efficiently handle alerts
        const newAlertObject = alertArray.find((each) => each.alert_time == elapsedTime);
        if (newAlertObject) {
            setAlertObject(newAlertObject);
            setTimeout(() => setAlertObject(null), 3000);
        }

        // Collect updates before applying them to state
        let updatedCurrentVideos = { ...currentVideo };
        let updatedVideoErrors = { ...videoError };
        let updatedVideoSrc = { ...videoSrc };

        requestAnimationFrame(() => {
            let hasStateChanged = false; // Flag to track state changes

            Object.keys(videosData).forEach((each) => {
                const videoElement = videoRef.current[each];
                const newVideo = videosList[each];

                if (newVideo) {
                    const isSameVideo = currentVideo[each]?.s3_link === newVideo.s3_link;

                    if (isSameVideo && isSeeking) {
                        const seekTime = elapsedTime - parseInt(newVideo.start_time);
                        if (seekTime > 0 && videoElement?.duration > seekTime) {
                            videoElement.currentTime = seekTime;
                            console.log(`⏩ Seeking within the same video to ${seekTime} seconds`);
                            setIsSeeking(false);
                        }
                        // console.log("Hey Seeking")
                        return; // Skip unnecessary updates
                    }

                    if (!isSameVideo && videoElement) {
                        console.log("🎥 Switching video...");
                        videoElement.pause();
                        videoElement.currentTime = 0;
                        videoElement.src = "";
                        videoElement.load();
                    }

                    updatedVideoErrors[each] = !(newVideo.s3_link && newVideo.s3_link !== "None");
                    updatedVideoSrc[each] = newVideo.s3_link ?? "";
                    updatedCurrentVideos[each] = newVideo;

                    hasStateChanged = true;
                } else {
                    updatedVideoErrors[each] = true;
                    updatedVideoSrc[each] = "None";
                    hasStateChanged = true;
                }
            });

            // ✅ Update state only if there are actual changes
            if (hasStateChanged) {
                setVideoError((prev) => (JSON.stringify(prev) !== JSON.stringify(updatedVideoErrors) ? updatedVideoErrors : prev));
                setVideoSrc((prev) => (JSON.stringify(prev) !== JSON.stringify(updatedVideoSrc) ? updatedVideoSrc : prev));
                setCurrentVideo((prev) => (JSON.stringify(prev) !== JSON.stringify(updatedCurrentVideos) ? updatedCurrentVideos : prev));
            }
        });
    };


    const previousVideoSrcRef = useRef({});  // Track previous videoSrc state
    const retryCountsRef = useRef({});  // Ref to track retry attempts for each video

    // useEffect(() => {
    //   if(isPlaying){
    //     requestAnimationFrame(async () => {
    //       const playPromises = [];

    //       Object.keys(videoSrc).forEach((each) => {
    //         const videoElement = videoRef.current[each];

    //         if (videoElement && videoSrc[each] && videoSrc[each] !== "None" && videoSrc[each] !== "") {
    //           // Ensure video source is set before playing
    //           if (videoElement.src !== videoSrc[each]) {
    //             videoElement.src = videoSrc[each];
    //             videoElement.load(); // Ensure it loads before playing
    //           }

    //           var retryCount = retryCountsRef.current[each] || 0;

    //           // Create a promise to handle the video load and retries
    //           const loadPromise = new Promise((resolve) => {
    //             videoElement.onloadeddata = () => resolve(); // Resolve when the video is loaded
    //             videoElement.onerror = () => {
    //               if (retryCount < 3) {
    //                 console.log(`Retrying to load video: ${each}, attempt ${retryCount + 1}`);
    //                 retryCountsRef.current[each] = retryCount + 1;  // Increment retry count
    //                 retryCount = retryCount + 1
    //                 videoElement.load();  // Retry loading the video
    //               } else {
    //                 console.warn(`Failed to load video: ${each} after 3 attempts`);  // Reject after 3 attempts
    //                 // setVideoError((prev) => ({ ...prev, [each]: true }));
    //               }
    //             };
    //           });

    //           // Add the load promise to the array
    //           playPromises.push(loadPromise);
    //         } else {
    //           // Handle invalid URLs
    //           setVideoError((prev) => ({ ...prev, [each]: true }));
    //         }
    //       });

    //       try {
    //         // Wait for all the videos to be loaded before playing
    //         await Promise.all(playPromises);

    //         // Once all videos are loaded, start playing the videos
    //         Object.keys(videoSrc).forEach((each) => {
    //           const videoElement = videoRef.current[each];
    //           if (videoElement) {
    //             // Play the video once it's ready
    //             videoElement.play().catch((error) => {
    //               console.error(`Error playing video ${each}:`, error);
    //               setVideoError((prev) => ({ ...prev, [each]: true }));
    //             });
    //             // videoElement.playbackRate = 5;
    //           }
    //         });

    //         // Handle seeking or further updates after videos are loaded and played
    //         Object.keys(videoSrc).forEach((each) => {
    //           const videoElement = videoRef.current[each];

    //           if (videoElement) {
    //             const videoData = videosData[each]?.find(
    //               (video) => elapsedTime >= parseInt(video.start_time) && elapsedTime < parseInt(video.end_time)
    //             );

    //             if (videoData) {
    //               const seekTime = elapsedTime - parseInt(videoData.start_time);
    //               console.log(isSeeking, seekTime, videoElement.duration)
    //               if (isSeeking && seekTime > 0 && videoElement.duration > seekTime) {
    //                 console.loh("Hi SeekTime")
    //                 videoElement.currentTime = seekTime;
    //                 console.log(`⏩ Seeking within the same video to ${seekTime} seconds`);
    //                 setIsSeeking(false);
    //               }
    //             } else {
    //               setVideoError((prev) => ({ ...prev, [each]: true }));
    //             }
    //           }
    //         });
    //         // setIsPlaying(true);
    //       } catch (error) {
    //         console.error(error);  // Log error if a video fails to load after 3 attempts
    //       }
    //     });
    //   }
    // }, [videoSrc, isSeeking]);

    useEffect(() => {
        // if (isPlaying) {
        // setIsPlaying(false)
        requestAnimationFrame(() => {
            const playPromises = [];

            Object.keys(videoSrc).forEach((each) => {
                const videoElement = videoRef.current[each];

                if (videoElement && videoSrc[each] && videoSrc[each] !== "None" && videoSrc[each] !== "") {
                    // Ensure video source is set before playing
                    if (videoElement.src !== videoSrc[each]) {
                        videoElement.src = videoSrc[each];
                        videoElement.load(); // Ensure it loads before playing
                    }

                    let retryCount = retryCountsRef.current[each] || 0;

                    // Create a promise to handle the video load and retries
                    const loadPromise = new Promise((resolve, reject) => {
                        videoElement.onloadeddata = () => resolve(); // Resolve when the video is loaded
                        videoElement.onerror = () => {
                            if (retryCount < 3) {
                                // console.log(`Retrying to load video: ${each}, attempt ${retryCount + 1}`);
                                retryCountsRef.current[each] = retryCount + 1; // Increment retry count
                                videoElement.load(); // Retry loading the video
                            } else {
                                // console.warn(`Failed to load video: ${each} after 3 attempts`);
                                setVideoError((prev) => ({ ...prev, [each]: true })); // Mark as error after 3 attempts
                                reject(new Error(`Failed to load video: ${each}`)); // Reject after 3 attempts to avoid blocking
                            }
                        };
                    });

                    // Add the load promise to the array
                    playPromises.push(loadPromise);
                } else {
                    // Handle invalid URLs immediately by marking them as error
                    setVideoError((prev) => ({ ...prev, [each]: true }));
                }
            });

            // Once all the promises have been processed, you can execute the play logic
            Promise.all(playPromises)
                .then(() => {
                    // Once all videos are loaded, start playing the videos
                    Object.keys(videoSrc).forEach((each) => {
                        const videoElement = videoRef.current[each];
                        if (videoElement && !videoError[each]) {  // Ensure no error before playing
                            videoElement.play().catch((error) => {
                                console.error(`Error playing video ${each}:`, error);
                                setVideoError((prev) => ({ ...prev, [each]: true }));
                            });
                        }
                    });

                    // Handle seeking or further updates after videos are loaded and played
                    Object.keys(videoSrc).forEach((each) => {
                        const videoElement = videoRef.current[each];
                        if (videoElement) {
                            const videoData = videosData[each]?.find(
                                (video) => elapsedTime >= parseInt(video.start_time) && elapsedTime < parseInt(video.end_time)
                            );

                            if (videoData) {
                                const seekTime = elapsedTime - parseInt(videoData.start_time);
                                if (isSeeking && seekTime > 0 && videoElement.duration > seekTime) {
                                    // console.log("Seeking to time:", seekTime);
                                    videoElement.currentTime = seekTime;
                                    // console.log(`⏩ Seeking within the same video to ${seekTime} seconds`);
                                    setIsSeeking(false);
                                }
                            } else {
                                setVideoError((prev) => ({ ...prev, [each]: true }));
                            }
                        }
                    });

                })
                .catch((error) => {
                    console.error("Error during video loading:", error);
                });
        });
        // setIsPlaying(true)
        // }
    }, [videoSrc, isSeeking]);


    useEffect(() => {
        // Function to check if any video is paused
        Object.keys(videosData).forEach((each) => {
            const video = videoRef.current[each];

            // Check if video exists, is a valid element, has a valid source, and is paused
            if (video && video instanceof HTMLVideoElement && videoSrc[each] && video.paused) {
                try {
                    // Ensure the video has a valid source before attempting to play it
                    if (!video.src && !video.currentSrc) {
                        console.error(`No valid source found for video ${each}`);
                        return; // Skip if no valid source is available
                    }

                    // Check if the video can be played (readyState 4 means video is ready)
                    if (video.readyState >= 4) {
                        // Play the video, suppressing the error if interrupted
                        const playPromise = video.play();

                        if (playPromise !== undefined) {
                            playPromise
                                .catch((error) => {
                                    // Ignore the "play interrupted by pause" error
                                    if (error.name !== 'AbortError') {
                                        console.error('Error playing video:', error);
                                    }
                                });
                        }
                    } else {
                        // If the video is not ready, add an event listener to play once it's ready
                        video.addEventListener('canplay', () => {
                            video.play();
                        });
                    }
                } catch (error) {
                    console.error("Error playing video:", error); // Log any error
                }
            }
        });
    }, [elapsedTime]); // Effect depends on elapsedTime


    useEffect(() => {
        // if (isSeeking) {
        // console.log("tyuioi")
        if (isPlaying) {
            // console.log("huawei")
            handleVideoPlayback();
        }
        // }
    }, [elapsedTime]);

    const togglePlayPause = () => {
        setIsPlaying((prev) => {
            const newIsPlaying = !prev;

            // Using requestAnimationFrame for simultaneous execution
            if (elapsedTime != 0) {
                requestAnimationFrame(() => {
                    // Create an array of promises that correspond to each video play/pause action
                    const playPromises = Object.keys(videosData).map((each) => {
                        const video = videoRef.current[each];
                        if (video) {
                            if (newIsPlaying) {
                                return video.play().catch(() => {
                                    // setVideoError((prev) => ({ ...prev, [each]: true }));
                                    return false; // Return false to prevent rejection in Promise.all
                                });
                            } else {
                                video.pause();
                                return Promise.resolve(true); // Resolve as true for pause action
                            }
                        }
                        return Promise.resolve(true); // Resolve if no video found
                    });

                    // Wait for all promises to resolve and update the state accordingly
                    Promise.all(playPromises).then((results) => {
                        if (results.some((res) => res)) {
                            // console.log("At least one video started playing, updating isPlaying state");
                            // setIsPlaying(newIsPlaying); // Update the state if at least one video played
                        } else {
                            // console.warn("No videos started playing");
                        }
                    });
                });
            }
            return newIsPlaying; // This will set the state to the new value
        });
    };


    // useEffect(() => {
    //   if (clickAlert != null) {
    //     onClickingAlert(clickAlert)
    //   }
    // }, [clickAlert])

    const onClickingAlert = (each) => {
        // console.log("huawei-------------------------------------------")
        const alertTime = each.alert_time; // ✅ Get the clicked alert time
        setElapsedTime(parseInt(alertTime)); // ✅ Update progress bar
        setIsSeeking(true);  // ✅ Mark as seeking (prevents auto updates interfering)
        setIsPlaying(true);  // ✅ Ensure video starts playing
        setIsSliderOpen(false); // ✅ Close alerts panel
        setAlertObject(each)
        setTimeout(() => {
            setAlertObject(null)
            // onCancelAlert(null)
        }, 3000)
    };
    const timeoutRef = useRef(null);
    const [timeoutCheck, setTimeoutCheck] = useState(false);
    const handleMouseEnter = () => {
        setHoverPlayer(true);
        setTimeoutCheck(true);
        clearTimeout(timeoutRef.current);
        // console.log("---------------------------------------------------------------------")
    };

    const handleMouseLeave = () => {
        setHoverPlayer(false);
        setTimeoutCheck(false);
        clearTimeout(timeoutRef.current);
    };
    // useEffect(() => {
    //   if(hoverPlayer || timeoutCheck){
    //     setTimeout(() => {
    //       setHoverPlayer(false);
    //       setTimeoutCheck(false);
    //     }, 3000)
    //   }
    // }, [hoverPlayer])
    const sliderBackground = `linear-gradient(to right, rgb(94, 176, 228) ${(elapsedTime / durationInSeconds) * 100
        }%, #fff ${(elapsedTime / durationInSeconds) * 100}%)`;
    // const convertSecondsToHMS = (seconds) => {
    //   let hours = Math.floor(seconds / 3600);
    //   let minutes = Math.floor((seconds % 3600) / 60);
    //   let secs = seconds % 60;
    //   // #ffffff34
    //   return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    // }
    const convertSecondsToHMS = (seconds, startDate) => {
        // Parse the startDate (in ISO 8601 format)
        const startTime = new Date(startDate);

        // Add the seconds to the start date
        startTime.setSeconds(startTime.getSeconds() + seconds);
        console.log(startTime)
        // Extract hours, minutes, and seconds
        let hours = startTime.getHours();
        let minutes = startTime.getMinutes();
        let secs = startTime.getSeconds();

        // Return the time formatted as HH:mm:ss
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    }
    useEffect(() => {
        requestAnimationFrame(async () => {
            var playPromises = [];
            playPromises = Object.keys(videosData).map((each) => {
                const video = videoRef.current[each];
                video.muted = !checkMute
            });
        })
        // setCheckMute((prev) => !prev)
    }, [checkMute])
    const onClickingLive = () => {
        console.log("check_here123")
        getLiveData(true)
    }
    console.log(elapsedTime, videoError, "elapsedTime")
    return (
        <div className="scrollable-container" style={{ width: "100%", height: "100%", overflowX: "hidden", position: "relative", overflowY: "auto" }}>
            <div
                className="d-flex flex-column"
                style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    background: "#f9f9f9", // Force black background when no video
                    borderRadius: "5px",
                    // overflowX: "hidden"
                }}
            >

                {/* Video Player with Black Fallback */}
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                        flexGrow: 1,
                        padding: "13px 13px 0px 13px"
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    // onMouseMove={() => {setTimeoutCheck(true)}}
                    className="d-flex flex-wrap justify-content-between"
                >
                    {(!isPlaying || hoverPlayer || timeoutCheck) && <div className="d-flex justify-content-center align-items-center" style={{ position: "absolute", width: "100%", height: "100%", top: "0%", left: "0%", backgroundColor: "rgba(239, 239, 239, 0.3)", zIndex: "10001" }}>
                        <div className="bg-light d-flex justify-content-center align-items-center" onClick={togglePlayPause} style={{ width: "75px", height: "75px", borderRadius: "50%", border: "3px solid blue", zIndex: "100003" }}>
                            {isPlaying ? <Pause size={27} color={"blue"} /> : <Play size={27} color={"blue"} />}
                        </div>
                    </div>}
                    {Object.keys(videosData).length > 0 && Object.keys(videosData).map((each, index) => {
                        return (
                            <div className="d-flex flex-column" style={{ width: "49%", height: "47%", borderRadius: "10px", overflow: "hidden", position: "relative" }}>
                                <video
                                    // ref={videoRef[each]}
                                    key={`New Key-${index}`}
                                    ref={(el) => (videoRef.current[each] = el)}
                                    width="100%"
                                    // height="100px"
                                    controls={false}
                                    preload="auto" // Auto-preload video for smoother playback
                                    style={{
                                        background: videoError[each] ? "#000" : "#000",
                                        flexGrow: 1,
                                        // position: "relative"
                                    }}
                                    autoplay
                                >
                                    {videoSrc ? (
                                        <source src={videoSrc[each]} type="video/mp4" />
                                    ) : (
                                        <source
                                            src="data:video/mp4;base64,AAAA" // Empty black video
                                            type="video/mp4"
                                        />
                                    )}
                                    Your browser does not support the video tag.
                                </video>
                                <div className="d-flex" style={{ position: "absolute", bottom: "3%", left: index % 2 == 0 && "3%", right: index % 2 == 1 && "3%" }}>
                                    <div className="d-flex align-items-center" style={{ backgroundColor: '#efefef', padding: "5px 15px 5px 15px", color: "blue", borderRadius: "5px" }}>
                                        <HiVideoCamera className="mr-1" />
                                        <p className="m-0" style={{ fontWeight: "500", fontSize: "13px" }}>{each}</p>
                                    </div>
                                </div>
                                {videoError[each] && (
                                    <div
                                        className="m-0 text-danger d-flex justify-content-center pl-3 pr-3"
                                        style={{
                                            position: "absolute",
                                            width: "100%",
                                            bottom: "31%",
                                            // color: "#fff",
                                            fontSize: "13px",
                                            fontWeight: "500"
                                        }}
                                    >
                                        <div className="bg-light p-3 d-flex flex-column align-items-center" style={{ maxWidth: "100%", borderRadius: "5px",backgroundColor: "" ,  color: "red" }}>
                                            <AlertTriangle size={15} />
                                            <p className="m-0 mt-1" style={{ fontSize: "11px" }}>Video not available at this moment.</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>

                {/* Progress Bar */}

                <div
                    style={{
                        // position: "absolute",
                        width: "100%",
                        // bottom: "5px",
                        zIndex: "1001",
                        cursor: "pointer",
                        flexShrink: 0,
                        borderTop: "1px solid #efefef"
                    }}
                    className="p-2 pl-2 pr-2 d-flex flex-column"
                >
                    <div className="d-flex justify-content-between align-items-center pl-0">
                        <div className="d-flex align-items-center">
                            <div
                                onClick={togglePlayPause}
                                style={{
                                    cursor: "pointer",
                                    zIndex: "100001",
                                    padding: "0px",
                                    borderRadius: "1px solid #efefef"
                                }}
                                className="mr-1 mb-1"
                            >
                                {isPlaying ? <Pause size={23} /> : <Play size={23} />}
                            </div>
                            {liveVideo ? <p className="m-0 pl-2 d-flex align-items-center" style={{ color: "blue", fontWeight: "bold" }}>
                                <div style={{ width: "11px", height: "11px", borderRadius: "50%", backgroundColor: "blue" }}></div>
                                &nbsp;Live</p>
                                : <p className="m-0" style={{ fontSize: "13px", fontWeight: "500", color: "#000" }}>{`${formatTime(elapsedTime, startDate)} / ${formatTime(durationInSeconds, startDate)}`}</p>}
                        </div>
                        <div className="d-flex align-items-center mr-1 mb-1">
                            <div className="ml-1 mr-2">
                                <button className="btn btn-primary" style={{ fontSize: "11px" }} onClick={() => { onClickingLive() }}><Radio size={13} /> Live</button>
                            </div>
                            <div className="ml-1 mr-2" onClick={() => { setIsSliderOpen(true) }}>
                                <button className="btn btn-danger" style={{ fontSize: "11px" }}><AlertTriangle size={13} /> Alerts</button>
                            </div>
                            <div title={checkMute ? "Mute" : "Unmute"} className="ml-1 mr-1" onClick={() => { setCheckMute((prev) => !prev) }}>
                                {checkMute ? <VolumeIcon size={23} /> : <VolumeOffIcon size={23} />}
                            </div>
                            <div title={"Playback Rate"} className="ml-1 mr-1">
                                <img src={"https://cdn-icons-png.flaticon.com/128/17055/17055362.png"} style={{ width: "23px", height: "23px" }} />
                            </div>
                        </div>
                    </div>
                    <input
                        id="progress-slider"
                        type="range"
                        min="0"
                        max="100"
                        step="1"
                        value={(elapsedTime / durationInSeconds) * 100}
                        onChange={(e) => {
                            // console.log((e.target.value / 100) * durationInSeconds)
                            if (isPlaying) {
                                setIsSeeking(true)
                                setElapsedTime(parseInt((e.target.value / 100) * durationInSeconds));
                                onChangeDuration(parseInt((e.target.value / 100) * durationInSeconds))
                            }
                        }}
                        // disabled={!liveVideo}
                        style={{
                            background: sliderBackground,
                            width: "100%",
                            position: "relative"
                        }}
                    />
                </div>

                {/* <!-- Sliding Panel --> */}
                <div className={`slider-panel ${isSliderOpen ? "open" : ""} d-flex flex-column`} id="sliderPanel">
                    <div className="d-flex justify-content-between align-items-center p-2" style={{ borderBottom: "1px solid #efefef", flexShrink: 0 }}>
                        <div className="d-flex align-items-center">
                            <AlertTriangle color={"#fff"} size={15} className="mr-1" />
                            <p className="m-0" style={{ fontWeight: "500", fontSize: "13px" }}>Alerts</p>
                        </div>
                        <button className="btn btn-danger" id="closeSlider" style={{ padding: "5px", fontSize: "10px" }} onClick={() => { setIsSliderOpen(false) }}>✖</button>
                    </div>
                    <div className="scrollable-container p-3" style={{ flexGrow: 1, overflowY: 'auto' }}>
                        {/* <button className="btn btn-danger close-btn" id="closeSlider" onClick={() => {setIsSliderOpen(false)}}>×</button> */}

                        {alertArray.map((each) => {
                            return (
                                <div className="bg-light p-2 mb-3" title="Click to watch the video" onClick={() => { onClickingAlert(each); }} style={{ cursor: "pointer", borderRadius: "5px" }}>
                                    <p className="m-0" style={{ fontWeight: "500", fontSize: "13px", color: "#000" }}>{each.alert_type}</p>
                                    <p className="m-0" style={{ fontWeight: "500", fontSize: "11px", color: "#000" }}>Timestamp: {convertSecondsToHMS(parseInt(each.alert_time))}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>

                {alertObject &&
                    <div className="position-absolute top-0 bg-danger p-2 d-flex align-items-center" style={{ width: "100%", zIndex: "1003" }}>
                        <AlertTriangle size={25} color={"#fff"} className="mr-1" />
                        <p className="m-0" style={{ fontWeight: "500", fontSize: "15px", color: "#fff" }}>{alertObject.alert_type}</p>
                    </div>
                }
            </div>
        </div>
    );
};
const AllProgressBarWithLiveVideo = ({ name, alertArray, durationInSeconds, videosData, truckPlay, startDate, onTrackPlaying, getLiveData, liveVideo, onChangeDuration }) => {
    // console.log(videosData, "startDate")
    const [elapsedTime, setElapsedTime] = useState(0);
    const [isComplete, setIsComplete] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(Object.keys(videosData).reduce((acc, key) => {
        acc[key] = null;
        return acc;
    }, {}));
    const [videoError, setVideoError] = useState(Object.keys(videosData).reduce((acc, key) => {
        acc[key] = false;
        return acc;
    }, {}));
    const [videoSrc, setVideoSrc] = useState(Object.keys(videosData).reduce((acc, key) => {
        acc[key] = videosData[key][0].s3_link;
        return acc;
    }, {})); // Track video source URL
    useEffect(() => {
        // console.log("123456789")
    }, [])
    const videoRef = useRef({});
    const [isSeeking, setIsSeeking] = useState(false);
    const [isPlaying, setIsPlaying] = useState(true); // ✅ Track Play/Pause
    const intervalRef = useRef(null); // ✅ Track progress update interval
    const [isSliderOpen, setIsSliderOpen] = useState(false)
    const [alertObject, setAlertObject] = useState(null);
    const [hoverPlayer, setHoverPlayer] = useState(true);
    const [playbackRate, setPlaybackRate] = useState(1);
    const [prevPlaybackRate, setPrevPlaybackRate] = useState(1);
    const [checkMute, setCheckMute] = useState(true)
    useEffect(() => {
        // console.log(elapsedTime, "1000000000000000000000000000000000000000000000000000")
        if (isPlaying) {
            const intervalId = setInterval(() => {
                // onChangeDuration(elapsedTime + 1)
                setElapsedTime((prev) => prev + 1);
            }, 1000 / playbackRate);
            // console.log(elapsedTime, "elapsedTime")
            return () => clearInterval(intervalId);
        }
    }, [elapsedTime, durationInSeconds, isPlaying]);
    useEffect(() => {
        // onTrackPlaying(isPlaying)
    }, [isPlaying])

    useEffect(() => {
        console.log("hui", durationInSeconds)
        if (elapsedTime >= durationInSeconds) {
            console.log("huiti", elapsedTime, durationInSeconds)
            setTimeout(() => {
                requestAnimationFrame(() => {
                    Object.keys(videosData).forEach((each) => {
                        const video = videoRef.current[each];
                        if (video) {
                            video.pause();
                            video.currentTime = 0;
                            video.src = "";
                            video.load();
                        }
                    });
                    setElapsedTime(0); // ✅ Reset time when reaching duration
                    setIsComplete(true);
                    setIsPlaying(false);
                    var updatedVideoErrors = Object.keys(videosData).reduce((acc, key) => {
                        acc[key] = false;
                        return acc;
                    }, {})
                    setTimeout(() => {
                        setVideoError(updatedVideoErrors);
                    }, 100);
                });
            }, 0)
            getLiveData(true)
            // return;
        }

    }, [elapsedTime])

    useEffect(() => {
        // console.log(videosData)
        // setElapsedTime(videosData[videosData.length - 1]?.start_time);
        // setIsPlaying(true);
    }, [])

    useEffect(() => {
        if (elapsedTime == 0) {
            // var updatedVideoErrors = Object.keys(videosData).reduce((acc, key) => {
            //   acc[key] = false;
            //   return acc;
            // }, {})
            // setTimeout(() => {
            //   setVideoError(updatedVideoErrors);
            // }, 100);
        }
        if (playbackRate != prevPlaybackRate) {
            var updatedVideoErrors = Object.keys(videosData).map((each) => {
                videoRef.current[each].playbackRate = playbackRate
            }, {})
            setPrevPlaybackRate(playbackRate)
        }
    }, [elapsedTime, playbackRate])

    const formatTime = (seconds, startDate) => {
        // Parse the startDate (in ISO 8601 format)
        const startTime = new Date(startDate);

        // Add the seconds to the start date
        startTime.setSeconds(startTime.getSeconds() + seconds);

        // Get the updated hours, minutes, and seconds
        const hours = startTime.getHours();
        const minutes = startTime.getMinutes();
        const remainingSeconds = startTime.getSeconds();

        // Return the formatted time in HH:mm:ss format
        return `${hours > 0 ? (hours < 10 ? "0" + hours : hours) + ":" : ""}${minutes < 10 ? "0" + minutes : minutes
            }:${remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds}`;
    };

    // useEffect(() => {
    //   Object.keys(videosData).forEach((each) => {
    //     const videoElement = videoRef.current[each];
    //     // console.log(videoElement)
    //     if (videoElement && videoSrc[each] && videoSrc[each] !== "None" && videoSrc[each] !== "" && videoSrc[each] !== null) {
    //       // Ensure video source is set before playing
    //       if (videoElement && videoSrc[each] !== null) {
    //         // console.log("huiyui")
    //         videoElement.src = videoSrc[each];
    //         videoElement.load(); // Ensure it loads before playing
    //         videoElement.play();
    //       }
    //     } else {
    //       setVideoError((prev) => ({ ...prev, [each]: true }));
    //     }
    //   })

    // }, [])
    useEffect(() => {
        console.log(localStorage.getItem("videodata"), JSON.stringify(videosData), localStorage.getItem("videodata") == JSON.stringify(videosData))
        var localObject = JSON.parse(localStorage.getItem("videodata"))
        var check = false
        if (localObject) {
            Object.keys(videosData).map((each) => {
                if (videosData[each][0].s3_link == localObject[each][0]?.s3_link) {
                    check = true
                }
            })
        }
        if (check) {
            requestAnimationFrame(() => {
                Object.keys(videosData).forEach((each) => {
                    const video = videoRef.current[each];
                    if (video) {
                        video.pause();
                        video.currentTime = 0;
                        video.src = "";
                        video.load();
                    }
                });
                setElapsedTime(0); // ✅ Reset time when reaching duration
                setIsComplete(true);
                setIsPlaying(true);
                var updatedVideoErrors = Object.keys(videosData).reduce((acc, key) => {
                    acc[key] = true;
                    return acc;
                }, {})
                setTimeout(() => {
                    setVideoError(updatedVideoErrors);
                }, 0);
            });
        } else {
            requestAnimationFrame(() => {
                localStorage.setItem("videodata", JSON.stringify(videosData))
                Object.keys(videosData).forEach((each) => {
                    const videoElement = videoRef.current[each];

                    // Ensure the video element exists and the source is valid
                    if (videoElement && videoSrc[each] && videoSrc[each] !== "None" && videoSrc[each] !== "" && videoSrc[each] !== null) {

                        // Set video source and load it
                        videoElement.src = videoSrc[each];
                        videoElement.load(); // Ensure it loads before playing

                        // Check if the video's duration is greater than the specified durationInSeconds
                        videoElement.onloadedmetadata = () => {
                            const videoDuration = videoElement.duration; // Get the video's duration in seconds

                            // If the video duration is greater than the specified durationInSeconds, seek to that time
                            if (videoDuration > durationInSeconds) {
                                videoElement.currentTime = videoDuration - durationInSeconds; // Seek to the time specified
                            }

                            // Play the video after loading and seeking
                            videoElement.play();
                        };

                    } else {
                        // If there is an error with the video, set the videoError state
                        setVideoError((prev) => ({ ...prev, [each]: true }));
                    }
                });
            })
        }
    }, []); // Add dependencies to re-run the effect when these change


    useEffect(() => {
        // Function to check if any video is paused
        // Object.keys(videosData).forEach((each) => {
        //   const video = videoRef.current[each];

        //   // Check if video exists, is a valid element, has a valid source, and is paused
        //   if (video && video instanceof HTMLVideoElement && videoSrc[each] && video.paused) {
        //     try {
        //       // Ensure the video has a valid source before attempting to play it
        //       if (!video.src && !video.currentSrc) {
        //         console.error(`No valid source found for video ${each}`);
        //         return; // Skip if no valid source is available
        //       }

        //       // Check if the video can be played (readyState 4 means video is ready)
        //       if (video.readyState >= 4) {
        //         // Play the video, suppressing the error if interrupted
        //         const playPromise = video.play();

        //         if (playPromise !== undefined) {
        //           playPromise
        //             .catch((error) => {
        //               // Ignore the "play interrupted by pause" error
        //               if (error.name !== 'AbortError') {
        //                 console.error('Error playing video:', error);
        //               }
        //             });
        //         }
        //       } else {
        //         // If the video is not ready, add an event listener to play once it's ready
        //         video.addEventListener('canplay', () => {
        //           video.play();
        //         });
        //       }
        //     } catch (error) {
        //       console.error("Error playing video:", error); // Log any error
        //     }
        //   }
        // });
    }, [elapsedTime]); // Effect depends on elapsedTime


    const togglePlayPause = () => {
        setIsPlaying((prev) => {
            const newIsPlaying = !prev;

            // Using requestAnimationFrame for simultaneous execution
            if (elapsedTime != 0) {
                requestAnimationFrame(() => {
                    // Create an array of promises that correspond to each video play/pause action
                    const playPromises = Object.keys(videosData).map((each) => {
                        const video = videoRef.current[each];
                        if (video) {
                            if (newIsPlaying) {
                                return video.play().catch(() => {
                                    // setVideoError((prev) => ({ ...prev, [each]: true }));
                                    return false; // Return false to prevent rejection in Promise.all
                                });
                            } else {
                                video.pause();
                                return Promise.resolve(true); // Resolve as true for pause action
                            }
                        }
                        return Promise.resolve(true); // Resolve if no video found
                    });

                    // Wait for all promises to resolve and update the state accordingly
                    Promise.all(playPromises).then((results) => {
                        if (results.some((res) => res)) {
                            // console.log("At least one video started playing, updating isPlaying state");
                            // setIsPlaying(newIsPlaying); // Update the state if at least one video played
                        } else {
                            // console.warn("No videos started playing");
                        }
                    });
                });
            }
            return newIsPlaying; // This will set the state to the new value
        });
    };

    const onClickingAlert = (each) => {
        // console.log("huawei-------------------------------------------")
        const alertTime = each.alert_time; // ✅ Get the clicked alert time
        setElapsedTime(parseInt(alertTime)); // ✅ Update progress bar
        setIsSeeking(true);  // ✅ Mark as seeking (prevents auto updates interfering)
        setIsPlaying(true);  // ✅ Ensure video starts playing
        setIsSliderOpen(false); // ✅ Close alerts panel
        setAlertObject(each)
        setTimeout(() => {
            setAlertObject(null)
            // onCancelAlert(null)
        }, 3000)
    };
    const timeoutRef = useRef(null);
    const [timeoutCheck, setTimeoutCheck] = useState(false);
    const handleMouseEnter = () => {
        setHoverPlayer(true);
        setTimeoutCheck(true);
        clearTimeout(timeoutRef.current);
        // console.log("---------------------------------------------------------------------")
    };

    const handleMouseLeave = () => {
        setHoverPlayer(false);
        setTimeoutCheck(false);
        clearTimeout(timeoutRef.current);
    };

    const sliderBackground = `linear-gradient(to right, rgb(94, 176, 228) ${(elapsedTime / durationInSeconds) * 100
        }%, #fff ${(elapsedTime / durationInSeconds) * 100}%)`;

    const convertSecondsToHMS = (seconds, startDate) => {
        // Parse the startDate (in ISO 8601 format)
        const startTime = new Date(startDate);

        // Add the seconds to the start date
        startTime.setSeconds(startTime.getSeconds() + seconds);
        // console.log(startTime)
        // Extract hours, minutes, and seconds
        let hours = startTime.getHours();
        let minutes = startTime.getMinutes();
        let secs = startTime.getSeconds();

        // Return the time formatted as HH:mm:ss
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    }
    useEffect(() => {
        requestAnimationFrame(async () => {
            var playPromises = [];
            playPromises = Object.keys(videosData).map((each) => {
                const video = videoRef.current[each];
                video.muted = !checkMute
            });
        })
        // setCheckMute((prev) => !prev)
    }, [checkMute])
    const onClickingLive = () => {
        getLiveData(false)
    }
    // console.log(elapsedTime, videoError, videoSrc, "elapsedTime")
    return (
        <div className="scrollable-container" style={{ width: "100%", height: "100%", overflowX: "hidden", position: "relative", overflowY: "auto" }}>
            <div
                className="d-flex flex-column"
                style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    background: "#f9f9f9", // Force black background when no video
                    borderRadius: "5px",
                    // overflowX: "hidden"
                }}
            >

                {/* Video Player with Black Fallback */}
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                        flexGrow: 1,
                        padding: "13px 13px 0px 13px"
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    // onMouseMove={() => {setTimeoutCheck(true)}}
                    className="d-flex flex-wrap justify-content-between"
                >
                    {(!isPlaying || hoverPlayer || timeoutCheck) && <div className="d-flex justify-content-center align-items-center" style={{ position: "absolute", width: "100%", height: "100%", top: "0%", left: "0%", backgroundColor: "rgba(239, 239, 239, 0.3)", zIndex: "10001" }}>
                        <div className="bg-light d-flex justify-content-center align-items-center" onClick={togglePlayPause} style={{ width: "75px", height: "75px", borderRadius: "50%", border: "3px solid blue", zIndex: "100003" }}>
                            {isPlaying ? <Pause size={27} color={"blue"} /> : <Play size={27} color={"blue"} />}
                        </div>
                    </div>}
                    {Object.keys(videosData).length > 0 && Object.keys(videosData).map((each, index) => {
                        return (
                            <div className="d-flex flex-column" style={{ width: "49%", height: "47%", borderRadius: "10px", overflow: "hidden", position: "relative" }}>
                                <video
                                    // ref={videoRef[each]}
                                    key={`New Key-${index}`}
                                    ref={(el) => (videoRef.current[each] = el)}
                                    width="100%"
                                    // height="100px"
                                    controls={false}
                                    preload="auto" // Auto-preload video for smoother playback
                                    style={{
                                        background: videoError[each] ? "#000" : "#000",
                                        flexGrow: 1,
                                        // position: "relative"
                                    }}
                                    autoplay
                                >
                                    {videoSrc ? (
                                        <source src={videoSrc[each]} type="video/mp4" />
                                    ) : (
                                        <source
                                            src="data:video/mp4;base64,AAAA" // Empty black video
                                            type="video/mp4"
                                        />
                                    )}
                                    Your browser does not support the video tag.
                                </video>
                                <div className="d-flex" style={{ position: "absolute", bottom: "3%", left: index % 2 == 0 && "3%", right: index % 2 == 1 && "3%" }}>
                                    <div className="d-flex align-items-center" style={{ backgroundColor: '#efefef', padding: "5px 15px 5px 15px", color: "blue", borderRadius: "5px" }}>
                                        <HiVideoCamera className="mr-1" />
                                        <p className="m-0" style={{ fontWeight: "500", fontSize: "13px" }}>{each}</p>
                                    </div>
                                </div>
                                {videoError[each] && (
                                    <div
                                        className="m-0 text-danger d-flex justify-content-center pl-3 pr-3"
                                        style={{
                                            position: "absolute",
                                            width: "100%",
                                            bottom: "31%",
                                            // color: "#fff",
                                            fontSize: "13px",
                                            fontWeight: "500"
                                        }}
                                    >
                                        <div className="bg-light p-3 d-flex flex-column align-items-center" style={{ maxWidth: "100%", borderRadius: "5px",backgroundColor: "" ,  color: "red" }}>
                                            <AlertTriangle size={15} />
                                            <p className="m-0 mt-1" style={{ fontSize: "11px" }}>Video not available at this moment.</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>

                {/* Progress Bar */}

                <div
                    style={{
                        // position: "absolute",
                        width: "100%",
                        // bottom: "5px",
                        zIndex: "100001",
                        cursor: "pointer",
                        flexShrink: 0,
                        borderTop: "1px solid #efefef"
                    }}
                    className="p-2 pl-2 pr-2 d-flex flex-column"
                >
                    <div className="d-flex justify-content-between align-items-center pl-0">
                        <div className="d-flex align-items-center">
                            <div
                                onClick={togglePlayPause}
                                style={{
                                    cursor: "pointer",
                                    zIndex: "100001",
                                    padding: "0px",
                                    borderRadius: "1px solid #efefef"
                                }}
                                className="mr-1 mb-1"
                            >
                                {isPlaying ? <Pause size={23} /> : <Play size={23} />}
                            </div>
                            <p className="m-0 pl-2 d-flex align-items-center" style={{ color: "blue", fontWeight: "bold" }}>
                                <div style={{ width: "11px", height: "11px", borderRadius: "50%", backgroundColor: "blue" }}></div>
                                &nbsp;Live</p>
                        </div>
                        <div className="d-flex align-items-center mr-1 mb-1">
                            <div className="ml-1 mr-2">
                                <button className="btn btn-primary" style={{ fontSize: "11px" }} onClick={() => { onClickingLive() }}><HiVideoCamera size={13} /> Back to Recordings </button>
                            </div>
                            <div className="ml-1 mr-2" onClick={() => { setIsSliderOpen(true) }}>
                                <button className="btn btn-danger" style={{ fontSize: "11px" }}><AlertTriangle size={13} /> Alerts</button>
                            </div>
                            <div title={checkMute ? "Mute" : "Unmute"} className="ml-1 mr-1" onClick={() => { setCheckMute((prev) => !prev) }}>
                                {checkMute ? <VolumeIcon size={23} /> : <VolumeOffIcon size={23} />}
                            </div>
                            <div title={"Playback Rate"} className="ml-1 mr-1">
                                <img src={"https://cdn-icons-png.flaticon.com/128/17055/17055362.png"} style={{ width: "23px", height: "23px" }} />
                            </div>
                        </div>
                    </div>
                    {/* <input
              id="progress-slider"
              type="range"
              min="0"
              max="100"
              step="1"
              value={100}
              onChange={(e) => {
                // console.log((e.target.value / 100) * durationInSeconds)
                // setIsSeeking(true)
                // setElapsedTime(parseInt((e.target.value / 100) * durationInSeconds));
                // onChangeDuration(parseInt((e.target.value / 100) * durationInSeconds))
              }}
              // disabled={!liveVideo}
              style={{
                background: sliderBackground,
                width: "100%",
                position: "relative"
              }}
            /> */}
                </div>

                {/* <!-- Sliding Panel --> */}
                <div className={`slider-panel ${isSliderOpen ? "open" : ""} d-flex flex-column`} id="sliderPanel">
                    <div className="d-flex justify-content-between align-items-center p-2" style={{ borderBottom: "1px solid #efefef", flexShrink: 0 }}>
                        <div className="d-flex align-items-center">
                            <AlertTriangle color={"#fff"} size={15} className="mr-1" />
                            <p className="m-0" style={{ fontWeight: "500", fontSize: "13px" }}>Alerts</p>
                        </div>
                        <button className="btn btn-danger" id="closeSlider" style={{ padding: "5px", fontSize: "10px" }} onClick={() => { setIsSliderOpen(false) }}>✖</button>
                    </div>
                    <div className="scrollable-container p-3" style={{ flexGrow: 1, overflowY: 'auto' }}>
                        {/* <button className="btn btn-danger close-btn" id="closeSlider" onClick={() => {setIsSliderOpen(false)}}>×</button> */}

                        {alertArray.map((each) => {
                            return (
                                <div className="bg-light p-2 mb-3" title="Click to watch the video" onClick={() => { onClickingAlert(each); }} style={{ cursor: "pointer", borderRadius: "5px" }}>
                                    <p className="m-0" style={{ fontWeight: "500", fontSize: "13px", color: "#000" }}>{each.alert_type}</p>
                                    <p className="m-0" style={{ fontWeight: "500", fontSize: "11px", color: "#000" }}>Timestamp: {convertSecondsToHMS(parseInt(each.alert_time))}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>

                {alertObject &&
                    <div className="position-absolute top-0 bg-danger p-2 d-flex align-items-center" style={{ width: "100%", zIndex: "1003" }}>
                        <AlertTriangle size={25} color={"#fff"} className="mr-1" />
                        <p className="m-0" style={{ fontWeight: "500", fontSize: "15px", color: "#fff" }}>{alertObject.alert_type}</p>
                    </div>
                }
            </div>
        </div>
    );
};
const ProgressBarWithLiveVideo = ({ name, alertArray, durationInSeconds, videosData, truckPlay, onTrackPlaying, getLiveData, liveVideo, onChangeDuration }) => {
    const [elapsedTime, setElapsedTime] = useState(0);
    const [isComplete, setIsComplete] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(null);
    const [videoError, setVideoError] = useState(false);
    const [videoSrc, setVideoSrc] = useState(""); // Track video source URL
    const videoRef = useRef(null);
    const [isSeeking, setIsSeeking] = useState(false);
    const [isPlaying, setIsPlaying] = useState(true); // ✅ Track Play/Pause
    const intervalRef = useRef(null); // ✅ Track progress update interval
    const [isSliderOpen, setIsSliderOpen] = useState(false)
    const [alertObject, setAlertObject] = useState(null);
    const [hoverPlayer, setHoverPlayer] = useState(true);
    console.log(videosData, durationInSeconds, "videosData")
    useEffect(() => {
        if (elapsedTime >= durationInSeconds) {
            const video = videoRef.current;
            if (video) {
                video.pause();
                video.currentTime = 0;
                video.src = "";
                video.load();
            }
            setElapsedTime(0); // ✅ Reset time when reaching duration
            setIsComplete(true);
            // setIsPlaying(false);
            setVideoError(true);
            getLiveData(true);
            return;
        }
        if (isPlaying) {
            const intervalId = setInterval(() => {
                onChangeDuration(elapsedTime + 1)
                setElapsedTime((prev) => prev + 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [elapsedTime, durationInSeconds, isPlaying]);

    // useEffect(() => {
    //   setElapsedTime(parseInt(videosData[videosData.length - 1].start_time));
    //   setIsPlaying(true);
    // }, [])
    useEffect(() => {
        // if (elapsedTime == 0) {
        //   setTimeout(() => {
        //     setVideoError(false);
        //   }, 100);
        // }
    }, [elapsedTime])

    // Function to format seconds into HH:MM:SS
    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${hours > 0 ? (hours < 10 ? "0" + hours : hours) + ":" : ""}${minutes < 10 ? "0" + minutes : minutes
            }:${remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds}`;
    };

    useEffect(() => {
        onTrackPlaying(isPlaying)
    }, [isPlaying])

    useEffect(() => {
        console.log(localStorage.getItem("videodata"), JSON.stringify(videosData), localStorage.getItem("videodata") == JSON.stringify(videosData))
        var localObject = JSON.parse(localStorage.getItem("videodata"))
        var check = false
        if (localObject) {
            // Object.keys(videosData).map((each) => {
            if (videosData[0].s3_link == localObject[0]?.s3_link) {
                check = true
            }
            // })
        }
        if (check) {
            const video = videoRef.current;
            if (video) {
                video.pause();
                video.currentTime = 0;
                video.src = "";
                video.load();
            }
            setElapsedTime(0); // ✅ Reset time when reaching duration
            setIsComplete(true);
            setIsPlaying(true);
            setTimeout(() => {
                setVideoError(true);
            }, 0);
        } else {
            localStorage.setItem("videodata", JSON.stringify(videosData))
            console.log(videosData[0].s3_link)
            setIsPlaying(true);
            setVideoError(false);
            const videoElement = videoRef.current;

            // Ensure the video element exists and the source is valid
            // if (videoElement && videoSrc && videoSrc !== "None" && videoSrc !== "" && videoSrc !== null) {

            // Set video source and load it
            videoElement.src = videosData[0].s3_link;
            videoElement.load(); // Ensure it loads before playing

            // Check if the video's duration is greater than the specified durationInSeconds
            videoElement.onloadedmetadata = () => {
                const videoDuration = videoElement.duration; // Get the video's duration in seconds

                // If the video duration is greater than the specified durationInSeconds, seek to that time
                if (videoDuration > durationInSeconds) {
                    videoElement.currentTime = videoDuration - durationInSeconds; // Seek to the time specified
                }

                // Play the video after loading and seeking
                videoElement.play();
            };

            // } else {
            //   // If there is an error with the video, set the videoError state
            //   setVideoError(true);
            // }
        }
    }, []); // Add dependencies to re-run the effect when these change



    useEffect(() => {
        // if (isSeeking) {
        console.log("tyuioi")
        if (isPlaying) {
            // handleVideoPlayback();
        }
        // }
    }, [elapsedTime]);

    // ✅ Play/Pause Button Functionality
    const togglePlayPause = () => {
        setIsPlaying((prev) => !prev);
        if (isPlaying) {
            console.log("ai")
            videoRef.current.pause();
        } else {
            videoRef.current.play().catch(() => setVideoError(true));
        }
    };

    // useEffect(() => {
    //   if (clickAlert != null) {
    //     onClickingAlert(clickAlert)
    //   }
    // }, [clickAlert])

    const onClickingAlert = (each) => {
        console.log("huawei-------------------------------------------")
        const alertTime = each.alert_time; // ✅ Get the clicked alert time
        setElapsedTime(parseInt(alertTime)); // ✅ Update progress bar
        setIsSeeking(true);  // ✅ Mark as seeking (prevents auto updates interfering)
        setIsPlaying(true);  // ✅ Ensure video starts playing
        setIsSliderOpen(false); // ✅ Close alerts panel
        setAlertObject(each)
        setTimeout(() => {
            setAlertObject(null)
            // onCancelAlert(null)
        }, 3000)
    };
    const timeoutRef = useRef(null);
    const [timeoutCheck, setTimeoutCheck] = useState(false);
    const handleMouseEnter = () => {
        setHoverPlayer(true);
        setTimeoutCheck(true);
        // clearTimeout(timeoutRef.current);
        console.log("---------------------------------------------------------------------")
    };

    const handleMouseLeave = () => {
        setHoverPlayer(false);
        setTimeoutCheck(false);
        // clearTimeout(timeoutRef.current);
    };

    const sliderBackground = `linear-gradient(to right, rgb(94, 176, 228) ${(elapsedTime / durationInSeconds) * 100
        }%, #ffffff34 ${(elapsedTime / durationInSeconds) * 100}%)`;
    const convertSecondsToHMS = (seconds) => {
        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds % 3600) / 60);
        let secs = seconds % 60;

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    }
    const onClickingLive = () => {
        getLiveData(false)
    }
    console.log(elapsedTime, "elapsedTime")
    return (
        <div className="d-flex flex-column p-1" style={{ width: "100%", height: "100%" }}>
            <div
                className="mt-1 d-flex flex-column"
                style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    background: "#efefef", // Force black background when no video
                    borderRadius: "5px",
                    // overflow: "hidden",
                    // maxHeight: "300px"
                    overflow: "hidden",
                    borderRadius: "10px"
                }}
            >

                {/* Video Player with Black Fallback */}
                <div
                    className="d-flex flex-column"
                    style={{
                        position: "relative",
                        width: "100%",
                        flexGrow: 1,
                        // height: "90%",
                        // background: "#000", // Black background for fallback
                        overflow: "hidden"
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className="bg-primary d-flex flex-column" style={{ width: "100%", height: "100%" }}>
                        {(!isPlaying || hoverPlayer || timeoutCheck) && <div className="d-flex justify-content-center align-items-center" style={{ position: "absolute", width: "100%", height: "100%", top: "0%", left: "0%", backgroundColor: "rgba(192, 187, 187, 0.3)", zIndex: "10001" }}>
                            <div className="d-flex flex-column justify-content-center align-items-center" onClick={togglePlayPause} style={{ width: "75px", height: "75px", backgroundColor: "#efefef", cursor: "pointer", border: "3px solid blue", borderRadius: "50%", zIndex: "100003" }}>
                                {isPlaying ? <Pause size={27} color={"blue"} /> : <Play size={27} color={"blue"} />}
                                {/* <p className="m-0" style={{color: "blue"}}>Play</p> */}
                                {/* <button className="btn btn-primary" onClick={togglePlayPause}>{isPlaying ? "Pause" : "Play"}</button> */}
                            </div>
                        </div>}
                        <video
                            ref={videoRef}
                            width="100%"
                            // height="100%"
                            controls={false}
                            style={{
                                background: videoError ? "#000" : "#000",
                                aspectRatio: "16/9", /* Force aspect ratio */
                                objectFit: "contain",
                                flexGrow: 1
                            }}
                        >
                            {videoSrc ? (
                                <source src={videoSrc} type="video/mp4" />
                            ) : (
                                <source
                                    src="data:video/mp4;base64,AAAA" // Empty black video
                                    type="video/mp4"
                                />
                            )}
                            Your browser does not support the video tag.
                        </video>
                        <div className="d-flex" style={{ position: "absolute", top: "3%", right: "3%" }}>
                            <div className="d-flex align-items-center" style={{ backgroundColor: '#efefef', padding: "5px 15px 5px 15px", color: "blue", borderRadius: "5px" }}>
                                <HiVideoCamera className="mr-1" />
                                <p className="m-0" style={{ fontWeight: "500", fontSize: "15px" }}>{name}</p>
                            </div>
                        </div>
                        {videoError && (
                            <div
                                className="m-0 text-danger d-flex justify-content-center pl-3 pr-3"
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    bottom: "41%",
                                    // color: "#fff",
                                    fontSize: "13px",
                                    fontWeight: "500"
                                }}
                            >
                                <div className="bg-light p-3 d-flex flex-column align-items-center" style={{ maxWidth: "100%", borderRadius: "5px" ,backgroundColor: "" ,  color: "red"}}>
                                    <AlertTriangle />
                                    <p className="m-0 mt-1">Video not available at this moment.</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Progress Bar */}

                <div
                    style={{
                        // position: "absolute",
                        width: "100%",
                        // bottom: "5px",
                        zIndex: "100001",
                        cursor: "pointer",
                        flexShrink: 0,
                        borderTop: "1px solid #efefef"
                    }}
                    className="p-2 pl-2 pr-2 d-flex flex-column"
                >
                    <div className="d-flex justify-content-between align-items-center pl-0">
                        <div className="d-flex align-items-center">
                            <div
                                onClick={togglePlayPause}
                                style={{
                                    cursor: "pointer",
                                    zIndex: "100001",
                                    padding: "0px",
                                    borderRadius: "1px solid #efefef"
                                }}
                                className="mr-1 mb-1"
                            >
                                {isPlaying ? <Pause size={23} /> : <Play size={23} />}
                            </div>
                            <p className="m-0 pl-2 d-flex align-items-center" style={{ color: "blue", fontWeight: "bold" }}>
                                <div style={{ width: "11px", height: "11px", borderRadius: "50%", backgroundColor: "blue" }}></div>
                                &nbsp;Live</p>
                        </div>
                        <div className="d-flex align-items-center mr-1">
                            <div className="ml-1 mr-2">
                                <button className="btn btn-primary" style={{ fontSize: "11px" }} onClick={() => { onClickingLive() }}><HiVideoCamera size={13} /> Back to Recordings </button>
                            </div>
                            <div className="ml-1 mr-2" onClick={() => { setIsSliderOpen(true) }}>
                                <button className="btn btn-danger" style={{ fontSize: "11px" }}><AlertTriangle size={13} /> Alerts</button>
                            </div>
                            <div title={"Mute"} className="ml-1 mr-1">
                                <VolumeIcon size={23} />
                            </div>
                            <div title={"Playback Rate"} className="ml-1 mr-1">
                                <img src={"https://cdn-icons-png.flaticon.com/128/17055/17055362.png"} style={{ width: "23px", height: "23px" }} />
                            </div>
                        </div>
                    </div>
                    {/* <input
              id="progress-slider"
              type="range"
              min="0"
              max="100"
              step="1"
              value={(elapsedTime / durationInSeconds) * 100}
              onChange={(e) => {
                // console.log((e.target.value / 100) * durationInSeconds)
                setElapsedTime(parseInt((e.target.value / 100) * durationInSeconds));
                setIsSeeking(true)
                onChangeDuration(parseInt((e.target.value / 100) * durationInSeconds))
              }}
              style={{
                background: sliderBackground,
                width: "100%",
                position: "relative"
              }}
            /> */}
                </div>


                {/* <!-- Sliding Panel --> */}
                <div className={`slider-panel ${isSliderOpen && "open d-block"} d-flex flex-column`} id="sliderPanel">
                    <div className="d-flex justify-content-between align-items-center p-2" style={{ borderBottom: "1px solid #efefef", flexShrink: 0 }}>
                        <div className="d-flex align-items-center">
                            <AlertTriangle color={"#fff"} size={15} className="mr-1" />
                            <p className="m-0" style={{ fontWeight: "500", fontSize: "13px" }}>Alerts</p>
                        </div>
                        <button className="btn btn-danger" id="closeSlider" style={{ padding: "5px", fontSize: "10px" }} onClick={() => { setIsSliderOpen(false) }}>✖</button>
                    </div>
                    <div className="scrollable-container p-3" style={{ flexGrow: 1, overflowY: 'auto' }}>
                        {/* <button className="btn btn-danger close-btn" id="closeSlider" onClick={() => {setIsSliderOpen(false)}}>×</button> */}

                        {alertArray.map((each) => {
                            return (
                                <div className="bg-light p-2 mb-3" title="Click to watch the video" onClick={() => { onClickingAlert(each); }} style={{ cursor: "pointer", borderRadius: "5px" }}>
                                    <p className="m-0" style={{ fontWeight: "500", fontSize: "13px", color: "#000" }}>{each.alert_type}</p>
                                    <p className="m-0" style={{ fontWeight: "500", fontSize: "11px", color: "#000" }}>Timestamp: {convertSecondsToHMS(parseInt(each.alert_time))}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>

                {alertObject &&
                    <div className="position-absolute top-0 bg-danger p-2 d-flex align-items-center" style={{ width: "100%", zIndex: "1003" }}>
                        <AlertTriangle size={25} color={"#fff"} className="mr-1" />
                        <p className="m-0" style={{ fontWeight: "500", fontSize: "15px", color: "#fff" }}>{alertObject.alert_type}</p>
                    </div>
                }
            </div>
        </div>
    );
};