import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Route from 'react-router-dom/Route'
import { BrowserRouter, Switch } from 'react-router-dom'

import { ScrollContext } from 'react-router-scroll-4';
// import './index.scss';


// ** Import custom components **
import store from './store';
import App from './components/app';

import Login from './components/login/login'
import EnmUserLogin from './components/login/enmuserlogin'
import Logout from './components/login/logout'
import Comingsoon  from './components/pages/comingsoon'
import TptGeoFence from './components/tptlogin/tpt-geofence-list'
import AddTptGeoFence from './components/tptlogin/add-tpt-geofence'
import EditTptGeoFence from './components/tptlogin/edit-tpt-geofence'
import ManageNearByTransporterTrucks from './components/tptlogin/transporter-nearby-trucks'
import TptTrucksGPSData from './components/tptlogin/tpttrucksgpsdataComponent';
import Maintenance  from './components/pages/maintenance';
import ManageUserMailMap from  './components/manage/usermailmap';
import ResetPassword from './components/login/reset-password';
import AndriodMap from './components/common/andriodmap';
import Error404 from './components/pages/error-404';
import TptEmptyTrucksVicinity from "./components/tptlogin/tptemptytrucksvicinity";
import TptRegisteredDevices from "./components/tptlogin/tptregisteredDevices";
import TptPendingInstallations from "./components/tptlogin/tptpendinginstallations";
// import tptGpsIntegration from "./components/tptlogin/tptGpsIntegration";
import tptGpsIntegration from "./components/tptlogin/tptintegrationwithtabsComponent";
import TptReportOverspeed from "./components/tptlogin/overspeed";
import TPTGpsEodAnalysis from "./components/tptlogin/tptgpseodanalysisComponent";
import TPTGPSBilling from "./components/tptlogin/tptgpsbillingComponent";
import TPTGPSBillingNonMSIL from "./components/tptlogin/tptgpsbillingnonmsilComponent";
import TPTSubUsers from "./components/tptlogin/tptsubusers";
import TPTGPSBillingDashboard from "./components/tptlogin/tptgpsbillingdashboardComponent";
import TPTViewGPSBillAction from "./components/tptlogin/tptviewgpsbilling";
import TPTGPSNonMSILBillingDashboard from "./components/tptlogin/tptgpsbillingdashboardnonmsil";
import TPTViewGPSNonMSILBillAction from "./components/tptlogin/tptviewgpsbillingnonmsil";
import AndriodGridMap from './components/common/andriodgridmap';
import TPTTrucksDistanceMatrix from './components/tptlogin/tpttrucksdistancematrixComponent';
import VehicleRegister from './components/tptlogin/tptvehicleregisterComponent';
import TPTTrucks from "./components/tptlogin/tpttrucksComponent";
import TPTCarrier from "./components/tptlogin/tptcarrierComponent";
import TPTGroup from "./components/tptlogin/tptgroupComponent";
import TPTGeofenceIncursion from "./components/tptlogin/tptgeofenceincurssionsComponent";
import TPTDrivers from "./components/tptlogin/tptdriversComponent";
import TPTNightDriving from "./components/tptlogin/tptnightdrivingComponent";
import TPTRailSummary from "./components/tptlogin/tptrailsummaryComponent";

import TPTConsignments from "./components/tptlogin/tptrailconsignmentsComponent";
import TPTCreateBatch from "./components/tptlogin/tptcreatebatchComponent";
import TPTMarkArrivalLoadingYard from "./components/tptlogin/tptmarkarrivalloadingyardComponent";
import TPTLoadingYardArrival from "./components/tptlogin/tptloadingyardarrivalComponent";
import TPTAddWagonCheck from "./components/tptlogin/tptaddwagonchecksheetComponent";
import TPTAddTrainLoadCheck from "./components/tptlogin/tptaddtrainloadingchecksheetComponent";
import TPTAddTrainUnLoadCheck from "./components/tptlogin/tptaddtrainunloadingchecksheetComponent";
import TPTLastMileBatch from "./components/tptlogin/tptlastmilebatchComponent";
import TPTDevices from "./components/tptlogin/tptdevicesComponent";
import TPTLoadingOfficers from './components/tptlogin/tptloadingofficersComponent';
import TPTTransporters from "./components/tptlogin/tpttransportersComponent";
import TPTOEMMaster from "./components/tptlogin/tptoemmasterComponent";
import TPTOEMLocations from "./components/tptlogin/tptoemlocationComponent";
import TPTOEMDealers from "./components/tptlogin/tptoemdealersComponent";
import TPTOEMTransitTimes from "./components/tptlogin/tptoemtransittimesComponent";
import TPTOEMConsignments from "./components/tptlogin/tptoemconsignmentsComponent";
import TPTHarshBreaking from "./components/tptlogin/tptharshbreakComponent";
import TPTGPSSingleScreen from "./components/tptlogin/tptgpsintegrationsinglescreenComponent";
import TptVicinityScreen from './components/tptlogin/tpttruckwithingeofence';
import TPTGpsEodAnalysisMonthly from "./components/tptlogin/tptgpseodanalysismonthlyComponent";
import TPTVinsConfirmDelivered from "./components/tptlogin/tptrailconsignmentsdeliveredComponent";
import TPTContinuousReport from "./components/tptlogin/tptcontinuousComponent";
import TPTStoppageReport from "./components/tptlogin/tptstoppagereportComponent";
import AndriodTPTRailMap from "./components/common/andriodtptrailmap";
import AndriodMapCurrentLocation from "./components/common/andriodmapcurrentlocation";
import OemApiTab from "./components/tptlogin/oemApiConfig";
import TptAlertEmails from './components/tptlogin/tptalertemails';
import TptEmails from "./components/tptlogin/tptemails";
import HollandTrips from "./components/tptlogin/hollandtrips";
import ChangePasswordComponent from './components/manage/changepassword.component';
import LoadingYardReport from './components/tptlogin/loadingyardreport';
import TafeTrips from './components/tptlogin/tpttafeconsignmentsComponent';
import TruckswithinFuelStations from './components/layouts/truckswithinfuelstations';
import FasttagTrucksScreen from './components/tptlogin/fasttagTrucksScreen';
import FasttagTrucksTranscScreen from './components/tptlogin/fasttagTrucksTranscScreen';
import ManualFastTagTranscEntry from './components/tptlogin/manualfasttagtranscEntry';
import ManualChallanEntry from './components/tptlogin/manualchallanEntry';
import ChallansScreen from './components/tptlogin/challansScreen';
import OverspeedHeatMap from "./components/tptlogin/heatmapview";
import StoppageHeatMap from "./components/tptlogin/heatmapstoppage";
import IVCTrips from "./components/tptlogin/ashokLeyLandJawaTrips";
import TruckStatus from "./components/tptlogin/truckStatus"
import TptHistoricTrips from "./components/tptlogin/tpthistorictripsComponent"
// import TruckStatus from "./components/tptlogin/truckStatus"
import MobileTrucksOnMap from './components/tptlogin/tptmobiletrucksonmapComponent';
import Transshipments from './components/tptlogin/tpttransshipmentsComponent';
import WareHouseTransshipments from './components/tptlogin/warehousetransshipmentComponent';
import TVPTransshipments from './components/tptlogin/TVPtransshipmentComponent';
import OlaTrips from './components/tptlogin/tptolatripsComponent';
import ShareOfBusiness from './components/tptlogin/shareofbusiness';
import NextLoadPlanner from "./components/tptlogin/nextloadplanner";
import TransshipmentReport from './components/tptlogin/transhipmentReport';
// import SobCompleteData from './components/tptlogin/sobcompletedata';
import EwayBillData from './components/tptlogin/ewaybillsdata';
import EwayBillRequests from './components/tptlogin/ewaybillrequests';
import GenerateEwayBill from './components/tptlogin/generatenewewaybill';
import ConsolidateEwayBill from './components/tptlogin/consolidateewaybill';
import ConsolidateEwayBillRequests from './components/tptlogin/consolidateewaybillrequests';
import ExtendEwayBill from './components/tptlogin/extendedewaybill';
import TptDriverLogs from './components/tptlogin/tptdriverLogs';
import stockyardManagementSystemTabsComponent from './components/tptlogin/stockyardManagementSystemTabsComponent';
// import Billing from './components/tptlogin/billing';  // this file made by yeshwant not required in project
import BillingSummary from './components/tptlogin/billingsummary';
import BillingDownload from './components/tptlogin/billingdownload';
import uploadMasterList from './components/tptlogin/uploadmasterlist';
import uploadRemovedList from './components/tptlogin/uploadremovedlist';
import FiatTrips from './components/tptlogin/fiatTripsComponent';
import EwayBillManualEntry from './components/tptlogin/manualEwayBillEntryComponent';
import OtherOemConsignments from './components/tptlogin/otherOemConsignmentsComponent';
import OtherOEMHistoricTripData from './components/tptlogin/otherOEMHistoricTripData';
import FreeWheelingReport from './components/tptlogin/freewheelingreport';
import HCILTripsComponent from './components/tptlogin/hciltripscomponent';
import TptBillingApproval from './components/tptlogin/tptbillingapprovals';
import HistoricTruckStatus from './components/tptlogin/historictruckstatus';
import TptBillingPopup from './components/tptlogin/billingpopup';
import StockyardDashboard from './components/tptlogin/stockyardManagementDashboardComponent';
import stockyardTransportersDashboardForMobile from './components/tptlogin/stockyardManagementDashboardForMobile';
import CourtCasesTrack from './components/tptlogin/tptCourtCasesTrack'
import TicketManagement from './components/tptlogin/ticketmanagement'
import EwayBillConsolidatedReport from './components/tptlogin/ewaybillconsolidatedreport'
import TestTPTTrips from './components/tptlogin/tptTestTrips';
import UserLoginsDetails from './components/tptlogin/ivcUserLoginDetails';
import RakeDeviceMapping from './components/tptlogin/rakedevicemappingcomponent';
import ReportsComponent from './components/tptlogin/reporttabscomponent';
import Notifications from './components/tptlogin/tptnotifications';
import RakeDevices from './components/tptlogin/rakedevices';
import TPTLoginDetails from './components/tptlogin/logindetails';
import CronJobs from './components/tptlogin/tptcronjobs';
import CurrentGeofences from './components/tptlogin/currentgeofences';
import LoadingDashboard from './components/tptlogin/loadingdashboard';
import TicketManagementForStoppages from './components/tptlogin/ticketManagementForStoppages';
import DealerDataComponent from './components/tptlogin/dealersDataComponent';
import EwayBillsOtherPartyData from './components/tptlogin/ewaybillsotherpartydata';
import TruckStatusOneMonthData from './components/tptlogin/truckStatusOneMonthData';
import MobileMap from './components/tptlogin/mobileMapComponent.js'
import MobileDeviceMap from './components/tptlogin/mobileDeviceMap.js'


class Root extends React.Component {

    render() {
        return (
        <Provider store={store}>
            <BrowserRouter basename={'/'}>
                <ScrollContext>
                    <Switch>

                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
                        <Route exact path={`${process.env.PUBLIC_URL}/enmuserlogin/:token`} component={EnmUserLogin} />
                        <Route exact path={`${process.env.PUBLIC_URL}/optirun`} component={Login} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/coming-soon`} component={Comingsoon} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />
                        <Route path={`${process.env.PUBLIC_URL}/registration/activation`} component={Comingsoon} />
                        <Route path={`${process.env.PUBLIC_URL}/resetpassword`} component={ResetPassword} />
                        <Route path={`${process.env.PUBLIC_URL}/usermailmap/:type/:ticketid`} component={ManageUserMailMap} />
                        <Route path={`${process.env.PUBLIC_URL}/map/:type/:code/:leg`} component={AndriodMap} />
                        <Route path={`${process.env.PUBLIC_URL}/mobile/:screen/:user/:code/:sdate/:edate/:frequency`} component={AndriodGridMap} />
                        <Route path={`${process.env.PUBLIC_URL}/truckdetails/:screen/:user/:truck_no/:fromdate/:expirydate`} component={AndriodGridMap} />
                        <Route path={`${process.env.PUBLIC_URL}/mobilemapview/:screen/:user/:dept/:code/:trip_type`} component={AndriodGridMap} />
                        <Route path={`${process.env.PUBLIC_URL}/consignmentmap/:screen/:truck_no/:trip_no/:time`} component={AndriodGridMap} />
                        <Route path={`${process.env.PUBLIC_URL}/mobilemap/:screen/:truckno`} component={AndriodGridMap} />
                        <Route path={`${process.env.PUBLIC_URL}/mapview/:screen/:user/:code/:sdate/:edate`} component={AndriodGridMap} />
                        <Route path={`${process.env.PUBLIC_URL}/railmap/:vehicle_id/:dealer_code/:truck_no/:sdate/:edate`} component={AndriodTPTRailMap} />
                        <Route path={`${process.env.PUBLIC_URL}/trucklocation/:truck_no/:device_id`} component={AndriodMapCurrentLocation} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/overspeedheatmap`} component={OverspeedHeatMap} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/stoppageheatmap`} component={StoppageHeatMap} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/mobiletrucksonmap/:transporter_code`} component={MobileTrucksOnMap} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/stockyarddashboard`} component={StockyardDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/stockyarddashboard/:pageType`} component={StockyardDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/stockyarddashboardformobile/:transporter`} component={stockyardTransportersDashboardForMobile} />
                        <Route path={`${process.env.PUBLIC_URL}/mobile/mobilemap`} component={MobileMap} />
                        <Route path={`${process.env.PUBLIC_URL}/mobile/mobiledevicemap`} component={MobileDeviceMap} />
                       <App>
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/geofences`} component={TptGeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/addtptgeofence`} component={AddTptGeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/editTptGeofence/:geofencename`} component={EditTptGeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/carrierlocationonmap`} component={ManageNearByTransporterTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/logout`} component={Logout} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/viewcarrierroute`} component={TptTrucksGPSData} />
                        <Route path={`${process.env.PUBLIC_URL}/error-404`} component={Error404} />
                        <Route path={`${process.env.PUBLIC_URL}/profile/changepassword`} component={ChangePasswordComponent} />

                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/emptytruckvicinity`} component={TptEmptyTrucksVicinity} />

                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/truckwithingeofence`} component={TptVicinityScreen} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/deviceslist`} component={TptRegisteredDevices} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/pendinginstallations`} component={TptPendingInstallations} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/gpsintegrationstatus`} component={tptGpsIntegration} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/overspeed`} component={TptReportOverspeed} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/gpseodanalysis`} component={TPTGpsEodAnalysis} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/monthlydistancetraveledreport`} component={TPTGpsEodAnalysis} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptgpsbilling/:month/:year`} component={TPTGPSBilling} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptgpsbillingnonmsil/:month/:year`} component={TPTGPSBillingNonMSIL} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/subusers`} component={TPTSubUsers} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptgpsbillingdashboard`} component={TPTGPSBillingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptviewgpsbillaction`} component={TPTViewGPSBillAction} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptgpsnonmsilbillingdashboard`} component={TPTGPSNonMSILBillingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptviewgpsnonmsilbillaction`} component={TPTViewGPSNonMSILBillAction} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/trucksdistancematrix`} component={TPTTrucksDistanceMatrix} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/vehicleregister`} component={VehicleRegister} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/logindetails`} component={TPTLoginDetails} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/trucks`} component={TPTTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/carriers`} component={TPTCarrier} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/groups`} component={TPTGroup} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/geofenceincursion`} component={TPTGeofenceIncursion} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/managedrivers`} component={TPTDrivers} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/nightdriving`} component={TPTNightDriving} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptrailsummary`} component={TPTRailSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptconsignments/:refno/:railno`} component={TPTConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/createtptconsignmentbatch/:refno`} component={TPTCreateBatch} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptmarkarrivalloadingyard/:batchno`} component={TPTMarkArrivalLoadingYard} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptloadingyardarrival/:refno/:railno`} component={TPTLoadingYardArrival} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/addtptwagonchecksheet/:refno/:railno`} component={TPTAddWagonCheck} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/addtpttrainloadchecksheet/:refno/:railno`} component={TPTAddTrainLoadCheck} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/addtpttrainunloadchecksheet/:refno/:railno`} component={TPTAddTrainUnLoadCheck} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptallconsignments`} component={TPTConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptlastmilebatch/:refno/:railno`} component={TPTLastMileBatch} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/devices`} component={TPTDevices} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/loadingofficers`} component={TPTLoadingOfficers} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/transporters`} component={TPTTransporters} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/oemmaster`} component={TPTOEMMaster} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/oemlocations`} component={TPTOEMLocations} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/oemdealers`} component={TPTOEMDealers} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/oemtransittimes`} component={TPTOEMTransitTimes} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/oemconsignments`} component={TPTOEMConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/harshbreak`} component={TPTHarshBreaking} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/gpssingletracking`} component={TPTGPSSingleScreen} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/monthlydistancetravelled`} component={TPTGpsEodAnalysisMonthly} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/deliveredconfirm/:refno/:railno`} component={TPTVinsConfirmDelivered} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/continuousreport`} component={TPTContinuousReport} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/stoppagereport`} component={TPTStoppageReport} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/oemapiconfiguration`} component={OemApiTab} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptalertemails`} component={TptAlertEmails} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptemails`} component={TptEmails} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/hollandtrips`} component={HollandTrips} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/loadingyard`} component={LoadingYardReport} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tafetrips`} component={TafeTrips} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/truckswithinfuelstations`} component={TruckswithinFuelStations} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/fasttagTrucksScreen`} component={FasttagTrucksScreen} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/fasttagTrucksTranscScreen`} component={FasttagTrucksTranscScreen} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/challansScreen`} component={ChallansScreen} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/mahindratrips`} component={TafeTrips} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/ashokleylandtrips`} component={IVCTrips} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/jawatrips`} component={IVCTrips} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/truckStatus`} component={TruckStatus} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/manualfasttagtranscEntry`} component={ManualFastTagTranscEntry} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/manualchallanEntry`} component={ManualChallanEntry} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tpthistorictrips`} component={TptHistoricTrips} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/truckStatus`} component={TruckStatus} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/transshipments`} component={Transshipments} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/warehousetransshipments`} component={WareHouseTransshipments} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tvptransshipments`} component={TVPTransshipments} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/olatrips`} component={OlaTrips} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/shareofbusiness`} component={ShareOfBusiness} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/nextloadplanner`} component={NextLoadPlanner} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/transshipmentreport`} component={TransshipmentReport} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/sobcompletedata`} component={SobCompleteData} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/ewaybillrequests`} component={EwayBillRequests} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/ewaybilldata/1`} component={EwayBillData} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/ewaybilldata/2`} component={EwayBillData} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/ewaybilldata/3`} component={EwayBillData} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/generatenewewaybill`} component={GenerateEwayBill} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/consolidateewaybill`} component={ConsolidateEwayBill} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/consolidateewaybillrequests`} component={ConsolidateEwayBillRequests} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/extendewaybill/:eventname`} component={ExtendEwayBill} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/driverlogs`} component={TptDriverLogs} />
                    
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/stockyardmanagement`} component={stockyardManagementSystemTabsComponent} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/billing`} component={Billing} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/billingsummary`} component={BillingSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/billingdownload/:billingId`} component={BillingDownload} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/uploadmasterlist`} component={uploadMasterList} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/uploadremovedlist`} component={uploadRemovedList} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/fiattrips`} component={FiatTrips} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/ewaybillentry`} component={EwayBillManualEntry} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/otheroemconsignments`} component={OtherOemConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/otheroemhistoricdata`} component={OtherOEMHistoricTripData} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/freewheelingreport`} component={FreeWheelingReport} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/hciltrips`} component={HCILTripsComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptbillingapprovals`} component={TptBillingApproval} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/historictruckstatus`} component={HistoricTruckStatus} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptbillingpopup`} component={TptBillingPopup} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/trackcourtcases`} component={CourtCasesTrack} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tickets`} component={TicketManagement} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/ewaybillreport`} component={EwayBillConsolidatedReport} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/testTripData`} component={TestTPTTrips} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/userlogindetails`} component={UserLoginsDetails} />    
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/rakedevicemapping`} component={RakeDeviceMapping} />  
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/allreports`} component={ReportsComponent} />   
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/notifications`} component={Notifications} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/rakedevices`} component={RakeDevices} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/cronjobs`} component={CronJobs} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/currentgeofence`} component={CurrentGeofences} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/loadingdashboard`} component={LoadingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/stoppagealerts`} component={TicketManagementForStoppages} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/otheroemdealersdata`} component={DealerDataComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/ewaybillotherpartydata`} component={EwayBillsOtherPartyData} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/truckstatusonemonthdata`} component={TruckStatusOneMonthData} />
                        
                        
                    </App>

                    </Switch>
                </ScrollContext>
            </BrowserRouter>
        </Provider>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));