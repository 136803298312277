/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types'
import { isArray } from 'highcharts';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import $ from 'jquery';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class Loginform extends Component{
	constructor(props) {
		super(props);
		var pageTitle = "Login";
		this.state = {
			//pageTitle: pageTitle,
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			style: {},
			email:'',
			password:'',
			loginMessage:'',
			currentDepartmentCode: null ,
			login_url: "",
			show1: false,
            basicTitle1: '',
            basicType1: "danger",
			redirectToChangePassword: false,
			passwordInput: '',
			selectedDevice : '',
			devices : [],
		};
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	componentDidMount(){
		if (localStorage.getItem("is_pswd_expired") === "1") {
			window.location.href = '/profile/changepassword';
			window.history.pushState(null, null, '/profile/changepassword');
			window.onpopstate = () => {
				// Keep the user on the same page if they try to navigate back
				window.history.pushState(null, null, '/profile/changepassword');
			};
			this.setState({
				redirectToChangePassword: true,
			})
		}
		this.logPageView(); // Log page view to GA
		// redirectURL.post("/lmconsignees/generateADFSMetaData").then((response)=>{
		// 	var login_url = response.data;
			
		// 	this.setState({
		// 		login_url : login_url,
		// 	})
		// }).catch((e)=>{
		// 	console.log(e);
		// })		
	};
	componentWillReceiveProps(){
		window.onpopstate = null;
		var pageTitle = "Login";
		//console.log(this.props.is_enmovil_transporter,"teststststs")
		if(this.props.is_enmovil_transporter == 1)
		{
			var pageTitle = "Transporter Login";
		}
		else if(this.props.is_load_management == 1)
		{
			var pageTitle = "Route Optimization Login";
		}
		this.setState({
			pageTitle: pageTitle,
			is_enmovil_transporter: this.props.is_enmovil_transporter,
			is_load_management : this.props.is_load_management
		});
	};

	closeAlert1 = async () => {
		console.log("expired")
		await this.setState({
			show1: false,
			redirectToChangePassword: true,
		});
	}


	onCancel = () => {
		console.log('checking')
	}

	

	changeHandler = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({[name]:value});
	}
	formHandler =async (event) => {
		
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedLogin,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		// Load FingerprintJS and generate a unique device ID
		const generateDeviceId = async () => {
			const fp = await FingerprintJS.load();
			const result = await fp.get();
			return result.visitorId;
		};

		var unique_device_id = await generateDeviceId()
		console.log(unique_device_id,"unique_device_id")
		
		var formdata = new FormData(event.target);
		// console.log(formdata);
		var email = this.state.email.replace(/\s/g,"");
		var is_enmovil_transporter = $("#is_enmovil_transporter").val();
		const fdata = {
			email:email ,
			password: this.state.password,
			is_enmovil_transporter: is_enmovil_transporter,
			device_id : unique_device_id,
		}
		//console.log(fdata);
		
		redirectURL.post('/applogin', fdata,{
		//redirectURL.post('/applogin', fdata,{
			headers:{
			//       'Content-Disposition': 'application/x-www-form-urlencoded',
				'Content-Type':'application/json'
			}
		})
			.then( async (response) => {
				console.log("After login ",response);
				if(response.data.exceedLimit == true){
					this.setState({
						username:'',
						email:'',
						password:'',
						loginMessage:response.data.msg,
						// show1: true,
						// basicTitle1: response.data.msg,
						// basicType1: "warning",
						// devices: response.data && response.data.logged_in_devices,
						exceedLimit: true,   
					});
				}
				else if(response.statusText == 'OK')
				{
					if(response.data.auth == true)
					{
						console.log(response.data.user,"response.data.user")
						var userloggparams = {
							token:response.data.token,
							userId:response.data.userid
						}
						await redirectURL.post("/manage/saveuserlogged",userloggparams)
						.then((resp) => {
							console.log("resp ", resp)
						})
						
						//	console.log("In  LoginForm response.data.user.dept_code = ", response.data.user.dept_code);
						localStorage.setItem('token', response.data.token);
											
						localStorage.setItem('userid', response.data.userid);
						let deptCode = JSON.stringify(response.data.user.dept_code);
						//console.log("In  LoginForm, response.data = ", response.data);
						localStorage.setItem('dept_code', deptCode);
						localStorage.setItem('is_admin', response.data.user.is_admin);
						localStorage.setItem('is_dept_admin', response.data.user.is_dept_admin);
						localStorage.setItem('is_global', response.data.user.is_global);
						localStorage.setItem('firstname', response.data.user.firstname);
						localStorage.setItem('username', response.data.user.username);
						localStorage.setItem('lastname', response.data.user.lastname);
						localStorage.setItem('email', response.data.user.email);
						localStorage.setItem('user_type', response.data.user.user_type);
						localStorage.setItem('is_oem', response.data.user.ie_oem);
						localStorage.setItem('is_hero', response.data.user.is_hero);
						localStorage.setItem('oem', response.data.user.oem);
						//console.log("response.data.menus ", response.data.menus);
						localStorage.setItem('usermenus', JSON.stringify(response.data.menus));
						//console.log("response.data.usermenucodes ", response.data.usermenucodes);
						localStorage.setItem('usermenucodes', JSON.stringify(response.data.usermenucodes));
						localStorage.setItem('transportercode', JSON.stringify(response.data.user.transporter_code));
						
						localStorage.setItem('roles', response.data.user.roles);
						localStorage.setItem('is_enmovil_transporter', this.state.is_enmovil_transporter);
						localStorage.setItem('is_sub_user', response.data.user.is_sub_user);
						localStorage.setItem('cha_code', response.data.user.cha_code);
						localStorage.setItem('plant_location', response.data.user.plant_location)
						try{
							if(response.data.user.vehicle_group != undefined)
							{
								localStorage.setItem('vehicle_group', response.data.user.vehicle_group);
							}
							else{
								localStorage.setItem('vehicle_group', "");
							}
						}
						catch(e){}
						try{
							if(response.data.user.is_stockyard != undefined)
							{
								localStorage.setItem('is_stockyard', response.data.user.is_stockyard);
							}
							else{
								localStorage.setItem('is_stockyard', "");
							}
						}
						catch(e){}
						
						//console.log('users roles', typeof response.data.user.roles);
						sessionStorage.setItem('ref', 1);
						
						if(response.data.user.loading_port_code !=undefined)
						{
							var params = {
								loading_port_code : response.data.user.loading_port_code
							}							
							await redirectURL.post("/lmconsignees/getLmLoadingPortName",params).then((response1)=>{
								//console.log(response1.data,"LLPN");
								var lpData = response1.data;
								var lpNames = [];
								if(lpData.length > 0)
								{
									lpData.map((item) => {
										if(item.city != '' && item.city != undefined)
										{
											lpNames.push({
												loading_port_code:item.loading_port_code,
												loading_port:item.city,
											})
										}
									})
									
								}
								//console.log(lpNames,"lpNames")
							localStorage.setItem('loadingPointName', JSON.stringify(lpNames));
								//console.log(localStorage.getItem("loadingPointName"),"Point Name")
							}).catch((e)=>{
								console.log(e);
							})
							localStorage.setItem('loading_port_code', response.data.user.loading_port_code[0]);
						}
						else if(response.data.user.is_load_management == 1)
						{
							if(response.data.user.roles == "lm_site_admin")
							{
								await redirectURL.post("/lmconsignees/getLmLoadingPorts").then((response2)=>{
									//console.log(response1.data,"LLPN");
									var lpData = response2.data;
									var lpNames = [];
									if(lpData.length > 0)
									{
										lpData.map((item) => {
											if(item.city != '' && item.city != undefined)
											{
												lpNames.push({
													loading_port_code:item.loading_port_code,
													loading_port:item.city,
												})
											}
										})
										localStorage.setItem('loading_port_code', response2.data[0].loading_port_code);
									}
									//console.log(lpNames,"lpNames")
								localStorage.setItem('loadingPointName', JSON.stringify(lpNames));
									//console.log(localStorage.getItem("loadingPointName"),"Point Name")
								}).catch((e)=>{
									console.log(e);
								})
							}
						}

						if (response.data.is_pswd_expired && response.data.is_pswd_expired == 1) {
							localStorage.setItem('is_pswd_expired', response.data.is_pswd_expired);
							this.setState({
								show1: true,
								basicTitle1: 'Your password has expired. Please update it to continue.',
								basicType1: "warning",
							})
						}

						if(response.data.user.is_load_management !=undefined)
						{
							localStorage.setItem('is_load_management', response.data.user.is_load_management);
						}
						if(response.data.user.lm_department !=undefined)
						{
							localStorage.setItem('lm_department', response.data.user.lm_department);
						}
						if(response.data.user.designation !=undefined)
						{
							localStorage.setItem('designation', response.data.user.designation);
						}
						if(response.data.user.active_status !=undefined)
						{
							localStorage.setItem('active_status', response.data.user.active_status);
						}
						if(response.data.user.is_department_admin !=undefined)
						{
							localStorage.setItem('is_department_admin', response.data.user.is_department_admin);
						}
						if(response.data.user.staff_id !=undefined)
						{
							localStorage.setItem('staff_id', response.data.user.staff_id);
						}
						if(response.data.user.dept_head_id !=undefined)
						{
							localStorage.setItem('dept_head_id', response.data.user.dept_head_id);
						}
						if(response.data.user.report_manager_id !=undefined)
						{
							localStorage.setItem('report_manager_id', response.data.user.report_manager_id);
						}
						this.setState({
							currentDepartmentCode: response.data.user.dept_code
						});
						if (response.data.token) {
						axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
						} else {
						delete axios.defaults.headers.common['Authorization'];
						}

						this.setState({
							username:'',
							email:'',
							password:'',
							loginMessage:response.data.msg
						});

						

					}
				else{
				
					this.setState({
						username:'',
						email:'',
						password:'',
						loginMessage:response.data.msg
					});
					
				}
					
					
				}
				else
				{
					this.setState({
						username:'',
						email:'',
						password:'',
						loginMessage:response.data.msg
					});
				}
			//console.log(localStorage.getItem('token'));
			})
			.catch(function (error) {
				console.log(error);
			});
		
			
			// another call to find menus for the user
			redirectURL.post('/menus/getmenus', {},{
			headers:{
				'Content-Type':'application/json'
			}
		})
		.then( response => {
			//console.log("Menus ",response);
			if (response.statusText == 'OK') {
				//console.log("In  SIDEBAR LoginForm MENUS response.data = ", response.data);
				let menuText = JSON.stringify(response.data);
				localStorage.setItem('menuText', menuText);

			} else {
				this.setState({
					username:'',
					email:'',
					password:'',
					loginMessage:response.data.msg
				});
			}
			let MENUITEMS = getMenuLocalStorage();

			//console.log("IN Sidebar componentWillMount, From DB MENUITEMS menuList ", MENUITEMS);
		})
		.catch(function (error) {
			console.log(error);
		});
		
	}

	getUserDashboardUrl(pathToMatch = null) {
 
		var uroles = localStorage.getItem("roles");
		var arr = []
				
		
		// redirectURL.post("/menus/roleinfo",{role:uroles})
		// .then((res) => {
		// 	//console.log("repson ", response.data)
		// 	var rec = res.data
		// 	if(rec.length > 0)
		// 	{
		// 		rec.map((item) => {
		// 			if(item.dashboard_url != '' && item.dashboard_url != undefined)
		// 			{
		// 				arr.push(item.dashboard_url)
		// 			}
		// 		})
				
		// 	}
		// 	else
		// 	{
		// 		arr.push("/dashboard/default")
		// 	}
		// 	if(arr.length > 0)
		// 	{
				
		// 		this.setState({
		// 			landingpage:arr[0]
		// 		});
		// 	}
		// 	else
		// 	{
		// 		this.setState({
		// 			landingpage:"/dashboard/default"
		// 		});
		// 	}
		// 	//return dashboardUrl;
		// })

		
		let dashboardUrl = "/tptlogin/gpsintegrationstatus";
		let currentDepartmentCode = null;
		let departmentCode = JSON.parse(localStorage.getItem("dept_code"));

		//console.log("IN render, departmentCode ", departmentCode);
		if (Array.isArray(departmentCode)) {
			currentDepartmentCode = departmentCode[0]; 
		} else {
			currentDepartmentCode = departmentCode; 
		}

		var userroles = localStorage.getItem("roles");
		//console.log("IN render, currentDepartmentCode ", currentDepartmentCode);
		//console.log("localStorage roles ", localStorage.getItem("roles"))
		if(localStorage.getItem("is_pswd_expired") == "1" ){
			dashboardUrl="/profile/changepassword";
		}
		else if(localStorage.getItem("is_enmovil_transporter") == 1)
		{
		if(localStorage.getItem("transportercode").indexOf("T243") >= 0)
			{
				if(localStorage.getItem("is_sub_user") == 1)
				{
					dashboardUrl="/tptlogin/tafetrips";
				}
				else
				{
					dashboardUrl="/tptlogin/gpsintegrationstatus";
				}
			}
			else if (userroles =="tpt_billing_screen") {
				dashboardUrl = "/tptlogin/billingsummary"
			}
			else if(localStorage.getItem("transportercode").indexOf("T322") >= 0 || localStorage.getItem("transportercode").indexOf("T321") >= 0)
			{
				if(localStorage.getItem("is_sub_user") == 1)
				{
					dashboardUrl="/tptlogin/tafetrips";
				}
				else
				{
					dashboardUrl="/tptlogin/gpsintegrationstatus";
				}
			}
			else if(localStorage.getItem("transportercode").indexOf("T215") >= 0 )
			{
				console.log(localStorage.getItem("transportercode"),"localStorage.getItem(transportercode)")
				if(localStorage.getItem("email") == "fiattrips@entrac.in")
				{
					console.log(localStorage.getItem("email"),"localStorage.getItem(email)")
					dashboardUrl="/tptlogin/fiattrips";
				}
				else
				{
					console.log("step2")
					dashboardUrl="/tptlogin/gpsintegrationstatus";
				}
			}
			else if(localStorage.getItem("transportercode").indexOf("313039") >= 0)
			{
				if(localStorage.getItem("is_sub_user") == 1)
				{
					dashboardUrl="/tptlogin/mahindratrips";
				}
				else
				{
					dashboardUrl="/tptlogin/gpsintegrationstatus";
				}
			}
			else if(localStorage.getItem("transportercode").indexOf("T305") >= 0)
			{
				if(localStorage.getItem("is_sub_user") == 1)
				{
					if(localStorage.getItem("is_stockyard") == 1)
					{
						dashboardUrl="/tptlogin/stockyardmanagement";
					}
					else if(userroles.indexOf("tpt_subuser_challan") >= 0)
					{
						dashboardUrl="/tptlogin/challansScreen";
					}
					else{
						if(localStorage.getItem("email") == "ivc.ashokleyland@entrac.in")
						{
							dashboardUrl="/tptlogin/ashokleylandtrips";						
						}
						else if(localStorage.getItem("email") == "ivc.jawa@entrac.in" || localStorage.getItem("email") == "rajkamalsingh@ivclogistics.com")
						{
							dashboardUrl="/tptlogin/jawatrips";
						}
						else if(localStorage.getItem("email") == "ivc.tafe@entrac.in" || localStorage.getItem("email") == "kuldeepsharma@ivclogistics.com")
						{
							dashboardUrl="/tptlogin/tafetrips";
						}
					}
				}
				else
				{
					dashboardUrl="/tptlogin/gpsintegrationstatus";
				}
			}
			else if(localStorage.getItem("transportercode").indexOf("T329") >= 0)
			{
				if(localStorage.getItem("is_sub_user") == 1)
				{
					if(localStorage.getItem("email") == "hciltrips@entrac.in")
					{
						dashboardUrl="/tptlogin/hciltrips";
					}
					else if(localStorage.getItem('email') == 'arpl_test@enmovil.in'){
						 dashboardUrl = "/tptlogin/testTripData"
					}
					else
					{
						dashboardUrl="/tptlogin/gpsintegrationstatus";
					}
				}
				else
				{
					dashboardUrl="/tptlogin/gpsintegrationstatus";
				}
			}
			else if(localStorage.getItem("transportercode").indexOf("302178") >= 0 || localStorage.getItem("transportercode").indexOf("313356") >= 0)
			{
				if(localStorage.getItem("is_sub_user") == 1)
				{
					dashboardUrl="/tptlogin/olatrips";
				}
			}
			else if (userroles.indexOf("tpt_subuser_railsummary") >= 0)
			{
				dashboardUrl="/tptlogin/tptrailsummary";
			}
			else if(localStorage.getItem("email").indexOf("billing@enmovil.in") >= 0){
				dashboardUrl="/tptlogin/billingsummary";
			}
			else if (userroles.indexOf("JWS") >= 0 || localStorage.getItem("email").indexOf("msil_rake@enmovil.in") >= 0)
			{
				dashboardUrl="/tptlogin/gpsintegrationstatus";
			}
			else if(localStorage.getItem("email").indexOf('amops5@orientalcarriers.in') >=0){
				dashboardUrl="/tptlogin/stockyardmanagement";
			}
			else if(localStorage.getItem("transportercode").indexOf("T275") >= 0){
				dashboardUrl="/tptlogin/ewaybilldata/1";
			}
			else
			{
				dashboardUrl="/tptlogin/gpsintegrationstatus";
			}
		}
		else
		{
			dashboardUrl="/tptlogin/gpsintegrationstatus";
		}
		// 	switch(currentDepartmentCode) {
		// 		case "SNDG":
		// 			//dashboardUrl="/dashboard/snddefault";
		// 			if(localStorage.getItem("user_type") == 'TRANSPORTER')
		// 			{
		// 				var userroles = localStorage.getItem("roles");
		// 				if (userroles.indexOf("tpt_rail") >= 0)
		// 				{
		// 					dashboardUrl="/railconsignments";
		// 				}
		// 				else
		// 				{
		// 					dashboardUrl="/sndtransporterconsignments";
		// 				}
		// 			}
		// 			else{
		// 				var userroles = localStorage.getItem("roles");
		// 				try{

		// 					if (userroles.indexOf("AG2") >= 0)
		// 					{
		// 						dashboardUrl="/sndconsignments";
		// 					}
		// 					else if (userroles.indexOf("led") >= 0)
		// 					{
		// 						dashboardUrl="/livemaptrucklocation";
		// 					}
		// 					else if (userroles.indexOf("GPSUSER") >= 0)
		// 					{
		// 						dashboardUrl="/mlltruckgps";
		// 					}
		// 					else if (userroles.indexOf("Plant") >= 0 || userroles.indexOf("RSO") >= 0
		// 					|| userroles.indexOf("Loading Officer") >= 0
		// 					|| userroles.indexOf("Unloading Officer") >= 0
		// 					|| userroles.indexOf("BA") >= 0)
		// 					{
		// 						dashboardUrl="/mllsummary";
		// 					}
		// 					else{
		// 						dashboardUrl="/snddashboardtemplate";
		// 					}
		// 				}
		// 				catch(e){
		// 					dashboardUrl="/snddashboardtemplate";
		// 				}
		// 			}
		// 			break;
					
		// 		case "LOG-PRT":
		// 			//dashboardUrl="/dashboard/prtdefault";
		// 			if(localStorage.getItem("user_type") == 'TRANSPORTER')
		// 			{
		// 				var userroles = localStorage.getItem("roles");
		// 				if (userroles.indexOf("tpt_rail") >= 0)
		// 				{
		// 					dashboardUrl="/railconsignments";
		// 				}
		// 				else if (userroles.indexOf("tpt_courier") >= 0)
		// 				{
		// 					dashboardUrl="/activecourierconsignments";
		// 				}

		// 				else if (userroles.indexOf("tpt_alloybars") >= 0)
		// 				{
		// 					dashboardUrl="/alloybarsComponent";
		// 				}
		// 				else if(userroles.indexOf("actl") >= 0){
		// 					dashboardUrl="/actlcontainers";
		// 				}
		// 				else if(userroles.indexOf("cha_prt_exports") >= 0)
		// 				{
		// 					dashboardUrl="/exports/transhipmentconsignments"
		// 				}
		// 				else
		// 				{
		// 					dashboardUrl="/prttransporterconsignments";
		// 				}
		// 			}
		// 			else if (localStorage.getItem("roles") == "PACC")
		// 			{
		// 				dashboardUrl="/prtallconsignments";
		// 			}
		// 			else{
		// 				dashboardUrl="/prtdashboardtemplate";
		// 			}
					
		// 			break;
		// 		case "LOG-TNP":
		// 			//dashboardUrl="/dashboard/tnpdefault";
		// 			var userroles = localStorage.getItem("roles");
		// 			if(userroles.indexOf("TNP DDVM") > 0)
		// 			{
		// 				dashboardUrl="/managementdashboard";
		// 			}
		// 			else if(userroles.indexOf("tnp_rp") >= 0)
		// 			{
		// 				dashboardUrl="/returnemptypallets";
		// 			}
		// 			else
		// 			{
		// 				dashboardUrl="/tnpdashboardtemplate";
		// 			}
		// 			break;
		// 		case "LOG-SC":
		// 			//dashboardUrl="/dashboard/scdefault";
		// 			if(localStorage.getItem("user_type") == 'TRANSPORTER')
		// 			{
		// 				var userroles = localStorage.getItem("roles");
		// 				if (userroles.indexOf("tpt_alloybars") >= 0)
		// 				{
		// 					dashboardUrl="/alloybarsComponent";
		// 				}

		// 				else if(userroles.indexOf("actl") >= 0){
		// 					dashboardUrl="/actlcontainers";
		// 				}
		// 				else if(userroles.indexOf("lcl_containers") >= 0){
		// 					dashboardUrl="/lclimports";
		// 				}
		// 				else if(userroles.indexOf("fcl_dsr") >= 0){
		// 					dashboardUrl="/importsshipments";
		// 				}
		// 				else if (userroles.indexOf("sc_container_alloys") >= 0)
		// 				{
		// 					dashboardUrl="/imports/consignments";
		// 				}
		// 				else if(userroles.indexOf("air_cargo_form") >= 0)
		// 				{
		// 					dashboardUrl="/aircargoform";
		// 				}
		// 				else
		// 				{
		// 					dashboardUrl="/imports/consignments";
		// 				}
		// 			}
		// 			else{
		// 				var userroles = localStorage.getItem("roles");
		// 				if (userroles.indexOf("sc_ppc") >= 0)
		// 				{
		// 					dashboardUrl="/imports/consignments";
		// 				}
		// 				else if (userroles.indexOf("sc_exports") >= 0)
		// 				{
		// 					dashboardUrl="/exports/consignments";
		// 				}
		// 				else if(userroles.indexOf("actl") >= 0){
		// 					dashboardUrl="/actlcontainers";
		// 				}
		// 				else if (userroles.indexOf("sc_container_alloys") >= 0)
		// 				{
		// 					dashboardUrl="/imports/consignments";
		// 				}
		// 				else if(userroles.indexOf("air_cargo_form") >= 0)
		// 				{
		// 					dashboardUrl="/aircargoform";
		// 				}
		// 				else
		// 				{
		// 					dashboardUrl="/imports/consignments";
		// 				}
		// 			}

					
		// 			break;
		// 		case "SUP-CHN":
		// 			//dashboardUrl="/dashboard/scdefault";
		// 			dashboardUrl="/imports/consignments";
		// 			break;
				
		// 		case "Customs Coils":
		// 			//dashboardUrl="/dashboard/prtdefault";
		// 			dashboardUrl="/chacoils";
		// 			break;
		// 		case "Customs Containers":
		// 			//dashboardUrl="/dashboard/prtdefault";
		// 			dashboardUrl="/chanhavasheva";
		// 			break;
		// 		case "AD":
		// 			//dashboardUrl="/dashboard/scdefault";
		// 			var userroles = localStorage.getItem("roles");
		// 			if (userroles.indexOf("lm_admin") >= 0 || userroles.indexOf("lm_user") >= 0)
		// 			{
		// 				dashboardUrl="/lmloadsummary";
		// 			}
		// 			else if (userroles.indexOf("SITE_ADMIN") >= 0 || userroles.indexOf("SR MGMT") >= 0)
		// 			{
		// 				dashboardUrl="/managementdashboard";
		// 			}
					
		// 			else if (userroles.indexOf("MahindraAdmin") >= 0)
		// 			{
		// 				dashboardUrl="/mllsummary";
		// 			}
		// 			else
		// 			{
		// 				dashboardUrl="/livetrucksinfo";
		// 			}
		// 			break;
		// 		case "LM":
		// 			var userroles = localStorage.getItem("roles");
		// 			//var usermenus = localStorage.getItem("usermenus");
		// 			// if (userroles.indexOf("lm_admin") >= 0 || userroles.indexOf("lm_user") >= 0)
		// 			// {
						
		// 			// }
		// 			if (userroles.indexOf("lm_site_admin") >= 0)
		// 			{
		// 				dashboardUrl="/lmdepartments";
		// 			}
		// 			else
		// 			{
		// 				dashboardUrl="/lmwelcome";
		// 			}
		// 			break;
		// 		case "SND DDVM":
		// 			//dashboardUrl="/dashboard/snddefault";
		// 			dashboardUrl="/dashboardsummary";
		// 			break;
		// 		case "TNP DDVM":
		// 			//dashboardUrl="/dashboard/snddefault";
		// 			dashboardUrl="/dashboardsummary";
		// 			break;
				
		// 		case "PRT DDVM":
		// 			//dashboardUrl="/dashboard/snddefault";
		// 			dashboardUrl="/dashboardsummary";
		// 			break;
			
		// 		case "SC DDVM":
		// 			//dashboardUrl="/dashboard/snddefault";
		// 			dashboardUrl="/dashboardsummary";
		// 			break;
		// 		default:
		// 			let isTmsUser = false;
		// 			currentDepartmentCode = currentDepartmentCode.trim();
		// 			if (currentDepartmentCode !== "SA") {
		// 				// TMS users don't belong to any department
		// 				let userMenuCodes = JSON.parse(localStorage.getItem("usermenucodes"));
		// 				if (Array.isArray(userMenuCodes)) {
		// 					isTmsUser = userMenuCodes.includes("tms");
		// 				} else if (userMenuCodes == "tms") {
		// 					isTmsUser = true; 
		// 				}
		// 			} else {
		// 				console.log("IN default, ELSE, currentDepartmentCode = ", currentDepartmentCode);
		// 			}
		// 			console.log("isTmsUser ",isTmsUser)
		// 			if (isTmsUser) {
		// 				dashboardUrl="/ticketmanagementsystem";
		// 			} else {
		// 				dashboardUrl="/dashboard/default";
		// 			}
					
		// 			console.log("IN getUserDashboardUrl, default, params = ", departmentCode);
		// 	}
		// }
		
		console.log("dashboardUrl Login", dashboardUrl)
		return dashboardUrl;
	}
	render(){ 
		const isPswdExpired = localStorage.getItem("is_pswd_expired") === "1";
		// Show the popup if the password is expired
		if (isPswdExpired && this.state.show1) {
			console.log("Password Expired")
		}
		else if(localStorage.getItem('token') != '' && localStorage.getItem('token') != null)
		{
			// let dashboardUrl = this.getUserDashboardUrl();
			// //let dashboardUrl = this.state.landingpage;
			// // return <Redirect to="dashboard/snddefault" push={true} />
			// return <Redirect to={dashboardUrl} push={true} />
			if (this.state.redirectToChangePassword || isPswdExpired) {
				return <Redirect to="/profile/changepassword" push={true} />;
			} 
			else if(localStorage.getItem("roles") == 'tpt_admin')
            {
                return <Redirect to="/tptlogin/overspeed" push={true} />
            }
            else{
				
				let dashboardUrl = this.getUserDashboardUrl();
				//let dashboardUrl = this.state.landingpage;
				// return <Redirect to="dashboard/snddefault" push={true} />
				return <Redirect to={dashboardUrl} push={true} />
			}
			
		}

		return (
			<>
				<SweetAlert
					show={this.state.show1}
					type={this.state.basicType1}
					title={this.state.basicTitle1}
					onConfirm={this.closeAlert1} 
					onCancel={this.onCancel}
				>

				</SweetAlert>
                 <form className="theme-form" onSubmit={this.formHandler} >
					 {(this.state.loginMessage)?
					 <div className="alert alert-danger">{this.state.loginMessage}</div>
						:""}
                     <div className="form-group">
                         <label className="col-form-label pt-0">Email ID</label>
                         <input required type="text" name='email' className="form-control form-control-lg" value={this.state.email} onChange={this.changeHandler} />
                     </div>
                     <div className="form-group">
                         <label className="col-form-label">Password</label>
                         <input required type="password" name='password' value={this.state.password} onChange={this.changeHandler} className="form-control form-control-lg"/>
                     </div>
                     <div className="form-group form-row mt-3">
						 <div className="col-md-12 textAlignCenter">
                             <button type="submit" id="loginButton" className="btn btn-secondary">LOGIN
                             </button>
                         </div>
                     </div>
					 <input type="hidden" name="is_enmovil_transporter" id="is_enmovil_transporter" value={this.props.is_enmovil_transporter}></input>

					 {(this.props.is_load_management == 1) ?
					 <div className="form-group form-row mt-3">
						<div className="col-md-4">
							<a href={"/resetpassword"} className="float-left">Forgot Password</a>
						</div>
						<div className="col-md-5">
							<a href={"/register"} className="float-left">Register</a>
						</div>
						<div className="col-md-3">
							<a href={this.state.login_url} className="float-right">Login with ADFS</a>
						</div>
					</div>
						: 
					<div className="form-group form-row mt-3">
						 <div className="col-md-6">
                             <a href={"/resetpassword"} className="float-left">Forgot Password</a>
                         </div>
						 <div className="col-md-6">
                             <a href={"/register"} className="float-right">Register</a>
                         </div>
                 	</div>
					}
                 </form>
			</>
		)
	}
};
function getMenuLocalStorage() {
    let menuText = localStorage.getItem('menuText');
    let MENUITEMS = null;
    //   console.log("deptCodes = ", deptCodes);
    //   console.log("Type of deptCodes = ", typeof deptCodes);
       try {
         MENUITEMS = JSON.parse(menuText);
       } catch (error) {
           console.log('Error while Parsing menuText', error);
       }
       return MENUITEMS;
}
Loginform.contextTypes={
		router:PropTypes.object.isRequired
}


