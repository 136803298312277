import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules, AllEnterpriseModules} from '@ag-grid-enterprise/all-modules';
// import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import redirectURL from '../redirectURL';

import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var childJson = []

export default class ExtendEwayBill extends Component{
        constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            dept_code:"",
            pagetitle:"",
            bulkslide:"",
            benefitCert:"",
            date:"",
            deptName:"",
            indenterName:"",
            mobile:"",
            indenterEmail:"",
            erpPO:"",
            erpPoNo:[],
            erpPoDate:[],
            ringiNo:"",
            ringiDesc:"",
            cargoDeliveryPlant:"",
            itemDesc:"",
            grossWt:"",
            dimensions:"",
            cargo_chargeable_weight:"",
            supplierName:"",
            supplierAddr:"",
            supplierCnctPerson:"",
            country:"",
            emailID:"",
            phone:"",
            deliveryTerms:"Ex Works",
            pickUpAddr:"",
            transporter_document_date:"",
            LC : "",
            LCno : "",
            nature : "",
            freeTrade : "",
            benefitCert : "",
            disableERP:"disabled",
            disableLC:"disabled",
            deliveryLabel : "Pick Up Address",
            airportName:"",
            port : "",
            erpPo_date :"",
            cargo_date:"",
            rows: [{}],
            ringirows: [{}],
            packetrows: [{}],
            brequest:"activet",
            prepaid:"",
            track:"",
            requestData:"show-m",
            trackData:"show-n",
            trackResData : 'show-n',
            ffsuppliers: [],
            requestFields:"show-m",
            prepaidFields:"show-n",
            addresstxt:"",
            request_type:1,
            pagetitle:"Extend E-Way Bill",
            supply_type : "",
            sub_supply_type : "",
            transaction_type : "",
            document_type : "",
            document_number : "",
            document_date : "",
            legal_name_of_consignor : "",
            gstin_of_consignor : "",
            address1_of_consignor : "",
            address2_of_consignor : "",
            pincode_of_consignor : "",
            place_of_consignor : "",
            state_of_consignor : "",
            legal_name_of_consignee : "",
            gstin_of_consignee : "",
            address1_of_consignee : "",
            address2_of_consignee : "",
            pincode_of_consignee : "",
            place_of_consignee : "",
            state_of_supply : "",
            itemList : "",
            taxable_amount : "",
            cgst_amount : "",
            sgst_amount : "",
            igst_amount : "",
            cess_amount : "",
            cess_nonadvol_value : "",
            other_value : "",
            total_invoice_value : "",
            transporter_name : "",
            transporter_id : "",
            remaining_distance : "",
            transit_type : "",
            vehicle_type : "",
            vehicle_number : "",
            transporter_document_number : "",
            transporter_document_date : "",
            showOthers : "show-n",
            transporter_gstin : "",
            eway_bill_number : "",
            extend_request_from : 1,
            eway_bill_valid_date : "",
            destination_pincode : "",
            consignment_status: "", // Track selected value
            address_line1: "",
            address_line2: "",
            address_line3: "",
            //components : {datepicker:getDatePicker()}
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.fetchEwayBillDetails = this.fetchEwayBillDetails.bind(this);
        this.packetsHandler = this.packetsHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
    	this.logPageView(); // Log page view to GA
        var inputEventval = window.atob(this.props.match.params.eventname);
        var extend_request_from = this.props.match.params.extend_request_from;
		// console.log
        this.setState({
            eway_bill_number : inputEventval,
            extend_request_from : extend_request_from
        });
		var reqparams = {
            eway_bill_number : inputEventval,
            extend_request_from : extend_request_from
        }
        redirectURL.post("/consignments/getEwayBillDetails",reqparams).then((response)=>{
            var resp = response.data;
            if(resp.length > 0)
            {
                var eway_bill_valid_date = resp[0].eway_bill_valid_date;
                if(eway_bill_valid_date !=undefined && eway_bill_valid_date !="")
                {
                    var ewaybillvaliddate = moment.parseZone(eway_bill_valid_date).format('DD/MM/YYYY');
                    $("#eway_bill_valid_date").val(ewaybillvaliddate);
                }
                this.setState({
                    eway_bill_valid_date : resp[0].eway_bill_valid_date,
                    destination_pincode  : resp[0].pincode_of_consignee
                });
            }
        }).catch((e)=>{
            console.log(e);
        })
        var params = {
            transporter_code : localStorage.getItem("transportercode")
        }
        redirectURL.post("/consignments/getTransporterGSTDetails",params).then((response)=>{
            var resp = response.data;
            if(resp.length > 0)
            {
                this.setState({
                    transporter_gstin : resp[0].gstin
                });
            }
        }).catch((e)=>{
            console.log(e);
        })
        loadDateTimeScript();        
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    
onClickHideAll(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:''
    });
    
}
closeAlert = () => {
    this.setState({
        show: false
    });
    var redirectScreen = "";
    if(this.state.extend_request_from === 1)
    {
        redirectScreen = "ewaybilldata/1";
    }
    else
    {
        redirectScreen = "ewaybillotherpartydata";
    }
    window.location.href = "/tptlogin/"+redirectScreen;
    //window.location.href="/tptlogin/ewaybillrequests";
}

fetchEwayBillDetails = (e) => {
    var fieldName = e.target.name;
    var eway_bill_number = e.target.value;
    console.log(eway_bill_number,"eway_bill_number")
    var gstin_of_generator = this.state.transporter_gstin;
    if(gstin_of_generator !="" && gstin_of_generator !=undefined && eway_bill_number !=undefined && eway_bill_number !="")
    {
        var params = {
            eway_bill_number : eway_bill_number,
            gstin_of_generator : gstin_of_generator,
        }
        redirectURL.post("/consignments/fecthDetailsByEwayBillNumber",params).then((response)=>{
            if(response.data.message == "success")
            {
                var ewayBillData = response.data.ewaybilldata;
                var array=fieldName.split("_");
                var rowNumber = array[array.length-1];
                $("#eway_bill_date_"+rowNumber).val(ewayBillData.eway_bill_date);
                $("#generated_by_"+rowNumber).val(ewayBillData.userGstin);
                $("#inv_no_"+rowNumber).val(ewayBillData.document_number+" "+ewayBillData.document_date);
                $("#total_invoice_value_"+rowNumber).val(ewayBillData.total_invoice_value);
                $("#eway_bill_valid_date_"+rowNumber).val(ewayBillData.eway_bill_valid_date);
            }
            else
            {
                this.setState({
                    basicTitle:response.data.message,
                    basicType : "warning",
                    show : true,
                }); 
            }
        }).catch((e)=>{
            console.log(e);
        })
    }
}

changeHandler = (event) => {
    console.log("Select", event.target.value);
    let name = event.target.name;
    console.log(name);
    let value = event.target.value;
    this.setState({[name]:value});
    if(name == "sub_supply_type")
    {
        if(value == "Others")
        {
            this.setState({showOthers : "show-m"});
        }
        else
        {
            this.setState({showOthers : "show-n"});
        }
    }
    if(name == "mode_of_transport")
    {
        if(value == "road")
        {
            $("#vehicle_type").removeAttr("disabled",true);
            $("#vehicle_number").removeAttr("disabled",true);
            $("#trn_doc_no").html("Transporter Doc. No");
        }
        else if(value == "ship or ship cum road/rail")
        {
            $("#vehicle_type").attr("disabled",true);
            $("#vehicle_number").removeAttr("disabled",true);
            $("#trn_doc_no").html("Bill of lading No");
        }
        else if(value == "air")
        {
            $("#vehicle_type").attr("disabled",true);
            $("#vehicle_number").attr("disabled",true);
            $("#trn_doc_no").html("Airway Bill No");
        }
        else
        {
            $("#vehicle_type").attr("disabled",true);
            $("#vehicle_number").attr("disabled",true);
            $("#trn_doc_no").html("RR No");
        }
    }
    if (name === "place_of_consignor" && this.state.consignment_status === "T") {
        this.setState({
            address_line1: value,
            address_line2: value,
            address_line3: value,
        });
    }
    // Handling consignment_status change
    if (name === "consignment_status") {
        if (value === "M") {
            // Show and enable Mode, Vehicle No, Address fields
            this.setState({
                showMode: true,
                showVehicleNo: true,
                showTransitType: false,
                showAddress: false,
                mode_of_transport: "",
                transit_type: "", // Reset Transit Type when switching to 'M'
                address_line1: "",
                address_line2: "",
                address_line3: "",
            });

            $("#mode, #vehicle_number, #address_line1, #address_line2, #address_line3")
                .attr("required", true)
                .removeAttr("disabled");
            $("#transit_type").removeAttr("required").attr("disabled", true);
        } else if (value === "T") {
            // Show and enable Transit Type, hide others
            this.setState({
                showMode: false,
                showVehicleNo: false,
                showTransitType: true,
                showAddress: true,
                mode_of_transport: "",
                vehicle_number: "",
                address_line1: this.state.place_of_consignor,
                address_line2: this.state.place_of_consignor,
                address_line3: this.state.place_of_consignor,
            });

            $("#transit_type").attr("required", true).removeAttr("disabled");
            $("#mode, #vehicle_number, #address_line1, #address_line2, #address_line3")
                .removeAttr("required")
                .attr("disabled", true);
        }
    }
}
dateValidation = (currentDate) => {
    return currentDate.isBefore(moment(this.state.deliverymaxDate)) && currentDate.isAfter(moment(this.state.deliveryminDate));
};
changeFileHandler = async (e) => {
    console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.files[0] });
}
formHandler(event){
    event.preventDefault();
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    })
    var transportercode = JSON.parse(localStorage.getItem("transportercode"));
    var gstin_of_generator = this.state.transporter_gstin
    var params = {
        transporter_code : transportercode[0],
        userGstin : gstin_of_generator
    };
    params.eway_bill_number = this.state.eway_bill_number;
    params.vehicle_number = this.state.vehicle_number;
    params.place_of_consignor = this.state.place_of_consignor;
    params.state_of_consignor = $("#state_of_consignor option:selected").text();
    params.remaining_distance = this.state.remaining_distance;
    params.transporter_document_number = this.state.transporter_document_number;
    params.transporter_document_date = $("#transporter_document_date").val();
    params.extend_validity_reason = this.state.extend_validity_reason;
    params.extend_remarks = this.state.extend_remarks;
    params.from_pincode = this.state.from_pincode;
    params.consignment_status = this.state.consignment_status;
    if(this.state.consignment_status === "T")
    {
        params.mode_of_transport = "intransit";
    }
    else
    {        
        params.mode_of_transport = this.state.mode_of_transport;
    }
    params.transit_type = this.state.transit_type;
    params.address_line1 = this.state.address_line1;
    params.address_line2 = this.state.address_line2;
    params.address_line3 = this.state.address_line3;
    var extend_request_from = this.state.extend_request_from;
    // if(localStorage.getItem("is_hero") == 1 || localStorage.getItem("is_hero") == "1"){
    //     extend_request_from = 2
    // }
    params.extend_request_from = extend_request_from;
    console.log(params,"params")
    redirectURL.post("/consignments/extendEwayBill",params).then((response)=>{   
        this.setState({
            basicTitle:response.data.message,
            basicType : "success",
            show : true,
            loadshow:'show-n',
            overly:'show-n',
        });
        // window.location.href = "/tptlogin/ewaybilldata";
    }).catch((e)=>{
        console.log(e);
    })
      
}

handleAddRow = () => {
    const item = {
        erpPoNo: "",
        erpPoDate: "",
    };
    this.setState({
        rows: [...this.state.rows, item]
    });
};
handleRemoveRow = (idx) => {
    this.state.rows.splice(idx, 1);
    this.setState({ rows: this.state.rows });
};

handleAddRingiRow = () => {
    const item = {
        ringi_file: "",
        ringiDesc: "",
    };
    this.setState({
        ringirows: [...this.state.ringirows, item]
    });
};
handleRemoveRingiRow = (idx) => {
    this.state.ringirows.splice(idx, 1);
    this.setState({ ringirows: this.state.ringirows });
};

packetsHandler = (e)=> {
    var noOfPackets = e.target.value;
    if(parseInt(noOfPackets) >= 1)
    {
        var lespcks = parseInt(noOfPackets)-1;
        var bhanu=[{}];
        for(var n=0;n<lespcks;n++)
        {
            const item = {
                itemDesc: "",
                grossWt: "",
                dimensions: "",
                cargo_chargeable_weight:"",
            };
            this.setState({
                packetrows: [...bhanu, item]
            });
            var bhanu=[...bhanu, item];
        }
    }
    else
    {
        alert("No of packets should be greater than 0");
        $("#no_of_packets").val(1);
        const item = {
            itemDesc: "",
            grossWt: "",
            dimensions: "",
            cargo_chargeable_weight:"",
        };
        this.setState({
            packetrows: [item]
        });
    }
}

getApproxDistance = (access_token) => {
    var from_pincode = $("#from_pincode").val();
    var to_pincode = this.state.destination_pincode;
    if(from_pincode !=undefined && from_pincode !="" && to_pincode !=undefined && to_pincode !="")
    {
        var params = {
            "fromPincode" : from_pincode,
            "toPincode" : to_pincode,
        }
        redirectURL.post("/consignments/getApproxDistanceFromAPI",params).then((response)=>{   
            var resp = response.data.distanceDetails;
            console.log(resp,"resp")
            if(resp !="" && resp !=undefined)
            {
                $("#remaining_distance").val(resp.distance);
                this.setState({
                    remaining_distance : resp.distance
                });
                const stateNameFromAPI = resp.stateName;
                if(stateNameFromAPI !=null && stateNameFromAPI !=undefined)
                {
                    const selectElement = document.getElementById("state_of_consignor");
                    if (selectElement) {
                        for (let option of selectElement.options) {
                            if (option.text.toLowerCase().includes(stateNameFromAPI.toLowerCase())) {
                                selectElement.value = option.value;
                                break;
                            }
                        }
                    }
                }
            }
        }).catch((e)=>{
            console.log(e);
        })
    }
}
render(){
    const { consignment_status, mode_of_transport, vehicle_number, transit_type } = this.state;

    const headStyle = {
        marginBottom: "1.5%"
    }
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
            		<div className={"col-xl-12 col-lg-12 "+this.state.requestData}>
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} - {this.state.eway_bill_number}</span>
                                    <span className="layoutbtns pull-right">
										<a className="btn btn-danger" href="/tptlogin/ewaybilldata/1">
											Back
										</a>
                                    </span>
								</h5>
				   			</div>
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Reason For Extension* : </label>
                                            <select name="extend_validity_reason" placeholder="" className="form-control requestFields"  onChange={this.changeHandler} required>
                                                <option value="">Select Reason</option>
                                                <option value="Natural Calamity">Natural Calamity</option>
                                                <option value="Law and Order Situation">Law and Order Situation</option>
                                                <option value="Transhipment">Transhipment</option>
                                                <option value="Accident">Accident</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Remarks* : </label>
                                            <input type="text" name="extend_remarks" placeholder="" className="form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Eway Bill Valid Till : </label>
                                            <input type="text" name="eway_bill_valid_date" id="eway_bill_valid_date" placeholder="" className="form-control requestFields" readOnly/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Current Place* : </label>
                                            <input type="text" name="place_of_consignor" placeholder="" className="form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Current Pincode* : </label>
                                            <input type="text" name="from_pincode" id="from_pincode" placeholder="" className="form-control requestFields"  onChange={this.changeHandler} onBlur={this.getApproxDistance} required/>
                                            Destination Pincode : {this.state.destination_pincode}
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Current State <i class="fa fa-asterisk ewayBill-mandatory" style={{color:"red"}} aria-hidden="true"></i> : </label>
                                            <select name="state_of_consignor" id="state_of_consignor" placeholder="" className="form-control requestFields"  onChange={this.changeHandler} required>
                                                <option value="">Select State</option>
                                                <option value="35">ANDAMAN AND NICOBAR</option>
                                                <option value="28">ANDHRA PRADESH</option>
                                                <option value="12">ARUNACHAL PRADESH</option>
                                                <option value="18">ASSAM</option>
                                                <option value="10">BIHAR</option>
                                                <option value="4">CHANDIGARH</option>
                                                <option value="22">CHHATTISGARH</option>
                                                <option value="26">DADAR AND NAGAR HAVELI</option>
                                                <option value="25">DAMAN AND DIU</option>
                                                <option value="7">DELHI</option>
                                                <option value="30">GOA</option>
                                                <option value="24">GUJARAT</option>
                                                <option value="6">HARYANA</option>
                                                <option value="2">HIMACHAL PRADESH</option>
                                                <option value="1">JAMMU AND KASHMIR</option>
                                                <option value="20">JHARKHAND</option>
                                                <option value="29">KARNATAKA</option>
                                                <option value="32">KERALA</option>
                                                <option value="31">LAKSHADWEEP</option>
                                                <option value="23">MADHYA PRADESH</option>
                                                <option value="27">MAHARASHTRA</option>
                                                <option value="14">MANIPUR</option>
                                                <option value="17">MEGHALAYA</option>
                                                <option value="15">MIZORAM</option>
                                                <option value="13">NAGALAND</option>
                                                <option value="21">ORISSA</option>
                                                <option value="34">PONDICHERRY</option>
                                                <option value="3">PUNJAB</option>
                                                <option value="8">RAJASTHAN</option>
                                                <option value="11">SIKKIM</option>
                                                <option value="33">TAMIL NADU</option>
                                                <option value="36">TELANGANA</option>
                                                <option value="16">TRIPURA</option>
                                                <option value="9">UTTAR PRADESH</option>
                                                <option value="5">UTTARAKHAND</option>
                                                <option value="19">WEST BENGAL</option>
                                                <option value="97">OTHER TERRITORY</option>
                                                <option value="99">OTHER COUNTRY</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-3 "}>
                                            <label className="col-xl-12 col-lg-12">Approximate Distance (in KM) <i class="fa fa-asterisk ewayBill-mandatory" style={{color:"red"}} aria-hidden="true"></i> <i className="fa fa-question-circle" title="(Auto calculate PIN to PIN in KM)" aria-hidden="true"></i>: </label>
                                            <input type="text" name="remaining_distance" id="remaining_distance" placeholder="" className="form-control requestFields num-decimal-cls"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Consignment Is* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="consignment_status"  onChange={this.changeHandler}  required>
                                                <option value="">-- Select Option --</option>
                                                <option value="M">In Movement </option>
                                                <option value="T">In Transit</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        {/* <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Mode* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="transit_type"  onChange={this.changeHandler} required >
                                                <option value="">-- Select Mode --</option>
                                                <option value="road">Road</option>
                                                <option value="rail">Rail</option>
                                                <option value="air">Air</option>
                                                <option value="ship or ship cum road/rail">Ship or Ship Cum Road/Rail</option>
                                            </select>
                                        </div> */}
                                        {/* Mode Field - Only visible for 'M' */}
                                        {consignment_status === "M" && (
                                            <div className="col-xl-3">
                                                <label className="col-xl-12 col-lg-12">Mode* : </label>
                                                <select
                                                    className="form-control col-xl-12 col-lg-12"
                                                    name="mode_of_transport"
                                                    value={mode_of_transport}
                                                    onChange={this.changeHandler}
                                                    required
                                                >
                                                    <option value="">-- Select Mode --</option>
                                                    <option value="road">Road</option>
                                                    <option value="rail">Rail</option>
                                                    <option value="air">Air</option>
                                                    <option value="ship or ship cum road/rail">
                                                        Ship or Ship Cum Road/Rail
                                                    </option>
                                                </select>
                                            </div>
                                        )}
                                        {/* Transit Type Field - Only visible for 'T' */}
                                        {consignment_status === "T" && (
                                            <div className="col-xl-3">
                                                <label className="col-xl-12 col-lg-12">Transit Type* : </label>
                                                <select
                                                    className="form-control col-xl-12 col-lg-12"
                                                    name="transit_type"
                                                    value={transit_type}
                                                    onChange={this.changeHandler}
                                                    required
                                                >
                                                    <option value="">-- Select Transit Type --</option>
                                                    <option value="Road">On Road</option>
                                                    <option value="Warehouse">Warehouse</option>
                                                    <option value="others">Others</option>
                                                </select>
                                            </div>
                                        )}
                                        {/* <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Transit Type* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="transit_type"  onChange={this.changeHandler} required >
                                                <option value="">-- Select Transit Type --</option>
                                                <option value="Road">On Road</option>
                                                <option value="Warehouse">Warehouse</option>
                                                <option value="others">Others</option>
                                            </select>
                                        </div> */}
                                        {/* Vehicle No Field - Only visible for 'M' */}
                                        {consignment_status === "M" && (
                                            <div className="col-xl-3">
                                                <label className="col-xl-12 col-lg-12">Vehicle No* : </label>
                                                <input
                                                    type="text"
                                                    name="vehicle_number"
                                                    className="form-control requestFields"
                                                    value={vehicle_number}
                                                    onChange={this.changeHandler}
                                                    required
                                                />
                                            </div>
                                        )}
                                        {/* <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Vehicle No* : </label>
                                            <input type="text" name="vehicle_number" id="vehicle_number" className="form-control requestFields" onChange={this.changeHandler} required/>
                                        </div> */}
                                        {/* Address Fields - Visible for "M" */}
                                        {this.state.showAddress && (
                                            <>
                                                <div className="col-xl-3">
                                                    <label className="col-xl-12 col-lg-12">Address Line 1* : </label>
                                                    <input
                                                        type="text"
                                                        id="address_line1"
                                                        name="address_line1"
                                                        className="form-control"
                                                        value={this.state.address_line1}
                                                        onChange={this.changeHandler}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-xl-3">
                                                    <label className="col-xl-12 col-lg-12">Address Line 2* : </label>
                                                    <input
                                                        type="text"
                                                        id="address_line2"
                                                        name="address_line2"
                                                        className="form-control"
                                                        value={this.state.address_line2}
                                                        onChange={this.changeHandler}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-xl-3">
                                                    <label className="col-xl-12 col-lg-12">Address Line 3* : </label>
                                                    <input
                                                        type="text"
                                                        id="address_line3"
                                                        name="address_line3"
                                                        className="form-control"
                                                        value={this.state.address_line3}
                                                        onChange={this.changeHandler}
                                                        required
                                                    />
                                                </div>
                                            </>
                                        )}
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12" id="trn_doc_no">Transporter Doc. No : </label>
                                            <input type="text" name="transporter_document_number" id="transporter_document_number" className="form-control requestFields"  onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Date : </label>
                                            <input type="text" name="transporter_document_date" id="transporter_document_date" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>           
            </div>
        )
    }
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
        yearStart: 2017,
        format:'d/m/Y',
        maxDate:true,
		timepicker:false,
        prevButton:false,
        nextButton:false,
    });
    
    $('.cargo_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d/m/Y',
        maxDate:true,
		timepicker:false,
	});

    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    script.crossOrigin = "anonymous"
	index.parentNode.insertBefore(script,index)
}

// $('body').on('focus',".datetimepicker_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false
// 	});
// });

// $('body').on('focus',".cargo_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false,

// 	});
// });

$(document).on("input", ".num-decimal-cls", function(evt) {
    var self = $(this);
    self.val(self.val().replace(/[^0-9\.]/g, ''));
    if ((evt.which != 46 || self.val().indexOf('.') != -1) && (evt.which < 48 || evt.which > 57)) 
    {
        evt.preventDefault();
    }
});
$(document).on("input", ".gst", function(evt) {
    if($(this).val() !="" && $(this).val().length == 15)
    {
        var statecode = $(this).val().substring(0, 2);
        var pancarno = $(this).val().substring(2, 12);
        var entityNumber = $(this).val().substring(12, 13);
        var defaultZvalue = $(this).val().substring(13, 14);
        var checksumdigit = $(this).val().substring(14, 15);
        if ($(this).val().length != 15) {
            alert('GST Number is invalid');
            $(this).focus();
            return false;
        }
        if (pancarno.length != 10) {
            alert('GST number is invalid ');
            $(this).focus();
            return false;
        }
        if (defaultZvalue !== 'Z') {
            alert('GST Number is invalid Z not in Entered Gst Number');
            $(this).focus();
        }

        if ($.isNumeric(statecode)) {
            $('#gst_state_code').val(statecode).trigger('change');
        } else {
            alert('Please Enter Valid State Code');
            $(this).focus();
        }

        if ($.isNumeric(checksumdigit)) {
            return true;
        } else {
            alert('GST number is invalid last character must be digit');
            $(this).focus();
        }
    }
});