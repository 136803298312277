/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";


import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import RouteAnimate from '../tptlogin/routeAnimate';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Consignmentforceclose from '../manage/consignmentforceclose';
//import Grid from '../layouts/gridnosidebarComponent';
import DateFormater from '../layouts/dateComponent';
import DrawMap from '../common/drawmap';
import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYYHHMM,
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault,
	getDDMMYYYYHHMM,
	getHyphenDDMMMYYYY
} from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CSVFileValidator from 'csv-file-validator';
$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class TPTTrips extends Component {

	constructor(props) {
		super(props);
		this.displayData = [];
		this.state = {
			deptcode: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			eventAction: null,
			forceCloseRowNode: "",
			allRowData: [],
			activeFilter: null,
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			sliderCommentTranslate: "",
			tabsliderTranslate: '',
			timelineinfo: '',
			consignment_code: '',
			sliderSegmentTranslate: "",
			mapinfo: '',
			commentsRowData: [],
			dealer: '',
			maptruckno: '',
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			overly: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			basicTitle: '',
			rownode: '',
			leg_no: '',
			basicType: "default",
			modules: AllModules,
			filterstatess: {},
			columnsdatas: {},
			rowgroupdcols: [],
			valuecolumns: [],
			activeconsignemnt: [],
			activetrucks: [],
			transitdelays: [],
			gpsdatana: [],
			overspeedtrucks: [],
			nightdrive: [],
			open: false,
			openforceclose: false,
			pivotmodeis: false,
			pivotcols: [],
			defTransitCoords: '',
			routeTruck: {},
			sidebarSubHeader: '',
			googelRoutes: '',
			plants: [],
			consgmntdata: [],
			transitdata: [],
			gpsntavadata: [],
			overspeed: [],
			nightdriving: [],
			loadingdelay: [],
			stoppages: [],
			critical: [],
			probaccdnt: [],
			counters_data: [],
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				pivot: true,
				enableValue: true,
				enableRowGroup: true,
			},
			rowData: [],
			gridOptions: {
				context: {
					componentParent: this,
				}
			},
			maprowData: null,
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			triptype: { "value": 'all', "label": 'All' },
			tripvalue: '',
			consigner: [{ "value": 'all', "label": 'All' }],
			originalData: [],
			isCounterDisplay: 1,
			screenpage: '',
			pagetitle: 'Active User Login Details',
			countersjson: '',
			originalcountersjson: '',
			consigneecoords: '',
			consigner_coordinates: '',
			containerslist: [],
			forceclosedata: '',
			reached_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			reachedhh: '',
			reachedmm: '',
			reachedss: '00',
			left_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			lefthh: '',
			leftmm: '',
			leftss: '00',
			invoice_time: '',
			invoicehh: '',
			invoicemm: '',
			segementConsignmentCode: "",
			counter_screen: "",
			invoicess: '00',
			reasonforceclose: '',
			sliderTranslatesidebar: '',
			consignment_details: '',
			startDatetime: '',
			endDatetime: '',
			tolls: [],
			excelStyles: [
				{
					id: "dateFormat",
					dataType: 'dateTime',
					numberFormat: { format: "yyyy-mm-dd" }
				}
			],
			getRowHeight: function (params) {
				if (typeof params.data.dct_comment != 'undefined') {
					//console.log("pars ", params.data.dct_comment.length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.dct_comment.length / 50))
					);
				}
				if (typeof params.data.vin_list != 'undefined') {
					//console.log("pars ", params.data.vin_list.toString().length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.vin_list.toString().length / 50))
					);
				}
				else if (typeof params.data.force_closure_reason != 'undefined') {
					//console.log("pars ", params.data.dct_comment.length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.force_closure_reason.length / 50))
					);
				}
				else {
					return 30;
				}
			},
			defaultsdate: '',
			defaultedate: '',
			movementtype: { "value": "all", "label": "All" },
			usergridstate: [],
			screenurl: "",
			screentitle: "",
			showumncr: 0,
			showummove: 0,
			usermanualncrmodal: false,
			usermanualmovemodal: false,
			slideuploadeway: "",
			cancelled_data: [],
			pod_received_data: [],
			trip_closed_by_pod: [],
			transporterTrucks: [],
			tpttrucks: [],
			adaniCheck: 0,
			addressColumn: 300,
			pdfRowData: [],
			pdfColumns: [],
			msilCounters: {
				"transit_delay": [],
				"nightdriving_exception": [],
				"no_gps_data": [],
				"overspeeding_exception": [],
				"loading_delay_exception": [],
				"enroute_exception": [],
				"is_critical": [],
				"probable_accident": []
			},
			oems: [],
			oem: { "value": "", "label": "OEM" },
			dealers: [],
			plantlocations: [],
			dealer: { "value": "", label: "Dealer" },
			city: { "value": "", label: "City" },
			transittype: { "value": "Road", "label": "Road" },
			transit_distance: "",
			transit_time: "",
			truck_no: "",
			trip_no: "",
			vin_no: "",
			invoice_date: "",
			gate_in_time: "",
			gate_out_time: "",
			selected_eway_bill_date: "",
			quantity: "",
			fleet_amount: "",
			distance_in_km: "",
			expected_eta: "",
			drivers: [],
			driver: { "value": "", "label": "Driver" },
			sliderRso: "",
			sliderUpdate: "",
			tcode: "",
			cities: [],
			filteredDataTrip: [],
			alltrucks: [],
			showTrucksListTrip: "show-n",
			localtransporter: [],
			selectedRowNode: "",
			sliderForceCloseTranslate: "",
			csvcontent: [],
			't329Check': 0,
			sliderBulkForceClose: '',
			csvcontentBulk: '',
			file1: '',
			selectedService: [],
			servicelocationvalues: [],
			servicelocation: [],
			tickets_no: [],
			expected_date_of_service: [],
			transporters_name: [],
			ticket_remarks: [],
			service_remarks: [],
			service_location_list: [],
			transporter_list: [],
			selectedStatus: [],
			update_remarks: [],
			expected_closure_date: [],
			mobile_number: [],
			startDate: moment.parseZone(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
			endDate: moment.parseZone(new Date()).format("YYYY-MM-DD"),
			transporter_code: "T346",
		}
		this.gridApi = null;
		this.gridColumnApi = null;

	}

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	componentDidMount() {
		loadDateTimeScript()
		this.onLoadPageData();
	}



	onLoadPageData = async () => {
		const { transporter_code } = this.state
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m'
		})
		var params = {
			transporter_code:transporter_code
		}
		await redirectURL.post("/dashboard/tptLogsData", params).then(async (response) => {
			var records = response.data.records;
			this.setState({
				rowData: records,
				loadshow: 'show-n',
				overly: 'show-n'
			});

		})
	}

	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	  };
	

	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);
	}

	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderForIVCLogs: "",
		});

	}

	onRowSelection(event) {
		var containerslist = [];
		var rwCount = event.api.getSelectedNodes();
		var rowCount = event.data

		rwCount.map((item) => {
			containerslist.push(item.data.truck_no)
		});

		this.setState({
			containerslist: containerslist
		})

	}

	changeHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	onCellClicked(e) {
		if (e.colDef.field == "logout") {
			var params = { unique_id: (e.data._id).toString(), user: localStorage.getItem("email") }
			if (window.confirm("Are you sure! Do you want to force logout")) {
				this.setState({
					loadshow: "show-m",
					overly: "show-m"
				})
				redirectURL.post("/dashboard/mobileloginforcelogout", params).then((response) => {
					if (response.data.message == 'success') {
						this.setState({
							show: true,
							basicTitle: "Force Logged Out Successfully",
							basicType: "success",
							loadshow: "show-n",
							overly: "show-n"
						});
					}
					else {
						this.setState({
							show: true,
							basicTitle: "Failed To Force Logout",
							basicType: "danger",
							loadshow: "show-n",
							overly: "show-n"
						});
					}
					window.location.reload();

				}).catch(function (error) {
					console.log(error);
				})
			}
		}
	}

	onSubmitTransporterData =(event) => {
		event.preventDefault()
		console.log("check_entered_or_not")
		const { transporter_code } = this.state
		if (["", undefined, null].includes(transporter_code)) {
			this.setState({
				show: true,
				basicType: "danger",
				basicTitle: "Enter Transporter Code"
			})
			return;
		}
		this.onLoadPageData();
	}
	  

	render() {
		var columnDefs = [
			{
				headerName: "Email",
				field: "logged_in_email",
				width: 220,
				filter: false,
				resizable: true,
				cellStyle: function (params) {					
					if (params.data.isTopEmail === 1) {
						return { backgroundColor: "yellow", fontWeight: 'bold' };
					}
					return null; 
				},
				valueGetter: function (params) {
					return params.data.logged_in_email;
				}
			},
			{
				headerName: "Mobile Type",
				field: "mobile_type",
				resizable: true,
				width: 120,
				filter: false,
			},
			{
				headerName: "Status",
				field: "is_active",
				width: 120,
				// pinned : "left",
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter: function (params) {
					if (params.data.is_active == 1) {
						return "Active"
					}
					else {
						return "Not Active"
					}
				}
			},
			{
				headerName: "Login Time",
				field: "created_datetime",
				width: 150,
				editable: false,
				filter: "agSetColumnFilter", resizable: true,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.created_datetime)
				}
			},
			// {
			// 	headerName: "Logout Time",
			// 	field: "logout_datetime",
			// 	width: 150,
			// 	editable: false,
			// 	filter: "agSetColumnFilter", resizable: true,
			// 	valueGetter: function (params) {
			// 		return getHyphenDDMMMYYYYHHMM(params.data.logout_datetime)
			// 	}
			// },
			{
				headerName: "",
				field: "logout",
				pinned: 'left',
				resizable: true,
				width: 160,
				filter: false,
				cellRenderer: function (params) {
					var htmloption = '<button style="padding:2% 10% 5% 10%" class="btn btn-warning" title="Force Logout">Force Logout</button>'
					return htmloption;
				},
				editable: false
			},
		];

		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}

				<div className="row">
					<div className='col-xl-12 col-md-12'>
						<div>
							<div className="row">
								<div className="col-2">
									<input
										type="text"
										placeholder="Transporter Code"
										className="form-control"
										value={this.state.transporter_code}
										onChange={(e) => this.setState({ transporter_code: e.target.value })}
									/>
								</div>
								<div className="form-group"   >
									<button className="btn btn-success" type="submit" onClick={this.onSubmitTransporterData.bind(this)}>Submit</button>
								</div>

							</div>
						</div>
					</div>
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
								</h5>
							</div>
							<div className="card-body pt-10px" >
								<div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={false}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										stopEditingWhenGridLosesFocus={true}
										enableCellChangeFlash={false}
										rowClassRules={this.state.rowClassRules}
										gridOptions={this.state.gridOptions}
										masterDetail={true}
										rowSelection={this.state.rowSelection}
										onRowSelected={this.onRowSelection.bind(this)}
										suppressRowClickSelection={true}
										overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
										excelStyles={this.state.excelStyles}
										onCellClicked={this.onCellClicked.bind(this)}
									/>

								</div>
							</div>
						</div>
					</div>

				</div>

				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
			</div>

		);
	}
}

$(document).ready(function () {
	$(document).on("click", ".tptdropdown-truck-no", function () {
		var vals = $(this).text();
		$("#inputTruckticket").val(vals);
		$(".trucks-dropdown").removeClass("show-m");
		$(".trucks-dropdown").addClass("show-n");
	});

	$("body").on("click", function (e) {
		var container = $("#inputTruckticket");
		if (!container.is(e.target)) {
			try {
				if ($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown") {
					$(".trucks-dropdown").removeClass("show-m");
					$(".trucks-dropdown").addClass("show-n");
				}
			} catch (e) { }

		}


	})
})

function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
function timeConvert(n) {
	// var num = n/1000;
	// var hours = (num / (3600*24));
	// var rhours = Math.floor(hours);
	// var minutes = (n) / (60*60);
	// var rminutes = Math.round(minutes);

	var diffMs = n;
	var diffDays = Math.floor(diffMs / 86400000); // days
	var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


	return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
	var timeinfo = new window.google.maps.InfoWindow({
		content: "Time at this location: " + timedet
	});

	marker.addListener('click', function () {

		if (currentinfowindow != null) {
			currentinfowindow.close();
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}
		else {
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}

	});
}

function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}
function CheckForHtmlTag() {
	var rs = document.getElementById("invalid_reason");
	var reg = /<(.|\n)*?>/g;
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";
		rs.value = ''
		//alert();
		return 1;
	}
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}

function loadDateTimeScript() {
	$('.datetimepicker_mask').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i',
		onShow: false
	});
	$('.datetimepicker_date').datetimepicker({
		mask: '39-19-9999',
		format: 'd-m-Y',
		timepicker: false,
		onShow: false
	});
	var index = window.document.getElementsByTagName("script")[1]
	var script = window.document.createElement("script")
	script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
	return function (a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		} else if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	}
}


function GetSortAscOrder(prop) {
	return function (a, b) {
		if (a[prop] > b[prop]) {
			return 1;
		} else if (a[prop] < b[prop]) {
			return -1;
		}
		return 0;
	}
}

function secondsToHM(seconds) {
	seconds = Number(seconds);
	// var d = Math.floor(seconds / (3600*24));
	// var h = Math.floor(seconds % (3600*24) / 3600);
	// var m = Math.floor(seconds % 3600 / 60);
	// var s = Math.floor(seconds % 60);

	// var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
	// var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
	// var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
	// var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
	// return dDisplay + hDisplay + mDisplay;

	var hh = Math.floor((seconds / 3600));
	var hrr = Math.floor(seconds - (hh * 3600));
	var mm = Math.floor(hrr / 60)
	if (mm < 10) {
		var mins = "0" + mm;
	}
	else {
		var mins = mm;
	}

	if (hh < 10) {
		var hrs = "0" + hh;
	}
	else {
		var hrs = hh;
	}
	return hrs + ":" + mins;
}